(function(){
    'use strict';

    angular.module('pixie.dashboard').controller('SearchController', ['$rootScope', '$scope', '$http', '$stateParams', '$state', function($rootScope, $scope, $http, $stateParams, $state) {

        getSearchResults($stateParams.query);

        //on photo or folder deletion remove it from $scope items array
        var unbind = $rootScope.$on('activity.happened', function(e, action, type, items) {
            if (action !== 'deleted') {
                return;
            }

            $scope.items[$state.current.url] = $scope.items[$state.current.url].filter(function(item) {
                return items.indexOf(item) === -1;
            });
        });

        //cleanup
        $scope.$on('$destroy', function() { unbind(); });

        function getSearchResults(query) {
            return $http.get('search/'+query+'?full=true').then(function(response) {
                $scope.items[$state.current.url] = response.data;
            }).finally(function() {
                $rootScope.ajaxProgress.files = false;
            });
        }
    }]);
}());