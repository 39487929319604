(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('itemLock', [ 'dialogManager', function(dialogManager) {
        return {
            restrict: 'E',
            template: '<md-button ng-if="item.is_protected" class="md-icon-button" ng-click="toggleLock()">'+
                        '<md-icon md-font-icon>{{ item.is_locked ? \'lock\' : \'lock_open\' }}</md-icon>'+
                      '</md-button>',
            scope: {
                item : '='
            },
            link: function($scope, el) {
                $scope.toggleLock = function() {
                    if($scope.item.is_locked) {
                        dialogManager.showUnlockDialog($scope.item);
                    } else {
                        dialogManager.showLockDialog($scope.item);
                    }
                };
            }
        };
    }]);
})();