(function () {
    'use strict';

    // should be a directive; scope pollution.
    angular.module('app')
    .controller('RegisterController', ['$rootScope', '$scope', '$state', '$stateParams', 'users', '$http', 'utils', 'dialogManager', 'api', '$translate', function($rootScope, $scope, $state, $stateParams, users, $http, utils, dialogManager, api, $translate) {
        $scope.emailFormat = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        $scope.credentials = {};

        if($stateParams.share_link) {
          $http.get($rootScope.baseUrl + 'shareable/check-link?link='+$stateParams.share_link).then(function(response) {
              $scope.share_owner = response.data.ownerName;
          }, function(error){
              // clear off the share_link
              $state.go('register', { share_link: null  });
          });
        }

        function goToDashboard() {
            if ($stateParams.family_invite_link) {
                $state.go('dashboard.hives', { family_invite_link: $stateParams.family_invite_link });
            } else if ($stateParams.share_link) {
                $state.go('dashboard.folders', { share_link: $stateParams.share_link });
            } else if ($stateParams.room_link) {
              $state.go('dashboard.rooms', { roomId: $stateParams.room_link });
            } else {
                $state.go('dashboard.story', { share_link: $stateParams.share_link, family_invite_link: $stateParams.family_invite_link });
            }
        }

        $scope.showLoginForm = function($event) {
          $event.preventDefault();
          $state.go('login', { share_link: $stateParams.share_link, family_invite_link: $stateParams.family_invite_link, room_link: $stateParams.room_link });
        };

        $scope.submit = function() {
          $scope.loading = true;
          if ($scope.credentials.is_nonprofit || $scope.credentials.is_org) {
            api.account.checkRegistration($scope.credentials).then(function() {
              var text = $scope.credentials.is_nonprofit ? utils.getSetting('introContentMode_6') : utils.getSetting('introContentMode_7');
              text = text || '<p>Content to be added soon</p>';
              utils.confirm({
                  title: $scope.credentials.is_nonprofit ? 'register.disclaimerTitle' : 'register.disclaimerOrgTitle',
                  subcontentHTML: text,
                  ok: 'register.disclaimerAccept',
                  cancel: 'register.disclaimerDecline',
                  onConfirm: function() {
                      $scope.loading = true;
                      if ($scope.credentials.is_nonprofit) {
                        dialogManager.showOrganizationMemberDialog().then(function(result) {
                          register(result);
                        }, function() {
                          // handle error case
                          $scope.loading = false;
                        });
                      }

                      if ($scope.credentials.is_org) {
                        dialogManager.showOrganizationJoinDialog().then(function(result) {
                          register(result);
                        }, function() {
                          // handle error case
                          $scope.loading = false;
                        });
                      }
                  }
              });
              $scope.loading = false;
            }, function(response) {
              $scope.errors = response.data;
              $scope.loading = false;
            });
          } else {
            register();
          }
        };

        function register(result) {
          result = result || null;
          $scope.loading = true;
          return users.register($scope.credentials).then(function() {
            if (result) {
              if ('code' in result) {
                api.organization.approveCouponCode(result).then(function(response) {
                  utils.showToast(utils.trans(response, true));

                  if ($scope.credentials.is_nonprofit) {
                    $rootScope.$broadcast('user.joined.organization');
                    goToDashboard();
                  } else {
                    api.me.organization().then(function(response) {
                      if (response) {
                        users.current.isOrgOwner = true;
                        users.current.organization_id = response;
                      }
                      $state.go('dashboard.story', {manage_orgs: 1});
                    }, function(response) {
                      utils.showToast(utils.trans(response, true));
                    });
                  }
                }, function(response) {
                  $scope.data.error = $translate.instant(response.data);
                }).finally(function() {
                  $scope.loading = false;
                });
              } else {
                api.organization.addOrganizationMember(result).then(function(response) {
                  utils.showToast(utils.trans(response, true));
                  users.updateJoinRequests(function() {
                    goToDashboard();
                  });
                }, function(response) {
                  utils.showToast(utils.trans(response.data, true));
                }).finally(function() {
                  $scope.loading = false;
                });
              }
            } else {
              goToDashboard();
            }
          }, function(response) {
            $scope.errors = response.data;
          }).finally(function() {
            $scope.loading = false;
          });
        }

        $scope.showInfo = function() {
          var text = utils.getSetting('introContentMode_4');
          text = text || '<p>Content to be added soon</p>';
          utils.confirm({
            'title': 'register.nonProfitInfo',
            'subcontentHTML': text,
            'ok': 'okay',
            'type': 'alert'
          });
        };

        $scope.showOrgInfo = function() {
          var text = utils.getSetting('introContentMode_5');
          text = text || '<p>Content to be added soon</p>';
          utils.confirm({
            'title': 'register.nonProfitOrgInfo',
            'subcontentHTML': text,
            'ok': 'okay',
            'type': 'alert'
          });
        };

        // check to see if the user is already logged in; if they are, redirect them (this also uses the remember token)
        users.loggedIn().then(function success(data) {
          goToDashboard();
        }, function failure(data) {
            // nothing
        });

    }]);
}());
