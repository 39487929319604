(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminFaq', ['utils', '$rootScope', 'dialogManager', 'api', function(utils, $rootScope, dialogManager, api) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/faq.html',
            scope: {},
            link: function($scope, $element, $attr) {
                $scope.data = {
                    items: [],
                    selected: [],
                    loading: false,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    }
                };

                $scope.toggleAllItems = function() {
                    $scope.data.selected = $scope.data.items;
                };

                $scope.toggleSelect = function(item) {
                    var index = $scope.data.selected.indexOf(item);
                    if(index==-1) {
                        $scope.data.selected.push(item);
                    } else {
                        // remove it.
                        $scope.data.selected.splice(index, 1);
                    }
                };

                $scope.isItemSelected = function(item) {
                    return ($scope.data.selected.indexOf(item) !== -1);
                };

                $scope.showEditItemModal = function($event, item) {
                    $event.preventDefault();

                    dialogManager.showEditFaqDialog($event, item).then(function(data) {
                        var item;
                        var is_new = false;
                        if(data.item) {
                            item = data.item;
                            is_new = false;
                        } else {
                            item = new api.resources.faq();
                        }

                        // update the fields.
                        item.question = data.question;
                        item.answer = data.answer;
                        item.image_url = data.image_url;
                        item.video_id = data.video_id;

                        if(data.item) {
                            // update.
                            item.$update();
                        } else {
                            // new save
                            item.$save().then(function(data) {
                                $scope.data.items.push(data);
                            });
                        }
                    }, function() {
                        // handled failed case
                    });
                };

                $scope.deleteItems = function(items) {
                    // remove them all.
                    var aux = [];
                    items.forEach(function(item) {
                        aux.push(item);
                    });

                    angular.forEach(aux, function(item) {
                        var index;
                        // remove from selected
                        index = $scope.data.selected.indexOf(item);
                        if(index != -1) {
                            $scope.data.selected.splice(index, 1);
                        }

                        // remove from normal
                        index = $scope.data.items.indexOf(item);
                        if(index != -1) {
                            $scope.data.items.splice(index, 1);
                        }

                        // delete.
                        item.$delete(); 
                    });
                };

                $scope.data.items = api.resources.faq.query();
            }
       	};
    }]);
}());