(function () {
    'use strict';

    angular.module('pixie.dashboard')

        /**
         * This is Files Dashboard section navbar (2 of them)
         */
        .directive('filesNavBar', ['$state', function ($state) {
            return {
                restrict: 'E',
                templateUrl: 'filesNavbar.html',
                scope: {},
                link: function ($scope) {
                }
            };
        }])
        .directive('filesPrimaryNavbar', ['selectedItems', '$http', 'utils', '$state', 'folders', 'files', 'fileTypes', 'uploadManager', '$rootScope', '$mdSidenav', function (selectedItems, $http, utils, $state, folders, files, fileTypes, uploadManager, $rootScope, $mdSidenav) {
            return {
                restrict: 'E',
                templateUrl: 'filesPrimaryNavbar.html',
                scope: {
                    leftPanel: "@"
                },
                link: function ($scope) {
                    $scope.data = {
                        selectedItem: false,
                        files: files,
                        searchResults: [],
                        searchText: undefined,
                        time: new Date().getTime().toString(),
                        showFromLeftPanel: true,
                        buttons: {
                            upload: true,
                            new_folder: true,
                            new_document: true,
                            search: true
                        },
                        isRoomPage: false,
                        centerLogo: false,
                    };

                    function isMobileDevice() {
                        var isMobile = (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());
                        return isMobile;
                    }

                    function updateButtonVisibility() {
                        var displayButtons = (["dashboard.folders"].indexOf($state.current.name) > -1) ? true : false;
                        if (displayButtons) {
                            if ((folders.selected.permission === undefined || folders.selected.permission === 1) && !folders.selected.is_offer && !folders.selected.is_media_folder && (!folders.selected.is_parent_template || (folders.selected.is_parent_template && folders.selected.owned_by_current_user))) {
                                displayButtons = true;
                            } else {
                                displayButtons = false;
                            }
                        }

                        $scope.data.buttons.upload = displayButtons;
                        $scope.data.buttons.new_folder = displayButtons;
                        $scope.data.buttons.new_document = displayButtons;
                        $scope.data.buttons.search = displayButtons;

                        if ($scope.leftPanel == 'yes') {
                            $scope.data.showFromLeftPanel = displayButtons;
                            $scope.data.buttons.facebook = true;
                        } else {
                            $scope.data.showFromLeftPanel = true;
                        }
                    }

                    // initial one.
                    updateButtonVisibility();

                    // on a seletion changed.
                    $scope.$on('folders.selected.changed', function (e, data) {
                        updateButtonVisibility();
                    });

                    $scope.$on('$stateChangeSuccess', function (e, toState, params) {
                        updateButtonVisibility();
                        checkIsRoomPage(toState.name);
                    });

                    checkIsRoomPage($state.current.name);

                    function checkIsRoomPage(stateName) {
                        if (stateName == 'dashboard.rooms') {
                            $scope.data.isRoomPage = true;
                        } else {
                            $scope.data.isRoomPage = false;
                        }
                    }

                    $scope.toggleLeftMenu = function () {
                        $mdSidenav('left').open();
                    };

                    $scope.upload = function (files) {
                        uploadManager.upload(files);
                    };

                    $scope.getSearchResults = function (query) {
                        $scope.data.time = new Date().getTime().toString();
                        return $http.get('search/' + query).then(function (response) {
                            $scope.data.searchResults = response.data;
                            return response.data;
                        });
                    };

                    $scope.goToSearchPage = function () {
                        if (!$scope.data.searchText) {
                            return;
                        }

                        utils.toState('dashboard.search', { query: $scope.data.searchText });
                        $scope.data.searchResults = [];
                        $scope.data.searchText = undefined;
                    };

                    $scope.newFolder = function ($event) {
                        folders.openNewModal($event);
                    };

                    $scope.newDocument = function ($event) {
                        files.openNewDocwModal($event, folders.selected.id);
                    };

                    $scope.getMimeIcon = function (mime) {
                        return fileTypes.getMimeIcon(mime);
                    };

                    $scope.getIsThumbnail = function (item) {
                        return item.flags & 0x02 ? true : false;
                    };

                    $scope.getThumbnail = function (item) {
                        return $rootScope.baseUrl + 'user-file/' + item.share_id + '?thumb=1&t=' + $scope.data.time;
                    };

                    $scope.selectItem = function () {
                        if (!$scope.data.selectedItem) {
                            return;
                        }

                        selectedItems.set($scope.data.selectedItem, $scope.data.selectedItem.type, true);
                        selectedItems.preview();

                        // previewStatus.open = true;
                        $scope.data.searchText = '';
                        $scope.data.selectedItem = false;
                    };
                }
            };
        }])
        .directive('filesSecondaryNavbar', ['folders', 'selectedItems', 'items', 'localStorage', '$rootScope', '$mdSidenav', '$state', 'utils', function (folders, selectedItems, items, localStorage, $rootScope, $mdSidenav, $state, utils) {
            return {
                restrict: 'E',
                templateUrl: 'filesSecondaryNavbar.html',
                scope: {},
                link: function ($scope) {
                    $scope.data = {
                        displayFolderOptions: true,
                        sortOrder: items.sortOrder(),
                        selectedView: localStorage.get('selectedView', 'grid'),
                        isFamilyOpenAndVisible: false, // FIXME DEFAULT
                        isActivityOpenAndVisible: false, // FIXME DEFAULT
                        selected: 'story',
                        showFolderPath: false,
                        isRoomPage: false,
                        // breadcrumbs: false,
                    };

                    function updateFolderButtonVisibility() {
                        $scope.data.displayFolderOptions = (["dashboard.folders", "dashboard.trash", "dashboard.recent", "dashboard.favorites", "dashboard.search"].indexOf($state.current.name) > -1) ? true : false;
                    }

                    function updateSelectedButton(stateName) {
                        $scope.data.showFolderPath = false;
                        switch (stateName) {
                            case 'dashboard.folders':
                            case 'dashboard.recent':
                            case 'dashboard.trash':
                            case 'dashboard.favorites':
                                $scope.data.selected = 'storage';
                                $scope.data.name = utils.trans('myStorage', true);
                                $scope.data.showFolderPath = true;
                                break;
                            case 'dashboard.story':
                            case 'dashboard.story.view':
                            case 'dashboard.story.current':
                                $scope.data.selected = 'story';
                                $scope.data.name = utils.trans('myStory', true);
                                break;
                            case 'dashboard.hives':
                            case 'dashboard.hives.details':
                            case 'dashboard.hives.details.member':
                            case 'dashboard.hives.details.member.files':
                            case 'dashboard.hives.details.member.stories':
                            case 'dashboard.hives.details.member.post':
                                $scope.data.selected = 'family';
                                $scope.data.name = utils.trans('myFamily', true);
                                break;
                            case 'dashboard.buzz':
                            case 'dashboard.buzz.timeline':
                            case 'dashboard.buzz.timeline.date':
                            case 'dashboard.buzz.chat':
                            case 'dashboard.buzz.video':
                                $scope.data.selected = 'buzz';
                                $scope.data.name = utils.trans('buzz.theBuzz', true);
                                break;
                            case 'dashboard.timeline':
                                $scope.data.selected = 'timeline';
                                $scope.data.name = utils.trans('post.myTimeline', true);
                                break;
                            case 'dashboard.help':
                                $scope.data.selected = 'help';
                                $scope.data.name = utils.trans('help', true);
                                break;
                            default:
                                $scope.data.selected = '';
                                $scope.data.name = '';
                                break;
                        }

                        if (stateName == 'dashboard.rooms') {
                            $scope.data.isRoomPage = true;
                        } else {
                            $scope.data.isRoomPage = false;
                        }
                    }

                    updateSelectedButton($state.current.name);
                    updateFolderButtonVisibility();

                    $scope.getfolderBreadcrumbs = function () {
                        if ($scope.data.displayFolderOptions) {
                            return folders.breadcrumbs;
                        } else {
                            return false;
                        }
                    };

                    // keep updating on state change
                    $scope.$on('$stateChangeSuccess', function (e, toState, params) {
                        updateFolderButtonVisibility();
                        updateSelectedButton(toState.name);
                    });

                    $scope.openFolder = function (shareId) {
                        folders.open(shareId);
                    };

                    $scope.showSettings = function () {
                        if ($state.current.name !== "dashboard.folders") {
                            $state.go('dashboard.folders');
                        }
                        // $scope.openLeftPanel();
                    };

                    $scope.openLeftPanel = function () {
                        $mdSidenav('left').open();
                    };

                    $scope.openMyHive = function () {
                        $state.go('dashboard.hives');
                        $rootScope.$broadcast('hives.clicked');
                        // $scope.openLeftPanel();
                    };

                    $scope.openBuzz = function () {
                        $state.go('dashboard.buzz');
                        $rootScope.$broadcast('buzz.tab.switched', -1);
                        $rootScope.$broadcast('left.buzzOption.clicked', -1);
                        // $scope.openLeftPanel();
                    };

                    // this is hackish, but a direct bug fix; the contextmenu should be angularized.
                    $scope.hideContext = function () {
                        $rootScope.$broadcast('contextmenu.closed');
                    };

                    $scope.isFolderSelected = function (shareId) {
                        return (folders.selected.shareId === shareId);
                    };

                    $scope.itemsSelected = function () {
                        return selectedItems.getLength() ? true : false;
                    };

                    $scope.isItemOffer = function () {
                        // FIXME: Multiple selected items fix
                        return selectedItems.first('is_offer') ? true : false;
                    };

                    $scope.isItemMediaFolder = function () {
                        // FIXME: Multiple selected items fix
                        return selectedItems.first('is_media_folder') ? true : false;
                    };

                    $scope.templateItems = function () {
                        return selectedItems.first('is_parent_template') ? true : false;
                    };

                    $scope.isActionMenuDisabled = function () {
                        return ((!$scope.itemsSelected()) || $scope.isItemOffer() || $scope.isItemMediaFolder() || $scope.templateItems()) ? true : false;
                    };

                    $scope.changeSortOrder = function (newOrder) {
                        $scope.data.sortOrder = newOrder;
                        items.changeSortOrder(newOrder);
                    };

                    $scope.toggleSelectedView = function () {
                        if ($scope.data.selectedView === 'list') {
                            $scope.data.selectedView = 'grid';
                        } else {
                            $scope.data.selectedView = 'list';
                        }

                        localStorage.set('selectedView', $scope.data.selectedView);
                        $rootScope.$broadcast('items.view.changed', { view: $scope.data.selectedView });
                    };
                    $scope.$on('hives.member.tab.switched', function (evt, tabOption) {
                        $scope.data.selected = 'family';
                    });
                }
            };
        }]);
})();


// Top Navbar:
//   upload($files) (ng-file-select, ng-multiple, ng-file-change, allow-dir)
//   folders.openNewModal($event)
//   files.openNewDocwModal()
//   goToSearchPage()
//   selectedItem
//   selectItem()
//   getSearchResults(searchText)
//   fileTypes
//
//
