(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminJoinCodes', ['utils', '$rootScope', 'dialogManager', 'api', 'users', function(utils, $rootScope, dialogManager, api, users) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/organization-join-codes.html',
            scope: {},
            link: function($scope, $element, $attr) {
                var isOrgOwner = users.current && users.current.isOrgOwner;

                $scope.data = {
                    items: [],
                    selected: [],
                    loading: true,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    },
                    selectedOrg: isOrgOwner ? users.current.organization_id : null,
                    filterType: 'Show',
                };

                $scope.toggleAllItems = function() {
                    //all items already selected, deselect all
                    if ($scope.data.selected.length === $scope.data.items.length) {
                        $scope.data.selected = [];
                    }

                    //all items arent selected, copy all items array to selected items
                    else {
                        $scope.data.selected = $scope.data.items.slice();
                    }
                };

                $scope.toggleSelect = function(item) {
                    var index = $scope.data.selected.indexOf(item);
                    if(index==-1) {
                        $scope.data.selected.push(item);
                    } else {
                        // remove it.
                        $scope.data.selected.splice(index, 1);
                    }
                };

                $scope.isItemSelected = function(item) {
                    return ($scope.data.selected.indexOf(item) !== -1);
                };

                $scope.showEditItemModal = function($event, item) {
                    $event.preventDefault();
                    dialogManager.showEditOrganizationJoinCodeDialog($event, item).then(function(data) {
                        var item;
                        var oldItem;
                        var is_new = false;
                        if(data.item) {
                            oldItem = angular.copy(data.item);
                            item = data.item;
                            is_new = false;
                        } else {
                            item = new api.resources.joinCode();
                        }

                        // update the fields.
                        item.code = data.code;
                        item.start_date = data.start_date;
                        item.end_date = data.end_date;
                        item.org_id = data.org_id;

                        if(data.item) {
                            // update.
                            item.$update().then(function(data) {
                                item.org_name = data.org_name;
                                item.organization_id = data.organization_id;
                            }, function(response) {
                                // utils.showToast(utils.trans(response.data));
                                if (oldItem) {
                                    item.code = oldItem.code;
                                    item.start_date = oldItem.start_date;
                                    item.end_date = oldItem.end_date;
                                    item.org_id = oldItem.org_id;
                                }
                            });
                        } else {
                            // new save
                            item.$save().then(function(data) {
                                data.start_date = item.start_date;
                                data.end_date = item.end_date;
                                $scope.data.items.push(data);
                            }, function(response) {
                                utils.showToast(utils.trans(response.data));
                            });
                        }
                    }, function() {
                        // handle error case
                    });
                };

                $scope.deleteItems = function(items) {
                    // remove them all.
                    angular.forEach(items, function(item) {
                        var index;

                        index = $scope.data.items.indexOf(item);
                        // delete.
                        if ($scope.data.filterType.toLowerCase() == 'show') {
                            // remove from normal
                            if(index != -1) {
                                $scope.data.items.splice(index, 1);
                            }
                            item.$delete();
                        } else {
                            var itemOld = angular.copy(item);
                            itemOld.deleted_at = new Date();
                            item.$delete();
                            $scope.data.items[index] = angular.copy(itemOld);
                        }
                    });

                    $scope.data.selected = [];
                };

                $scope.reactivateJoinCode = function(item) {
                    api.joinCode.reactivate(item.id).then(function(data) {
                        item.deleted_at = null;
                    }, function(response) {
                        utils.showToast(utils.trans(response.data));
                    });
                };

                $scope.loadJoinCodes = function() {
                    $scope.data.loading = true;
                    api.resources.joinCode.query({org_id: $scope.data.selectedOrg, filter: $scope.data.filterType.toLowerCase()}, function(codes) {
                        $scope.data.items = codes;
                        $scope.data.loading = false;
                    });
                };

                $scope.loadJoinCodes();
            }
        };
    }]);
}());