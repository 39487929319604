angular.module('app').factory('storyTopics', ['$http', '$rootScope', '$state', '$mdDialog', 'utils', 'files', 'api', function($http, $rootScope, $state, $mdDialog, utils, files, api) {
   
    var storyTopics = {

        /**
         * All topics
         */
        all: [],

        // /**
        //  * Paginate all existing topics.
        //  *
        //  * @returns {promise}
        //  */
        //  paginate: function(params) {
        //    return $http.get('topics', {params: params});
        // },

        /**
         * Create a new topic with given info.
         *
         * @param {object} info
         * @returns {promise}
         */
        create: function(topic_info) {
            return $http.post('story-topic', topic_info);
        },

        /**
         * Delete given topic from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(values) {
            var promise;
          
            if (angular.isArray(values)) {
                promise = $http.post('story-topic/delete-all/', {storyTopics:values});
            } else {
                promise = $http.delete('story-topic/'+values.id);
                values = [values];
            }

            return promise.then(function(response) {
                storyTopics.all = storyTopics.all.filter(function(storyTopic) {
                    return values.indexOf(storyTopic) === -1;
                });
                utils.showToast(response.data);
            });
        },

        /**
         * Update the topic
         *
         * @returns {string|undefined}
         */
        update: function(storyTopic, id) {
            var topicId  = id || this.current.id; 
            return $http.put($rootScope.baseUrl+'story-topic/'+topicId, storyTopic);
        }  
    };
    
    return storyTopics;
}]);
