(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('storyNavigation', ['api', '$rootScope', 'dialogManager', 'utils', 'stories', function(api, $rootScope, dialogManager, utils, stories) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'assets/views/directives/story-navigation.html',
        link: function($scope, $element) {
          var originatorEv;
          $scope.data = {
            storySections: [],
            loading: true,
            progress: 0
          };

          function addTopicToSection(selectedTopic, isUpdate) {
            isUpdate = isUpdate || false;
            angular.forEach($scope.data.storySections, function(section) {
              if (section.id == selectedTopic.section_id) {
                section.expanded = true;
                if (!isUpdate) {
                  section.topics.push(selectedTopic);
                }
              } else {
                section.expanded = false;
              }

              angular.forEach(section.topics, function(topic) {
                if (topic.id == selectedTopic.id) {
                  if (isUpdate) {
                    topic.topic = selectedTopic.topic;
                  }
                  topic.selected = true;
                } else {
                  topic.selected = false;
                }
              });
            });
          }

          $scope.openMenu = function($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
          };

          $scope.editTopic = function(section, topic) {
            dialogManager.showAddTopicDialog(section.id, topic).then(function(newTopic) {
              addTopicToSectionCall(newTopic, true);
            }, function() {
              // handle failed case
            });
          };

          function addTopicToSectionCall(newTopic, isUpdate) {
            isUpdate = isUpdate || false;
            addTopicToSection(newTopic, isUpdate);
            updateStoryProgress();
            if (isUpdate) {
              $rootScope.$broadcast('left.story.updated', newTopic);
            } else {
              $rootScope.$broadcast('left.story.added', newTopic);
            }
          }

          function removeTopic(selectedTopic) {
            var idx = -1;
            angular.forEach($scope.data.storySections, function(section) {
              if (section.id == selectedTopic.section_id) {
                angular.forEach(section.topics, function(topic, index) {
                  if (topic.id == selectedTopic.id) {
                    idx = index;
                  }
                });
                if (idx !== -1) {
                  section.topics.splice(idx, 1);
                }
              }
            });
          }

          $scope.deleteTopic = function(section, topic) {
            api.storyTopic.delete(section.id, topic.id).then(function() {
              var nextTopic = null;
              if (topic.selected) {
                nextTopic = getNextTopic();
              }
              removeTopic(topic);
              // $rootScope.$broadcast('left.story.deleted', topic);
              if (nextTopic) {
                $rootScope.$broadcast('left.story.clicked', nextTopic);
              } else {
              }
              utils.showToast(utils.trans('topic.deletedSuccessfully', true));
              updateStoryProgress();
            }, function(response) {
              utils.showToast(response.data);
              // handle
            });
          };

          $scope.addTopic = function(section) {
            dialogManager.showAddTopicDialog(section, null).then(function(topic) {
              addTopicToSectionCall(topic, false);
            }, function() {
              // handle failed case
            });
          };

          $scope.setSelectedTopic = function(selectedTopic, centerDashboardLoaded) {
            centerDashboardLoaded = centerDashboardLoaded || false;
            angular.forEach($scope.data.storySections, function(section) {
              if (section.id == selectedTopic.section_id) {
                section.expanded = true;
              } else {
                section.expanded = false;
              }
              angular.forEach(section.topics, function(topic) {
                if (topic.id == selectedTopic.id) {
                  topic.selected = true;
                } else {
                  topic.selected = false;
                }
              });
            });

            if (!centerDashboardLoaded) {
              $rootScope.$broadcast('left.story.clicked', selectedTopic);
            }
          };

          // TODO: Figure out why not handled in setSelectedTopic
          function updateStorySection(selectedTopic) {
            angular.forEach($scope.data.storySections, function(section) {
              if (section.id == selectedTopic.section_id) {
                section.expanded = true;
              } else {
                section.expanded = false;
              }
            });
          }

          function getNextTopic() {
            var foundSection = -1;
            for (var i = 0; i < $scope.data.storySections.length ; i++) {
              for (var j = 0; j < $scope.data.storySections[i].topics.length ; j++ ) {
                if ($scope.data.storySections[i].topics[j].selected) {
                  if (j+1 < $scope.data.storySections[i].topics.length) {
                    $scope.data.storySections[i].topics[j].selected = false;
                    $scope.data.storySections[i].topics[j+1].selected = true;
                    return $scope.data.storySections[i].topics[j+1];
                  } else if (i+1 < $scope.data.storySections.length) {
                    if ($scope.data.storySections[i+1].topics.length) {
                      $scope.data.storySections[i].expanded = false;
                      $scope.data.storySections[i].topics[j].selected = false;
                      $scope.data.storySections[i+1].expanded = true;
                      $scope.data.storySections[i+1].topics[0].selected = true;
                      return $scope.data.storySections[i+1].topics[0];
                    }
                  }

                  if ((i+1) == $scope.data.storySections.length) {
                    if ((j+1) == $scope.data.storySections[i].topics.length) {
                      if ($scope.data.storySections[i].topics.length > 1) {
                        $scope.data.storySections[i].topics[j].selected = false;
                        $scope.data.storySections[i].topics[j-1].selected = true;
                        return $scope.data.storySections[i].topics[j-1];
                      }
                    }
                  }
                  break;
                }
              }
            }

            //TODO: If foundSelected that means MyStory input ended
            if ($scope.data.storySections.length && $scope.data.storySections[0].topics.length) {
              $scope.data.storySections[0].expanded = true;
              $scope.data.storySections[0].topics[0].selected = true;
              return $scope.data.storySections[0].topics[0];
            }
            return null;
          }

          stories.get().then(function(loadedStories) {
            $scope.data.storySections = loadedStories;
            $scope.data.loading = false;
          }, function(data) {
            console.log("error case ", data);
            $scope.data.loading = true;
          });

          function updateStoryProgress() {
            api.me.userStoryProgress().then(function(data) {
              $scope.data.progress = data;
            });
          }

          updateStoryProgress();

          $scope.$on('center.story.clicked', function(e, story) {
            updateStorySection(story);
            $scope.setSelectedTopic(story, true);
          });

          $scope.$on('center.story.saved', function(e, story) {
            updateFilledStory(story);
            updateStoryProgress();
          });

          $scope.$on('center.story.name.changed', function(e, story) {
            addTopicToSection(story, true);
          });

          $scope.$on('center.story.deleted', function(e, story) {
            removeTopic(story);
          });

          function updateFilledStory(selectedStory) {
            angular.forEach($scope.data.storySections, function(section) {
              if (selectedStory.section_id == section.id) {
                angular.forEach(section.topics, function(topic) {
                  if (topic.id == selectedStory.topic_id) {
                    topic.filled = selectedStory.filled;
                  }
                });
              }
            });
          }
        }
      };
    }]);
})();