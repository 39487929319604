(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('fileGridItem', [ 'dialogManager', 'fileTypes', 'selectedItems', '$rootScope', 'contextMenu', 'localStorage', '$timeout', function(dialogManager, fileTypes, selectedItems, $rootScope, contextMenu, localStorage, $timeout) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'assets/views/directives/file-grid-item.html',
            scope: {
                item : '='
            },
            link: function($scope, $element, $attr) {
                $scope.data = {
                    mimeIcon: undefined,
                    imageUrl: undefined,
                    name: undefined
                };

                $scope.$watch('item', function (newValue) {
                    if (newValue) {
                        // if it's an image or it has a custom thumbnail
                        if (fileTypes.isImage(newValue.mime) || (newValue.flags & 0x02)) { // FIXME: evaluate this method, vs. decomposing flags into the item (has_custom_thumbnail)
                            // $scope.data.imageUrl = $rootScope.baseUrl+'user-file/'+newValue.share_id+'?w=167&h=131'; // probably something better than this.
                            $scope.data.imageUrl = $rootScope.baseUrl + 'user-file/' + newValue.share_id + '?thumb=1'; // probably something better than this.
                            $scope.data.mimeIcon = undefined;
                        }
                        else if(newValue.type=='folder')
                        {
                            $scope.data.imageUrl = (newValue.class_type == 'normal') ?
                                'assets/images/specialfolders/folder.png' :
                                'assets/images/specialfolders/'+newValue.class_type+'.png';
                            $scope.data.mimeIcon = undefined;
                        } else {
                            $scope.data.mimeIcon = fileTypes.getMimeIcon(newValue.mime);
                            $scope.data.imageUrl = undefined;
                        }

                        var filteredName = newValue.name;
                        if (localStorage.get('stripExtensions', true) && newValue.type !== 'folder')
                        {
                            $scope.data.name = filteredName.replace(/\.[^/.]+$/, "");
                        } else {
                            $scope.data.name = filteredName;
                        }
                    }
                    if (newValue && newValue.is_new) {
                        $rootScope.$broadcast('update.item.thumb', [newValue]);
                    }
                }, true);

                $scope.selectItem = function($event, item) {
                    var keyExpandSelection = $event.metaKey; // WAS ctrlKey

                    //if ctrl is pressed and user clicked on item
                    //that is already selected, deselect it
                    if ($rootScope.ajaxProgress.autoLoadMore) {
                        if (keyExpandSelection && selectedItems.has(item)) {
                            selectedItems.deselect(item);
                        } else {
                            selectedItems.set(item, item.type, !keyExpandSelection);
                        }
                    }
                };

                $scope.preview = function($event, item) {
                    if($attr['linkShareableId']) {
                        // fixme: use $event, don't use selectedItems (as it should be *this* item.)
                        selectedItems.preview($attr['linkShareableId']);
                    } else {
                        // fixme: use $event, don't use selectedItems (as it should be *this* item.)
                        selectedItems.preview();
                    }
                };

                $scope.openContextMenu = function($event) {
                    contextMenu.open();
                };

                $scope.displayContextMenu = function ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    contextMenu.show($event, true);
                };

                $scope.itemIsSelected = function(item) {
                    return selectedItems.has(item);
                };

                $scope.$on('update.item.thumb', function (evt, data) {
                    if (data.length && (fileTypes.isImage($scope.item.mime) || ($scope.item.flags & 0x02)) ) {
                        data.forEach(function (updateItem) {
                            $timeout(function () {
                                if (updateItem.id == $scope.item.id) {
                                    $scope.$apply(function () {
                                        $scope.data.imageUrl = $rootScope.baseUrl + 'user-file/' + $scope.item.share_id + '?t=' + Date.now();
                                        $scope.data.mimeIcon = undefined;
                                    });
                                }
                            });
                        });
                    }
                });
            }
        };
    }]);
})();
