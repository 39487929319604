(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminIntroDialogs', ['utils', '$rootScope', 'dialogManager', 'api', function(utils, $rootScope, dialogManager, api) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/intro-dialogs.html',
            scope: {},
            link: function($scope, $element, $attr) {
                var modeIds = ['introContentMode_0', 'introContentMode_1', 'introContentMode_2', 'introContentMode_3', 'introContentMode_4', 'introContentMode_5', 'introContentMode_6', 'introContentMode_7', 'introContentMode_8', 'introContentMode_9', 'introContentMode_10', 'introContentMode_11'];
                var modeNames = ['My Storage', 'My Story', 'My Hives', 'My Timeline', 'The non-profit member info text', 'The non-profit org info text', 'The non-profit member registration disclaimer', 'The non-profit org registration disclaimer', 'The non-profit org owner initial registration login popup message', 'The Buzz', 'My Chat', 'My Video Chat'];

                $scope.data = {
                    items: [],
                    selected: [],
                    loading: false,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    }
                };

                $scope.showEditItemModal = function($event, item) {
                    $event.preventDefault();

                    dialogManager.showEditIntroDialog($event, item).then(function(data) {
                        api.user.updateModeInfo(item.id, data).then(function() {
                            item.value = data;
                        }, function() {
                            // handle failed case
                        });
                    }, function() {
                        // handle failed case
                    });
                };

                function loadModesData(modes) {
                    var idx;
                    angular.forEach(modes, function(mode) {
                        idx = -1;
                        mode.modeName = '';
                        idx = modeIds.indexOf(mode.name);
                        if ( idx !== -1) {
                            mode.modeName = modeNames[idx];
                        }
                    });
                    return modes;
                }

                api.user.modesIntro().then(function(data) {
                    $scope.data.items = loadModesData(data);
                }, function() {
                    // handle failed case
                });
            }
       	};
    }]);
}());