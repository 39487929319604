(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('sharingIcon', [ 'dialogManager', function(dialogManager) {
        return {
            restrict: 'E',
            template: '<md-button ng-if="data.shared" class="md-icon-button" ng-click="show($event)">'+
                        '<md-icon md-font-icon>person</md-icon>'+
                      '</md-button>',
            scope: {
                item : '='
            },
            link: function($scope, el) {
                $scope.data = {};
                
                $scope.$watch('item', function(newValue) {
                    if(newValue) {
                        $scope.data.shared = newValue.is_shared || newValue.is_being_shared;
                    }
                });
                
                $scope.show = function($event) {
                    dialogManager.showSharedInfo($event, $scope.item);
                };

                $scope.$on('sharing.stopped', function(evt, newItem) {
                    if (newItem.id == $scope.item.id) {
                        $scope.data.shared = false;
                    }
                });                 
            }
        };
    }]);
})();