(function () {
    'use strict';

    angular.module('app')
    .directive('contactUsForm', ['users', '$http', function(users, $http) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'contactUsForm.html',
            link: function($scope) {
                $scope.data = {
                    form: {
                        first_name: (users.current && users.current.first_name) ? users.current.first_name : '',
                        last_name: (users.current && users.current.last_name) ? users.current.last_name : '',
                        email: (users.current && users.current.email) ? users.current.email : '',
                        type: '',
                        message: ''
                    },
                    submitting: false,
                    submitted: false
                };

                $scope.submit = function($event) {
                    $event.preventDefault();
                    
                    $scope.data.submitting = true;
                    $http.post('contact', $scope.data.form).then(function(response) {
                        $scope.data.submitted= true;
                    }, function() {
                        // error!
                    }).finally(function() {
                        $scope.data.submitting = false;
                    });
                };
            }
        };
    }]);
}());


/*
<script type="text/ng-template" id="contactUsForm.html">
		<form class="contactus" ng-submit="data..submitContactForm()">
			<md-input-container class="full-width">
				<label>{{ 'firstName' | translate }}</label>
				<input name="first_name" type="text" ng-model="users.contact.first_name" ng-disabled="users.contact.first_name">
			</md-input-container>

			<md-input-container class="full-width">
				<label>{{ 'lastName' | translate }}</label>
				<input name="last_name" type="text" ng-model="users.contact.last_name" ng-disabled="users.contact.last_name">		                
			</md-input-container>

			<md-input-container class="full-width">
				<label>{{ 'email' | translate }}</label>
				<input name="email" class="email" type="email" ng-model="users.contact.email" ng-disabled="users.contact.email">
			</md-input-container>

			<md-input-container class="full-width">
				<label>{{ 'questionType' | translate }}</label>
				<md-select name="type" ng-model="users.contact.type">
					<md-option value="bug" translate>bug</md-option>
					<md-option value="suggestion" translate>suggestion</md-option>
					<md-option value="question" translate>question</md-option>
				</md-select>
			</md-input-container>

			<md-input-container class="full-width">
				<label>{{ 'message' | translate }}</label>
				<textarea name="message" rows="4" ng-model="users.contact.message"></textarea>
			</md-input-container>

			<div class="buttons">
				<md-button type="submit" class="md-raised md-primary">{{:: 'submit' | translate }}</md-button>
			</div>
		</form>
</script>
*/