(function(){
	'use strict';

	angular.module('pixie.admin') .directive('adminFirstRun', ['utils', '$rootScope', '$resource', 'dialogManager', function(utils, $rootScope, $resource, dialogManager) {
		return {
			restrict: 'E',
			templateUrl: 'assets/views/admin/firstrun.html',
			scope: {},
			link: function($scope, $element, $attr) {
				$scope.data = {
					items: [],
					selected: [],
					loading: false,
					params: {
						itemsPerPage: 10,
						page: 1 // 0?
					}
				};

				var InitialRunDialog = $resource(
					'/firstrun/:id', {id: '@id'},
					{update: {method: 'PUT', isArray: false }}
				);

				$scope.toggleAllItems = function() {
					$scope.data.selected = $scope.data.items;
				};

				$scope.toggleSelect = function(item) {
					var index = $scope.data.selected.indexOf(item);
					if(index==-1) {
						$scope.data.selected.push(item);
					} else {
						// remove it.
						$scope.data.selected.splice(index, 1);
					}
				};

				$scope.isItemSelected = function(item) {
					return ($scope.data.selected.indexOf(item) !== -1);
				};

				$scope.showEditItemModal = function($event, item) {
					$event.preventDefault();

					dialogManager.showEditFirstRunDialog($event, item).then(function(data) {
						var item;
						var is_new = false;
						if(data.item) {
							item = data.item;
							is_new = false;
						} else {
							item = new InitialRunDialog();
						}

						// update the fields.
						item.title = data.title;
						item.description = data.description;
						item.short_name = data.short_name;
						item.bit_value = data.bit_value;
						item.image_url = data.image_url;

						if(data.item) {
							// update.
							item.$update();
						} else {
							// new save
							item.$save().then(function(data) {
								$scope.data.items.push(data);
							});
						}
					}, function() {
						// handle faided case
					});
				};

				$scope.deleteItems = function(items) {
					// remove them all.
					angular.forEach(items, function(item) {
						var index;

						// remove from selected
						index = $scope.data.selected.indexOf(item);
						if(index != -1) {
							$scope.data.selected.splice(index, 1);
						}

						// remove from normal
						index = $scope.data.items.indexOf(item);
						if(index != -1) {
							$scope.data.items.splice(index, 1);
						}

						// delete.
						item.$delete(); // doesn't work cause route isn't setup.
					});
				};

				$scope.data.items = InitialRunDialog.query();
			}
		};
	}]);
}());