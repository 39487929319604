(function () {
    'use strict';

    angular.module('app')
    .directive('homeHexagons', ['$window', function($window) {
        return {
            restrict: 'E',
            scope: {},
            replace: true,
            template: 
            '<ul id="hexGrid">'+
              '<li class="hex" ng-repeat="hexagon in data.hexagons">'+
                '<div class="hexIn" ng-class="{hide: hexagon.hidden}">'+
                  '<a class="hexLink" href="#">'+
                    '<img ng-src="{{hexagon.image}}" alt="{{hexagon.title}}" />'+
                    '<h1>{{ hexagon.title }}</h1>'+
                    '<p>{{ hexagon.description }}</p>'+
                  '</a>'+
                '</div>'+
              '</li>'+
            '</ul>',
            link: function($scope) {
                var hexagons = [
                {
                    hidden: false,
                    image: '/assets/images/home/privacy.jpg',
                    title: 'PRIVACY',
                    description: 'You have full control of your privacy on Story Hives. No one sees your posts without your permission.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/your_story.jpg',
                    title: 'MY STORY',
                    description: 'Your story matters which is why we have built a platform to give you the tools to tell it and to share it with whomever you decide'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/social_media.jpg',
                    title: 'IS THIS SOCIAL MEDIA',
                    description: 'We are "controlled" social media, which means you control who has access to your story hives.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/costs.jpg',
                    title: 'COST',
                    description: 'For our partner nonprofit groups the site is completely free. For the public, there are minimal storage costs as your account grows.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/purpose.jpg',
                    title: 'PURPOSE',
                    description: 'Our purpose is to provide the tools that help you tell your story and share it with the important people in your life.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/teens.jpg',
                    title: 'TEENS',
                    description: 'Story Hives is a safe place to tell your story and share it with the important people in your life.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/missionaries.jpg',
                    title: 'MISSIONARIES',
                    description: 'Finally a secure and private platform to communicate with your friends, family and supporters while you are in unfriendly territories.'
                },
                {
                    hidden: false,
                    image: '/assets/images/home/military.jpg',
                    title: 'MILITARY',
                    description: 'Finally a secure and private platform to communicate with your friends and family while you are deployed.'
                }
              ];
              $scope.data = {
                  width: $window.innerWidth,
                  hexagons: []
              };
              
              function adjustForSize() {
                  var hidden = [];
                  
                  if($scope.data.width>=1201) {
                      hidden = [0, 4, 6, 9, 13, 14, 15];
                  } else if($scope.data.width>=901) {
                      hidden = [0, 4, 5 ];
                  } else if($scope.data.width>=601) {
                      hidden = [0, 3, 10 ];
                  } else {
                      hidden = [8, 9, 10, 11, 12 ];
                  }
                  
                  var hexes = [];
                  hexagons.forEach(function(hex) {
                      while(hidden.indexOf(hexes.length)>=0) {
                          // this one is hidden.
                          hexes.push({
                              hidden: true
                          });
                      }
                      hexes.push(hex);
                  });
                  
                  hexes.forEach(function(hex, index) {
                      console.log(index+") hidden: "+(hex.hidden?'true':'false'));
                  });
                  
                  $scope.data.hexagons = hexes;
              }
              
              function onResize() {
                  // uncomment for only fire when $window.innerWidth change   
                  // if (scope.width !== $window.innerWidth)
                  {
                      $scope.data.width = $window.innerWidth;
                      adjustForSize();
                      $scope.$digest();
                  }
              }
              
              // watch resize.
              angular.element($window).bind('resize', onResize);
              $scope.$on('$destroy', function() {
                  angular.element($window).off('resize', onResize);
              });
              
              
              adjustForSize();
          }
      };
    }]);
}());
