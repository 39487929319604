(function () {
	'use strict';

	angular.module('app')
		.directive('buzzPage', ['api', 'users', 'utils', '$state', '$rootScope', 'dialogManager', '$q', '$timeout', '$stateParams', 'listeners', 'rooms', '$location', function (api, users, utils, $state, $rootScope, dialogManager, $q, $timeout, $stateParams, listeners, rooms, $location) {
			return {
				restrict: 'E',
				templateUrl: 'assets/views/pages/buzz-page.html',
				link: function ($scope, el, attrs) {
					$scope.data = {
						selectedIndex: -1,
						videoPeers: [],
						loadingVideoPeers: false,
						callMode: false,
						callee: null,
						caller: null,
						remoteVideoLoaded: false,
						localVideoLoaded: false,
						callEnded: false,
						callStatusText: 'Calling...',
					};

					var localVideo;
					var remoteVideo;
					var localStream;
					var remoteStream;
					var isCaller = false;
					var ruid;

					// Check params for incoming video call redirect
					if ($stateParams.incoming_call) {
						answerCall();
						isCaller = false;
					}

					var tabs = ['timeline', 'chat', 'video'];

					function setSelectedIndex() {
						var states = $state.current.name.split('.');
						if (states.length > 2 && states[2]) {
							$scope.data.selectedIndex = tabs.indexOf(states[2]);
							$rootScope.$broadcast('buzz.tab.switched', $scope.data.selectedIndex);
							updateTabSettings();
						}
					}

					setSelectedIndex();

					$scope.$on('user.online.status', function () {
						console.log('in user.online.status scope on');
						updateOnlineStatus();
					});

					function updateOnlineStatus() {
						// Update only if hive members
						if (!$scope.data.videoPeers || ($scope.data.videoPeers && !$scope.data.videoPeers.length)) {
							return;
						}

						$timeout(function () {
							console.log("update online status before - ", $scope.data.videoPeers);
							$scope.data.videoPeers.forEach(function (peer) {
								var busy_status = false;
								if (listeners.busyUsers && listeners.busyUsers.length) {
									listeners.busyUsers.forEach(function (busyUserId) {
										if (peer.user_id == busyUserId) {
											busy_status = true;
										}
									});
								}

								var found = false;
								if (!busy_status) {
									if (listeners.onlineUsers && listeners.onlineUsers.length) {
										listeners.onlineUsers.forEach(function (onlineUserId) {
											if (peer.user_id == onlineUserId) {
												found = true;
											}
										});
									}
								}

								peer.online_status = found;
								peer.busy_status = busy_status;
							});
							console.log("update online status after - ", $scope.data.videoPeers);
						});
					}

					$scope.$on('left.buzzOption.clicked', function (evt, tabOption) {
						$scope.data.selectedIndex = tabOption;
						updateTabSettings();
					});

					$scope.onSelectBuzzOption = function (tabOption) {
						$rootScope.$broadcast('buzz.tab.switched', $scope.data.selectedIndex);
						updateTabSettings();
					};

					$scope.initiateRoom = function () {
						// dialogManager.showGetRoomDialog();
						var newRoomdId = Math.random().toString(36).slice(2);
						rooms.sessionId = undefined;
						rooms.numOfVideos = 0;
						// Launching in a new window
						var url = window.location.origin + '/dashboard/rooms/' + newRoomdId;
						window.open(url, "_blank");
					};

					function answerCall() {
						console.log("I AM IN CALL ANSWERS");
						$timeout(function () {
							$scope.data.selectedIndex = 2;
							$scope.data.loadingVideoPeers = false;
							$scope.data.callMode = true;
							$scope.data.caller = listeners.caller;
							$scope.data.callee = listeners.callerInfo;
							listeners.setupVideoCall($scope.data.caller.id, $scope.data.callee.id, false);
							$scope.data.callMode = true;
						});
						updateTabSettings();
						start();
					}

					$scope.$on('user.call.answer', function () {
						answerCall();
					});

					function updateTabSettings() {
						if ($scope.data.selectedIndex == 2 && !$scope.data.callMode) {
							$scope.data.loadingVideoPeers = false;
							loadHiveMembers();
						}
					}

					function loadHiveMembers(callback) {
						if ($scope.data.loadingVideoPeers) {
							return;
						}

						$scope.data.loadingVideoPeers = true;

						//Might need to change this
						api.me.familyMembers().then(function (family) {
							$scope.data.videoPeers = prepareVideoPeersData(family);
							updateOnlineStatus();
							$scope.data.loadingVideoPeers = false;

							if (typeof callback == "function") {
								callback();
							}
						});
					}

					function prepareVideoPeersData(family) {
						return family.map(function (familyMember) {
							familyMember.url = users.getAvatar(familyMember);
							familyMember.full_name = users.getName(familyMember);
							familyMember.online_status = false;
							familyMember.busy_status = false;
							return familyMember;
						});
					}

					$scope.initiateCall = function (callee) {
						// console.log("in initiateCall");
						if (callee.online_status) {
							$scope.data.callEnded = false;
							$scope.data.callee = callee;
							$scope.data.caller = listeners.caller;
							$scope.data.callMode = true;
							$scope.startVideoCallToUser();
						}
					};

					$scope.startVideoCallToUser = function () {
						console.log('calling user ', $scope.data.callee.user_id);
						isCaller = true;
						listeners.setupVideoCall($scope.data.caller.id, $scope.data.callee.user_id, true);
						start();
					};

					function start() {
						console.log('Requesting local stream');

						$scope.data.callStatusText = "Calling...";
						$scope.data.callEnded = false;

						navigator.mediaDevices.getUserMedia({
							audio: false,
							video: true
						})
							.then(gotStream)
							.catch(function (e) {
								alert('getUserMedia() error: ' + e.name + ' - ');
								console.log("error is ", e);
							});
					}

					function gotStream(stream) {
						console.log('Received local stream ');
						$scope.data.localVideoLoaded = true;

						if (!localVideo) {
							localVideo = document.getElementById('localVideo');
						}

						if (localVideo) {
							localVideo.srcObject = stream;
							localStream = stream;
							listeners.videoCall(stream);
						} else {
							console.log("Log Local Video Load");
						}
					}

					$scope.$on('user.videoChat.signalClose', function (evt, type) {
						console.log("user.videoChat.signalClose event ", type);
						onSignalClose(type);
					});

					function onSignalClose(type) {
						console.log('BUZZPAGE Ending call with type ' + type);

						$timeout(function () {
							$scope.$apply(function () {
								setStatus(type);
								$scope.data.localVideoLoaded = false;
								$scope.data.remoteVideoLoaded = false;
								$scope.data.callEnded = true;
								// console.log('$scope.data.callEnded  ' , $scope.data.callEnded);
								// console.log('$scope.data.callMode  ' , $scope.data.callMode);
								closeMedia();
								clearView();
								isCaller = false;
							});
						});
					}

					function setStatus(type) {
						if (type == 'close') {
							$scope.data.callStatusText = 'Called Ended';
						} else if (type == 'busy') {
							$scope.data.callStatusText = 'Callee is busy';
						} else if (type == 'noanswer') {
							if (isCaller) {
								$scope.data.callStatusText = 'No answer';
							} else {
								$scope.data.callStatusText = 'Call Ended';
							}
						}
					}

					function closeMedia() {
						if (localStream) {
							localStream.getTracks().forEach(function (track) {
								track.stop();
							});
						}
					}

					function clearView() {
						if (localVideo) {
							localVideo.srcObject = null;
						}

						if (remoteVideo) {
							remoteVideo.srcObject = null;
						}

					}

					$scope.$on('user.videoChat.onSignalAnswer', function () {
						onSignalAnswer();
					});

					function onSignalAnswer(answer) {
						$timeout(function () {
							$scope.data.callStatusText = 'Ringing...';
						});
					}

					$scope.returnToMemberList = function () {
						if ($scope.data.callEnded && $scope.data.callMode) {
							$scope.data.callMode = false;
							loadHiveMembers();
						}
					};

					$scope.endCall = function () {
						console.log(" YOU ARE ENDING THIS CALL");
						listeners.postCallEnded();
						listeners.onSignalClose('close');
					};


					/* Video chat add/remove video
					// and audio work - in haitus
					//
						
					$scope.removeLocalAudio = function() {
						console.log("remove audio ");
					};
						
					$scope.addLocalVideo = function() {
						console.log("add local videooo");
						navigator.mediaDevices
								.getUserMedia({video: true})
								.then(function(stream) {
								var videoTracks = stream.getVideoTracks();
								if (videoTracks.length > 0) {
										console.log('Using video device: ' , videoTracks[0].label);
								}
								localStream.addTrack(videoTracks[0]);
								localVideo.srcObject = null;
								localVideo.srcObject = localStream;
								pc.addTrack(videoTracks[0], localStream);
								$scope.data.hiddenLocalVideo = false;
								$scope.data.localVideoLoaded = true;
								var message = {from: luid, to:ruid, type: 'signal', subtype: 'add-video', content: videoTracks[0], time:new Date()};
								$http.post('video-chat', message);
								console.log("video chat - add video ", videoTracks[0]);
								pc.createOffer(
									offerOptions
								).then(
									onCreateOfferSuccess,
									onCreateSessionDescriptionError
								);
							});
					};
						
					$scope.removeLocalVideo = function() {
						console.log("remove video ");
						if (localStream) {
							console.log('in local stream');
							var videoTracks = localStream.getVideoTracks();
							videoTracks.forEach(function(videoTrack) {
								videoTrack.stop();
								localStream.removeTrack(videoTrack);
							});
							localVideo.srcObject = null;
							localVideo.srcObject = localStream;
							var message = {from: luid, to:ruid, type: 'signal', subtype: 'hide-video', content: 'Hide Video', time:new Date()};
							$http.post('video-chat', message);
							$timeout(function() {
								$scope.data.localVideoLoaded = false;
								$scope.data.hiddenLocalVideo = true;
							});
						} else {
							console.log('NOTT in local stream');
						}
					};
						
					$scope.removeRemoteVideo = function() {
						console.log("remove  remote video ");
						if (remoteStream) {
							console.log('in remote stream');
							var videoTracks = remoteStream.getVideoTracks();
							videoTracks.forEach(function(videoTrack) {
								videoTrack.stop();
								remoteStream.removeTrack(videoTrack);
							});
							remoteVideo.srcObject = null;
							remoteStream = null;
							remoteVideo.srcObject = remoteStream;
						} else {
							console.log('NOTT in local stream');
						}
					}; */

					function getVideoPeer(remoteUserId) {
						var callee = {};
						// console.log("peers " , $scope.data.videoPeers);
						$scope.data.videoPeers.forEach(function (peer) {
							if (peer.user_id == remoteUserId) {
								callee = peer;
							}
						});
						// console.log("my callee is " , callee);
						return callee;
					}

					$scope.$on('user.videoStatus.failed', function () {
						if (!$scope.data.callEnded) {
							listeners.onSignalClose('close');
						}
					});

					function setRingingStatus() {
						$timeout(function () {
							$scope.data.callStatusText = 'Ringing...';
						});
					}

					$scope.$on('user.videoStatus.ringing', function () {
						setRingingStatus();
					});


					$scope.$on('user.remoteVideo.loaded', function () {
						setRemoteVideoLoaded();
					});

					function setRemoteVideoLoaded() {
						$timeout(function () {
							// console.log("CONNECTION DONE setRemoteVideoLoaded true ");
							$scope.data.remoteVideoLoaded = true;
						});
						// console.log("CONNECTION DONE 1");
					}

					$scope.$on('user.videoChat.gotRemoteStream', function (e, event) {
						console.log('remote stream even fired ', event);
						gotRemoteStream(event);
					});

					function gotRemoteStream(e) {
						console.log('gotRemoteStream', e.track, e.streams[0]);

						if (isCaller) {
							$scope.$apply(function () {
								$scope.data.remoteVideoLoaded = true;
							});
						}

						if (!remoteVideo) {
							remoteVideo = document.getElementById('remoteVideo');
						}

						if (remoteVideo) {
							// reset srcObject to work around minor bugs in Chrome and Edge.
							remoteVideo.srcObject = null;
							remoteVideo.srcObject = e.streams[0];
							// console.log("---- " , remoteVideo.srcObject);
							remoteStream = e.streams[0];
							if (remoteVideo.srcObject !== e.stream) {
								remoteVideo.srcObject = e.streams[0];
								remoteStream = e.streams[0];
								listeners.setRemoteStream(remoteStream);
								console.log('received remote stream');
							}
						} else {
							console.log("Log Remote Video Load Error");
						}
					}

					$scope.$on('user.set.remoteStream', function (e) {
						// console.log("setting remoteStream" , remoteStream);
						if (remoteStream) {
							// console.log("have stream");
							listeners.setRemoteStream(remoteStream);
						}
					});
				}
			};
		}]);
}());
