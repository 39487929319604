angular.module('app')

.factory('constants', function() {
    var constants = {

        FileSystem : {
            NonDeletable   : 1
        }

    };

    return constants;
});