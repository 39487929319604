(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('familyTree', ['api', 'users', '$rootScope', 'dialogManager', '$state', '$stateParams', function(api, users, $rootScope, dialogManager, $state, $stateParams) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'assets/views/directives/family-tree.html',
        link: function($scope, $element) {
          $scope.data = {
            allfamily: [],
            filteredfamily: [],
            searchText: '',
            selectedHive: 'default'
          };

          function loadFamilyMembers() {
            api.me.familyMembers($scope.data.selectedHive).then(function(family) {
              $scope.data.allfamily = family.map(function(familyMember) {
                familyMember.url =  users.getAvatar(familyMember);
                familyMember.full_name = users.getName(familyMember);
                familyMember.selected = false;
                return familyMember;
              });
              setFilteredFamily();
              // if (!$stateParams.memberId) {
              //   // selectFirstFamily();
              // } else {
              //   $scope.selectFamily($stateParams.memberId, true);
              // }
            });
          }

          $scope.$watch('data.searchText', function(newValue, oldValue) {
              if (oldValue != newValue) {
                setFilteredFamily();
              }
          });

          //TODO: NOT IN USE
          // function getMemberId() {
          //     if (!$stateParams.memberId) {
          //       if ($scope.data.filteredfamily.length) {
          //         $scope.data.filteredfamily.forEach(function(member) {
          //           if (member.selected) {
          //             return member.family_id;
          //           }
          //         });
          //         return $scope.data.filteredfamily[0].family_id;
          //       }
          //     } else {
          //       return $stateParams.memberId;
          //     }
          //   return null;
          // }
          //TODO: NOT IN USE
          // $scope.$on('sideNav.opened', function() {
          //   $scope.selectFamily(getMemberId() , false);
          // });

          $scope.selectFamily = function(familyId, loaded) {
            if (!familyId) {
              return;
            }

            loaded = loaded || false;
            var found = false;
            $scope.data.filteredfamily.forEach(function(member) {
              if (member.family_id == familyId) {
                member.selected = true;
                found = true;
              } else {
                member.selected = false;
              }
            });
            if (found) {
              if (!loaded) {
                $state.go('dashboard.family.member', { memberId: familyId });
              }
            } else {
              $scope.selectFamily($scope.data.filteredfamily[0].family_id);
            }
          };

          $scope.addMember = function() {
            dialogManager.addMemberDialog();
          };

          function setFilteredFamily() {
            if (!$scope.data.searchText) {
              $scope.data.filteredfamily = $scope.data.allfamily;
            } else {
              $scope.data.filteredfamily = $scope.data.allfamily.filter(function(member) {
                return member.full_name.indexOf($scope.data.searchText) !== -1;
              });
            }
          }

          function selectFirstFamily() {
            if ($scope.data.filteredfamily.length) {
              $scope.selectFamily($scope.data.filteredfamily[0].family_id);
            }
          }

          $scope.$on('family.deleted', function() {
            loadFamilyMembers();
          });

          $scope.$on('hive.selected', function(evt, selectedHive) {
            $scope.data.selectedHive = selectedHive.id;
            loadFamilyMembers();
          });

          loadFamilyMembers();
        }
      };
    }]);
})();