angular.module('app').factory('stories', ['api', '$q', function(api, $q) {

	var stories = [];
	var deferred = $q.defer();

	return {
		clear: function() {
			stories = [];
			deferred = $q.defer();
		},

		get: function() {
			return deferred.promise;
		},

		load: function(force) {
			force = force || false;

			if (stories.length && !force) {
				return deferred.promise;
			}

			if (force) {
				this.clear();
			}

			api.resources.story.query({}, function(data) {
				stories = data;
				deferred.resolve(stories);
			}, deferred.reject);

			return deferred.promise;
		}
	};
}]);
