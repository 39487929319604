(function(){
    'use strict';

    angular.module('pixie.dashboard')

  .directive('renderStory', ["$compile", "$timeout", function($compile, $timeout) {
    return {
        restrict: "A",
        link: function(scope, element, attrs) {
            var $ = angular.element;
            var storyContent = $compile(attrs.renderStory)(scope);

            var calculateHeight = function() {
                var contentHeight = 0;
                var imgEle = null;
                var videoPreviewEle = null;
                var diffHeight = 0;
                var maxHeight = 0;

                if ($(storyContent).find('img').length) {
                    imgEle = $($(storyContent).find('img').get(0));
                }

                if ($(storyContent).find('video-preview').length) {
                    videoPreviewEle = $($(storyContent).find('video-preview').get(0));
                }

                $(element).append(storyContent);

                $(storyContent).each(function() {
                    var child = $(this);
                    child.each(function() {
                        contentHeight += $(this).outerHeight();
                    });
                });

                // TODO: Check height of first image, then set height of story container (i.e. $($element).parent()) accordingly
                if (imgEle) {
                    diffHeight = Math.round(imgEle.offset().top - imgEle.closest('.section-container').offset().top);
                    // If first image lies within summary
                    if (diffHeight <= 350) {
                        maxHeight = Math.round(diffHeight + imgEle.outerHeight());
                        if (maxHeight > 350) {
                            if (maxHeight < contentHeight) {
                                maxHeight += 80;
                                scope.story.showReadMore = true;
                                scope.story.expansion = false;
                            } else {
                                maxHeight += 15;
                                scope.story.showReadMore = false;
                                scope.story.expansion = false;
                            }
                            imgEle.closest('.section-container').css('max-height', maxHeight + "px");
                        }
                    }
                }

                if (videoPreviewEle) {
                    var videoHeight = $(element).width() * 0.58;
                    videoHeight = Math.round(videoHeight * 100 + Number.EPSILON ) / 100;
                    diffHeight = Math.round(videoPreviewEle.offset().top - videoPreviewEle.closest('.section-container').offset().top);
                    contentHeight += videoHeight;

                    if (diffHeight <= 350) {
                        maxHeight = Math.round(diffHeight + videoHeight);
                        if (maxHeight > 350) {
                            var headLength = 15 + 25 + 20 + 15;
                            if (maxHeight < (contentHeight + headLength)) {
                                maxHeight += 60;
                                scope.story.showReadMore = true;
                                scope.story.expansion = false;
                            } else {
                                maxHeight += 15;
                                scope.story.showReadMore = false;
                                scope.story.expansion = false;
                            }
                            videoPreviewEle.closest('.section-container').css('max-height', maxHeight + "px");
                        }
                    }
                }
            };

            // console.log("story is " , storyContent);
            // console.log("content len ", $(storyContent).find("img").length);

            if ($(storyContent).find("img").length) {
                $(storyContent).find("img").on("load", function() {
                    calculateHeight();
                    $timeout(function(){
                        scope.story.bodyLoaded = true;
                        scope.$digest();
                    });
                });
            } else {
                calculateHeight();
                $timeout(function(){
                    scope.story.bodyLoaded = true;
                    scope.$digest();
                });
            }
        }
    };
  }]);



})();