angular.module('pixie.dashboard')

.directive('executor', ['$rootScope', '$stateParams', '$state', 'utils', '$http', '$mdDialog', 'dialogManager', 'users', function($rootScope, $stateParams, $state, utils, $http, $mdDialog, dialogManager, users) {
    return {
        restrict: 'E',
        template: '',
        link: function($scope, el, at) {

            if($stateParams && $stateParams.hash !== undefined && users.current) {
                var executor_hash = $stateParams.hash;
                
                // This could be in service, instead
                $http.get('executor/verify?hash='+executor_hash).then(function(response) {                                
                    dialogManager.showAcceptExecutorDialog(response.data, executor_hash).then(function(accounts) {
                        users.accounts = accounts;
                        $rootScope.$broadcast('user.changed', { fields: ['accounts' ] });
                    }, function() {
                        // cancelled.
                    }).finally(function() {
                        $state.go('dashboard.folders');
                    });
                }, function(error){                
                    utils.showToast(error.data);
                    $stateParams.hash = null;
                    $state.go('dashboard.folders');
                });
                            
                // As executor-request doesn't have a default preview, its better to load root
                
                return;
            } else {
                $state.go('login', { hash: $stateParams.hash });
            }
        }
    };
}]);
