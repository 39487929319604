(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('storyPage', ['api', '$rootScope', 'utils', 'users', 'dialogManager', '$state', '$stateParams', 'stories', 'localStorage', '$timeout', 'textAngularManager', function(api, $rootScope, utils, users, dialogManager, $state, $stateParams, stories, localStorage, $timeout, textAngularManager) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'assets/views/pages/story-page.html',
        link: function($scope, $element) {
          var nextSectionIndex = -1;
          var currentStoryIndex = -1;
          var currentSectionIndex = -1;
          var prevSectionIndex = -1;
          var userStories = [];
          var selectedUserStoryIndex = -1;
          var jumpToNextSection = -1;
          var originatorEv;
          var defaultFilters = [{
            id: "all",
            text: "All Posts",
            type: "all",
            selected: false,
            collapsed: true,
            user: false,
          }];

          $scope.data = {
            selectedStory: null,
            nextStoryIndex: -1,
            prevStoryIndex: -1,
            storyContent: '',
            oldContent: '',
            privacyChanged: false,
            loading: true,
            saving : false,
            nextTopic: '',
            prevTopic: '',
            currentSection: '',
            nextSection: '',
            isQAMode: true,
            storySections: [],
            filter: 'all',
            isEmptyMyStory: false,
            selectedPrivacy: [],
            selectedUser: [],
            selectedFilterUser: [],
            selectedFilterPrivacy: [],
            options: [],
            defaultOptions: [],
            storyLoaded: false,
            userList: [],
            privacyList: [],
            filterType: 'all',
            filterText: 'You are viewing: ',
            filterOptions: [],
            defaultFilterOptions: [],
            userFilter: false,
            initialContent: true,
            sectionFilter: 'all',
            selectedViewStory: localStorage.get('selectedViewStory', 'list'),
            hasImageUpload: false,
            initializeViewMode: false
          };
          $scope.currState = $state;
          users.get().then(function() {
            $scope.data.defaultOptions = loadOptions(users.privacyOptions);
            $scope.data.defaultFilterOptions = angular.copy(loadOptions(users.privacyOptions));
            stories.nextDone = false;
            stories.get().then(function(loadedStories) {
              $scope.data.storySections = loadedStories;
              $scope.data.loading = true;
              if (!stories.nextDone) {
                var nextStory = getNextStory();
                if (nextStory) {
                  setSelectedStory(nextStory);
                } else {
                  console.log("Empty story sections received");
                }
                stories.nextDone = true;
              }

              getSelectedStory();

              // this was already guaranteed loaded by users.get()
              userStories = users.userStories;

              loadStory();
              $scope.data.loading = false;
              // TODO: Checkif needed or not ( could get away with $scope.filterMyStory(0))
              loadStoryModeData();
              checkAndSetCurrentSectionAndToipc();
            });
          });

          $scope.setSectionFilter = function(val) {
            var i = 1;
            angular.forEach($scope.data.storySections, function(section) {
              if (val == 'all' || parseInt(val) == i) {
                section.visible = true;
              } else {
                section.visible = false;
              }
              i++;
            });
            $scope.data.sectionFilter = val;
            $scope.filterMyStory(false);
          };

          function loadOptions(options) {
            options.forEach(function(opt) {
              opt.text = opt.name;
              opt.user = false;
              if ('users' in opt) {
                opt.children = [];
                opt.collapsed = true;
                opt.users.forEach(function(user) {
                  var child = {};
                  child.id = user.id;
                  child.text = user.name;
                  child.user = true;
                  child.type = opt.type;
                  opt.children.push(child);
                });
              }
            });
            return options;
          }

          // check for register as organization member
          if ($stateParams.organization_member) {
              if (!users.current.organization_id && !users.current.isJoinRequest && !$stateParams.folderId) {
                  dialogManager.showOrganizationMemberDialog();
              }
          }

          if ($stateParams.manage_orgs) {
            var text = utils.getSetting('introContentMode_8');
            text = text || '<p>Content to be added soon</p>';
            utils.confirm({
              'title': "organization.createdPopupTitle",
              'subcontentHTML': text,
              'ok': 'okay',
              'type': 'alert'
            });
            $stateParams.manage_orgs = null;
          }

          function getSelectedStory() {
            for (var i = 0; i < $scope.data.storySections.length ; i++) {
              for (var j = 0; j < $scope.data.storySections[i].topics.length ; j++ ) {
                if ($scope.data.storySections[i].topics[j].selected) {
                  $scope.data.selectedStory = $scope.data.storySections[i].topics[j];
                  currentStoryIndex = j;
                  currentSectionIndex = i;
                  if (j+1 < $scope.data.storySections[i].topics.length) {
                    $scope.data.nextStoryIndex = j+1;
                    nextSectionIndex = i;
                  } else if (i+1 < $scope.data.storySections.length) {
                    nextSectionIndex = i+1;
                    if ($scope.data.storySections[i+1].topics.length) {
                      $scope.data.nextStoryIndex = 0;
                    } else {
                      $scope.data.nextStoryIndex = -1;
                    }
                  } else {
                    $scope.data.nextStoryIndex = -1;
                    nextSectionIndex = -1;
                  }

                  if (j-1 >= 0) {
                    $scope.data.prevStoryIndex = j-1;
                    prevSectionIndex = i;
                  } else if (i-1 >= 0) {
                    prevSectionIndex = i-1;
                    if ($scope.data.storySections[i-1].topics.length) {
                      $scope.data.prevStoryIndex =$scope.data.storySections[i-1].topics.length-1;
                    } else {
                      $scope.data.prevStoryIndex = -1;
                    }
                  } else {
                    $scope.data.prevStoryIndex = -1;
                    prevSectionIndex = -1;
                  }
                  break;
                }
              }
            }
          }

          function filterUserWiseStory(userStories) {
            var empty = true;
            angular.forEach($scope.data.storySections, function(section) {
              var sectionExists = false;
              if (section.visible) {
                angular.forEach(section.topics, function(topic) {
                  var topicExists = false;
                  angular.forEach(userStories, function(story) {
                    if (topic.section_id == story.story_section_id && topic.id == story.story_topic_id) {
                      sectionExists = true;
                      topicExists = true;
                      empty = false;
                      topic.readMore = false;
                    }
                  });
                  topic.exists = topicExists;
                });
              }
              section.exists = sectionExists;
            });
            $scope.data.isEmptyMyStory = empty;
            $scope.data.isQAMode = false;
            $scope.data.filterLoading = false;
          }

          $scope.filterStory = function(isSwitch) {
            $scope.data.userFilter = false;
            isSwitch = isSwitch || false;
            $scope.data.filterLoading = true;
            if (isSwitch) {
              $scope.data.filterLoading = false;
            } else if ($scope.data.selectedFilterPrivacy.length && $scope.data.selectedFilterPrivacy.length == 1) {
              $scope.data.filter = $scope.data.selectedFilterPrivacy[0];
              $scope.filterMyStory(isSwitch);
            } else if ($scope.data.selectedFilterUser.length && $scope.data.selectedFilterUser.length == 1) {
              api.userStory.sharedStories($scope.data.selectedFilterUser[0]).then(function(response) {
                $scope.data.userFilter = true;
                filterUserWiseStory(response);
              }, function(response) {
                //
                $scope.data.filterLoading = false;
              });
            }
          };

          $scope.filterMyStory = function(isSwitch) {
            isSwitch = isSwitch || false;
            var empty = true;
            angular.forEach($scope.data.storySections, function(section) {
              var sectionExists = false;
              if (section.visible) {
                angular.forEach(section.topics, function(topic) {
                  var topicExists = false;
                  if (($scope.data.filter == 'all' && topic.userPrivacy !== undefined ) || (topic.userPrivacy !== undefined && topic.userPrivacy.indexOf($scope.data.filter) != -1 && (topic.userIncluded == undefined || !topic.userIncluded.length )) || (topic.userPrivacy !== undefined && $scope.data.filter == 'private' && topic.userPrivacy.indexOf(0) != -1 && (topic.userIncluded == undefined || !topic.userIncluded.length ))) {
                    sectionExists = true;
                    topicExists = true;
                    empty = false;
                  }
                  topic.exists = topicExists;
                });
              }
              section.exists = sectionExists;
            });
            $scope.data.isEmptyMyStory = empty;
            $scope.data.isQAMode = false;
            $scope.data.filterLoading = false;
          };

          $scope.getSaveText = function() {
            return ($scope.data.nextStoryIndex != -1) ? 'Save and Next' : 'Save and Finish Story';
          };

          $scope.nextStorySelected = function() {
            if ( $scope.data.nextStoryIndex != -1 ) {
              $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].selected = false;
              $scope.data.storySections[nextSectionIndex].topics[$scope.data.nextStoryIndex].selected = true;
              getSelectedStory();
              loadStory();
              var story_id = $scope.data.storySections[nextSectionIndex].topics[$scope.data.nextStoryIndex].id;
              var section_id = $scope.data.storySections[nextSectionIndex].topics[$scope.data.nextStoryIndex].section_id;
              $rootScope.$broadcast('center.story.clicked', $scope.data.selectedStory);
              $state.go('dashboard.story.current',{sectionId:section_id, topicId:story_id});
            } else {
              $scope.data.isQAMode = false;
            }
          };

          $scope.prevStorySelected = function() {
            if ( $scope.data.prevStoryIndex != -1 ) {
              $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].selected = false;
              $scope.data.storySections[prevSectionIndex].topics[$scope.data.prevStoryIndex].selected = true;
              getSelectedStory();
              loadStory();
              var story_id = $scope.data.storySections[nextSectionIndex].topics[$scope.data.nextStoryIndex].id;
              var section_id = $scope.data.storySections[prevSectionIndex].topics[$scope.data.prevStoryIndex].section_id;
              $rootScope.$broadcast('center.story.clicked', $scope.data.selectedStory);
              $state.go('dashboard.story.current',{sectionId:section_id, topicId:story_id});
            }
          };

          $scope.hasContentLength = function() {
            if ($scope.data.loading || !$scope.data.storyContent || $scope.data.storyContent == 'Loading your story...') {
              return false;
            }

            if ($scope.data.hasImageUpload) {
              if ($scope.data.storyContent) {
                $scope.data.hasImageUpload = false;
              }
              return true;
            }

            if ($($scope.data.storyContent).text().trim().length >= 1 || $($scope.data.storyContent).find('img').length || $scope.data.oldContent != $scope.data.storyContent) {
              return true;
            }

            return isDeleting();
          };

          function isDeleting() {
            if (selectedUserStoryIndex != -1 && $scope.data.oldContent && !$scope.data.storyContent) {
              return true;
            }
            return false;
          }

          $scope.closeSelectBox = function () {
            $("md-backdrop").trigger ("click");
          };

          $scope.saveStory = function() {
            var userStory;
            $scope.data.saving = true;
            if (selectedUserStoryIndex == -1 ) {
              userStory = new api.resources.userStory();
              userStory.story_topic_id = $scope.data.selectedStory.id;
              userStory.story_section_id = $scope.data.selectedStory.section_id;
            } else {
              userStory = userStories[selectedUserStoryIndex];
            }
            var updated = {};
            updated['topic_id'] = userStory.story_topic_id;
            updated['section_id'] = userStory.story_section_id;
            updated['filled'] = false;
            // userStory.content = $scope.data.storyContent;
            // userStory.content = userStory.content.replace(/<img src="([-:\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"\/>/g, '<video-preview item="item" data-src="$1" data-alt="$2"></video-preview>');
            // console.log("content changed to ", userStory.content);
            userStory.content = $scope.data.storyContent;
            userStory.privacy = $scope.data.selectedPrivacy;
            userStory.users = $scope.data.selectedUser;
            if (selectedUserStoryIndex == -1 ) {
              userStory.$save().then(function(data) {
                userStories.push(data);
                updated['filled'] = true;
                $rootScope.$broadcast('center.story.saved', updated);
                updateUserStoryData(data);
                $scope.nextStorySelected();
                utils.showToast(utils.trans('story.addedSuccessfully', true));
                $scope.data.saving = false;
              }, function(data) {
                utils.showToast(data, true);
                $scope.data.saving = false;
              });
            } else {
              if (isDeleting()) {
                userStory.$delete().then(function(data) {
                  userStories.splice(selectedUserStoryIndex, 1);
                  unsetUserStoryData();
                  updated['filled'] = false;
                  utils.showToast(utils.trans('story.clearedSuccessfully', true));
                  $rootScope.$broadcast('center.story.saved', updated);
                  $scope.nextStorySelected();
                  $scope.data.saving = false;
                },function(data) {
                  utils.showToast(data, true);
                  $scope.data.saving = false;
                });
              } else {
                userStory.$update().then(function(data) {
                  userStories[selectedUserStoryIndex] = data;
                  updateUserStoryData(data);
                  $scope.nextStorySelected();
                  utils.showToast(utils.trans('story.updatedSuccessfully', true));
                  $scope.data.saving = false;
                  if(!$scope.data.isQAMode) {
                    $scope.switchToMyStoryMode();
                  }
                }, function(data) {
                  utils.showToast(data, true);
                  $scope.data.saving = false;
                });
              }
            }
          };

          $scope.switchToMyStoryMode = function() {
            if (!$scope.data.initializeViewMode) {
              $scope.data.filter = 'all';
              $scope.data.sectionFilter = 'all';
              $scope.data.selectedFilterPrivacy.splice(0);
              $scope.data.selectedFilterUser.splice(0);
              var opts = angular.copy(defaultFilters);
              opts = opts.concat($scope.data.defaultFilterOptions);
              $scope.data.filterOptions.splice.apply($scope.data.filterOptions, [0, $scope.data.filterOptions.length].concat(opts));
              loadAllUsersStories();
            } else {              
              $scope.data.filter = 'all';
              $scope.data.sectionFilter = 'all';
              loadStoryModeData(true);
              $scope.filterStory(true);
              $scope.data.isQAMode = false;
            }
          };

          function loadAllUsersStories() {
            $scope.data.isQAMode = false;
            $scope.data.filterLoading = true;
            api.userStory.getContent().then(function(contentLoadedStories) {
              var i, j;
              for(i = 0; i < userStories.length; i++) {
                if (!userStories[i].content) {
                  for(j = 0; j < contentLoadedStories.length; j++) {
                    if (contentLoadedStories[j].story_section_id == userStories[i].story_section_id && contentLoadedStories[j].story_topic_id == userStories[i].story_topic_id && contentLoadedStories[j].content) {
                      userStories[i].content = contentLoadedStories[j].content;
                      break;
                    }
                  }
                }
              }
              loadStoryModeData(true);
            }, function() {
              // HANDLE FAILED CASE

            }).finally(function() {
              $scope.setSectionFilter('all');
              $scope.filterStory(true);
              $scope.data.filterLoading = false;
              $scope.data.initializeViewMode = true;
              $scope.data.isQAMode = false;
            });
          }

          function setSelectedStory(selectedTopic) {
            angular.forEach($scope.data.storySections, function(section) {
              if (selectedTopic.section_id == section.id) {
                section.expanded = true;
              } else {
                section.expanded = false;
              }
              angular.forEach(section.topics, function(topic) {
                if (topic.id == selectedTopic.id) {
                  topic.selected = true;
                } else {
                  topic.selected = false;
                }
              });
            });
            getSelectedStory();
          }

          function updateUserStoryData(storyData) {
            $scope.data.storySections[currentSectionIndex].exists = true;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].exists = true;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userContent = storyData.content;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userPrivacy = storyData.privacy;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userIncluded = storyData.included_users;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].readMore = false;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].showReadMore = false;
            if (storyData.content.length >= 150) {
              $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].showReadMore = true;
            }
          }

          function unsetUserStoryData() {
            $scope.data.storySections[currentSectionIndex].exists = false;
            $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].exists = false;
            delete $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userContent;
            delete $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userPrivacy;
            delete $scope.data.storySections[currentSectionIndex].topics[currentStoryIndex].userIncluded;
          }

          function leftClicked(story) {
            if (!$scope.data.isQAMode) {
              $scope.data.isQAMode = true;
            }
            setSelectedStory(story);
            loadStory();
          }

          //These all should be scoped as one
          $scope.$on('left.story.clicked', function(e, story) {
            leftClicked(story);
          });

          $scope.$on('left.story.updated', function(e, story) {
            leftClicked(story);
          });

          $scope.$on('left.story.added', function(e, story) {
            leftClicked(story);
          });

          // $scope.$on('left.story.deleted', function(e, story) {
          //   angular.forEach($scope.data.storySections, function(section) {
          //     if (section.id == story.section_id) {
          //       var idx = -1;
          //       angular.forEach(section.topics, function(topic, index) {
          //         if (topic.id == story.id) {
          //           idx = index;
          //         }
          //       });
          //       if (idx !== -1) {
          //         section.topics.splice(idx, 1);
          //       }
          //     }
          //   });
          // });

          function loadStoryModeData() {
            var empty = true;
            angular.forEach($scope.data.storySections, function(section) {
              var sectionExists = false;
              angular.forEach(section.topics, function(topic) {
                var topicExists = false;
                angular.forEach(userStories, function(story) {
                  if (story.story_section_id == topic.section_id) {
                    if (story.story_topic_id == topic.id) {
                      topic.userContent = '';
                      topic.userPrivacy = story.privacy;
                      topic.userIncluded = story.included_users;
                      topicExists = true;
                      sectionExists = true;
                      empty = false;
                      topic.showReadMore = false;
                      topic.expansion = false;
                      topic.bodyLoaded = false;
                      if (story.content) {
                        topic.userContent = story.content.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');
                        if (!angular.element(story.content).find("img").length && !angular.element(story.content).find("video-preview").length) {
                          var dimensions = calculateWordDimensions(story.content, ['storyPageTopicBody'], false);
                          if (dimensions.height > 260) {
                            topic.showReadMore = true;
                            topic.expansion = false;
                          }
                          topic.bodyLoaded = true;
                        }
                      }
                    }
                  }
                });
                topic.exists = topicExists;
              });
              section.exists = sectionExists;
              section.visible = true;
            });
            $scope.data.isEmptyMyStory = empty;
          }

          function getNextStory() {
            var foundSelected = false;
            for (var i = 0; i < $scope.data.storySections.length ; i++) {
              if ($scope.data.storySections[i].expanded) {
                for (var j = 0; j < $scope.data.storySections[i].topics.length ; j++ ) {
                  if ($scope.data.storySections[i].topics[j].selected) {
                    if (j+1 < $scope.data.storySections[i].topics.length) {
                      $scope.data.storySections[i].topics[j].selected = false;
                      $scope.data.storySections[i].topics[j+1].selected = true;
                      return $scope.data.storySections[i].topics[j+1];
                    } else if (i+1 < $scope.data.storySections.length) {
                      if ($scope.data.storySections[i+1].topics.length) {
                        $scope.data.storySections[i].expanded = false;
                        $scope.data.storySections[i].topics[j].selected = false;
                        $scope.data.storySections[i+1].expanded = true;
                        $scope.data.storySections[i+1].topics[0].selected = true;
                        return $scope.data.storySections[i+1].topics[0];
                      }
                    }
                    foundSelected = true;
                    break;
                  }
                }
              }
            }
            if (!foundSelected && $scope.data.storySections.length) {
              for (var iss = 0; iss < $scope.data.storySections.length; iss++) {
                if ($scope.data.storySections[iss].topics.length) {
                    $scope.data.storySections[iss].expanded = true;
                    $scope.data.storySections[iss].topics[0].selected = true;
                    return $scope.data.storySections[iss].topics[0];
                }
              }
            }
            return null;
          }

          function getSelectedUserStoryIndex() {
            var selectedUserStoryIndex = -1;

            for (var i = 0; i < userStories.length ; i++) {
              if ($scope.data.selectedStory && userStories[i].story_section_id == $scope.data.selectedStory.section_id && userStories[i].story_topic_id == $scope.data.selectedStory.id ) {
                selectedUserStoryIndex = i;
                break;
              }
            }

            if (selectedUserStoryIndex == -1) {
              if (userStories.length && !$scope.data.selectedStory) {
                selectedUserStoryIndex = 0;
              }
            }

            return selectedUserStoryIndex;
          }

          function checkAndSetCurrentSectionAndToipc() {
            var states = $state.current.name.split('.');
            if (states.length === 3 && states[2] && states[2] === 'view') {
                $scope.switchToMyStoryMode();
            } 
            if ($scope.data.storySections && $state.params.sectionId && $state.params.topicId) {
              if (states.length === 3 && states[2] && states[2] === 'current') {
                angular.forEach($scope.data.storySections, function(section) {
                  if ($state.params.sectionId == section.id) {
                    section.expanded = true;
                  } else {
                    section.expanded = false;
                  }
                  angular.forEach(section.topics, function(topic) {
                    if (topic.id == $state.params.topicId) {
                      topic.selected = true;
                    } else {
                      topic.selected = false;
                    }
                  });
                });
                getSelectedStory();
                loadStory();
              }
            }
          }

          function loadStory() {
            $scope.data.loading = true;
            $scope.data.storyContent = "Loading your story...";
            $scope.data.fetchContent = false;
            selectedUserStoryIndex = getSelectedUserStoryIndex();

            if (selectedUserStoryIndex == -1) {
              $scope.data.storyContent = '';
              $scope.data.oldContent = '';
              $scope.data.userList.splice(0);
              $scope.data.privacyList.splice(0, $scope.data.privacyList.length, 0);
              $scope.data.loading = false;
              $scope.data.initialContent = false;
              loadStoryAfterData();
              editorFocus();
            } else if (selectedUserStoryIndex != -1) {
              $scope.data.fetchContent = true;
              $scope.data.loading = false;
              $scope.data.userList.splice.apply($scope.data.userList, [0, $scope.data.userList.length].concat(userStories[selectedUserStoryIndex].included_users));
              $scope.data.privacyList.splice.apply($scope.data.privacyList, [0, $scope.data.privacyList.length].concat(userStories[selectedUserStoryIndex].privacy));
              loadStoryAfterData();
              editorFocus();
              if ('content' in userStories[selectedUserStoryIndex] && userStories[selectedUserStoryIndex].content) {
                $scope.data.storyContent = userStories[selectedUserStoryIndex].content;
                $scope.data.oldContent = userStories[selectedUserStoryIndex].content;
                $scope.data.fetchContent = false;
                $scope.data.initialContent = false;
                $scope.data.loading = false;
                editorFocus();
              } else {
                $scope.data.initialContent = false;
                $scope.data.loading = false;
                api.file.htmlContent(userStories[selectedUserStoryIndex].share_id).then(function(data) {
                  $scope.data.storyContent = data;
                  $scope.data.oldContent = data;
                  userStories[selectedUserStoryIndex].content = data;
                  $scope.data.fetchContent = false;
                  editorFocus();
                }, function() {
                  $scope.data.loading = false;
                  // HANDLE FAILED CASE
                });
              }
            }
          }

          // var editorScope = textAngularManager.retrieveEditor('story-editor').scope;

          function editorFocus() {
              $timeout(function() {
                var editorScope = textAngularManager.retrieveEditor('story-editor').scope;
                if (editorScope) {
                  editorScope.displayElements.text.trigger('focus');
                }
              }, 5);
            // if (!editorScope) {
            //   editorScope = textAngularManager.retrieveEditor('story-editor').scope;
            // }

            // if (editorScope) {
            //   editorScope.displayElements.text.trigger('focus');
            // }
          }

          function loadStoryAfterData() {
            var newOptions = angular.copy($scope.data.defaultOptions);
            $scope.data.privacyChanged = false;
            if ($scope.data.nextStoryIndex != -1) {
              $scope.data.nextTopic = $scope.data.storySections[nextSectionIndex].topics[$scope.data.nextStoryIndex].topic;
            } else {
              $scope.data.nextTopic = "";
            }

            if ($scope.data.prevStoryIndex != -1) {
              $scope.data.prevTopic = $scope.data.storySections[prevSectionIndex].topics[$scope.data.prevStoryIndex].topic;
            } else {
              $scope.data.prevTopic = "";
            }

            if (nextSectionIndex != -1) {
              if (nextSectionIndex == currentSectionIndex) {
                var idx = nextSectionIndex + 1;
                if (idx < $scope.data.storySections.length) {
                  $scope.data.nextSection = $scope.data.storySections[idx].name;
                  jumpToNextSection = idx;
                } else {
                  $scope.data.nextSection = "";
                  jumpToNextSection = -1;
                }
              } else if (nextSectionIndex > currentSectionIndex) {
                $scope.data.nextSection = $scope.data.storySections[nextSectionIndex].name;
                jumpToNextSection = nextSectionIndex;
              }
            } else {
              $scope.data.nextSection = "";
              jumpToNextSection = -1;
            }
            $scope.data.currentSection = $scope.data.storySections[currentSectionIndex].name;

            if (!$scope.data.storyLoaded) {
              $scope.data.storyLoaded = true;
              $scope.data.options = newOptions;
            } else {
              // $scope.data.options.splice.apply($scope.data.options, [0, $scope.data.options.length].concat(newOptions));
              //TODO:
              // This is not a good way to do this
              $rootScope.$broadcast('storypage.options.changed', newOptions);
            }
          }

          // check for an email parameters managnement
          if($stateParams.manage_email) {
            users.showAccountSettingsModal(null, 'notification').then(function(response) {
            }).finally(function() {
              $state.go('dashboard.story', { manage_email: null  });
            });
          }

          $scope.privacyChanged = function() {
            $scope.data.privacyChanged = true;
            if (!$scope.data.selectedUser.length && !$scope.data.selectedPrivacy.length) {
              $scope.data.privacyChanged = false;
            }
          };

          $scope.getPrivacyName = function(privacy, isUser) {
            isUser = isUser || false;

            if (privacy === undefined) {
              return;
            }

            var name = '';

            $scope.data.filterOptions.forEach(function(option) {
              if (!isUser && ((option.id == privacy) || (privacy === 0 && option.id == 'private'))) {
                  name = option.name;
              }
              if (isUser && 'children' in option) {
                option.children.forEach(function(child) {
                  if (child.id == privacy && child.user == isUser) {
                    name = child.text;
                  }
                });
              }
            });
            return name ? name : 'Something went wrong';
          };

          $scope.openMenu = function($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
          };

          $scope.editMyStoryName = function(story) {
            dialogManager.showAddTopicDialog(story.section_id, story).then(function(newStory) {
              story.topic = newStory.topic;
              $rootScope.$broadcast('center.story.name.changed', newStory);
            }, function() {
              // handle failed case
            });
          };

          $scope.deleteMyStory = function(story) {
            api.storyTopic.delete(story.section_id, story.id).then(function() {
              utils.showToast(utils.trans('topic.deletedSuccessfully', true));
              $rootScope.$broadcast('center.story.deleted', story);
            }, function() {
              //handle failed case
            });
          };

          $scope.getPrivacyNames = function(story) {
            var names = [];
            var filterId = $scope.data.filter;
            filterId = filterId == 'private' ? 0 : $scope.data.filter;
            if (story.userPrivacy.length && !story.userIncluded.length) {
              story.userPrivacy.forEach(function(privacy) {
                if (filterId == privacy || filterId == 'all') {
                  var name = $scope.getPrivacyName(privacy, false);
                  if (name) {
                    names.push(name);
                  }
                }
              });
            } else if (story.userPrivacy.length && story.userIncluded.length) {
              story.userIncluded.forEach(function(privacy) {
                if ($scope.data.userFilter || filterId == 'all') {
                  var name = $scope.getPrivacyName(privacy, true);
                  if (name) {
                    names.push(name);
                  }
                }
              });
            }

            return names.length ? names : ['Something went wrong'];
          };


          $scope.getSelectedPrivacyNames = function(story) {
            var privacyNames = $scope.getPrivacyNames(story);
            var numberOfPrivacies = privacyNames.length;

            if (numberOfPrivacies > 1) {
              var needsOxfordComma = numberOfPrivacies > 2;
              var lastPrivacyConjunction = (needsOxfordComma ? ',' : '') + ' and ';
              var lastPrivacy = lastPrivacyConjunction + privacyNames[privacyNames.length - 1];
              return privacyNames.slice(0, -1).join(', ') + lastPrivacy;
            }
            return privacyNames.join('');
          };

          $scope.nextSectionClicked = function() {
            if (jumpToNextSection != -1) {
              $scope.data.nextStoryIndex = 0;
              nextSectionIndex = jumpToNextSection;
              $scope.nextStorySelected();
            }
          };

          $scope.toggleSelectedView = function() {
              if ($scope.data.selectedViewStory === 'list') {
                  $scope.data.selectedViewStory = 'grid';
              } else {
                  $scope.data.selectedViewStory = 'list';
              }

              localStorage.set('selectedViewStory', $scope.data.selectedViewStory);
          };

          // Calculate dimensions of html without rendering
          function calculateWordDimensions(text, classes, escape) {
              classes = classes || [];

              if (escape === undefined) {
                  escape = true;
              }

              classes.push('textDimensionCalculation');

              var div = document.createElement('div');
              div.setAttribute('class', classes.join(' '));

              if (escape) {
                  $(div).text(text);
              } else {
                div.innerHTML = text;
              }

              document.body.appendChild(div);

              var dimensions = {
                  width : $(div).outerWidth(),
                  height : $(div).outerHeight()
              };

              div.parentNode.removeChild(div);

              return dimensions;
          }

          $scope.$on('imgeditor.completed', function() {
            $timeout(function() {
              $scope.data.hasImageUpload = true;
            }, 3000);
          });

          $scope.$on('select-editor-image',
            function(emittedEvent, event, $element, editorScope)  {
              // setup the editor toolbar
              // Credit to the work at http://hackerwins.github.io/summernote/ for this editbar logic/display
              event.preventDefault();

              var finishEdit = function(){
                  editorScope.updateTaBindtaTextElement();
                  editorScope.hidePopover();
              };

              editorScope.displayElements.popover.css('width', '375px');
              var container = editorScope.displayElements.popoverContainer;
              container.empty();
              var buttonGroup = angular.element('<div class="btn-group" style="padding-right: 6px;">');
              var fullButton = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1">100% </button>');
              fullButton.on('click', function(event){
                  event.preventDefault();
                  $element.css({
                      'width': '100%',
                      'height': ''
                  });
                  finishEdit();
              });
              var halfButton = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1">50% </button>');
              halfButton.on('click', function(event){
                  event.preventDefault();
                  $element.css({
                      'width': '50%',
                      'height': ''
                  });
                  finishEdit();
              });
              var quartButton = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1">25% </button>');
              quartButton.on('click', function(event){
                  event.preventDefault();
                  $element.css({
                      'width': '25%',
                      'height': ''
                  });
                  finishEdit();
              });
              var resetButton = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1">Reset</button>');
              resetButton.on('click', function(event){
                  event.preventDefault();
                  $element.css({
                      width: '',
                      height: ''
                  });
                  finishEdit();
              });
              buttonGroup.append(fullButton);
              buttonGroup.append(halfButton);
              buttonGroup.append(quartButton);
              buttonGroup.append(resetButton);
              container.append(buttonGroup);

              buttonGroup = angular.element('<div class="btn-group" style="padding-right: 6px;">');
              var floatLeft = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1"><i class="fa fa-align-left"></i></button>');
              floatLeft.on('click', function(event){
                  event.preventDefault();
                  // webkit
                  $element.css('float', 'left');
                  // firefox
                  $element.css('cssFloat', 'left');
                  // IE < 8
                  $element.css('styleFloat', 'left');
                  finishEdit();
              });
              var floatRight = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1"><i class="fa fa-align-right"></i></button>');
              floatRight.on('click', function(event){
                  event.preventDefault();
                  // webkit
                  $element.css('float', 'right');
                  // firefox
                  $element.css('cssFloat', 'right');
                  // IE < 8
                  $element.css('styleFloat', 'right');
                  finishEdit();
              });
              var floatNone = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1"><i class="fa fa-align-justify"></i></button>');
              floatNone.on('click', function(event){
                  event.preventDefault();
                  // webkit
                  $element.css('float', '');
                  // firefox
                  $element.css('cssFloat', '');
                  // IE < 8
                  $element.css('styleFloat', '');
                  finishEdit();
              });
              buttonGroup.append(floatLeft);
              buttonGroup.append(floatNone);
              buttonGroup.append(floatRight);
              container.append(buttonGroup);

              buttonGroup = angular.element('<div class="btn-group">');
              var remove = angular.element('<button type="button" class="btn btn-default btn-sm btn-small" unselectable="on" tabindex="-1"><i class="fa fa-trash-o"></i></button>');
              remove.on('click', function(event){
                  event.preventDefault();
                  $element.remove();
                  finishEdit();
              });
              buttonGroup.append(remove);
              container.append(buttonGroup);

              //Replace with material icons
              var iconsForPopOver = [
                  { selectorClass: "fa-align-left" , iconClass: "format_align_left"},
                  { selectorClass: "fa-align-right" , iconClass: "format_align_right"},
                  { selectorClass: "fa-align-justify" , iconClass: "format_align_justify"},
                  { selectorClass: "fa-trash-o" , iconClass: "delete_outline"},
              ];

              iconsForPopOver.forEach(function(icon) {
                  editorScope.displayElements.popover.find('.'+icon.selectorClass)
                  .each(function() {
                      angular.element(this).addClass('material-icons').text(icon.iconClass);
                  });
              });

              // NOTE: This is replacing the reflowPopover on textAngular with this, because theirs doesn't work in our environment properly.
              patchEditorReflowPopover(editorScope);
              editorScope.showPopover($element);
              editorScope.showResizeOverlay($element);
            }
          );

          function patchEditorReflowPopover(scope) {
            // this is patching editor to use this vs. the one that they had before.
            // this patch only has to be installed once, but it's being installed every time the popup is shown; no damage there, but unecessary.
            // had to be done because the scrolling code (vertical) calculation was off in the textAngular one.
            scope.reflowPopover = function(_el){
              var scrollTop = scope.getScrollTop(scope.displayElements.scrollWindow[0], false);

               var pos = _el.position();
               var offset = pos.top + scope.displayElements.scrollWindow[0].scrollTop;

              /* istanbul ignore if: catches only if near bottom of editor */
              if(pos.top < 51) {
                  // scope.displayElements.popover.css('top', _el[0].offsetTop + _el[0].offsetHeight + scope.displayElements.scrollWindow[0].scrollTop + 'px');
      // rdm; because I'm in a scrollbox, this doesn't work, so instead it needs to NOT have the scrollTop added.  I think this works if the entire thing is scrolling, but not
      // if it's just the area that you are typing in.
                  scope.displayElements.popover.css('top', offset + _el[0].offsetHeight + 'px');
                  scope.displayElements.popover.removeClass('top').addClass('bottom');
              } else {
                  // scope.displayElements.popover.css('top', _el[0].offsetTop - 54 + scope.displayElements.scrollWindow[0].scrollTop + 'px');
                  scope.displayElements.popover.css('top', offset - 54 + 'px');
                  scope.displayElements.popover.removeClass('bottom').addClass('top');
              }
              var _maxLeft = scope.displayElements.text[0].offsetWidth - scope.displayElements.popover[0].offsetWidth;
              var _targetLeft = _el[0].offsetLeft + (_el[0].offsetWidth / 2.0) - (scope.displayElements.popover[0].offsetWidth / 2.0);
              var _rleft = Math.max(0, Math.min(_maxLeft, _targetLeft));
              var _marginLeft = (Math.min(_targetLeft, (Math.max(0, _targetLeft - _maxLeft))) - 11);
              _rleft += window.scrollX;
              _marginLeft -= window.scrollX;
              scope.displayElements.popover.css('left', _rleft + 'px');
              scope.displayElements.popoverArrow.css('margin-left', _marginLeft + 'px');
            };
          }
        }
      };
    }])

    .filter('flattenStory', function() {
      return function(storySections) {
        var stories = [];

        angular.forEach(storySections, function(section) {
          if (section.exists && section.visible) {
            angular.forEach(section.topics, function(topic) {
              if (topic.exists) {
                stories.push(topic);
              }
            });
          }
        });
        return stories;
      };
    })

    .filter('oddItems', function() {
      return function(stories) {
        var oddStories = [];
        var i = 1;

        angular.forEach(stories, function(story) {
          if (i%2 == 1) {
            oddStories.push(story);
          }
          i++;
        });

        return oddStories;
      };
    })

    .filter('evenItems', function() {
      return function(stories) {
        var evenStories = [];
        var i = 1;

        angular.forEach(stories, function(story) {
          if (i%2 == 0) {
            evenStories.push(story);
          }
          i++;
        });

        return evenStories;
      };
    });
})();