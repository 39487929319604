(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('dashboardFooter', ['$http', function($http) {
        return {
            restrict: 'E',
            templateUrl: 'dashboardFooter.html',
            scope: {
            },
            link: function($scope) {
                $scope.data= {
                    items: []
                };

                $http.get('footermenu').then(function(response) {
                    $scope.data.items = response.data;
                });
            }
        };
    }]);

})();