angular.module('pixie.dashboard')

.directive('familyInvite', ['$stateParams', '$state', 'users', function($stateParams, $state, users) {
    return {
        restrict: 'E',
        template: '',
        link: function($scope, el, at) {
            if($stateParams && $stateParams.link !== undefined){
                if(users.current) {
                    $state.go('dashboard.hives', { family_invite_link: $stateParams.link });
                } else {
                    $state.go('login', { family_invite_link: $stateParams.link });
                }
                return;
            }
        }
    };
}]);
