(function(){
    'use strict';

    angular.module('app').controller('OffersController', ['$scope', '$rootScope', '$state', '$mdDialog', 'utils', 'users', 'offers', 'dialogManager', 'api', function($scope, $rootScope, $state, $mdDialog, utils, users, offers, dialogManager, api) {

        $scope.offers = offers;

        //Offers search query
        $scope.search = { query: '' };

        $scope.selectedItems = [];

        var oldItemsPerPage =  10;
        var oldQuery = '';

        $scope.isItemSelected = function(item) {
            return $scope.selectedItems.indexOf(item) > -1;
        };

        $scope.select = function(item) {
            var idx = $scope.selectedItems.indexOf(item);
            if (idx > -1) {
                $scope.selectedItems.splice(idx, 1);
            }
            else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.deleteOffers = function() {

            offers.delete($scope.selectedItems).then(function() {
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        $scope.toggleAllOffers = function() {

            //all items already selected, deselect all
            if ($scope.selectedItems.length === offers.all.length) {
                $scope.selectedItems = [];
            }

            //all items aren't selected, copy all users array to selected items
            else {
                $scope.selectedItems = offers.all.slice();
            }
        };

        $scope.showCreateOfferModal = function($event) {
            dialogManager.showCreateOfferDialog($event).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.showUpdateOfferModal = function(offer, $event) {
            dialogManager.showUpdateOfferDialog($event, offer).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.changeState = function(offer) {
            api.offers.toggleActive(offer.id, offer.isActive).then(function() {
                utils.showToast('updatedOfferSuccessfully', true);
                $scope.paginate($scope.params);
                $scope.selectedItems = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };

        $scope.paginate = function(params) {
            params = params || $scope.params;
            if ($scope.usersAjaxInProgress) {
                return;
            }

            if (typeof params === 'object' && params !== null && 'query' in params) {
                if (params.query != oldQuery) {
                    oldQuery = params.query;
                    if ('page' in params) {
                        params.page = 1;
                    }
                }
            }

            if (typeof params === 'object' && params !== null && 'itemsPerPage' in params) {
                if (oldItemsPerPage) {
                    if (params.itemsPerPage != oldItemsPerPage) {
                        oldItemsPerPage = params.itemsPerPage;
                        if ('page' in params) {
                            params.page = 1;
                        }
                    }
                }
            }


            $scope.usersAjaxInProgress = true;

            api.offers.paginate(params).then(function(response) {
                $scope.items = response.data;
                $scope.totalItems = response.total;


                $scope.usersAjaxInProgress = false;

                // clear any selected items
                $scope.selectedItems = [];
            });
        };

        $scope.paginate($scope.params);
    }]);
}());