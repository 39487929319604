(function () {
    'use strict';

    angular.module('app')
    .directive('timelinePanel', ['api', '$rootScope', '$state', function(api, $rootScope, $state) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/timeline-panel.html',
            link: function($scope, el, attrs) {
                $scope.data = {
                    years: [],
                    loading: false,
                    selectedDay: null,
                    type: 'all',
                    isDateLoaded: true
                };

                function loadPanel() {
                    $scope.data.loading = true;
                    api.post.dayWisePosts($scope.data.type).then(function(response) {
                        $scope.data.years = adjustTimelinePanelData(response.data);
                        if ($scope.data.selectedDay) {
                            $scope.selectDay($scope.data.selectedDay);
                        } else {
                            selectFirstDay();
                        }
                    }).finally(function() {
                        $scope.data.loading = false;
                    });
                }

                function adjustTimelinePanelData(data) {
                    var years = getYears(data);
                    years.forEach(function(year) {
                        year.months = getMonths(data, year.text);
                        year.months.forEach(function(month) {
                            month.days = getDays(data, month.text);
                            month.count = 0;
                            month.days.forEach(function(day){
                                month.count += day.count;
                            });
                        });
                        year.count = 0;
                        year.months.forEach(function(month){
                            year.count += month.count;
                        });
                    });
                    return years;
                }

                function getYears(data) {
                    var years = [];
                    var foundYears = [];
                    data.forEach(function(year) {
                        if (foundYears.indexOf(year.created_year) === -1) {
                            foundYears.push(year.created_year);
                            years.push({'text': year.created_year, 'expanded': false, 'selected': false});
                        }
                    });
                    return years;
                }

                function getMonths(data, year) {
                    var months = [];
                    var foundMonths = [];
                    data.forEach(function(month) {
                        if (month.created_month.indexOf(year) === 0 && (foundMonths.indexOf(month.created_month.split('-')[1]) === -1)) {
                            months.push({'text': month.created_month, 'expanded': false, 'selected': false, 'month_num': new Date(parseInt(month.created_month.split('-')[0]), parseInt(month.created_month.split('-')[1])-1)});
                            foundMonths.push(month.created_month.split('-')[1]);
                        }
                    });
                    return months;
                }

                function getDays(data, month) {
                    var days = [];
                    var foundDays = [];
                    data.forEach(function(day) {
                        if (day.created_date.indexOf(month) === 0 && foundDays.indexOf(day.created_date.split('-')[2]) === -1) {
                            days.push({'count':day.count, 'text': day.created_date, 'expanded': false, 'selected': false, 'day_num': parseInt(day.created_date.split('-')[2]), 'day_ordinal': moment(day.created_date, 'YYYY-MM-DD').format("Do")});
                            foundDays.push(day.created_date.split('-')[2]);
                        }
                    });
                    return days;
                }

                function selectFirstDay(isStart) {
                    isStart = isStart || false;
                    var states = $state.current.name.split('.');
                    if (!isStart) {
                        clearAllSelected();
                    }
                    if (states.length === 4 && states[3] && states[3] === 'date' && $state.params.date) {
                        $scope.StayOnCurrentDate();
                    } else if ($scope.data.years.length) {
                        if ($scope.data.years[0].months.length) {
                            if ($scope.data.years[0].months[0].days.length) {
                                $scope.data.years[0].expanded = true;
                                $scope.data.years[0].selected = true;
                                $scope.data.years[0].months[0].expanded = true;
                                $scope.data.years[0].months[0].selected = true;
                                $scope.data.years[0].months[0].days[0].expanded = true;
                                $scope.data.years[0].months[0].days[0].selected = true;
                            } else {
                                console.log('unexpected scenario - contact administrator');
                            }
                        } else {
                            console.log('unexpected scenario - contact administrator');
                        }
                    }
                }

                function clearAllSelected() {
                    $scope.data.years.forEach(function(year) {
                        year.expanded = false;
                        year.selected = false;
                        year.months.forEach(function(month) {
                            month.expanded = false;
                            month.selected = false;
                            month.days.forEach(function(day) {
                                day.expanded = false;
                                day.selected = false;
                            });
                        });
                    });
                }

                $scope.toggleYear = function (selectedYear) {
                    $scope.data.years.forEach(function(year) {
                        var yearExpanded = false;
                        if (selectedYear.text == year.text) {
                            yearExpanded = !year.expanded;
                        }
                        year.expanded = yearExpanded;
                        year.selected = yearExpanded;
                    });
                };

                $scope.toggleMonth = function (selectedMonth) {
                    $scope.data.years.forEach(function(year) {
                        year.months.forEach(function(month) {
                            var monthExpanded = false;
                            if (selectedMonth.text == month.text) {
                                monthExpanded = !month.expanded;
                            }
                            month.expanded = monthExpanded;
                            month.selected = monthExpanded;
                        });
                    });
                };

                $scope.selectDay = function (selectedDay, loadmore) {
                    $scope.data.selectedDay = selectedDay;
                    $scope.data.years.forEach(function(year) {
                        year.months.forEach(function(month) {
                            month.days.forEach(function(day) {
                                var daySelected = false;
                                if (selectedDay.text == day.text) {
                                    daySelected = true;
                                }
                                day.expanded = daySelected;
                                day.selected = daySelected;
                            });
                        });
                    });
                    $rootScope.$broadcast('left-nav.selectDate' , { date : selectedDay.text, more: loadmore });
                };

                $scope.$on('timelinepost.reloadpanel', function(evt, isCreatePost) {
                    isCreatePost = isCreatePost || false;
                    if (isCreatePost) {
                        $scope.data.selectedDay = null;
                    }
                    if ($scope.data.isDateLoaded) {
                        loadPanel();
                    }
                });

                $scope.$on('timeline.tab.changed', function(evt, currentType) {
                    $scope.data.type = currentType;
                    $scope.data.years = [];
                    $scope.data.selectedDay = null;
                    loadPanel() ;
                });

                loadPanel();
                $scope.StayOnCurrentDate = function () {
                    var states = $state.current.name.split('.');
                    if (states.length === 4 && states[3] && states[3] === 'date') {
                        if ($state.params.date) {
                            var selectedDay = {
                                text: $state.params.date,
                                selected: true,
                                expended: true
                            };
                            var dateFormat = $state.params.date.split('-');
                            var year = { text: dateFormat[0] };
                            var month = { text: dateFormat[0] + '-' + dateFormat[1] };
                            $scope.selectDay(selectedDay, true);
                            $scope.toggleYear(year);
                            $scope.toggleMonth(month);
                            $scope.data.isDateLoaded = false;
                        }
                    }
                };
            }
        };
    }]);
}());
