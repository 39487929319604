(function () {
    'use strict';

    angular.module('pixie.dashboard')

        /**
         * This is the main view center dashboard.
         */
        .directive('centerDashboard', ['utils', 'folders', 'activity', 'dashboardState', 'uploadManager', '$state', 'listeners', 'users', '$rootScope', function (utils, folders, activity, dashboardState, uploadManager, $state, listeners, users, $rootScope) {
            return {
                restrict: 'E',
                templateUrl: 'centerDashboard.html',
                scope: {},
                link: function ($scope) {
                    // $scope.utils = utils; // yuck.

                    $scope.data = {
                        dashboardState: dashboardState,
                        storyMode: $state.current.name == "dashboard.story",
                        roomsMode: $state.current.name == "dashboard.rooms"
                    };

                    function loadStoryClass(stateName) {
                        if (stateName == 'dashboard.story') {
                            $scope.data.storyMode = true;
                        } else {
                            $scope.data.storyMode = false;
                        }

                        if (stateName == 'dashboard.rooms') {
                            $scope.data.roomsMode = true;
                        } else {
                            $scope.data.roomsMode = false;
                        }
                    }

                    $scope.$on('$stateChangeSuccess', function (e, toState, params) {
                        loadStoryClass(toState.name);
                    });

                    $rootScope.$on('user.leaveWebsite', function () {
                        listeners.leaveWebsite();
                    });

                    console.log("Center dashboard directive loadedddd");

                    // Load website user listener
                    listeners.loadWebsiteUsersListener();
                    listeners.loadBusyUsers();

                    if (users.current) {
                        listeners.loadVideoChatListener(users.current);
                    } else {
                        alert("MyVideo has issues!");
                    }

                    // these are first time loads.
                    // folders.bindEvents();

                    if (!folders.available.length) {
                        // folders.getAll(null);
                        // folders.bindEvents();
                        folders.getFromCache();
                        // activity.getAll();
                    }

                    $scope.upload = function (files) {
                        uploadManager.upload(files);
                    };
                }
            };
        }])
        .value('dashboardState', { loaded: false })
        .value('previewStatus', { open: false });
})();