(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('hivePosts', [ function() {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/hive-posts.html',
            scope : {
                familyPosts : "=",
                myPosts : "="
            },
            link: function($scope, el, attr) {
                //
            }
        };
    }]);

}());