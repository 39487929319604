(function(){
    'use strict';

    angular.module('pixie.admin')

    .directive('showAdminKeys', ['utils', function(utils) {
        return {
            restrict: 'A',
            compile: function(el) {
                el.on('click', function(e) {
                    $('[type="password"]', '#keys').attr('type', 'text');
                    el.hide();
                });
            }
       	};
    }]);
}());