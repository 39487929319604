(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminEditOrganization', ['utils', '$rootScope', 'dialogManager', 'api', '$q', 'users', function(utils, $rootScope, dialogManager, api, $q, users) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/admin-edit-organization.html',
            scope: {},
            link: function($scope, $element, $attr) {
                var orgObj = null;
                $scope.data = {
                    isOrgOwner: users.current && users.current.isOrgOwner,
                    userOrgId: null,
                    org: null,
                    loading: true,
                };

                if ($scope.data.isOrgOwner) {
                    $scope.data.userOrgId = users.current.organization_id;
                    api.resources.organization.query({type: 'org_id', org_id: $scope.data.userOrgId}, function(orgs) {
                        orgs.forEach(function(org) {
                            if (org.id == $scope.data.userOrgId) {
                                org.zip = org.zip ? parseInt(org.zip) : org.zip;
                                $scope.data.org = org;
                                orgObj = angular.copy(org);
                            }
                        });
                        $scope.data.loading = false;
                    });
                }

                $scope.saveOrganization = function(submit) {
                    submit = submit || false;
                    $scope.data.org.$update().then(function(response) {
                        if (submit) {
                            $scope.submitOrganization();
                        } else {
                            utils.showToast(utils.trans('organization.updatedSuccessfully'));
                            $scope.data.org.owner_name = response.owner_name;
                        }
                        orgObj = angular.copy($scope.data.org);
                    }, function(response) {
                        utils.showToast(utils.trans(response.data));
                    });
                };

                $scope.resetOrganization = function() {
                    if (!orgObj) {
                        return;
                    }

                    $scope.data.org = angular.copy(orgObj);
                };

                $scope.submitOrganization = function() {
                    api.organization.submit($scope.data.org.id).then(function(response) {
                        utils.showToast(utils.trans('organization.submittedSuccessfully'));
                        $scope.data.org.submitted_date = response.submitted_date;
                        $scope.data.org.rejection_date = response.rejection_date;
                        $scope.data.org.request_count = response.request_count;
                    }, function(response) {
                        utils.showToast(utils.trans(response.data));
                    });
                };

                $scope.getOrgStatus = function() {
                    if (!$scope.data.org) {
                        return '-';
                    }

                    if ($scope.data.org.submitted_date && $scope.data.org.rejection_date) {
                        return 'Rejected';
                    } else if (!$scope.data.org.submitted_date && !$scope.data.org.rejection_date && !$scope.data.org.approval_date) {
                        return 'Not Submitted';
                    } else if ($scope.data.org.approval_date) {
                        return 'Approved';
                    } else if ($scope.data.org.submitted_date && !$scope.data.org.rejection_date && !$scope.data.org.approval_date) {
                        return 'Pending/Submitted';
                    }
                };
            }
        };
    }]);
}());