(function () {
	'use strict';

	angular.module('app')
	.directive('familyPage', ['$state','api', 'dialogManager', '$rootScope', '$mdSidenav', 'users', 'utils', '$stateParams', '$timeout', function($state, api, dialogManager, $rootScope, $mdSidenav, users, utils, $stateParams, $timeout) {
		return {
			restrict: 'E',
			scope: {
			},
			templateUrl: 'assets/views/pages/family-page.html',
			link: function($scope, el, attrs) {
				$scope.data = {
					loading: false,
					imageUrl: "assets/images/specialfolders/folder.png",
					selectedItem: null,
					searchText: '',
					loaded: false,
					users: users,
					hasMember: false
				};
				$scope.currState = $state;
				if ($stateParams.memberId) {
					$scope.data.loading = true;

					api.me.familyMember($stateParams.memberId).then(function(family) {
						if (family['info'] && family['info']['family_id'] == $stateParams.memberId) {
							family.info.name = users.getName(family.info);
							family.info.avatar = users.getAvatar(family.info);
							$scope.data.family = family;
							console.log("wallaaahi " , $scope.data.family);
							$scope.data.hasMember = true;
							$scope.data.loading = false;
							$scope.data.loaded = true;
						} else {
							reloadGroupPage();
						}
					}, function() {
						reloadGroupPage();
					});
				}

				function reloadGroupPage() {
					$scope.data.hasMember = false;
					$scope.data.loading = false;
					$scope.data.loaded = true;
					if ($stateParams.hiveId) {
						$timeout(function() {
							$state.go('dashboard.hives.details', { hiveId: $stateParams.hiveId });
						}, 1500);
					}
				}

				$scope.editFamily = function(family) {
					dialogManager.showEditFamilyDialog(family).then(function(updated_family) {
						$scope.data.family.info.description = updated_family.description;
						$scope.data.family.info.relation = updated_family.relation;
					}, function() {
					});
				};

				$scope.deleteFamily	= function(family) {
					var name = users.getName(family);
					dialogManager.confirmDeleteFamilyMember(name, $scope.data.family.info.type).then(function(delete_items){
						api.resources.family.delete({id: family.family_id, delete_items: delete_items, type: $scope.data.family.info.type});
						utils.showToast(utils.trans('family.removedFamilyMemberSuccessful', true));
						if ($scope.data.family.info.type == 'default') {
							$rootScope.$broadcast('hive.member.default.deleted', family.user_id);
						} else {
							$rootScope.$broadcast('hive.member.custom.deleted', $stateParams.hiveId, family.family_id);
						}
						if ($stateParams.hiveId) {
							$state.go('dashboard.hives.details', { hiveId: $stateParams.hiveId });
						}
					}, function() {
					});
				};

				$scope.showFamilyFiles = function(family) {
					$mdSidenav('right').open().then(function() {
						$rootScope.$broadcast('rightpanel.set.view', { view: 'family'});
						$rootScope.$broadcast('rightpanel.set.user_id', family.user_id);
					});
				};

				$scope.addMemberClick = function() {
					dialogManager.addMemberDialog();
				};
				var tabs = ['files', 'stories', 'post'];
				function setSelectedIndex() {
					var states = $state.current.name.split('.');
					if (states.length > 3 && states[4]) {
						$scope.selectedTab = tabs.indexOf(states[4]);
						$rootScope.$broadcast('hives.member.tab.switched', $scope.data.selectedIndex);
					}
				}
				setSelectedIndex();
				$scope.$watch('currState.current.name', function (newval, oldval) {
					var states = newval.split('.');
					if (states.length > 3 && states[4]) {
						if(tabs.indexOf(states[4]) !== -1) {
							$rootScope.$broadcast('hives.member.tab.switched', $scope.data.selectedIndex);
						}
					}
                });
			}
		};
	}]);
}());
