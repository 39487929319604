(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('hiveStories', ['$rootScope', '$http', 'utils', 'fileTypes', 'users', 'api', '$stateParams', 'localStorage', '$location', '$anchorScroll', '$timeout', function($rootScope, $http, utils, fileTypes, users, api, $stateParams, localStorage, $location, $anchorScroll, $timeout) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/hive-stories.html',
            scope : {
                familyStories : "=",
                myStories : "=",
            },
            link: function($scope, el, attr) {
                var storyType = ['sharedToMe', 'sharedByMe'];
                $scope.sections = [];
                $scope.data = {
                    sharedByMe: {
                      isEmpty : true,
                      loading : true,
                      view: localStorage.get('sharedByMeView', 'list'),
                      sectionFilter: '0',
                    },
                    sharedToMe: {
                      isEmpty : true,
                      loading : true,
                      view: localStorage.get('sharedToMeView', 'list'),
                      sectionFilter: '0',
                    }
                };

                function getSections(sections) {
                  $scope.sections[0] = "All Sections";
                  sections.forEach(function(element) {
                    if(typeof $scope.sections[element.id] === 'undefined') {
                      $scope.sections[element.id] = element.name;
                    }
                  });
                }

                function loadUserStories(sharedStories, storyIndex) {

                    var data = {};
                    if ($stateParams.memberId) {
                        data['member_id'] = $stateParams.memberId;
                    }

                    api.resources.story.query(data, function(userStories) {
                        getSections(userStories);
                        var container = userStories;

                        if (data['member_id']) {
                          data['story_ids'] =  sharedStories.map(function(story) {
                            return story.id;
                          });
                        }

                        if (data.story_ids.length) {
                          getStoriesContent(data.story_ids, sharedStories, container, storyIndex);
                        } else {
                          $scope.setSectionFilter('0', storyIndex);
                          $scope.data[storyType[storyIndex]].loading = false;
                        }
                    });
                }

                function getStoriesContent(storyIds, sharedStories, container, storyIndex) {
                    api.userStory.getContent(storyIds).then(function(contentLoadedStories) {
                      var i, j;
                      for(i = 0; i < sharedStories.length; i++) {
                        if (!sharedStories[i].content) {
                          for(j = 0; j < contentLoadedStories.length; j++) {
                            if (contentLoadedStories[j].story_section_id == sharedStories[i].story_section_id && contentLoadedStories[j].story_topic_id == sharedStories[i].story_topic_id && contentLoadedStories[j].content) {
                              sharedStories[i].content = contentLoadedStories[j].content;
                              sharedStories[i].originalId =  contentLoadedStories[j].id;
                              // If the user who's viewing the image is not the owner, prevent downloading
                              if(storyIndex == 0) {
                                sharedStories[i].content = sharedStories[i].content.replace("<img ", '<img draggable="false" onContextMenu="return false;" ');
                              }

                              break;
                            }
                          }
                        }
                      }
                      loadStoryModeData(sharedStories, container, storyIndex);
                    }, function() {
                      // HANDLE FAILED CASE
                    }).finally(function() {

                        $scope.setSectionFilter('0', storyIndex);
                        $scope.data[storyType[storyIndex]].loading = false;
                    });
                }

                function loadStoryModeData(sharedStories, container, storyIndex) {
                    var empty = true;
                    angular.forEach(container, function(section) {
                      var sectionExists = false;
                      angular.forEach(section.topics, function(topic) {
                        var topicExists = false;
                        angular.forEach(sharedStories, function(story) {
                          if (story.story_section_id == topic.section_id) {
                            if (story.story_topic_id == topic.id) {
                              topic.userContent = story.content;
                              topic.userPrivacy = story.privacy;
                              topic.userIncluded = story.included_users;
                              topic.originalId = story.originalId;
                              topicExists = true;
                              sectionExists = true;
                              empty = false;
                              topic.showReadMore = false;
                              topic.expansion = false;
                              topic.bodyLoaded = false;
                              if (story.content) {
                                if (!angular.element(story.content).find("img").length) {
                                  var dimensions = calculateWordDimensions(story.content, ['storyPageTopicBody'], false);
                                  if (dimensions.height > 260) {
                                    topic.showReadMore = true;
                                    topic.expansion = false;
                                  }
                                  topic.bodyLoaded = true;
                                }
                              }
                            }
                          }
                      });
                      topic.exists = topicExists;
                    });
                    section.exists = sectionExists;
                    section.visible = true;
                  });
                  container['isEmpty'] = empty;
                  container['loading'] = false;
                  container['view'] = $scope.data[storyType[storyIndex]].view;
                  container['sectionFilter'] = '0';
                  $scope.data[storyType[storyIndex]] = container;
                  // $timeout(function() {
                  //   if($location.hash()) {
                  //     $anchorScroll();
                  //   }
                  // }, 1000);
                  
                }

                function calculateWordDimensions(text, classes, escape) {
                  classes = classes || [];

                  if (escape === undefined) {
                    escape = true;
                  }

                  classes.push('textDimensionCalculation');

                  var div = document.createElement('div');
                  div.setAttribute('class', classes.join(' '));

                  if (escape) {
                    $(div).text(text);
                  } else {
                    div.innerHTML = text;
                  }

                  document.body.appendChild(div);

                  var dimensions = {
                    width : $(div).outerWidth(),
                    height : $(div).outerHeight()
                  };

                  div.parentNode.removeChild(div);

                  return dimensions;
                }

                $scope.setSectionFilterFromSelect = function(filter, storyIndex) {
                  if(storyIndex == 1) {
                    $scope.setSectionFilter($scope.data.sharedByMe.sectionFilter, storyIndex);
                  } else {
                    $scope.setSectionFilter($scope.data.sharedToMe.sectionFilter, storyIndex);
                  }
                };

                $scope.setSectionFilter = function(val, storyIndex) {
                  var i = 1;
                  var stories = storyIndex == 1 ? $scope.myStories : $scope.familyStories;

                  angular.forEach($scope.data[storyType[storyIndex]], function(section) {
                  // angular.forEach(stories, function(section) {
                    if (val == 0 || val == i) {
                      section.visible = true;
                    } else {
                      section.visible = false;
                    }
                    i++;
                  });
                  setIsEmptyHiveStory(storyIndex);
                  $scope.data[storyType[storyIndex]].sectionFilter = val;
                };

                function setIsEmptyHiveStory(storyIndex) {
                  var isEmpty = true;
                  var stories = storyIndex == 1 ? $scope.myStories : $scope.familyStories;

                  angular.forEach($scope.data[storyType[storyIndex]], function(section) {
                    if (section.visible) {
                      angular.forEach(section.topics, function(topic) {
                        if (topic.exists) {
                          isEmpty = false;
                        }
                      });
                    }
                  });
                  $scope.data[storyType[storyIndex]].isEmpty = isEmpty;
                }

                $scope.toggleSelectedView = function(storyIndex) {
                  if ($scope.data[storyType[storyIndex]].view === 'list') {
                      $scope.data[storyType[storyIndex]].view = 'grid';
                  } else {
                      $scope.data[storyType[storyIndex]].view = 'list';
                  }

                  localStorage.set(storyIndex ? 'sharedByMeView' : 'sharedToMeView', $scope.data[storyType[storyIndex]].view);
                };

                function loadStoryModeData2(sharedStories, container, storyIndex) {
                    var empty = true;
                    angular.forEach(container, function(section) {
                      var sectionExists = false;
                      var flipColor = false;
                      angular.forEach(section.topics, function(topic) {
                        var topicExists = false;
                        angular.forEach(sharedStories, function(story) {
                          if (story.story_section_id == topic.section_id) {
                            if (story.story_topic_id == topic.id) {
                              topic.userContent = story.content;
                              topic.readMore = false;
                              topicExists = true;
                              sectionExists = true;
                              empty = false;
                              topic.showReadMore = false;
                              if (topic.userContent && topic.userContent.length >= 150) {
                                topic.showReadMore = true;
                              }
                            }
                          }
                        });
                        topic.exists = topicExists;
                        topic.flip = topicExists && flipColor ? true : false;
                        if (topicExists) {
                            flipColor = !flipColor;
                        }
                      });
                      section.exists = sectionExists;
                    });
                    container['isEmpty'] = empty;
                    container['loading'] = false;
                    storyIndex === 0 ? $scope.data.sharedToMe = container : $scope.data.sharedByMe = container;
                }

                $scope.$watch('familyStories', function() {
                    if ($scope.familyStories && $scope.familyStories != null && $scope.familyStories.length) {
                       loadUserStories($scope.familyStories, 0);
                    } else {
                        $scope.data.sharedToMe.isEmpty = true;
                        $scope.data.sharedToMe.loading = false;
                    }
                });

                $scope.$watch('myStories', function() {
                    if ($scope.myStories && $scope.myStories != null && $scope.myStories.length) {
                        loadUserStories($scope.myStories, 1);
                    } else {
                        $scope.data.sharedByMe.isEmpty = true;
                        $scope.data.sharedByMe.loading = false;
                    }
                });
            }
        };
    }])

    .filter('flattenStory', function() {
      return function(storySections) {
        var stories = [];
        angular.forEach(storySections, function(section) {
          if (section.exists && section.visible) {
            angular.forEach(section.topics, function(topic) {
              if (topic.exists) {
                stories.push(topic);
              }
            });
          }
        });
        return stories;
      };
    })

    .filter('oddItems', function() {
      return function(stories) {
        var oddStories = [];
        var i = 1;

        angular.forEach(stories, function(story) {
          if (i%2 == 1) {
            oddStories.push(story);
          }
          i++;
        });

        return oddStories;
      };
    })

    .filter('evenItems', function() {
      return function(stories) {
        var evenStories = [];
        var i = 1;

        angular.forEach(stories, function(story) {
          if (i%2 == 0) {
            evenStories.push(story);
          }
          i++;
        });

        return evenStories;
      };
    });

}());