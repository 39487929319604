(function(){
    'use strict';

    angular.module('app').controller('StorySectionController', ['$scope', '$rootScope', '$state', '$mdDialog', 'utils', 'users', 'storySections', 'dialogManager', 'api', function($scope, $rootScope, $state, $mdDialog, utils, users, storySections, dialogManager, api) {

        $scope.storySections = storySections;

        //sections search query
        $scope.search = { query: '' };

        $scope.selectedItems = [];
        $scope.isItemSelected = function(item) { 
            return $scope.selectedItems.indexOf(item) > -1;
        };
       
        $scope.showCreateSectionDialog = function($event) {
            dialogManager.showCreateSectionDialog($event).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.showUpdateSectionDialog = function(section, $event) {
            dialogManager.showUpdateSectionDialog($event, section).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.showCreateTopicDialog = function($event) {
            dialogManager.showCreateTopicDialog($event, $scope.sections).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.showUpdateTopicDialog = function(topic, $event) {
            dialogManager.showUpdateTopicDialog($event, topic, $scope.sections).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };
        
        $scope.changeState = function(section) {
            api.storySections.toggleActive(section.id, section.active).then(function() {
                utils.showToast('section.updatedSectionSuccessfully', true);
                $scope.selectedItems = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };
        
        $scope.changeStateTopic = function(topic) {
            api.storyTopic.toggleActive(topic.id, topic.active).then(function() {
                utils.showToast('topic.updatedTopicSuccessfully', true);
                $scope.selectedItems = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };

        $scope.paginate = function(params) {
            params = params || $scope.params;
            if ($scope.usersAjaxInProgress) {
                return;
            }

            $scope.usersAjaxInProgress = true;

            api.storySections.paginate(params).then(function(response) {
                $scope.sections = response.sections.data;
                $scope.topics = response.topics;

                $scope.usersAjaxInProgress = false;

                // clear any selected items
                $scope.selectedItems = [];
            });
        };
        
        $scope.paginate($scope.params);
    }]);
}());