(function () {
'use strict';

angular.module('app').controller('RootController', ['$scope', '$rootScope', 'users', 'utils', function($scope, $rootScope, users, utils) {
    
    // most of this stuff can go away; not sure which (if any) of it is still used.
    
    $scope.users = users;
    $scope.utils = utils;
    
    $rootScope.ajaxProgress = {
        //show files spinner as soon as possible and until files are fetched
        files: true
    };

}]);
}());
