(function () {
'use strict';

angular.module('app')

.directive('spaceUsage', ['$http', 'users', 'utils', 'dialogManager', 'api', '$translate', function($http, users, utils, dialogManager, api, $translate) {

    return {
        restrict: 'E',
        replace: true,
        scope: {},
        template: '<div class="space-usage" ng-show="data.loaded">'+
                    '<p id="runningOutSpace" class="hidden" style="text-align:left;color:#B71C1C;font-weight:bold;text-transform:uppercase">  </p>' +
                    '<div class="progress" ng-show="data.showPrgoress">'+
                        '<div class="progress-bar" role="progressbar" ng-style="{\'width\': data.percentageUsed+\'%\', \'background-color\': data.barBackgroundColor }" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>'+
                    '</div>'+
                    '<div layout="row">'+
                        '<div flex="grow">'+
                            '<div style="text-align: left; margin-top: 13px;">'+
                                '<span class="space-used">{{data.spaceUsed}}</span> / <span class="space-avail">{{data.spaceAvailable}}</span>'+
                            '</div>'+
                        '</div>'+
                        '<button class="upgrade md-primary md-raised md-button md-ink-ripple" ng-click="upgrade($event)" ng-show="data.canUpgrade && data.loaded">{{:: "upgrade" | translate }}</button>'+
                    '</div>'+
                  '</div>',
        link: function($scope, el) {
            $scope.data = {
                loaded: false,
                spaceUsed: '',
                spaceAvailable: '',
                percentageUsed: 0,
                barBackgroundColor: 'rgb(255, 193, 7)',
                canUpgrade: utils.getSetting('enablePayments'),
                showPrgoress: true
            };

            function checkUserPermissions() {
                if (users.current && users.current.permissions && ('is_nonprofit' in users.current.permissions || 'org_owner' in users.current.permissions)) {
                    $scope.data.canUpgrade = false;
                }
            }

            $scope.$on('user.joined.organization', function() {
                update();
            });

            function update() {
                $scope.data.loaded = false;
                $http.get('users/space-usage').then(function(response) {
                    $scope.data.spaceUsed = utils.formatFileSize(response.data.space_used);
                    $scope.data.spaceAvailable = response.data.is_unlimited ? utils.trans('unlimitedStorage') : utils.formatFileSize(response.data.max_space);
                    $scope.data.showPrgoress = !response.data.is_unlimited;
                    $scope.data.percentageUsed = (response.data.space_used / response.data.max_space * 100);
                    if (users.current) {
                        users.current.storage_info = response.data.space_used;
                        users.current.is_unlimited = response.data.is_unlimited;
                        users.current.space_available = response.data.max_space;
                    }
                    if($scope.data.percentageUsed >= 90) {
                        var message = $scope.data.percentageUsed >= 100 ? $translate.instant('noSpaceLeft') : $translate.instant('runningOutSpace');
                        $("#runningOutSpace").html(message);
                        $("#runningOutSpace").removeClass("hidden");
                        $scope.data.barBackgroundColor = '#B71C1C';
                    } else {
                        $scope.data.barBackgroundColor = 'rgb(255, 193, 7)';
                        if(!$("#runningOutSpace").hasClass("hidden")){
                            $("#runningOutSpace").addClass("hidden");
                         }
                    }

                    if (response.data.is_unlimited && $scope.data.canUpgrade) {
                        updateUserPermissions(true);
                    } else {
                        $scope.data.loaded = true;
                    }
                });
            }

            $scope.$on('activity.happened', function() {
                update();
            });

            $scope.$on('user.subscribed', function() {
                update();
            });

            $scope.upgrade = function(ev) {
                dialogManager.showUpgradeDialog(ev);
            };

            function updateUserPermissions(updated) {
                updated = updated || false;
                api.me.permissions().then(function(permissions) {
                    users.current.permissions = permissions;
                    checkUserPermissions();
                    if (!updated) {
                        update();
                    } else {
                        $scope.data.loaded = true;
                    }
                }, function() {
                    // handle failed case..
                });
            }

            update();

        }
    };
}]);
}());
