(function(){
    'use strict';

    angular.module('app').controller('EmailLoggerController', ['$scope', '$rootScope', '$state', 'api', 'EmailLogger', 'dialogManager', function($scope, $rootScope, $state, api, logs, dialogManager) {
        var requests = ['All', 'None'];
        $scope.params = {};

        //search query
        $scope.search = { query: '' };
        $scope.selectedItems = [];
        $scope.data = {
            filterType: 'Show',
            requestType: requests[0],
            requests: requests,
        };

        $scope.filterUsers = function() {
            $scope.paginate($scope.params, true);
        };

        $scope.paginate = function(params, isFilter) {
            if ($scope.ajaxInProgress) {
                return;
            }
            $scope.ajaxInProgress = true;
            isFilter = isFilter || false;
            params = params || $scope.params;
            params['filter'] = $scope.data.filterType.toLowerCase();
            params['type'] = $scope.data.requestType.replace(" ", "").replace('-','').toLowerCase();
            if (isFilter) {
                params['page'] = 1;
            }
            $scope.items = [];
            logs.paginateEmailLogger(params).then(function(response) {
                $scope.items = response.data.data;
                $scope.totalItems = response.data.total;

                $scope.ajaxInProgress = false;

                // clearing all selected items
                $scope.selectedItems = [];
            });
        };
        $scope.viewEmailLogger = function($event, email) {
            $event.stopPropagation();
            dialogManager.showEmailLoggerDetailDialog($event, email).then(function(data) {
            }, function() {
                // handle failed case
            });
        };

        $scope.paginate($scope.params);
    }]);
}());