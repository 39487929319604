(function () {
    'use strict';

    angular.module('pixie.dashboard')

        /**
         * This is Dashboard Files Section (only main, not trash or recent or favorites)
         */
        .directive('dashboardFileContents', ['files', 'folders', 'items', '$stateParams', 'utils', '$state', 'dialogManager', 'users', 'api', '$rootScope', '$timeout', function (files, folders, items, $stateParams, utils, $state, dialogManager, users, api, $rootScope, $timeout) {
            return {
                restrict: 'E',
                templateUrl: 'dashboardFileContents.html',
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        infiniteScrollPage: 2, // FIXME? should this be 2?
                        disableInfinateScroll: false,
                        items: [],
                    };

                    $scope.paginateFiles = function () {
                        files.getAll(false, { folderId: folders.selected.id, page: $scope.data.infiniteScrollPage + 1 }).then(function (response) {
                            if (response.data['current_page'] >= response.data['last_page']) {
                                $scope.data.disableInfinateScroll = true;
                            }

                            $scope.data.infiniteScrollPage = response.data['current_page'];

                            //                $scope.$broadcast('pagination.files', response.data.data);
                            items.paginate(response.data.data);
                        });
                    };

                    $scope.$on('items.refresh', function (e, data) {
                        items.refresh();
                        $rootScope.$broadcast('showedItems.refresh');
                    });

                    $scope.$on('files.changed', function (e, data) {
                        $scope.data.items = items.get();
                    });
                    
                    function shouldShowFirstRunDialog() {
                        var shouldShow = true;

                        if ($stateParams.share_link || $stateParams.family_invite_link || $stateParams.question || $stateParams.manage_email || $stateParams.organization_member) {
                            shouldShow = false;
                        }

                        return shouldShow;
                    }

                    $scope.$on('forced.refresh', function (e, data) {
                        $rootScope.$broadcast('update.item.thumb', data);
                    });

                    $scope.$on('limit.reached', function (e, data) {
                        console.log("limmt reacheddd ", data);
                        utils.confirm({
                            title: 'uploadImage.limitReach',
                            subcontentHTML: "You have reached your storage limit. Please remove any unwanted files to free up more space OR you can upgrade your storage capacity. ",
                            ok: 'OK',
                            type: 'alert',
                            onConfirm: function () {
                                $rootScope.$broadcast('update.item.thumb', data);
                            },
                            onRejection: function () {
                                $rootScope.$broadcast('update.item.thumb', data);
                            }
                        });
                    });

                    folders.getSelected().then(function (data) {
                        items.refresh();
                        // Removed as of FF-157
                        // if(shouldShowFirstRunDialog()) {
                        //     var newFolder = data.new;
                        //     var name = 'dashboard.'+newFolder.class_type;
                        //     api.user.firstrun(name).then(function(response) {
                        //         if(response.show) {
                        //             dialogManager.showFirstRunDialog(response.info);
                        //         }
                        //     });
                        // }

                        if (data.folder_loaded) {
                            $rootScope.$broadcast('folders.loaded');
                        }
                    });

                    $scope.$on('activity.happened', function (e, type, itemType, data, origin) {
                        items.handleActivity(e, type, itemType, data, origin);
                    });


                    // check for a share.
                    if ($stateParams.share_link) {
                        api.share.checkLink($stateParams.share_link).then(function (response) {
                            if (typeof response == 'string') {
                                utils.showToast(response);
                                // this clears the share link (it will by default keep the other parameters.)
                                $state.go('dashboard.folders', { share_link: null });
                            } else {
                                dialogManager.showMoveSharedDialog(response).then(function (moveInfo) {
                                    api.share.move(response.id, moveInfo.name).then(function (data) {
                                        if (data == 'success') {
                                            // folders.getAll(null);
                                            folders.getFromCache();
                                            $state.go('dashboard.folders', { share_link: null, folderId: moveInfo.folderId });
                                            // folders.selectByShareId(moveInfo.folderId || 'root');
                                        } else {
                                            utils.showToast(data, true);
                                        }
                                    }, function () {
                                        utils.showToast('genericError', true);
                                    });
                                }, function () {
                                    // cancelled.
                                    utils.showToast("Share Cancelled");
                                    $state.go('dashboard.folders', { share_link: null });
                                });
                            }
                        }, function (error) {
                            utils.showToast('genericError', true);
                            $state.go('dashboard.folders', { share_link: null });
                        });
                    }

                    // check for a question...
                    if ($stateParams.question) {
                        users.getQuestion($stateParams.question).then(function (response) {
                            dialogManager.showQuestionDialog(response.data).then(function (data) {
                                if (data.is_video == false) {
                                    $state.go('dashboard.folders', { folderId: data.folder.share_id, question: null });
                                }
                            });
                        }, function (response) {
                            utils.showToast(response.data);
                            $state.go('dashboard.folders', { question: null });
                        });
                    }
                }
            };
        }])
        .directive('dashboardTrashContents', ['$rootScope', 'files', 'folders', 'trash', '$mdDialog', function ($rootScope, files, folders, trash, $mdDialog) {
            return {
                restrict: 'E',
                templateUrl: 'dashboardTrashContents.html',
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        items: trash.items
                    };

                    $scope.emptyTrash = function ($event) {
                        var confirm = $mdDialog.confirm()
                            .title('Would you like to delete all files in the trash?')
                            .textContent('All of the files in the trash will be deleted.  This cannot be undone.')
                            .targetEvent($event)
                            .ok('Delete Forever')
                            .cancel('Cancel');

                        $mdDialog.show(confirm).then(function () {
                            trash.empty().then(function () {
                                $rootScope.$broadcast('activity.happened', 'permaDeleted', 'item', $scope.data.items);
                                $scope.data.items = trash.items;
                            });
                        }, function () {
                            // cancelled
                        });
                    };

                    folders.updateBreadcrumbs(); // likely unneeded.

                    $scope.$on('activity.happened', function (e, type, itemType, restoredItems) {
                        if (type === 'restored' || type === 'permaDeleted' || type === 'trashed') {
                            $scope.data.items = trash.items = trash.items.filter(function (item) {
                                return restoredItems.indexOf(item) === -1;
                            });
                        }
                    });

                    if (!trash.loaded) {
                        trash.getTrashedItems().then(function () {
                            $scope.data.items = trash.items;
                        });
                    }
                }
            };
        }])
        .directive('dashboardRecentContents', ['files', 'folders', '$http', 'dashboardState', function (files, folders, $http, dashboardState) {
            return {
                restrict: 'E',
                templateUrl: 'dashboardRecentContents.html',
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        items: []
                    };

                    folders.updateBreadcrumbs(); // likely unneeded.

                    function getItems() {
                        $http.get('files/recent').then(function (response) {
                            $scope.data.items = response.data;
                            dashboardState.recentLoaded = true;
                        });
                    }

                    $scope.$on('activity.happened', function (e, type, itemType, restoredItems) {
                        getItems();
                    });

                    getItems();
                }
            };
        }])
        .directive('dashboardFavoriteContents', ['favorites', 'folders', function (favorites, folders) {
            return {
                restrict: 'E',
                templateUrl: 'dashboardFavoriteContents.html',
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        items: favorites.items
                    };

                    folders.updateBreadcrumbs(); // likely unneeded.

                    $scope.$on('activity.happened', function (e, type, itemType, restoredItems) {
                        // items would need to handle this and move it to the favorites location I think.
                        /*
                                            var newItems = $scope.items[$state.current.url].slice();
                        
                                            if (type === 'unfavorited') {
                                                newItems = newItems.filter(function(item) {
                                                    return data.indexOf(item) === -1;
                                                });
                                            }
                        
                                            if (type === 'renamed') {
                                                newItems = newItems.map(function(file) {
                                                    if (file.id == data.id) {
                                                        file.name = data.name;
                                                    }
                        
                                                    return file;
                                                });
                                            }
                        
                                            favorites.items = newItems;
                                            $scope.items[$state.current.url] = favorites.items;
                        */
                    });

                    if (!favorites.loaded) {
                        favorites.getFavoritedItems().then(function () {
                            $scope.data.items = favorites.items;
                        });
                    }
                }
            };
        }])
        .directive('dashboardSearchContents', ['favorites', 'folders', '$stateParams', '$http', '$rootScope', function (favorites, folders, $stateParams, $http, $rootScope) {
            return {
                restrict: 'E',
                templateUrl: 'dashboardSearchContents.html',
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        items: favorites.items
                    };

                    folders.selected = false;
                    folders.updateBreadcrumbs(); // likely unneeded.
                    getSearchResults($stateParams.query);

                    //on photo or folder deletion remove it from $scope items array
                    $scope.$on('activity.happened', function (e, action, type, items) {
                        if (action !== 'deleted') {
                            return;
                        }

                        $scope.data.items = $scope.data.items.filter(function (item) {
                            return items.indexOf(item) === -1;
                        });
                    });

                    function getSearchResults(query) {
                        return $http.get('search/' + query + '?full=true').then(function (response) {
                            $scope.data.items = response.data;
                        }).finally(function () {
                            $rootScope.ajaxProgress.files = false;
                        });
                    }
                }
            };
        }]);
})();




// Top Navbar:
//   upload($files) (ng-file-select, ng-multiple, ng-file-change, allow-dir)
//   folders.openNewModal($event)
//   files.openNewDocwModal()
//   goToSearchPage()
//   selectedItem
//   selectItem()
//   getSearchResults(searchText)
//   fileTypes
//
//
