/* global videojs */
(function () {
	'use strict';

	angular.module('pixie.dashboard')
	.directive('videoPreview', ['api', '$rootScope', function(api, $rootScope) {
		function canPlayType(mime) {
			var success = false;
			switch(document.createElement('video').canPlayType(mime)) {
				case 'maybe':
				case 'probably':
					success = true;
					break;
				default:
					success = false;
					break;
			}
			return success;
		}

		return {
			restrict: 'E',
			scope: {
				item: '=?'
			},
			templateUrl: 'assets/views/directives/video-player.html?t=4', // query bump
			link: function($scope, $element, $attr) {
				$scope.data = {
					canPlayType: true,
					processing: false,
					loading: true
				};

				var currentPlayer;
				var video;

				function createVideoElement(time, posterUrl) {
					$('.video-preview-container', $element).html(
							'<video id="video-preview'+ time + '" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="auto" poster="'+posterUrl+'" data-setup="{}">'+
								'<p class="vjs-no-js">'+
									'To view this video please enable JavaScript, and consider upgrading to a web browser that'+
									'<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>'+
								'</p>'+
							'</video>');
				}

				function disposePlayer() {
					if(currentPlayer) {
						currentPlayer.dispose();
						currentPlayer = undefined;
					}
				}


				function playItem(item) {
					api.file.videoInfo(item.share_id).then(function(data) {
						if(data['processing']) {
							// show processing
							// console.log("Processing!");
							$scope.data.loading = false;
							$scope.data.processing = true;
						} else {
							$scope.data.canPlayType = true;
							$scope.data.processing = false;

							if(currentPlayer) {
								disposePlayer();
							}

							if($scope.data.canPlayType)
							{
								var srcs = [];
								var posterUrl;
								angular.forEach(data, function(value, key) {
									if(key=='processing' || key=='thumb') {
										// skip this.
									} else if(key=='poster') {
										posterUrl = value;
									} else {
										srcs.push({ type: key, src: value });
									}
								});

								var time = '';

								if (video) {
									time = '-' + new Date().getTime().toString();
								}

								createVideoElement(time, posterUrl);

								currentPlayer = videojs('video-preview' + time, {
									controls: true,
									autoplay: false,
									preload: 'auto',
									fluid: true,
									poster: posterUrl,
									sources: srcs
								}, function() {
									$scope.data.loading = false;
									$scope.$digest();
								});

								if (!video) {
									$scope.data.loading = false;
								}

								currentPlayer.ready(function() {
									// $scope.data.loading = false;
								});
							} else {
								$scope.data.loading = false;
							}
						}
					}, function failure(data) {
						console.log(data);
					});
				}

				$scope.$on('$destroy', function() {
					disposePlayer();
				});

				if (!$scope.item) {
					video = angular.element($element).attr('video');

					if (video) {
						adjustLoaderHeight();
						api.file.details(video).then(function(file) {
							$scope.item = file;
						}, function() {

						});
					}
				}

				function adjustLoaderHeight() {
					if (angular.element('.section-container').length) {
						var videoHeight = angular.element('.section-container').width() * 0.58;
						videoHeight = Math.round(videoHeight * 100 + Number.EPSILON ) / 100;
						videoHeight = videoHeight.toString() + 'px';
						angular.element($element).find('.video-preview-loader').css('height', videoHeight);
					} else if (angular.element('.preview').length) {
						angular.element($element).find('.video-preview-loader').css('height', 'unset');
					} else if (angular.element('.post').length) {
						angular.element($element).find('.video-preview-loader md-progress-circular').css({'top': 'unset', 'left': 'unset'});
					}
				}

				$scope.$watch('item', function(item) {
					if (item) {
						$scope.data.loading = true;
						playItem(item);
					}
				});

			}
		};
	}]);
}());