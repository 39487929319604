(function () {
	'use strict';

	angular.module('app')
		.directive('hiveDetailView', ['api', 'dialogManager', '$stateParams', 'users', '$rootScope', '$timeout', '$state', function(api, dialogManager, $stateParams, users, $rootScope, $timeout, $state) {
		return {
			restrict: 'E',
			scope: {
			},
			templateUrl: 'assets/views/pages/hive-detail-view.html',
			link: function($scope, el, attrs) {
				$scope.data = {
					hiveId: null,
					members: [],
					hive: null,
					loaded: false,
				};

				if ($stateParams.hiveId) {
					$scope.data.hiveId = $stateParams.hiveId;
				}

				api.resources.hive.get({id: $scope.data.hiveId}, function(hive) {
					$scope.data.hive = hive;
					if ($scope.data.hive) {
						loadHiveMembers();
					} else {
						reloadMyHives();
					}
				}, function(data) {
					reloadMyHives();
				});

				function reloadMyHives() {
					$scope.data.loaded = true;
					$scope.data.hive = false;
					$timeout(function() {
						$state.go('dashboard.hives');
					}, 1500);
				}

				function prepareFamilyData(family) {
					return family.map(function(familyMember) {
							familyMember.url =  users.getAvatar(familyMember);
							familyMember.full_name = users.getName(familyMember);
							familyMember.selected = false;
							return familyMember;
					});
				}

				function loadHiveMembers() {
					api.me.familyMembers($scope.data.hiveId).then(function(family) {
						$scope.data.members = prepareFamilyData(family);
						$scope.data.loaded = true;
					});
				}

				$scope.selectHive = function(family, onLoad) {
					if (!family) {
						return;
					}
					onLoad = onLoad || false;
					$scope.data.members.forEach(function(member) {
						if (member.id == family.id) {
							member.selected = true;
						} else {
							member.selected = false;
						}
					});

					if (!onLoad) {
						$rootScope.$broadcast('hive.member.selected', family.hive_id, family);
					}
				};

				$scope.addHiveMember = function() {
					if ($scope.data.hive) {
						if ($scope.data.hive.type == 'default') {
							dialogManager.addMemberDialog().then(function(emails) {
								if (emails.length) {
									$rootScope.$broadcast('hive.pending.member.added', emails);
								}
							}, function() {
								// handle failed case
							});
						} else {
							dialogManager.addCustomMemberDialog($scope.data.hiveId).then(function(hiveMembers){
								hiveMembers.forEach(function(member) {
									member.url =  users.getAvatar(member);
									member.full_name = users.getName(member);
									member.selected = false;
									$scope.data.members.push(member);
									$rootScope.$broadcast('hive.member.added', $scope.data.hive, member);
								});
							}, function() {
								// handle failed case
							});
						}
					}
				};


				$scope.editHive = function() {
					dialogManager.showAddHiveGroupDialog($scope.data.hive).then(function(hive) {
						$scope.data.hive = hive;
						$rootScope.$broadcast('hive.edited', hive);
					}, function(){

					});
				};

				$scope.deleteHive = function() {
					dialogManager.showDeleteHiveGroupDialog($scope.data.hive).then(function(hive) {
						$rootScope.$broadcast('hive.deleted', $scope.data.hive);
					}, function(){

					});
				};
			}
		};
	}]);
}());