(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('adContainer', ['users', 'utils', 'api', function(users, utils, api) {
        return {
            restrict: 'E',
            scope: {},
            replace: true,
            template: '<div class="parent-ad-container"><div class="ad-container" ng-show="data.ad" ng-bind-html="data.ad"></div><div style="margin-right: 20px; color: #FDC103" ng-show="data.arrow"><md-icon md-font-icon>arrow_forward_ios</md-icon></div></div>',
            link: function($scope, $element, $attr) {
                $scope.data = {
                    ad: null,
                    arrow: false,
                };

                if(!$attr['slot']) {
                    console.log("Ad container missing slot");
                    return;
                }

                if(!users.current || !users.current.isSubscribed) {
                    $scope.data.ad = utils.trustHtml(utils.getSetting($attr['slot']));
                    if ($attr['arrow']) {
                        $scope.data.arrow = true;
                        $('.parent-ad-container').addClass('arrow');
                        $('.parent-ad-container.arrow').click(function() {
                            $('.parent-ad-container.arrow .ad-container a').get(0).click();
                        });
                    }
                }

                $scope.$on('user.joined.organization', function() {
                    updateSubscription();

                });

                function updateSubscription() {
                    api.me.subscriptions().then(function(data) {
                        users.current.isSubscribed = data.subscription;
                        if (users.current.isSubscribed) {
                            $scope.data.ad = null;
                        }
                    }, function() {
                        //
                    });
                }

                if ($attr['arrow']) {
                    $('.left-dashboard-column .ad-container').append('<span style="flex: 1"></span><div>POP</div>');
                } else {
                    console.log("no arrow");
                }
            }
        };
    }]);
})();