angular.module('app').factory('rooms', ['$rootScope', '$state', '$mdDialog', 'utils', 'files', 'api', 'users', function($rootScope, $state, $mdDialog, utils, files, api, users) {

	// Disconnect participant on browser's window closed
	window.addEventListener('beforeunload', function () {
		rooms.disconnectSession();
	});

	/* AUXILIARY MEHTODS */
	function randomString() {
		return Math.random().toString(36).slice(2);
	}

	// Prepare HTML dynamic elements (URL clipboard input)
	function initializeSessionView(userData) {
		$('#copy-input').val(window.location.href);
		$('#videos').prepend('<div id="publisher-container" style="position: relative; overflow: hidden;; overflow: hidden;"><div class="profile" style="display:none; position: absolute; z-index: 1; color: white; font-size: 22px; flex-direction: column-reverse; justify-content: center; align-items: center; height: 100%;width: 100%;"><div class="text" style="padding-top: 4px;">' + userData.name + '</div><div class="avatar" style="background-image: url('+ userData.avatar_url +'); background-position: center center; background-size: cover; background-repeat: no-repeat; width: 10vw; height: 10vw; min-width: 10vw; min-height: 10vw;"></div></div></div>');
	}


	// Dynamic layout adjustemnt depending on number of videos
	function updateLayout() {
		console.warn('There are now ' + rooms.numOfVideos + ' videos');
		updateGridView();
	}

	function isMobileDevice() {
 		var isMobile = (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());
 		return isMobile;
	}

	function isSafariBrowser() {
		var isSafari = window.safari !== undefined;
		if (isSafari) {
			console.log("I AM SAFI REEEE");
			return true;
		}
		return false;
	}

	function updateGridView() {
		if (!isMobileDevice()) {
			console.log("I AM NOT MOBILE");

			var row = 1;
			if (rooms.numOfVideos == 1) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(1, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(1, 1fr)');
				row = 1;
			} else if (rooms.numOfVideos == 2) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(1, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
				row = 1;
			} else if (rooms.numOfVideos == 3) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(1, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(3, 1fr)');
				row = 1;
			} else if (rooms.numOfVideos <= 4) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
				row = 2;
			} else if (rooms.numOfVideos <= 6) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(3, 1fr)');
				row = 2;
			} else if (rooms.numOfVideos <= 8) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
				row = 2;
			} else if (rooms.numOfVideos <= 10) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(5, 1fr)');
				row = 2;
			} else if (rooms.numOfVideos <= 12) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(6, 1fr)');
				row = 2;
			} else if (rooms.numOfVideos <= 18) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(3, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(6, 1fr)');
				row = 3;
			} else if (rooms.numOfVideos <= 21) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(3, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(7, 1fr)');
				row = 3;
			} else if (rooms.numOfVideos <= 28) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(4, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(7, 1fr)');
				row = 4;
			} else if (rooms.numOfVideos <= 32) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(4, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(8, 1fr)');
				row = 4;
			} else if (rooms.numOfVideos <= 36) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(4, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(9, 1fr)');
				row = 4;
			} else if (rooms.numOfVideos <= 40) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(4, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(10, 1fr)');
				row = 4;
			} else if (rooms.numOfVideos <= 50) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(5, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(10, 1fr)');
				row = 5;
			} else {
				alert("50+ users have joined. Only 50 users are supported");
			}

			if (isSafariBrowser() && rooms.numOfVideos <= 50) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat('+ row +', calc((100vh - 156px)/'+row+')');
			}
		} else {
			console.log("I AM MOBILE");
			$('.rooms-page #videos').css('height', 'calc(100vh - 279px)');
			if (rooms.numOfVideos == 1) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(1, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(1, 1fr)');
			} else if (rooms.numOfVideos == 2) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(1, 1fr)');
			} else if (rooms.numOfVideos == 3) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(3, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(1, 1fr)');
			} else if (rooms.numOfVideos == 4) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(2, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
			} else if (rooms.numOfVideos <= 6) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(3, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
			} else if (rooms.numOfVideos <= 8) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(4, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
			} else if (rooms.numOfVideos <= 10) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(5, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
			} else if (rooms.numOfVideos <= 12) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(6, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(2, 1fr)');
			} else if (rooms.numOfVideos <= 15) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(5, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(3, 1fr)');
			} else if (rooms.numOfVideos <= 18) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(6, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(3, 1fr)');
			} else if (rooms.numOfVideos <= 21) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(7, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(3, 1fr)');
			} else if (rooms.numOfVideos <= 24) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(6, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
			} else if (rooms.numOfVideos <= 28) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(7, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
			} else if (rooms.numOfVideos <= 32) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(8, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
			} else if (rooms.numOfVideos <= 36) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(9, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
			} else if (rooms.numOfVideos <= 40) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(10, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(4, 1fr)');
			} else if (rooms.numOfVideos <= 45) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(9, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(5, 1fr)');
			} else if (rooms.numOfVideos <= 50) {
				$('.rooms-page #videos').css('grid-template-rows', 'repeat(10, 1fr)');
				$('.rooms-page #videos').css('grid-template-columns', 'repeat(5, 1fr)');
			} else {
				alert("50+ users have joined. Only 50 users are supported");
			}
		}
		console.log("I AM DONE here");
	}

	/**
	 * --------------------------
	 * SERVER-SIDE RESPONSIBILITY
	 * --------------------------
	 * These methods retrieve the mandatory user token from OpenVidu Server.
	 * This behavior MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
	 * the API REST, openvidu-java-client or openvidu-node-client):
	 *   1) Initialize a session in OpenVidu Server	(POST /api/sessions)
	 *   2) Generate a token in OpenVidu Server		(POST /api/tokens)
	 *   3) The token must be consumed in Session.connect() method
	 */

	var OPENVIDU_SERVER_URL = "https://vs.storyhives.com";
	var OPENVIDU_SERVER_SECRET = "88Fo6ZC8rokCq";

	// var OPENVIDU_SERVER_URL = "https://" + location.hostname + ":4443";
	// var OPENVIDU_SERVER_SECRET = "MY_SECRET";


	function getToken(mySessionId) {
		console.log(' in getToken ' , mySessionId);
		return createSession(mySessionId).then(function(sId) {
			console.log(' in createSession then ' , sId);
			return createToken(sId);
			// console.log('after createToken done ');
		});
	}


	function createSession(sId) { // See https://docs.openvidu.io/en/stable/reference-docs/REST-API/#post-apisessions
		return new Promise(function(resolve, reject) {
			$.ajax({
				type: "POST",
				url: OPENVIDU_SERVER_URL + "/api/sessions",
				data: JSON.stringify({ customSessionId: sId }),
				headers: {
					"Authorization": "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
					"Content-Type": "application/json"
				},
				success: function(response) {
					console.log("in create session success " , response);
					resolve(response.id);
				},
				error: function(error) {
					console.log("in create session error " , error);
					if (error.status === 409) {
						console.log(" status 409 ");
						resolve(sId);
					} else {
						console.warn('No connection to OpenVidu Server. This may be a certificate error at ' + OPENVIDU_SERVER_URL);
						if (window.confirm('No connection to OpenVidu Server. This may be a certificate error at \"' + OPENVIDU_SERVER_URL + '\"\n\nClick OK to navigate and accept it. ' +
							'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' + OPENVIDU_SERVER_URL + '"')) {
							// location.assign(OPENVIDU_SERVER_URL + '/accept-certificate');
						}
					}
				}
			});
		});
	}


	function createToken(sId) { // See https://docs.openvidu.io/en/stable/reference-docs/REST-API/#post-apitokens
		return new Promise(function(resolve, reject) {
			$.ajax({
				type: "POST",
				url: OPENVIDU_SERVER_URL + "/api/tokens",
				data: JSON.stringify({ session: sId }),
				headers: {
					"Authorization": "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
					"Content-Type": "application/json"
				},
				success: function(response) {
					console.log("create token success " , response);
					resolve(response.token);
				},
				error: function(error) {
					console.log("create token error " , error);
					reject(error);
				}
			});
		});
	}

	var rooms = {
		// OpenVidu object to initialize a session
		OV: undefined,

		// Session object where the user will connect
		session: undefined,

		// Publisher object which the user will publish
		publisher: undefined,

		// Unique identifier of the session
		sessionId: undefined,

		// True if the audio track of publisher is active
		audioEnabled: true,

		// True if the video track of publisher is active
		videoEnabled: true,

		// Keeps track of the number of videos that are being shown
		numOfVideos: 0,

		subscriber: undefined,

		// All subscribers
		subscribers: [],

		setRoomId: function (sessionId) {
			rooms.sessionId = sessionId;
		},

		joinRoom: function (sessionId) {
			rooms.clearRoomSession();
			console.log("sessionId os " , sessionId);
			rooms.sessionId = sessionId;

			if (!sessionId) {
				sessionId = 'loops' + randomString();
				// console.log("sessionId wtff " , sessionId);
			}

			// if (!this.sessionId) {
			// 	// If the user is joining to a new room
			// 	this.sessionId = randomString();
			// }

			// --- 1) Get an OpenVidu object ---
			rooms.OV = new OpenVidu();


			// --- 1.1) Set Speech Detection Event Config
			rooms.OV.setAdvancedConfiguration({
			    publisherSpeakingEventsOptions: {
			        interval: 50,   // Frequency of the polling of audio streams in ms
			        threshold: -50  // Threshold volume in dB
			    }
			});

			// --- 2) Init a session ---
			rooms.session = rooms.OV.initSession();

			// console.log("init session doneee ", rooms.session);


			// --- 3) Specify the actions when events take place in the session ---
			// On every new Stream received...
			rooms.session.on('streamCreated', function (event) {
				console.log("streamCreated 1 " , event);
				// event.stream.connection.session.connection.data
				var userInfo = JSON.parse(event.stream.connection.data);

				// Subscribe to the Stream to receive it. HTML video will be appended to element with 'subscriber' id
				var eleName = 'videos-container' + (rooms.subscribers.length+1);
				console.log("user  data", userInfo);
				$('#videos').append('<div id="'+eleName+'" style="position: relative; overflow: hidden;" data-index="' + rooms.subscribers.length  +'"><div class="fly" style="position: absolute; z-index: 1; color: red; left: 10px; bottom: 10px; cursor: pointer; display: none;"><i class="material-icons unmute" style="display:none">volume_up</i><i class="material-icons mute" style="display:none">volume_off</i></div><div class="profile" style="display:none; position: absolute; z-index: 1; color: white; font-size: 22px; flex-direction: column-reverse; justify-content: center; align-items: center; height: 100%;width: 100%;"><div class="text" style="padding-top: 4px;">' + userInfo.name + '</div><div class="avatar" style="background-image: url('+ userInfo.avatar_url +'); background-position: center center; background-size: cover; background-repeat: no-repeat; width: 10vw; height: 10vw; min-width: 10vw; min-height: 10vw;"></div></div></div>');
				rooms.subscriber = rooms.session.subscribe(event.stream, eleName);
				console.log("rooms subscriber ", rooms.subscriber);

				rooms.subscriber['userInfo'] = userInfo;
				// console.log("usr info " , rooms.subscriber['userInfo']);

				// When the new video is added to DOM, update the page layout to fit one more participant
				rooms.subscriber.on('videoElementCreated', function (event) {
					$rootScope.$broadcast('play.chime');
					console.log("my event obj iz " , event);
					console.log("videoElementCreated 1 ");
					rooms.numOfVideos++;
					rooms.subscribers.push(rooms.subscriber);
					$('#' + eleName).find('.fly').css('display', 'block');
					var muteAudioClass = '.mute';
					if (rooms.subscriber.stream.hasAudio) {
						muteAudioClass = '.unmute';
					}

					$('#' + eleName).find('.fly').find(muteAudioClass).css('display', 'block');
					$('#' + eleName + ' .fly i').bind('click', function (event) {
						var fly = $(event.target).closest('.fly');
						if (!$(fly).hasClass('disabled')) {
							var index = $(event.target).closest('#'+eleName).data('index');
							if (index != undefined) {
								rooms.changeAudioForSubscriber(index, '#' + eleName);
							}
						}

					});
					updateLayout();
				});

				// rooms.subscriber.on('streamAudioVolumeChange', function(event) {
				// 	console.log('subscriber audio volume change from ' + event.value.oldValue + ' to' + event.value.newValue);
				// });
			});

			// On every new Stream destroyed...
			rooms.session.on('streamDestroyed', function (event) {
				console.log("streamDestroyed 1", event);

				var conId = event.stream.connection.connectionId;
				var idx = -1;
				var counter = 0;
				rooms.subscribers.forEach(function(subscriber) {
					if (subscriber.videos.length) {
						subscriber.videos.forEach(function(video) {
							if (video.id.indexOf(conId) != -1) {
								idx = counter;
							}
						});
					}
					counter+=1;
				});

				if (idx != -1) {
					console.log("foundddedd ", idx);
					if (rooms.subscribers.length <= idx) {
						console.log("len b ", rooms.subscribers.length);
						rooms.subscribers.splice(idx, 1);
						console.log("len a ", rooms.subscribers.length);
					}
					$('#videos-container'+(idx+1)).remove();
				}

				// Update the page layout
				rooms.numOfVideos--;
				updateLayout();
			});


			rooms.session.on('signal:audio-changed', function(event) {
			    var isEnabled = true;
			    if (event.data == 'false') {
			    	isEnabled = false;
			    }

			    var index = 0;
			    rooms.subscribers.forEach(function(subscriber) {
			    	if (subscriber.remote && subscriber.id.indexOf(event.from.connectionId) != -1) {
			    		rooms.changeAudioForSubscriber(index, '#' + subscriber.targetElement.id, true, isEnabled);
			    	}
			    	index += 1;
			    });
			});

			rooms.session.on('signal:video-changed', function(event) {
			    var isEnabled = true;
			    if (event.data == 'false') {
			    	isEnabled = false;
			    }

			    var index = 0;
			    rooms.subscribers.forEach(function(subscriber) {
			    	console.log("my sub info " , subscriber);
			    	if (subscriber.remote && subscriber.id.indexOf(event.from.connectionId) != -1) {
			    		rooms.changeVideoForSubscriber(index, '#' + subscriber.targetElement.id, isEnabled);
			    	}
			    	index += 1;
			    });
			});
			// --- 4) Connect to the session with a valid user token ---


			// 'getToken' method is simulating what your server-side should do.
			// 'token' parameter should be retrieved and returned by your own backend
			getToken(sessionId).then(function(token) {

				console.log("getToken in then token is " , token);

				console.log("rooms.session " , rooms.session);

				// Connect with the token
				var userData = {'name': users.current.full_name, 'avatar_url': users.current.url};
				rooms.session.connect(token, JSON.stringify(userData))
					.then(function() {
						$rootScope.$broadcast('rooms.loaded');
						console.log("error1");


						// --- 5) Set page layout for active call ---
						// Update the URL shown in the browser's navigation bar to show the session id
						// var path = (location.pathname.slice(-1) == "/" ? location.pathname : location.pathname + "/");
						// window.history.pushState("", "", path + '#' + sessionId);


						// Auxiliary methods to show the session's view
						// showSessionHideJoin();
						console.log("my data is " , userData);
						initializeSessionView(userData);

						// --- 6) Get your own camera stream with the desired properties ---
						rooms.publisher = rooms.OV.initPublisher('publisher-container', {
							audioSource: undefined, // The source of audio. If undefined default audio input
							videoSource: undefined, // The source of video. If undefined default video input
							publishAudio: true,  	// Whether to start publishing with your audio unmuted or not
							publishVideo: true,  	// Whether to start publishing with your video enabled or not
							// resolution: '640x480',  // The resolution of your video
							frameRate: 30,			// The frame rate of your video
							insertMode: 'APPEND',	// How the video is inserted in target element 'videos-container'
							mirror: false       		// Whether to mirror your local video or not
						});


						// --- 7) Specify the actions when events take place in our publisher ---
						// When our HTML video has been added to DOM...
						rooms.publisher.on('videoElementCreated', function (event) {
							$rootScope.$broadcast('rooms.loaded');
							console.log("my event obj iz " , event);

							// When your own video is added to DOM, update the page layout to fit it
							console.log("videoElementCreated 123");
							rooms.numOfVideos++;
							updateLayout();
							$(event.element).prop('muted', true); // Mute local video to avoid feedback
						});

						// rooms.publisher.on('streamAudioVolumeChange', function(event) {
						// 	console.log('Publisher audio volume change from ' + event.value.oldValue + ' to' + event.value.newValue);
						// });

						// --- 8) Publish your stream ---
						rooms.session.publish(rooms.publisher);
					})
					.catch(function(error) {
						console.log("errorss 2 ");
						console.log('There was an error connecting to the session:', error.code, error.message);
						$rootScope.$broadcast('rooms.loaded');
					});

					rooms.session.on('publisherStartSpeaking', function(event) {
					    console.log('555Publisher ' + event.connection.connectionId + ' start speaking');
					    // console.log('sub ' , rooms.subscriber);
					    // console.log('pub ' , rooms.publisher);
					    // console.log('session ' , rooms.session);
					    var isSubsSpeaking = false;

					    rooms.subscribers.forEach(function(subscriber) {
						    if (subscriber.videos.length) {
						    	// console.log('hvae subs length', subscriber);
						    	subscriber.videos.forEach(function(video) {
						    		if (video.id.indexOf(event.connection.connectionId) != -1) {
						    			isSubsSpeaking = true;
						    			$(video.video).css('border', '2px solid #ffc106');
						    			$(video.video).css('border-left', '4px solid #ffc106');
						    			// console.log("found in border -start");
						    		}
						    	});
						    }
					    });

					    if (!isSubsSpeaking && rooms.publisher.videos.length) {
					    	$(rooms.publisher.videos[0]).css('border', '2px solid #ffc106');
					    	$(rooms.publisher.videos[0]).css('border-left', '4px solid #ffc106');
					    	// console.log("found in sub -start");
					    }
					});

					rooms.session.on('publisherStopSpeaking', function (event) {
					    console.log('4444Publisher ' + event.connection.connectionId + ' stop speaking');
					    var isSubsSpeaking = false;
					    rooms.subscribers.forEach(function(subscriber) {
						    if (subscriber.videos.length) {
						    	// console.log('subs hvae subs length', subscriber);
						    	subscriber.videos.forEach(function(video) {
						    		if (video.id.indexOf(event.connection.connectionId) != -1) {
						    			isSubsSpeaking = true;
						    			$(video.video).css('border', '0px');
						    			// console.log("found in sub -stop");
						    		}
						    	});
						    }
					    });

					    if (!isSubsSpeaking && rooms.publisher.videos.length) {
					    	$(rooms.publisher.videos[0]).css('border', '0px');
					    	// console.log("found in pubs -stop ");
					    }
					});
			});
		},

		leaveRoom: function() {
			if (rooms.session && rooms.sessionId && rooms.numOfVideos == 1) {
				$.ajax({
						type: "DELETE",
						url: OPENVIDU_SERVER_URL + "/api/sessions/" + rooms.sessionId,
						headers: {
							"Authorization": "Basic " + btoa("OPENVIDUAPP:" + OPENVIDU_SERVER_SECRET),
							"Content-Type": "application/json"
						},
						success: function(response) {
							console.log("in delete session success " , response);
						},
						error: function(error) {
							console.log("something went wrong while deleting session" , error);
						}
				});
			}

			// --- 9) Leave the session by calling 'disconnect' method over the Session object ---
			rooms.session.disconnect();

			// Back to welcome page
			$state.go('dashboard.buzz', {tab: 2});
			// window.location.href = window.location.origin + window.location.pathname;
		},

		muteAudio: function() {
			rooms.audioEnabled = !rooms.audioEnabled;
			rooms.publisher.publishAudio(rooms.audioEnabled);
			if (!rooms.audioEnabled) {
				$('#mute-audio').removeClass('btn-primary');
				$('#mute-audio').addClass('btn-default');
				$('#mute-audio').find('span').text("Unmute");
			} else {
				$('#mute-audio').addClass('btn-primary');
				$('#mute-audio').removeClass('btn-default');
				$('#mute-audio').find('span').text("Mute");
			}

			rooms.session.signal({
			      data: rooms.audioEnabled ? 'true' : 'false',    // Any string (optional)
			      to: [],                                         // Array of Connection objects (optional. Broadcast to everyone if empty)
			      type: 'audio-changed'                           // The type of message (optional)
			    });
		},

		muteVideo: function() {
			rooms.videoEnabled = !rooms.videoEnabled;
			rooms.publisher.publishVideo(rooms.videoEnabled);

			if (!rooms.videoEnabled) {
				$('#mute-video').removeClass('btn-primary');
				$('#mute-video').addClass('btn-default');
				$('#publisher-container').find('.profile').css('display', 'flex');
			} else {
				$('#mute-video').addClass('btn-primary');
				$('#mute-video').removeClass('btn-default');
				$('#publisher-container').find('.profile').css('display', 'none');
			}

			rooms.session.signal({
			      data: rooms.videoEnabled ? 'true' : 'false',    // Any string (optional)
			      to: [],                                         // Array of Connection objects (optional. Broadcast to everyone if empty)
			      type: 'video-changed'                           // The type of message (optional)
			});
		},

		disconnectSession: function() {
			if (rooms.sessionId && rooms.session) {
				rooms.sessionId = undefined;
				rooms.numOfVideos = 0;
				rooms.session.disconnect();
				rooms.clearRoomSession();
			}
		},

		clearRoomSession: function() {
			rooms.OV = undefined;
			rooms.session = undefined;
			rooms.publisher = undefined;
			rooms.sessionId = undefined;
			rooms.audioEnabled = true;
			rooms.videoEnabled = true;
			rooms.numOfVideos = 0;
			rooms.subscriber = undefined;
			rooms.subscribers = [];
		},

		addVideoImage: function() {
			rooms.numOfVideos++;
			$($('#videos > video')[0]).clone().appendTo('#videos');
			// console.log("number of videos ", rooms.numOfVideos);
			updateGridView();
		},

		changeAudioForSubscriber: function(index, ele, isPublisher, isEnabled) {
			// console.log("alig bafff");
			isPublisher = isPublisher || false;
			if (index < rooms.subscribers.length && rooms.subscribers[index]) {
				var sub = rooms.subscribers[index];
				ele = $(ele).find('.fly');
				if (isPublisher) {
					if (isEnabled) {
						$(ele).removeClass('disabled');
						$(ele).css({'color': 'red', 'cursor': 'pointer'});
					} else {
						$(ele).addClass('disabled');
						$(ele).css({'color': '#7b7474', 'cursor': 'not-allowed'});
					}
				} else {
					rooms.subscribers[index].stream.hasAudio = !rooms.subscribers[index].stream.hasAudio;
					rooms.subscribers[index].subscribeToAudio(rooms.subscribers[index].stream.hasAudio);
				}

				if ((!isPublisher && rooms.subscribers[index].stream.hasAudio) || (isPublisher && isEnabled && rooms.subscribers[index].stream.hasAudio)) {
					$(ele).find('.mute').css('display', 'none');
					$(ele).find('.unmute').css('display', 'block');
				} else {
					$(ele).find('.unmute').css('display', 'none');
					$(ele).find('.mute').css('display', 'block');
				}
				// console.log("has Audio after " + rooms.subscribers[index].stream.hasAudio);
			}
		},

		changeVideoForSubscriber: function(index, ele, isEnabled) {
			if (index < rooms.subscribers.length && rooms.subscribers[index]) {
				var sub = rooms.subscribers[index];
				rooms.subscribers[index].subscribeToVideo(isEnabled);
				rooms.subscribers[index].stream.hasVideo = isEnabled;
				if ($(ele).find('.profile').length) {
					ele = $(ele).find('.profile');
					if (!isEnabled) {
						$(ele).css('display', 'flex');
					} else {
						$(ele).css('display', 'none');
					}
				} else {
				}

			}
		}
	};

    return rooms;
}]);
