(function(){
    'use strict';

    angular.module('app').controller('EmailTemplateController', ['$scope', '$window','$rootScope', '$state', '$mdDialog', 'utils', 'templates', function($scope, $window, $rootScope, $state, $mdDialog, utils, templates) {
        $scope.templates = templates;

        //templates search query
        $scope.search = { query: '' };

        $scope.selectedItems = [];
        $scope.emailWrapper = '';
        // $scope.editTemplate = {};
        // $scope.editTemplate.body = '';
        // $scope.editTemplate.tokens = [];

        $scope.isItemSelected = function(item) { 
            return $scope.selectedItems.indexOf(item) > -1;
        };

        $scope.select = function(item) {
            var idx = $scope.selectedItems.indexOf(item);
            if (idx > -1) {
                $scope.selectedItems.splice(idx, 1);
            }
            else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.deleteTemplates = function() {

            templates.delete($scope.selectedItems).then(function() {
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        $scope.toggleAllTemplates = function() {

            //all items already selected, deselect all
            if ($scope.selectedItems.length === templates.all.length) {
                $scope.selectedItems = [];
            }

            //all items aren't selected, copy all templates array to selected items
            else {
                $scope.selectedItems = templates.all.slice();
            }
        };

        $scope.showCreateTemplateModal = function($event) {
            $scope.tokens = {};
            $scope.templateModel = {};
            $mdDialog.show({
                templateUrl: 'assets/views/modals/create-template.html',
                clickOutsideToClose: true,
                controllerAs: 'ctrl',
                controller: function() { this.parent = $scope; },
                targetEvent: $event,
            });
        };

        $scope.showUpdateTemplateModal = function(template, $event) {
            $scope.templateModel = angular.copy(template);
            // $scope.templateModel.variables = [];
            $scope.tokens = JSON.parse($scope.templateModel.tokens);
            $mdDialog.show({
                templateUrl: 'assets/views/modals/update-template.html',
                clickOutsideToClose: true,
                controllerAs: 'ctrl',
                controller: function() { this.parent = $scope; },
                targetEvent: $event,
            });
            $scope.tokenInputer();
        };

        $scope.showEditEmailWrapperModal = function($event) {
            $mdDialog.show({
                templateUrl: 'assets/views/modals/edit-email-wrapper.html',
                clickOutsideToClose: true,
                controllerAs: 'ctrl',
                controller: function() { this.parent = $scope; },
                targetEvent: $event,
            });
        };
        
        $scope.tokenInputer = function() {
            var tokens = {};
            var pattern = /{{\s*([a-zA-Z\d]+(:?[_:\-]+[a-zA-Z\d]+)*)\s*}}/g;
            ($scope.templateModel.body.match(pattern) || []).map(function(item){
                tokens[item.replace(pattern, "$1")] = $scope.tokens[item.replace(pattern, "$1")] || "";
            });

            $scope.tokens = angular.copy(tokens);
        };

        $scope.updateTemplate = function() {
            $scope.templateModel.tokens = JSON.stringify(angular.copy($scope.tokens));
            templates.updateTemplate($scope.templateModel, $scope.templateModel.id).then(function() {
                $mdDialog.hide();
                utils.showToast('updatedTemplateSuccessfully', true);
                $scope.paginate($scope.params);
                $scope.selectedItems = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };

        $scope.createNewTemplate = function() {
            $scope.templateModel.tokens = JSON.stringify($scope.tokens);
            templates.create($scope.templateModel).then(function() {
                $mdDialog.hide();
                utils.showToast('createdTemplateSuccessfully', true);
                $scope.paginate($scope.params);
                $scope.errors = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };

        $scope.paginate = function(params) {
            if ($scope.templatesAjaxInProgress) {
                return;
            }

            $scope.templatesAjaxInProgress = true;

            templates.paginate(params).then(function(response) {
                $scope.items = response.data.data;
                $scope.totalItems = response.data.total;

                $scope.templatesAjaxInProgress = false;
            });
        };

        $scope.paginate($scope.params);


        $scope.preview = function(isEmailWrapper) {
            var w =  $window.open("/blank.html", "_blank");
            var body = '';
            isEmailWrapper = isEmailWrapper || false;
            
            if ($scope.templateModel && !isEmailWrapper) {
                body = angular.copy($scope.templateModel.body);
            }
            
            angular.forEach(($scope.tokens || []), function(value, key){
                if(value){
                    var re = new RegExp('{{\\s*'+key+'\\s*}}', "g");
                    body = body.replace(re, value);
                }
            });
            
            templates.preview(body).then(function(response) {                
                w.document.write(response.data);
                w.document.title = "Email Template Preview";
            }, function(response) {
                w.close();
                $scope.setErrors(response.data);
            });
        };        

        $scope.getEmailWrapper = function(body) {
            templates.preview(body).then(function(response) {
                $scope.emailWrapper = response.data;
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };
        
        $scope.getEmailWrapper($scope.emailWrapper);

        $scope.editEmailWrapper = function() {
            templates.editEmailWrapper($scope.emailWrapper).then(function() {
                $mdDialog.hide();
                utils.showToast('updatedEamilWrapperSuccessfully', true);
                // $scope.emailWrapper = wrapper;
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };
    }]);
}());