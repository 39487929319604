// TODO: THIS FILE SHOULD BE COMPLETELY DELETED AFTER IMPLEMENTING ALL MODES
(function(){
    'use strict';

    angular.module('pixie.dashboard.right', [ 'material.components.sidenav' ] )

    .directive('rightDashboardColumn', ['$mdSidenav', '$mdMedia', function($mdSidenav, $mdMedia) {
        return {
            restrict: 'E',
            templateUrl: 'rightDashboardColumn.html',
            replace: true,
            link: function($scope) {
                $scope.data = {
                    view: 'activity',
                    familyUserId : null
                };
                
                $scope.$on('rightpanel.set.view', function(evt, data) {
                    $scope.data.view = data.view;
                });
                
                $scope.$on('rightpanel.set.user_id', function(evt, userId) {
                    $scope.data.familyUserId = userId;
                });

                $scope.close = function() {
                    $mdSidenav('right').close();
                };
                
                // should be able to do this with styles, but bootstrap and angular-material have issues (should be class="hide show-gt-md")
                $scope.showClose = function() {
                    return !$mdMedia('gt-md');
                };
            }
        };
    }])
    .directive('recentActivityPanel', ['$mdSidenav', 'users', 'utils', 'activity', 'localStorage', function($mdSidenav, users, utils, activity, localStorage) {
        return {
            restrict: 'E',
            templateUrl: 'recentActivityPanel.html',
            link: function($scope) {
                $scope.users = users; // sigh.
                $scope.data = {
                    activity: activity
                };
                
                $scope.filteredName= function(name) {
                    var result = name;
                    if(localStorage.get('stripExtensions', true))
                    {
                        result = result.replace(/\.[^/.]+$/, "");
                    }
                    return result;
                };
                
                $scope.toRelativeTime = function(time) {
                    return moment.utc(time).fromNow();
                };
            }
        };
    }])
    .filter('activityRelativeToContext', ['folders', function(folders) {
        return function(input) {
            return input.filter(function(activity) {
                if(folders.selected) {
                    // we might want to change this around some.
                    return activity.folder_id == folders.selected.id;
                }
                return false;
            });
        };
    }]);
    
    
})();