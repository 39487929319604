angular.module('app').factory('selectedItems', ['$rootScope', '$http', '$timeout', 'folders', 'files', 'deleter', 'utils', 'trash', 'labels', 'favorites', 'previewStatus', '$stateParams', 'dialogManager', 'lockables', 'api', function($rootScope, $http, $timeout, folders, files, deleter, utils, trash, labels, favorites, previewStatus, $stateParams, dialogManager, lockables, api) {
    var selectedItems = {

        /**
         * Password to view selected items, if required.
         */
        password: false,

        /**
         * Currently selected items in dashboard (folders and files)
         */
        items: false,

        current: false,

        /**
         * Get all currently selected items.
         *
         * @returns {array|boolean}
         */
        get: function () {
            if (this.items) {
                return this.items;
            } else if (this.lastFolder) {
                return [this.lastFolder];
            }
        },

        /**
         * Return number of currently selected items.
         *
         * @returns {int}
         */
        getLength: function() {
            return this.items ? this.items.length : 0;
        },

        /**
         * Get the first selected item or it's property
         *
         * @param {string} prop
         * @returns {object|string}
         */
        first: function(prop) {
            var first;
            if ( ! this.items) {
                first = folders.selected;
            } else {
                first = this.items[0];
            }

            if (!prop) {
                return first;
            }

            // NOTE: Removed for FF-46
            // if (this.password) {
            //     //remove any old query strings and add password to url
            //     first['absoluteUrl'] = first['absoluteUrl'].split('?')[0] += '?password='+this.password;
            // }

            return first && first[prop];
        },

        isAnyLocked: function() {
            for (var i = this.items.length - 1; i >= 0; i--) {
                if (this.items[i].is_locked){
                    return true;
                }
            }
            return false;
        },

        /**
         * Return if given item is currently selected.
         *
         * @param {object} item
         * @returns {boolean}
         */
        has: function(item) {
            if ( ! this.items) {
                return;
            }

            for (var i = 0; i < this.items.length; i++) {
                if (this.items[i].id == item.id) {
                    return true;
                }
            }
        },

        /**
         * Deselect given item.
         *
         * @param {int|string|object} id
         * @param {string|undefined} type
         */
        deselect: function(id, type) {
            var changed = false;
            if (angular.isObject(id)) {
                type = id.type; id = id.id;
            }

            if (this.items) {
                for (var i = 0; i < this.items.length; i++) {
                    var item = this.items[i];

                    if (item.id == id && item.type === type) {
                        selectedItems.items.splice(i, 1);
                        changed = true;
                        break;
                    }
                }
            }

            if(changed) {
                $rootScope.$broadcast('selection.changed', { selected: selectedItems.items });
            }
        },

        /**
         * Deselect all selected files.
         */
        deselectAll: function() {
            this.items = false;
            this.lastFolder = false;
            $rootScope.$broadcast('selection.changed', { selected: selectedItems.items });
        },

        /**
         * Select given items or item.
         *
         * @param {int|string} id
         * @param {string} type
         * @param {boolean} deselect
         */
        set: function(id, type, deselect) {
            var item;
            if ( ! this.items || deselect) {
                this.items = [];
            }

            if (angular.isObject(id)) {
                item = id;
            } else if (utils.stateIs('dashboard.trash')) {
                item = trash.getItemById(id);
            } else if (utils.stateIs('dashboard.favorites')) {
                item = favorites.getItemById(id);
            } else if (type === 'file') {
                item = folders.getFileById(id);
            } else {
                item = folders.getById(id);
            }

            //if item exists and it's not already in selected, select it now
            if (item && ! this.has(item)) {
                this.items.push(item);

                $rootScope.$broadcast('selection.changed', { selected: selectedItems.items });
            }
        },

        /**
         * Select given items.
         *
         * @param {array|object} items
         */
        setAll: function(items) {
            if (!angular.isArray(items)) {
                items = [items];
            }

            this.items = items;
            $rootScope.$broadcast('selection.changed', { selected: selectedItems.items });
        },

        /**
         * Check if given type matches first selected items mime type.
         *
         * @param {string} type
         * @param {string} mime
         * @returns {boolean}
         */
        mimeTypeIs: function(type, mime) {
            if ( ! mime) {
                mime = this.first('mime');
            }

            if (this.first('type') === 'folder') {
                return false;
            }

            //handle some special cases
            if (type === 'video' && mime === 'application/ogg') {
                return true;
            }
            if (type === 'zip' && mime === 'application/zip') {
                return true;
            }
            if (type === 'pdf' && mime === 'application/pdf') {
                return true;
            }
            if (type === 'html' && mime === 'text/html') {
                return true;
            }

            return mime.split('/')[0] === type;
        },

        /**
         * Return first currently selected items file format extracted from file mime.
         *
         * @returns {string}
         */
        getMimeFileType: function() {
            if (this.first('type') === 'folder') {
                return 'folder';
            }

            return this.first('mime').split('/')[1];
        },

        /**
         * Open rename modal for currently open file or folder.
         */
        rename: function () {

            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }

            if (this.first('type') === 'folder') {
                folders.openRenameModal(this.first().id);
            } else {
                files.openRenameModal(this.first().name);
            }
        },

        /**
         * Open set folder image modal for currently selected folder.
         */
        setFolderImage: function () {
            if (this.first('type') === 'folder') {
                dialogManager.showSetFolderImageDialog(selectedItems.first());
            }
        },

        /**
         * Resets folder image.
         */
        resetFolderImage: function () {
            if (this.first('type') === 'folder') {
                api.folder.resetFolderImage(selectedItems.first().share_id).then(function() {
                    folders.available.map( function(availabeFolder) {
                        if (availabeFolder.id == selectedItems.first().id) {
                          availabeFolder.folder_image_url = null;
                          availabeFolder.folder_image_id = null;
                        }
                      });
                    utils.showToast(utils.trans('folder.folderImageUpdatedSuccessfully'));
                });
            }
        },

        /**
         * Open purchase modal for current file.
         */
        purchase: function () {
            dialogManager.showPurchaseTemplateDialog(selectedItems.first());
        },

        /**
         * Show print preview for offer file
         */
        print: function (item) {
            item = item || selectedItems.first();
            if (item.is_offer && item.type == 'file' ) {
                var innerContents = "<h1>Unable to load data. Try again later.<h1>";
                var popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                popupWinindow.document.open();

                api.offers.htmlContent(item.id).then(function success(response) {

                    if (response) {
                        innerContents = response;
                    }

                    var html_page = "<html><head><style>"+
                                ".html-preview-container{"+
                                    "font-family: RobotoDraft,Roboto,'Helvetica Neue',sans-serif;"+
                                    "padding: 10px 20px;"+
                                    "background-color: #fff;"+
                                    ".md-whiteframe-z2();"+
                                    "width: auto;"+
                                    "overflow: auto;"+
                                "}"+
                                ".html-preview-container p img {"+
                                    "max-width: 100%"+
                                "}"+
                                "</style>"+
                                "<head><body onload='window.print()'><div class='html-preview-container'>" + innerContents + "</div></html>";

                    popupWinindow.document.write( html_page );
                    popupWinindow.document.close();
                }, function error() {
                    return utils.showToast(utils.trans('genericError'));
                });
            } else {
                return utils.showToast(utils.trans('print.cantPerformThisAction'));
            }
        },

        /**
         * Open lock modal for password protected.
         */
        lock: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            dialogManager.showLockDialog(selectedItems.first());
        },

        /**
         * Open remove password modal for password protected.
         */
        removePassword: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }

            lockables.tryRemoveLock(selectedItems.first()).then(function success() {
              // it's done.  nothing to see here. move along.
            }, function failure() {
              dialogManager.showRemoveLockDialog(selectedItems.first());
            });
        },

        changeThumbnail: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            dialogManager.showChangeThumbnailDialog(selectedItems.first()).then(function success(data) {
                if(data.changed) {
                    folders.refreshFiles();
                }
            });
        },


        /**
         * Open set as template store folder.
         */
        makeTemplateStore: function() {
            dialogManager.showMakeTemplateStoreDialog(selectedItems.first());
        },

        /**
         * Open edit html doc modal.
         */
        editHtmlDoc: function() {
            if (!selectedItems.mimeTypeIs('html')) {
                return utils.showToast(utils.trans('onlyHtmlIsEditable'));
            }

            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('offerIsNotEditable'));
            }

            $http.get($rootScope.baseUrl+'user-file/' + selectedItems.first().share_id).then(function(response) {
                dialogManager.showEditDocDialog(response.data, selectedItems.first('share_id')).then(function(data){
                    $rootScope.$broadcast('activity.happened', 'edited', 'file', data);
                }, function() {
                    // error msg displayed already
                });
            }, function error(response) {
                utils.showToast(response.data);
            });
        },

        /**
         * Update first selected item.
         *
         * @param {object|undefined} payload
         * @return promise
         */
        update: function(payload) {
            var slug = this.first('type') === 'file' ? 'files' : 'folders';

            return $http.put($rootScope.baseUrl+slug+'/'+this.first('id'), payload || this.first()).then(function(response) {
                if (slug === 'folders') {
                    folders.selected = response.data;
                } else {
                    selectedItems.set(response.data, true);

                    if (angular.isArray(folders.selected.files)) {
                        for (var i = 0; i < folders.selected.files.length; i++) {
                            if (folders.selected.files[i].id == response.data.id) {
                                folders.selected.files[i] = response.data; break;
                            }
                        }
                    }
                }
            });
        },

        /**
         * Start a download for selected items.
         *
         * @param {array|object|undefined} items
         */
        download: function (items) {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            $('#download-iframe').remove();

            if (items && !angular.isArray(items)) {
                items = [items];
            }

            if ( ! items) {
                items = this.get().map(function(item) {
                    return { id: item.id, share_id: item.share_id, type: item.type, url: item.absoluteUrl, mime: item.mime };
                });
            }

            var iframe = $('<iframe id="download-iframe" style="display: none"></iframe>');

            //downloading just a single file
            if (items.length === 1 && items[0].type === 'file') {
                $http.get('download/check/'+items[0].share_id).then(function(response) {
                    iframe.appendTo('body').attr('src', $rootScope.baseUrl+'download/file/'+items[0].share_id);
                }, function(response) {
                    utils.showToast(response.data);
                });
            }

            //download multiple items or a folder
            else {
                $http.post('download/create', {items:items}).then(function(response) {
                    iframe.appendTo('body').attr('src', $rootScope.baseUrl+'download/zip/'+response.data);
                }, function(response) {
                    utils.showToast(response.data);
                });
            }
        },

        /**
         * Copy selected items.
         */
        copy: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            $http.post('copy-items', {items: this.get(), folderId: folders.selected.id }).then(function(response) {
                if (response.data.files && response.data.files.length && folders.selected.files) {
                    folders.selected.files = folders.selected.files.concat(response.data.files);
                }

                if (response.data.folders && response.data.folders.length) {
                    folders.available = folders.available.concat(response.data.folders);
                }

                var count = response.data.files.length+response.data.folders.length;
                utils.showToast(utils.trans('copiedItems', {number: count}));
                $rootScope.$broadcast('activity.happened', 'copied', 'item', response.data.files.concat(response.data.folders));
            }, function(response) {
                if(angular.isString(response.data)) {
                    utils.showToast(response.data);
                }
            });
        },

        /**
         * unlink shared selected items.
         */
        unlink: function() {
            var items = this.get();
            // api.share.unklink(items)
            $http.post('shareable/unlink-items', {items: items}).then(function(response) {
                folders.selected.files = folders.selected.files.filter(function(file) {
                    return !response.data.files.find(function(item) {
                        return item.id == file.id;
                    });
                });

                folders.available = folders.available.filter(function(folder) {
                    return !response.data.folders.find(function(item) {
                        return item.id == folder.id;
                    });
                });
                $rootScope.$broadcast('activity.happened', 'unlinked', 'item', items);
                selectedItems.deselectAll();
                folders.refreshFiles();
                utils.showToast('Unlinked Items');
            }, function(response) {
                if(angular.isString(response.data)) {
                    utils.showToast(response.data);
                }
            });
        },

        /**
         * rename shared selected items.
         */
        sharedRename: function() {
            if (this.first('type') === 'folder') {
                folders.openSharedRenameModal(this.first().id);
            } else {
                files.openSharedRenameModal(this.first().name);
            }
        },


        /**
         * Open share dialog for currently selected items.
         */
        share: function () {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            dialogManager.showShareDialog(undefined, this.first());
        },

        /**
         * Preview first selected item.
         */
        preview: function (link_shareable_id) {
            //if we're in trash and it's a folder ask user to restore it first before previewing
            if (utils.stateIs('dashboard.trash')) {
                utils.confirm({
                    title: utils.trans('trashPreviewItemTitle'),
                    content: utils.trans('trashPreviewItemContent'),
                    ok: utils.trans('restore'),
                    onConfirm: function() {
                        selectedItems.restore();
                    }
                });

            } else if (this.first('type') === 'folder') {
                if (this.first('is_locked')) {
                    dialogManager.showUnlockDialog(this.first()).then(function success(item) {
                        selectedItems.previewFolder(link_shareable_id);
                    }, function err(msg) {
                        // already shown.
                    });
                } else {
                    this.previewFolder(link_shareable_id);
                }

            } else if (this.first('type') === 'file' && this.first('is_parent_template') && !this.first('owned_by_current_user') ) {
                dialogManager.showPurchaseTemplateDialog(selectedItems.first());
            } else {
                dialogManager.showPreviewDialog(undefined, this.items).then(function(data) {
                    if(data.postVerb == 'share') {
                        // share this item.
                        dialogManager.showShareDialog(undefined, data.item);
                    }
                }, function() {
                    // don't care.
                });
                $rootScope.$emit('preview.shown'); // not sure if we need htis.
            }
        },

        previewFolder: function(link_shareable_id) {
            if(link_shareable_id) {
                return $rootScope.$emit('share.fetch_child', this.first());
            }
            folders.open(this.first('share_id'));
        },

        /**
         * Copy Link for selected item into clipboard.
         */
        copyLink: function () {
            var item = this.first(),
                link = $rootScope.baseUrl+(! utils.getSetting('enablePushState') ? '#/' : '')+'view/'+item.type+'/'+item.share_id + '/' + item.name;

            if (item.is_offer) {
                utils.showToast('copyNotSupported', true);
                return;
            }

            var node = document.createElement('input'); node.value = link;
            document.body.appendChild(node);
            node.select();
            var copied = document.execCommand('copy');
            document.body.removeChild(node);

            if ( ! copied) {
                utils.showToast('copyNotSupported', true);
            } else {
                utils.showToast('copiedLink', true);
            }
        },

        /**
         * Delete selected items.
         */
        delete: function () {
            deleter.delete(this.get());
            this.deselectAll();
        },

        /**
         * Restore deleted file or folder from trash.
         */
        restore: function (items, previousItems) {
            items = items || [];
            previousItems = previousItems || [];

            var inst = this;

            if(previousItems.length == 0) {
                items = this.get();
                previousItems = items;
            } else {
                items.forEach(function(item){
                    for (var i = 0; i < previousItems.length; i++) {
                        if(item.id == previousItems[i].id && item.action == "cancel") {
                            previousItems.splice(i, 1);
                            break;
                        }
                    }
                });
            }

            $rootScope.ajaxProgress.files = true;
            $http.post($rootScope.baseUrl+'trash/restore', { items: items }).then(function(response) {
                if(response.data.notRestored.length == 0) {
                    $rootScope.$broadcast('activity.happened', 'restored', 'items', previousItems);

                    // previousItems.forEach(function(item) {
                    //     if (item.type === 'folder') {
                    //         folders.available.push(item);
                    //     } else {
                    //         var folder = folders.getById(item.folder_id);

                    //         if (folder && folder.files) {
                    //             folder.files.push(item);
                    //         }
                    //     }
                    // });
                    folders.available = response.data.data;

                    if(previousItems.length) {
                        utils.showToast(utils.trans('restoreSuccess', {number:previousItems.length}));
                    }

                } else {
                    inst.openMoveItemsModal(response.data, inst, previousItems);
                }
            }, function(response) {
                utils.showToast(response.data);

            }).finally(function() {
                $rootScope.ajaxProgress.files = false;
            });
        },

        openMoveItemsModal: function(data, inst, previousItems) {
            dialogManager.showMoveItemsDialog( data,inst, previousItems );
        },

        move: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            dialogManager.showMoveDialog();
        },


        /**
         * Add currently selected items to favorites.
         */
        favorite: function () {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            this.addLabel('favorite');
        },

        /**
         * Remove currently selected items from favorites.
         */
        unfavorite: function() {
            if (selectedItems.first('is_offer')) {
                return utils.showToast(utils.trans('cantPerformActionOnOffer'));
            }
            this.removeLabel('favorite');
        },

        /**
         * Add given label to currently selected items.
         *
         * @param {string} name
         */
        addLabel: function(name) {
            var items = this.get().filter(function(item) {
                return !utils.hasLabel(item, name);
            });

            labels.addLabel(name, items);
        },

        /**
         * Remove given label from currently selected items.
         *
         * @param {string} name
         */
        removeLabel: function(name) {
            labels.removeLabel(name, this.get());
        },

        /**
        * Post a file to my timeline privately
        *
        */
        postToTimeline: function() {
            if (selectedItems.first('can_post_to_timeline')) {
                dialogManager.showPostToTimelineDialog(selectedItems.first());
            }
        }
    };

    /**
     * Deselect any selected files on state change.
     */
    $rootScope.$on('$stateChangeSuccess', function() {
        selectedItems.deselectAll();
    });

    return selectedItems;
}]);