// FIXME: this needs to be moved into angular.
angular.module('pixie.dashboard')

    .directive('edContextMenuItem', ['contextMenu', 'selectedItems', 'folders', function (contextMenu, selectedItems, folders) {
        return {
            restrict: 'A',
            link: function ($scope, el, attrs) {
                el.on('contextmenu', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    $scope.$apply(function () {
                        //user right clicked on root folder
                        if (('edIsRootFolder' in attrs) || e.currentTarget.dataset.depth == 0) {
                            selectedItems.set(folders.getByName('root'), 'folder', true);
                            selectedItems.lastFolder = false;

                            //user right clicked on a folder in folder tree in left panel
                        } else if (!$scope.item) {
                            selectedItems.deselectAll();
                            selectedItems.lastFolder = folders.getById(e.currentTarget.dataset.id);
                            selectedItems.set(folders.getById(e.currentTarget.dataset.id), 'folder', true);

                            // if we have multiple items selected, but user right clicked on item that isn't
                            //currently selected, deselect all items and remove selected class from DOM
                        } else if (!selectedItems.has($scope.item)) {
                            selectedItems.deselectAll();
                            selectedItems.set($scope.item);
                            $('.selected').removeClass('selected');
                        } else {
                            selectedItems.set($scope.item);
                        }
                    });
                    contextMenu.show(e, false, false, $scope.item);
                    // contextMenu.show(e, true);
                });
            }
        };
    }])

    .directive('edMoreOptionsMenu', ['$rootScope', 'contextMenu', 'selectedItems', '$state', function ($rootScope, contextMenu, selectedItems, $state) {
        return {
            restrict: 'A',
            link: function ($scope, el) {

                var open = false,
                    menu = $('#context-menu');

                el.on('click', function () {
                    if (!open || contextMenu.open) {
                        var context = selectedItems.first('type'),
                            permission = selectedItems.first().permission,
                            rect = el[0].getBoundingClientRect(),
                            is_shared_base = selectedItems.first().is_shared_base;

                        if ($state.current.name.indexOf('trash') > -1) {
                            context = 'trash';
                        }


                        var foundShared = false;
                        var foundNormal = false;
                        for (var i = 0; i < selectedItems.items.length; i++) {
                            if (selectedItems.items[i].permission || selectedItems.items[i].isChild) {
                                foundShared = true;
                            }

                            if (selectedItems.items[i].permission === undefined) {
                                foundNormal = true;
                            }
                        }

                        if (foundShared && foundNormal) {
                            contextMenu.generateCommonMenu(context);
                        } else {
                            if (is_shared_base) { // permission !== undefined) { // rdm modified; the actions menu should show same as the contextual
                                contextMenu.generateSharedMenu(context);
                            }
                            else {
                                contextMenu.generateMenu(context);
                            }
                        }

                        contextMenu.positionMenu({
                            clientX: rect.left,
                            clientY: rect.top + el.height() + 10
                        });

                        open = true;
                        contextMenu.open = false;
                    } else {
                        contextMenu.hide();
                        open = false;
                    }
                });

                $rootScope.$on('contextmenu.closed', function () {
                    open = false;
                });
            }
        };
    }])

    .directive('edContextMenu', ['$rootScope', 'contextMenu', 'selectedItems', function ($rootScope, contextMenu, selectedItems) {
        return {
            restrict: 'A',
            compile: function (el) {
                el.on('click', '.context-menu-item', function (e) {
                    e.stopPropagation();
                    e.preventDefault();

                    selectedItems[e.currentTarget.dataset.action]();

                    el.hide();
                });

                el.on('contextmenu', function (e) {
                    e.preventDefault();
                });

                //hide custom menu on window resize and update max height
                el.css('max-height', window.innerHeight - 20);
                window.onresize = function () {
                    el.hide();
                    el.css('max-height', window.innerHeight - 20);
                };

                //hide custom context menu on left click if user didn't click inside the menu itself or on more options button
                $(document).on('click', function (e) {
                    var button = e.which || e.button,
                        clickedInsideMenu = $(e.target).closest(el).length || $(e.target).closest('#more-options').length;

                    if (button === 1 && !clickedInsideMenu) {
                        $rootScope.$emit('contextmenu.closed');
                    }
                });
            }
        };
    }])

    .factory('contextMenu', ['$rootScope', 'files', 'utils', 'selectedItems', 'folders', 'users', function ($rootScope, files, utils, selectedItems, folders, users) {

        var items = [
            { name: utils.trans('context.preview'), icon: 'visibility', action: 'preview', context: ['file', 'multiple-items'] },
            { name: utils.trans('context.edit'), icon: 'rate_review', action: 'editHtmlDoc', context: ['file'] },
            { name: utils.trans('context.share'), icon: 'share', action: 'share', context: ['file', 'folder'] },
            { name: utils.trans('context.postToTimeline'), icon: 'timeline', action: 'postToTimeline', context: ['file'] },
            // { name: utils.trans('copyLink'), icon: 'link', action: 'copyLink', context: ['file', 'folder'] },
            { name: utils.trans('context.folderImage'), icon: 'add_photo_alternate', action: 'setFolderImage', context: ['folder'] },
            { name: utils.trans('context.resetFolderImage'), icon: 'remove', action: 'resetFolderImage', context: ['folder'] },
            { name: utils.trans('context.moveTo'), icon: 'folder', action: 'move', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.makeACopy'), icon: 'file_copy', action: 'copy', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.rename'), icon: 'edit', action: 'rename', context: ['file', 'folder'] },
            { name: utils.trans('context.changeThumbnail'), icon: 'image', action: 'changeThumbnail', context: ['file'] },
            { name: utils.trans('templateStore.make'), icon: 'store_mall_directory', action: 'makeTemplateStore', context: ['folder'] },
            { name: utils.trans('context.favorite'), icon: 'star', action: 'favorite', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.download'), icon: 'get_app', action: 'download', context: ['file', 'folder', 'rootFolder', 'multiple-items'], separator: true },
            { name: utils.trans('context.lock'), icon: 'lock', action: 'lock', context: ['file', 'folder'], separator: true },
            { name: utils.trans('context.removePassword'), icon: 'lock_open', action: 'removePassword', context: ['file', 'folder'], separator: true },
            { name: utils.trans('context.remove'), icon: 'delete_outline', action: 'delete', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.restore'), icon: 'restore_from_trash', action: 'restore', context: ['trash'], state: ['dashboard.trash'] },
            { name: utils.trans('context.deleteForever'), icon: 'delete_outline', action: 'delete', context: ['trash'], state: ['dashboard.trash'] }
        ];

        var itemsForShare = [
            { name: utils.trans('context.preview'), icon: 'visibility', action: 'preview', context: ['file', 'multiple-items'] },
            { name: utils.trans('context.edit'), icon: 'rate_review', action: 'editHtmlDoc', context: ['file'], is_writable: true },
            { name: utils.trans('context.postToTimeline'), icon: 'timeline', action: 'postToTimeline', context: ['file'] },
            { name: utils.trans('context.moveTo'), icon: 'folder', action: 'move', context: ['file', 'folder', 'multiple-items'], is_writable: true },
            { name: utils.trans('context.makeACopy'), icon: 'file_copy', action: 'copy', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.rename'), icon: 'edit', action: 'rename', context: ['file', 'folder'], is_writable: true },
            { name: utils.trans('context.download'), icon: 'get_app', action: 'download', context: ['file', 'folder', 'rootFolder', 'multiple-items'], is_downloadable: true, separator: true },
            { name: utils.trans('context.lock'), icon: 'lock', action: 'lock', context: ['file', 'folder'], is_writable: true, separator: true },
            { name: utils.trans('context.removePassword'), icon: 'lock_open', action: 'removePassword', context: ['file', 'folder'], is_writable: true, separator: true },
            { name: utils.trans('context.unlink'), icon: 'link_off', action: 'unlink', context: ['file', 'folder'] },
            { name: utils.trans('context.remove'), icon: 'delete_outline', action: 'delete', context: ['file', 'folder', 'multiple-items'], is_writable: true },
        ];

        var commonItems = [
            { name: utils.trans('context.preview'), icon: 'visibility', action: 'preview', context: ['file', 'multiple-items'] },
            { name: utils.trans('context.makeACopy'), icon: 'file_copy', action: 'copy', context: ['file', 'folder', 'multiple-items'] },
            { name: utils.trans('context.download'), icon: 'get_app', action: 'download', context: ['file', 'folder', 'rootFolder', 'multiple-items'] },
        ];

        var restrictPersonalizedTemplateStore = ['share', 'delete', 'restore'];

        var contextmenu = {
            open: false,
            context: 'file',
            lastFolder: false,

            /**
             * Show context menu.
             *
             * @param {object} e right-click event
             * @param boolean isMobile mobile device?
             */
            show: function (e, isMobile, pos) {
                isMobile = isMobile || false;
                pos = pos || false;

                this.context = selectedItems.first('type');
                this.is_shared_base = selectedItems.first().is_shared_base;

                if (selectedItems.get().length > 1) {
                    this.context = 'multiple-items';
                }

                if (utils.stateIs('dashboard.trash') && !selectedItems.lastFolder) {
                    this.context = 'trash';
                }

                if (this.context === 'folder' && selectedItems.first('name') === 'root' && selectedItems.first('folder_id') === null && !selectedItems.lastFolder) {
                    this.context = 'rootFolder';
                }

                e.preventDefault();

                var foundShared = false;
                var foundNormal = false;

                for (var i = 0; i < selectedItems.items.length; i++) {
                    if (selectedItems.items[i].is_shared) {
                        foundShared = true;
                    } else {
                        foundNormal = true;
                    }
                }

                if (selectedItems.first('is_offer') == 1) {
                    this.generateOfferMenu(this.context);
                } else if (selectedItems.first('is_media_folder') == 1) {
                    this.generateMediaFolderMenu(this.context);
                } else if (selectedItems.first('is_parent_template') == 1 && selectedItems.first('owned_by_current_user') != 1) {
                    this.generateOfferMenu(this.context, true);
                } else if (selectedItems.first('is_parent_template') == 1 && selectedItems.first('owned_by_current_user') == 1) {
                    this.generateMenu(this.context);
                } else if (foundShared && foundNormal) {
                    this.generateCommonMenu(this.context);
                } else if (this.is_shared_base) {
                    this.generateSharedMenu(this.context);
                } else {
                    this.generateMenu(this.context);
                }

                // this.generateMenu(this.context);


                if (isMobile) {
                    if (pos && pos == "fixed") {
                        this.positionForMobileList(e);
                    } else {
                        this.positionForMobile(e);
                    }
                } else {
                    this.positionMenu(e);
                }
                this.open = true;
            },

            hide: function () {
                $('#context-menu').hide();
                $('#context-menu').find('.left-arrow').hide();
                $('#context-menu').find('.right-arrow').hide();
                $('.duplicateContextMenu').remove();
                this.open = false;
            },

            positionForMobileList: function (e) {
                var menu = $('#context-menu');
                var duplicateContextMenu = menu.clone();
                duplicateContextMenu.css('overflow', 'visible');
                duplicateContextMenu.css('width', '165px');
                duplicateContextMenu.find('.context-menu-item').css('padding', '5px !important');

                var arrowEle;
                arrowEle = duplicateContextMenu.find('.right-arrow');
                duplicateContextMenu.addClass('duplicateContextMenu');
                duplicateContextMenu.css('top', 4);
                duplicateContextMenu.css('right', 31);
                $($(e.currentTarget)[0]).prepend(duplicateContextMenu);
                arrowEle.show();
                duplicateContextMenu.show();
            },

            positionForMobile: function (e, pos) {
                var isLeft = false;

                if ((($(window).width() - 60) / 2) > e.clientX) {
                    isLeft = true;
                }

                var menu = $('#context-menu');
                var duplicateContextMenu = menu.clone();
                duplicateContextMenu.css('overflow', 'visible');
                duplicateContextMenu.css('width', '165px');
                duplicateContextMenu.find('.context-menu-item').css('padding', '5px !important');
                var arrowEle;
                var parent = $($(e.currentTarget)[0].closest('.user-item'))[0];
                var width = parent.offsetWidth;

                var xxx = $($(e.currentTarget)[0].closest('.option'));
                var height;
                if (xxx.length) {
                    height = xxx[0].offsetHeight;
                    height = height + 2 + (Math.round(height / 2));
                    height -= 23;
                } else {
                    height = Math.round($(e.currentTarget).height() / 2) - 36;
                }

                if ($('.duplicateContextMenu').length >= 1) {
                    $('.duplicateContextMenu').remove();
                }

                duplicateContextMenu.css('top', height);
                duplicateContextMenu.addClass('duplicateContextMenu');

                if (isLeft) {
                    arrowEle = duplicateContextMenu.find('.left-arrow');
                    duplicateContextMenu.css('left', width + 14);
                } else {
                    arrowEle = duplicateContextMenu.find('.right-arrow');
                    duplicateContextMenu.css('left', 'unset');
                    duplicateContextMenu.css('right', width + 14);
                }

                $($(e.currentTarget)[0].closest('.user-item')).prepend(duplicateContextMenu);
                arrowEle.show();
                duplicateContextMenu.show();
            },

            generateSharedMenu: function (context) {
                var menu = $('#context-menu');
                var show_lock = true;

                menu.find('li').remove();

                for (var i = 0; i < itemsForShare.length; i++) {
                    var item = itemsForShare[i];

                    if (item.is_writable === true && item.action != 'move' && !selectedItems.first().is_writable) {
                        continue;
                    }

                    if (item.action == 'move' && !selectedItems.first().is_writable && selectedItems.first().type == 'folder') {
                        continue;
                    }

                    if (item.action == 'move' && !selectedItems.first().is_writable && selectedItems.first().type == 'file') {
                        if ((!selectedItems.first().is_shared_base) || (selectedItems.first().is_downloadable && selectedItems.first().is_shared_base)) {
                            continue;
                        }
                    }

                    if (item.is_downloadable === true && !selectedItems.first().is_downloadable) {
                        continue;
                    }

                    if (item.action == 'preview' && selectedItems.first().type == 'folder') {
                        continue;
                    }

                    if (item.action == 'postToTimeline' && (!selectedItems.first().can_post_to_timeline || (selectedItems.first().is_shared_base && !selectedItems.first().is_downloadable))) {
                        continue;
                    }

                    if (item.action == 'copy' && selectedItems.first().is_shared_base && !selectedItems.first().is_downloadable) {
                        continue;
                    }

                    if (item.action == 'unlink' && !selectedItems.first().is_shared_base) {
                        continue;
                    }

                    if (item.action == 'delete' && selectedItems.first().is_shared_base) {
                        continue;
                    }

                    if (item.action == 'removePassword' && !selectedItems.first().is_protected) {
                        continue;
                    }

                    if (item.action == 'delete' && !selectedItems.first().is_deletable) {
                        continue;
                    }

                    if (item.action == 'editHtmlDoc' && (!selectedItems.first().file_name || selectedItems.first().file_name.indexOf('.html') == -1)) {
                        continue;
                    }

                    if (item.action == 'lock' && selectedItems.first().is_protected) {
                        item.name = utils.trans('context.changePassword');
                        show_lock = false;
                        item.separator = false;
                    } else if (item.action == 'lock') {
                        item.separator = true;
                    }

                    menu.append('<li class="context-menu-item" data-action="' + item.action + '"><i class="material-icons ' + item.icon + '">' + item.icon + '</i> ' + item.name + '</li>');

                    if (item.separator) {
                        menu.append('<li class="separator"></li>');
                    }

                    if (!show_lock) {
                        show_lock = true;
                        item.name = utils.trans('lock');
                    }
                }

                menu.show();
            },

            generateMenu: function (context) {
                var menu = $('#context-menu');
                var show_lock = true;
                menu.find('li').remove();

                for (var i = 0; i < items.length; i++) {
                    var item = items[i];

                    if (item.context.indexOf(context) === -1 || ((item.action == 'preview' || item.action == 'getLinks') && utils.stateIs('view'))) {
                        continue;
                    }

                    //skip move to action if there is only one folder
                    if (item.action === 'move' && folders.available.length === 1) {
                        continue;
                    }

                    //add un-favorite action instead of favorite if item is already favorited or we're in favorites state
                    if (item.action === 'favorite' && (selectedItems.getLength() === 1 && utils.hasLabel(selectedItems.first(), 'favorite') || utils.stateIs('dashboard.favorites'))) {
                        menu.append('<li class="context-menu-item" data-action="unfavorite"><i class="material-icons star_border">star_border</i> ' + utils.trans('context.unfavorite') + '</li>');
                        continue;
                    }

                    if (item.action == 'lock' && selectedItems.first().is_protected) {
                        item.name = utils.trans('context.changePassword');
                        show_lock = false;
                        item.separator = false;
                    } else if (item.action == 'lock') {
                        item.separator = true;
                    }

                    if (item.action == 'postToTimeline' && !selectedItems.first().can_post_to_timeline) {
                        continue;
                    }

                    if (item.action == 'rename' && selectedItems.first().class_type == 'myhope') {
                        continue;
                    }

                    if (item.action == 'setFolderImage' && selectedItems.first().class_type != 'normal') {
                        continue;
                    }

                    if (item.action == 'resetFolderImage' && selectedItems.first().class_type != 'normal') {
                        continue;
                    }

                    // only show to admins or at rootFolder or non shared folders
                    if (item.action == 'makeTemplateStore' && (item.context.indexOf(context) === -1 || !selectedItems.first().can_template_store)) {
                        continue;
                    }

                    if (item.action == 'editHtmlDoc' && selectedItems.first().extension !== 'html') {
                        continue;
                    }

                    if (item.action == 'removePassword' && !selectedItems.first().is_protected) {
                        continue;
                    }

                    if (item.action === 'removePassword' && (selectedItems.getLength() === 1 && selectedItems.get()[0].user_id == users.current.id)) {
                        // version with no ...
                        menu.append('<li class="context-menu-item" data-action="' + item.action + '"><i class="material-icons ' + item.icon + '">' + item.icon + '</i> ' + utils.trans('context.removePasswordNoEllipse') + '</li>');
                        continue;
                    }

                    if (item.action == 'delete' && !selectedItems.first().is_deletable) {
                        continue;
                    }

                    if ((restrictPersonalizedTemplateStore.indexOf(item.action) >= 0) && selectedItems.first().is_parent_template) {
                        continue;
                    }

                    if (selectedItems.first().is_template_folder && (item.action == 'move' || item.action == 'copy')) {
                        continue;
                    }

                    menu.append('<li class="context-menu-item" data-action="' + item.action + '"><i class="material-icons ' + item.icon + '">' + item.icon + '</i> ' + item.name + '</li>');

                    if (item.separator) {
                        menu.append('<li class="separator"></li>');
                    }

                    if (!show_lock) {
                        item.name = utils.trans('lock');
                        show_lock = true;
                    }
                }

                menu.show();
            },

            generateCommonMenu: function (context) {
                var menu = $('#context-menu');

                menu.find('li').remove();

                for (var i = 0; i < commonItems.length; i++) {
                    var item = commonItems[i];

                    if (item.context.indexOf(context) === -1 || ((item.action == 'preview' || item.action == 'getLinks' || item.action == '') && utils.stateIs('view'))) {
                        continue;
                    }

                    //skip move to action if there is only one folder
                    if (item.action === 'move' && folders.available.length === 1) {
                        continue;
                    }

                    //add un-favorite action instead of favorite if item is already favorited or we're in favorites state
                    if (item.action === 'favorite' && (selectedItems.getLength() === 1 && utils.hasLabel(selectedItems.first(), 'favorite') || utils.stateIs('dashboard.favorites'))) {
                        menu.append('<li class="context-menu-item" data-action="unfavorite"><i class="material-icons star_border">star_border</i>' + utils.trans('context.unfavorite') + '</li>');
                        continue;
                    }

                    menu.append('<li class="context-menu-item" data-action="' + item.action + '"><i class="material-icons ' + item.icon + '">' + item.icon + '</i> ' + item.name + '</li>');

                    if (item.separator) {
                        menu.append('<li class="separator"></li>');
                    }
                }

                menu.show();
            },

            generateOfferMenu: function (context, is_template_menu) {
                is_template_menu = is_template_menu || false;
                var menu = $('#context-menu');

                menu.find('li').remove();
                if (!is_template_menu || (is_template_menu && selectedItems.first('type') == 'folder')) {
                    menu.append('<li class="context-menu-item" data-action="preview"><i class="material-icons visibility">visibility</i>' + utils.trans('preview') + '</li>');
                    if (context == 'file') {
                        menu.append('<li class="context-menu-item" data-action="print"><i class="material-icons">print</i>' + utils.trans('context.print') + '</li>');
                    }
                } else if (!selectedItems.first('is_purchased')) {
                    menu.append('<li class="context-menu-item" data-action="purchase"><i class="material-icons store_mall_directory">store_mall_directory</i>' + utils.trans('templateStore.templateName') + '</li>');
                } else {
                    menu.append('<li class="context-menu-item" data-action="purchase"><i class="material-icons file_copy">file_copy</i>' + utils.trans('copyTo') + '</li>');
                }

                menu.show();
            },

            generateMediaFolderMenu: function (context) {
                var menu = $('#context-menu');

                menu.find('li').remove();
                if (selectedItems.first('type') == 'folder' || selectedItems.first('type') == 'file') {
                    if (context != 'trash') {
                        menu.append('<li class="context-menu-item" data-action="preview"><i class="material-icons visibility">visibility</i>' + utils.trans('preview') + '</li>');
                    } else {
                        menu.append('<li class="context-menu-item" data-action="restore"><i class="material-icons restore_from_trash">restore_from_trash</i>' + utils.trans('context.restore') + '</li>');
                    }

                    if (selectedItems.first('type') == 'file') {
                        menu.append('<li class="separator"></li>');
                        var text = context != 'trash' ? 'context.remove' : 'context.deleteForever';
                        menu.append('<li class="context-menu-item" data-action="delete"><i class="material-icons delete_outline">delete_outline</i>' + utils.trans(text) + '</li>');
                    }
                }

                menu.show();
            },

            generateTemplateStoreMenu: function (context) {
                var menu = $('#context-menu');
                menu.find('li').remove();

                menu.show();
            },

            positionMenu: function (e) {
                var menu = $('#context-menu');

                menu.css('display', 'block');

                var menuWidth = menu.width() + 4,
                    menuHeight = menu.height() + 20,
                    windowWidth = window.innerWidth,
                    windowHeight = window.innerHeight,
                    clickCoordsX = e.clientX,
                    clickCoordsY = e.clientY;

                if (windowWidth <= 768) {
                    menu.css('left', '10');
                } else if ((windowWidth - clickCoordsX) < menuWidth) {
                    menu.css('left', windowWidth - menuWidth + 1);
                } else {
                    menu.css('left', clickCoordsX + 1);
                }

                if ((windowHeight - clickCoordsY) < menuHeight) {
                    menu.css('top', windowHeight - menuHeight + 1);
                } else {
                    menu.css('top', clickCoordsY + 1);
                }

            }
        };

        $rootScope.$on('contextmenu.closed', function () {
            contextmenu.hide();
        });

        $rootScope.$on('$stateChangeSuccess', function () {
            contextmenu.hide();
        });

        return contextmenu;
    }]);