(function () {
    'use strict';

    angular.module('app')
        .factory('items', ['$filter', '$state', 'files', 'folders', '$rootScope', function ($filter, $state, files, folders, $rootScope) {
            var sortOrder = '-created_at';
            var filteredItems = [];
            var loadedItems = {};

            function sortSpecialFirst(subFolders) {
                var specialWisefolders = [];
                var normalFolders = [];

                angular.forEach(subFolders, function (subFolder) {
                    if (subFolder.class_type == 'normal') {
                        normalFolders.push(subFolder);
                    } else {
                        specialWisefolders.push(subFolder);
                    }
                });

                return specialWisefolders.concat(normalFolders);
            }

            //manually filter selected folders files so we can get a reference to resulting array
            function sortFiles(items, origin) {
                origin = origin || false;
                var selectedFolder = getSelectedFolder(origin);
                if (!selectedFolder || !angular.isArray(selectedFolder.files)) {
                    return;
                }
                if (!items) {
                    items = selectedFolder.files || [];
                }
                items = $filter('orderBy')(items, sortOrder);

                //push any child folders of currently selected folder into items array
                // var subFolders = folders.getSubFolders(folders.selected.id, folders.selected.name === 'root');
                var subFolders = folders.getSubFolders(selectedFolder.id, folders.selected.name === 'root');
                if (subFolders) {
                    subFolders = sortSpecialFirst(subFolders);
                    items = subFolders.concat(items);
                }
                // This should probably $broadcast, and have $rootScope set it somewhere else (and update as requierd)
                if (origin) {
                    loadedItems['/folders/' + origin] = items;
                } else if ($state.current.url.indexOf('/folders/:folderId') > -1) {
                    loadedItems['/folders/' + $state.params.folderId] = items;
                } else {
                    loadedItems['/folders?question'] = items;
                }
                if (!origin || origin == $state.params.folderId) {
                    filteredItems = items;
                    $rootScope.$broadcast('files.changed');
                    $rootScope.$broadcast('items.upload.show');
                }
            }

            function handleActivity(e, type, itemType, data, origin) {
                var newItems;
                origin = origin || false;

                if (origin) {
                    newItems = loadedItems['/folders/' + origin] || [];
                } else if ($state.current.url.indexOf('/folders/:folderId') > -1) {
                    newItems = loadedItems['/folders/' + $state.params.folderId] || [];
                } else {
                    newItems = loadedItems['/folders?question'] || [];
                }

                var selectedFolder = getSelectedFolder(origin);

                if (type === 'trashed' || type === 'moved' || type === 'unlinked') {
                    newItems = newItems.filter(function (file) {
                        return !data.find(function (item) {
                            return item.id == file.id && item.type == 'file';
                        });
                    });
                    $rootScope.$broadcast('items.auto.loadmore');
                }

                if (type === 'renamed' || type === 'edited') {
                    newItems = newItems.map(function (file) {
                        if (file.id == data.id) {
                            file.name = data.name;
                            file.description = data.description;
                        }
                        return file;
                    });
                }

                if (type === 'restored' || type === 'copied' || type === 'uploaded' || type === 'refresh') {
                    newItems = newItems.concat(data);
                    $rootScope.$broadcast('items.auto.loadmore');
                }

                if (type === 'created' && itemType === 'folder') {
                    newItems = [];
                }

                if (type === 'created' && itemType === 'file') {
                    newItems = newItems.concat(data);
                    $rootScope.$broadcast('items.auto.loadmore');
                }

                if (itemType === 'folder' && type === 'locked') {
                    loadedItems['/folders/' + data.share_id] = [];
                }

                //filter out sub-folders as they will be
                //fetch later in sort function and if they
                //are included here it will cause dupes error
                newItems = newItems.filter(function (file) {
                    return file.type !== 'folder';
                });

                // Update selected folder's files
                selectedFolder.files = newItems;
                folders.setFolderFiles(selectedFolder);
                sortFiles(newItems, origin);
            }

            function getSelectedFolder(origin) {
                var selectedFolder = folders.selected;
                if (origin && origin != $state.params.folderId) {
                    var originfolder = folders.getByShareId(origin);
                    if (originfolder) {
                        selectedFolder = originfolder;
                    }
                }
                return selectedFolder;
            }

            // Support for Infinite scroll event
            function paginateFiles(files) {
                var newFiles = null;
                if ($state.current.url.indexOf('/folders/:folderId') > -1) {
                    newFiles = loadedItems['/folders/' + $state.params.folderId].slice().concat(files);
                } else {
                    newFiles = loadedItems['/folders?question'].slice().concat(files);
                }

                newFiles = newFiles.slice().concat(files);
                //filter out sub-folders as they will be
                //fetch later in sort function and if they
                //are included here it will cause dupes error
                newFiles = newFiles.filter(function (file) {
                    return file.type !== 'folder';
                });

                sortFiles(newFiles);
            }

            // FIXME: Add broadcasting, only get() on change.

            return {
                changeSortOrder: function (order) {
                    sortOrder = order;
                    sortFiles();
                },
                sortOrder: function () {
                    return sortOrder;
                },
                refresh: function () {
                    sortFiles();
                },
                get: function () {
                    return filteredItems;
                },
                handleActivity: function (e, type, itemType, data, origin) {
                    handleActivity(e, type, itemType, data, origin);
                },
                paginate: function (files) {
                    paginateFiles(files);
                }
            };
        }]);
}());