angular.module('pixie.dashboard')
.directive('audioPreview', ['$templateRequest', '$translate', function($templateRequest, $translate) {
    return {
        restrict: 'E',
        scope: {
            item: '='
        },
        templateUrl: 'assets/views/directives/audio-player.html',
        link: function($scope, $element, $attr) {
            $scope.data = {
                canPlayType: true,
                ready: false
            };

            var player = $(".jp-jplayer", $element).jPlayer({
                ready: function() {
                    $scope.data.ready = true;
                    playItem($scope.item);
                },
                size: {
                    width: '100%',
                    height: 'auto'
                },
                volume: 0.2,
                cssSelectorAncestor: ".player-container",
                swfPath: "/assets",
                supplied: 'm4a',
                useStateClassSkin: true,
                autoBlur: false,
                smoothPlayBar: true,
                keyEnabled: true,
                remainingDuration: true,
                toggleDuration: true
            });

            function playItem(item) {
                if($scope.data.ready) {
                    player.jPlayer('setMedia', {
                        title: item.name,
                        m4a: item.absoluteUrl
                    });
debugger;
                    player.jPlayer('play');
                }
            }

            function canPlayType(mime) {
                return document.createElement('audio').canPlayType(mime);
            }

            $scope.$watch('item', function(item) {
                if(item) {
                    $scope.data.canPlay = canPlayType(item.mime);
                    playItem(item);
                }
            });
        }
    };

// .directive('audioPreview', ['$templateRequest', '$translate', function($templateRequest, $translate) {
//     return {
//         restrict: 'E',
//         link: function($scope, el) {
//             if ($scope.preview.current.is_locked) {
//                 return $scope.showLockContainer();
//             }
//
//             if ( ! document.createElement('audio').canPlayType($scope.preview.current.mime)) {
//                 return $scope.showNoPreviewContainer('<h2>Whoops!</h2> ' + $translate.instant('cantPlayAudio'));
//             }
//
//             $templateRequest('assets/views/directives/audio-player.html').then(function(data) {
//                 el.html(data);
//
//                 var player = $("#jquery_jplayer_1").jPlayer({
//                     ready: function() {
//                         $(this).jPlayer('setMedia', {
//                             title: $scope.preview.current.name,
//                             m4a: $scope.preview.current.absoluteUrl
//                         }).jPlayer('play');
//                     },
//                     volume: 0.2,
//                     cssSelectorAncestor: "#player-container",
//                     swfPath: "/assets",
//                     supplied: 'm4a',
//                     useStateClassSkin: true,
//                     autoBlur: false,
//                     smoothPlayBar: true,
//                     keyEnabled: true,
//                     remainingDuration: true,
//                     toggleDuration: true
//                 });
//             });
//         }
//     };
}]);