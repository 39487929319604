
angular.module('app').factory('users', ['$http', '$rootScope', '$state', '$q', '$mdDialog', 'utils', 'files', '$window', 'folders', 'dialogManager', 'api', '$translate', 'stories', 'localStorage', '$timeout', function($http, $rootScope, $state, $q, $mdDialog, utils, files, $window, folders, dialogManager, api, $translate, stories, localStorage, $timeout) {
    var myHopeOrganizationId = 5;

    var users = {

        /**
         * Currently logged in user.
         */
        current: false,

        /**
         * Executor for currently logged in user.
         */
        executors: false,

        /**
         * Accounts to which logged in user is an executor.
         */
        accounts: [],

        /**
         * Accounts to which logged in user is an executor.
         */
        modeInfo: [],

        /**
        * User Stories of the logged in user
        */
        userStories: [],

        /**
        *   Privacy Options of the logged in user
        */
        privacyOptions: [],

        /**
        *   When we assign the user, this promise is resolved onComplete
        * all the user data is loaded.  Use users.get().then(...)
        */
        loadedUserPromise: false,

        /**
         * All registered users. If current user is admin.
         */
        all: [],

         /** Paginate all existing users.
         *
         * @returns {promise}
         */
         paginate: function(params) {
            return $http.get('users', {params: params});
        },

        /**
         * Login in user matching given credentials.
         *
         * @param {object} credentials
         * @returns {promise}
         */
        login: function(credentials) {
            return $q(function(resolve, reject) {
                $http.post('login', credentials).then(function(loginResponse) { // rdm 5.3 change
                    users.assignCurrentUser(loginResponse.data.user).then(function(data) {
                        resolve(loginResponse.data);
                    }, reject);
                }, reject);
            });
        },

        /**
         * Register a new user with given credentials.
         *
         * @param {object} credentials
         * @returns {promise}
         */
        register: function(credentials) {
            return $http.post('register', credentials).then(function(response) { // rdm 5.3 change
               if ( ! users.current) {
                    users.assignCurrentUser(response.data);
               }
            });
        },

       /**
        * Check to see if a user is loggedIn (uses remember token as well..)
        *
        * @param {object} credentials
        * @returns {promise}
        */
       loggedIn: function() {
           return $q(function(resolve, reject) {
                $http.post('loggedIn').then(function(response) {
                    if(response.data.success === true) {
                        console.log("in users.loggedIn ");
                        users.assignCurrentUser(response.data.user).then(function(data) {
                            // resolve
                            resolve(response.data.user);
                        }, reject);
                    }
                    else {
                        reject(response.data.message);
                    }
                }, reject);
           });
       },

        /**
         * Set given user as currently logged in user.
         *
         * @param {object} user
         */
    assignCurrentUser: function(user) {
            if(user !== this.current) {
                console.log("User Changed!");
                this.current = user;

                users.loadedUserPromise = $q.defer();

                if(!user) {
                    // no user loaded, get() will return null.
                    users.loadedUserPromise.resolve(users.current);
                } else {
                    users.current.full_name = users.getName(users.current);
                    users.current.url = users.getAvatar(users.current);
                    folders.folderKey = user.id;

                    // it might not be required to load all of these things, but to be safe
                    //  we're doing that now.
                    stories.load().then(function() {
                        api.resources.userStory.query({}, function(data) {
                            users.userStories = data;

                            api.me.privacyOptions().then(function(data) {
                                users.privacyOptions = data;

                                users.loadAccounts().then(function() {
                                    users.loadedUserPromise.resolve(users.current);
                                }, users.loadedUserPromise.reject);
                            }, users.loadedUserPromise.reject);
                        }, users.loadedUserPromise.reject);
                    }, users.loadedUserPromise.reject);
                }
            }

            return users.loadedUserPromise.promise;
        },

        get: function() {
            return this.loadedUserPromise.promise;
        },

        /**
         * Delete given user from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(values) {
            var promise;
            if (angular.isArray(values)) {
                promise = $http.post('users', {users:values});
            } else {
                promise = $http.delete('users/'+values.id);
                values = [values];
            }

            return promise.then(function(response) {
                users.all = users.all.filter(function(user) {
                    return values.indexOf(user) === -1;
                });
                utils.showToast(response.data);
            });
        },

        /**
         * Change currently logged in users password.
         */
        changePassword: function(oldPass, newPass, newConfirm) {
            var params = {
                oldPassword: oldPass,
                newPassword: newPass,
                newPassword_confirmation: newConfirm
            };
            return $http.post($rootScope.baseUrl+'password/change', params);
        },

        /**
         * Logout current logged in user.
         *
         * @returns {promise}
         */
        logout: function() {
            $rootScope.$emit('user.leaveWebsite');
            return api.user.logout().then(function() {
                $rootScope.$emit('user.loggedOut');
                users.current = false;
                stories.clear();
                users.userStories = [];
                users.privacyOptions = [];
                users.loadedUserPromise = false;
                utils.showToast('logOutSuccess', true);
                $state.go('home');
            });
        },

        /**
         * Return username if set otherwise first part of email.
         *
         * @returns {string|undefined}
         */
        getUsernameForCurrentUser: function() {
            if ( ! this.current || ! this.current.email) {
                return;
            }

            if (this.current.username) {
                return this.current.username;
            }

            return this.current.email.split('@')[0];
        },

        /**
         * Return Full name if set otherwise username or first part of email.
         *
         * @returns {string}
         */
        getName: function(user) {
            user = user || this.current;

            if ( ! user) {
                return "Unknown";
            }

            if ( user.first_name || user.last_name ) {
                return (user.first_name ? (user.first_name + " ") : "") + (user.last_name ? (user.last_name + " ") : "");
            }

            if (user.username) {
                return user.username;
            }

            return user.email ? user.email.split('@')[0] : "Unknown";
        },

        /**
         * Return users avatar url or url for a default avatar.
         *
         * @returns {string}
         */
        getAvatar: function(user) {
            if (!user) {
                user = this.current;
            }

            if (user.avatar_url) {
                return user.avatar_url;
            }

            if (user.gender === 'male' || ! user.gender) {
                return $rootScope.baseUrl+'assets/images/avatars/male.png';
            } else {
                return $rootScope.baseUrl+'assets/images/avatars/female.png';
            }
        },

        /**
         * Remove currently logged in users custom avatar.
         *
         * @returns {promise}
         */
        removeAvatar: function() {
            return $http.delete($rootScope.baseUrl + 'users/'+this.current.id+'/avatar').then(function(response) {
                users.current.avatar_url = '';
                utils.showToast(response.data);
                $rootScope.$broadcast('user.changed', { fields: ['avatar_url'] });
            });
        },

        /**
         * Loads accounts to which logged in user is an executor.
         *
         * @returns {string}
         */
        loadAccounts: function(user) {
            // return $http.get($rootScope.baseUrl + 'executor/accounts').then(function(response) {
            //     users.accounts = response.data;
            //     // note that this has to do this since the user menu will already have been setup.  better way is likely
            //     // to chain the promises
            //     $rootScope.$broadcast('user.changed', { fields: ['accounts' ] });
            // });

            var deferred = $q.defer();

            if (users.accounts.length) {
                deferred.resolve(users.accounts);
            } else {
                $http.get($rootScope.baseUrl + 'executor/accounts').then(function(response) {
                    users.accounts = response.data;
                    $rootScope.$broadcast('user.changed', { fields: ['accounts' ] });
                    deferred.resolve(response.data);
                }, deferred.reject);
            }

            return deferred.promise;
        },

        /**
         * Loads user's executor.
         *
         * @returns {string}
         */
        loadExecutor: function(user) {
            return $http.get($rootScope.baseUrl + 'executor').then(function(response) {
                users.executors = response.data;
            });
        },

        /**
         * Switches account to selected executor
         *
         * @returns {string}
         */
        switchAccount: function(userId) {
            return $http.post($rootScope.baseUrl + 'executor/switch-account/' + userId).then(function(response) {
                utils.showToast(response.data);

                $window.location.reload();
                // this doesn't work properly, as need to reset users object as well
                // users.assignCurrentUser(response.data);
                // folders.redirectToHome();
            }, function(response) {
                utils.showToast(response.data);
            });
        },

        /**
         * Returns executor status.
         *
         * @returns {boolean}
         */
        isExecutorActive: function() {
            return users.executor && ((users.executor.pivot.flags & 1) == 1);
        },

        /**
         * Adds currently logged in user's executor.
         *
         * @returns {promise}
         */
        addExecutor: function(executor) {
            return $http.post($rootScope.baseUrl + 'executor', executor).then(function(response) {
                users.executors = response.data;
                utils.showToast(utils.trans('executorRequestSent', executor));

            }, function(response) {
                utils.showToast(response.data);
            });
        },

        /**
         * Remove currently logged in user's executor.
         *
         * @returns {promise}
         */
        removeExecutor: function(executor) {
            return $http.delete($rootScope.baseUrl + 'executor/' + executor.id).then(function(response) {
                users.executors = response.data;
                utils.showToast('executorRemoved', true);
            }, function(response) {
                utils.showToast(response.data);
            });
        },

        /**
         * Run non-profit registration process
         *
         * @return void
         */
        openSwitchToNonProfitDisclamerDialog: function() {
            utils.confirm({
                title: 'register.disclaimerTitle',
                content: 'register.disclaimerText',
                ok: 'register.disclaimerAccept',
                cancel: 'register.disclaimerDecline',
                onConfirm: function() {
                    dialogManager.showOrganizationMemberDialog(true).then(function(result) {
                        users.switchAsNonProfitMember(result);
                    }, function() {
                        // handle error case
                    });
                }
            });
        },

        switchAsNonProfitMember: function(result) {
            result = result || null;
            if (result) {
                if ('code' in result) {
                    api.organization.approveCouponCode(result).then(function(response) {
                        utils.showToast(utils.trans(response, true));
                        users.updateJoinRequests();
                        $rootScope.$broadcast('user.joined.organization');
                    }, function(response) {
                        utils.showToast($translate.instant(response.data));
                    });
                } else {
                    api.organization.addOrganizationMember(result).then(function(response) {
                        utils.showToast(utils.trans(response, true));
                        users.updateJoinRequests();
                    }, function(response) {
                        utils.showToast(utils.trans(response.data, true));
                    });
                }
            }
        },

        updateJoinRequests: function(callback) {
            callback = callback || false;
            api.me.joinRequests().then(function(joinRequest) {
                if (Object.keys(joinRequest).length) {
                    users.current.joinRequest = joinRequest;
                } else {
                    users.current.joinRequest = null;
                }
            }, function() {
                // handle failed case
            }).finally(function() {
                if (callback && typeof callback === "function") {
                    callback();
                }
            });
        },

        showAccountSettingsModal: function($event, fieldToFocus) {
            var options = {
                templateUrl: 'assets/views/modals/account-settings.html',
                targetEvent: $event,
                clickOutsideToClose: true,
                controller: ['$scope', '$upload', 'users', '$mdDialog', 'api', 'utils', '$translate', '$rootScope', function($scope, $upload, users, $mdDialog, api, utils, $translate, $rootScope) {
                    $scope.data =
                    {
                        errors: {},
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                        isAddingExecutor: false,
                        notifications: users.current.notification,
                        selectedIndex: 0,
                        user: {
                          id: users.current.id,
                          username: users.getUsernameForCurrentUser(),
                          first_name: users.current.first_name,
                          last_name: users.current.last_name,
                          gender: users.current.gender,
                          isOrgOwner: users.current.isOrgOwner,
                          isNonProfit: users.current && users.current.permissions && 'is_nonprofit' in users.current.permissions ? true : false
                        },
                        org_id: users.current.joinRequest ? users.current.joinRequest.organization_id : [],
                        contact_last_name: users.current.joinRequest ? users.current.joinRequest.entered_contact_last_name : '',
                        contact_first_name: users.current.joinRequest ? users.current.joinRequest.entered_contact_first_name : '',
                        contact_phone: users.current.joinRequest ? users.current.joinRequest.entered_contact_phone : '',
                        contact_email: users.current.joinRequest ? users.current.joinRequest.entered_contact_email : '',
                        coupon_code: '',
                        orgs: [],
                        type: 'contact',
                        haveCode: false,
                        codeValidated: false,
                        error: '',
                        requestStatus: users.current.joinRequest && users.current.joinRequest.rejection_date ? utils.trans('organization.statusRejected', true) : utils.trans('organization.statusPending', true),
                        saveButtonText: users.current.joinRequest && users.current.joinRequest.rejection_date ? utils.trans('organization.saveResubmit', true) : utils.trans('organization.save', true),
                        formValueChanged: false,
                    };

                    $scope.users = users;
                    $scope.users.updateJoinRequests(getOrganizations());
                    $scope.users.loadExecutor();
                    getOrganizations();

                    function getOrganizations() {
                        if ($scope.users.current.joinRequest && Object.keys($scope.users.current.joinRequest).length) {
                            api.resources.organization.query({}, function(data) {
                                $scope.data.orgs = data;
                            }, function() {
                                // handle error cases
                            });
                        }
                    }

                    $scope.formValueChanged = function() {
                        $scope.data.formValueChanged = false;

                        if ($scope.data.type != 'contact') {
                            $scope.data.formValueChanged = true;
                        }

                        if ($scope.users.current.joinRequest && $scope.data.contact_last_name != $scope.users.current.joinRequest.entered_contact_last_name) {
                            $scope.data.formValueChanged = true;
                        }

                        if ($scope.users.current.joinRequest && $scope.data.contact_first_name != $scope.users.current.joinRequest.entered_contact_first_name) {
                            $scope.data.formValueChanged = true;
                        }

                        if ($scope.users.current.joinRequest && $scope.data.contact_phone != $scope.users.current.joinRequest.entered_contact_phone) {
                            $scope.data.formValueChanged = true;
                        }

                        if ($scope.users.current.joinRequest && $scope.data.contact_email != $scope.users.current.joinRequest.entered_contact_email) {
                            $scope.data.formValueChanged = true;
                        }
                    };

                    $scope.upload = function(files) {
                        if (!files.length) {
                            return;
                        }
                        var file = files[0];

                        $upload.upload({
                            url: $rootScope.baseUrl + 'users/'+users.current.id+'/avatar',
                            file: file
                        }).success(function (data) {
                            users.current.avatar_url = data;
                            $rootScope.$broadcast('user.changed', { fields: ['avatar_url'] });
                        }).error(function(data, code) {
                            if (code === 422) {
                                utils.showToast(data.file[0]);
                            }
                        });
                    };

                    $scope.notificationExists = function(name) {
                      return ($scope.data.notifications.indexOf(name)>=0);
                    };

                    $scope.toggleNotification = function(name) {
                      var index = $scope.data.notifications.indexOf(name);
                      if(index>=0) {
                        $scope.data.notifications.splice(index, 1);
                      } else {
                        $scope.data.notifications.push(name);
                      }
                    };

                    $scope.changeNotificationSettings = function() {
                      return api.user.updateNotificationSettings($scope.data.user.id, $scope.data.notifications).then(function success(data) {
                        $mdDialog.hide();
                        users.assignCurrentUser(data);
                        $rootScope.$broadcast('user.changed', { fields: [ 'notifications'] });
                      }, function failure(failureReason) {
                        // this should do something useful (how does login spin the errors?)
                        $scope.errors = failureReason;
                      });
                    };

                    $scope.updateAccountSettings = function() {
                      return api.user.updateSettings($scope.data.user.id, $scope.data.user).then(function success(data) {
                        utils.showToast('profileUpdateSuccess', true);
                        $mdDialog.hide();
                        users.assignCurrentUser(data);
                        // FIXME: Compare to the original entry and determine what actually changed..
                        $rootScope.$broadcast('user.changed', { fields: ['username', 'first_name', 'last_name', 'gender'] });
                      }, function failure(msg) {

                      });
                    };

                    $scope.switchToNonProfit = function() {
                        $mdDialog.hide();
                        users.openSwitchToNonProfitDisclamerDialog();
                    };

                    $scope.saveRequest = function() {
                        var data = {};
                        if ($scope.data.type == 'contact') {
                            data['org_id'] = $scope.data.org_id;
                            data['entered_contact_last_name'] = $scope.data.contact_last_name;
                            data['entered_contact_first_name'] = $scope.data.contact_first_name;
                            data['entered_contact_email'] = $scope.data.contact_email;
                            data['entered_contact_phone'] = $scope.data.contact_phone;
                            data['id'] = $scope.users.current.joinRequest.id;
                            api.joinRequest.update(data).then(function(request) {
                                $scope.users.current.joinRequest = request;
                                utils.showToast('Your join request has been updated');
                                $mdDialog.hide();
                            }, function() {
                                // handle failed case
                            });
                        } else {
                            data['code'] = $scope.data.coupon_code;
                            data['org_id'] = $scope.data.org_id;
                            data['id'] = $scope.users.current.joinRequest.id;
                            api.organization.validateJoinCode(data).then(function(response) {
                                api.joinRequest.update(data).then(function(res) {
                                    $scope.users.current.joinRequest = res;
                                    $rootScope.$broadcast('user.joined.organization');
                                    utils.showToast('Your join request code has been approved');
                                    $mdDialog.hide();
                                }, function(response) {
                                    $scope.data.error = $translate.instant(response.data);
                                });
                            }, function(response) {
                                $scope.data.error = $translate.instant(response.data);
                            });
                        }
                    };

                    $scope.cancel = function() {
                      $mdDialog.hide();
                    };

                    $scope.changePassword= function() {
                        users.changePassword($scope.data.oldPassword, $scope.data.newPassword, $scope.data.confirmPassword).then(
                            function(response) {
                                utils.showToast(response.data);
                                $mdDialog.hide();
                            }, function(failureResponse) {
                                if (failureResponse.status === 422) {
                                    // this should do something useful (how does login spin the errors?)
                                    $scope.errors = failureResponse.data;
                                }
                            }
                        );
                    };

                    $scope.addExecutor = function() {
                        users.addExecutor($scope.newExecutor).then(function() {
                            $scope.isAddingExecutor = false;
                            $scope.newExecutor = {};
                        });
                    };
                }],
                onComplete: function($scope) {
                  var field = fieldToFocus || 'settings';
                  var tabs = ['settings', 'avatar', 'password', 'notification', 'executor' ];
                  $scope.data.selectedIndex = tabs.indexOf(field);
                }
            };

            return $mdDialog.show(options);
        },

        showSwitchAccountsModal: function() {
            users.loadAccounts().then(function() {
                dialogManager.showSwitchAccountsDialog().then(function(userId) {
                    users.switchAccount(userId);
                }, function() {
                    // cancelled
                });
                // you have to do this after it's loaded them...
            });
        },

        closeModal: function() {
            $mdDialog.hide();
            this.changePasswordModel = {};
        },

        getInvoices: function(id) {
            var userId  = id || this.current.id;

            return $http.get('users/'+userId+'/invoices');
        },

        getQuestion: function(hash) {
            return $http.get('me/question', { params: { hash: hash } } );
        },

        submitVideoAnswer: function(data) {
            return $http.post('me/answer/video', data );
        },

        submitAnswer: function(data) {
            return $http.post('me/answer/text', data );
        },

        isMyHopeUser: function() {
            return  users.current.organization_id == myHopeOrganizationId ? true : false;
        },

        permanentlyDelete: function(values) {
            var promise;
            promise = $http.post('users/permanentlydelete', {users:values});
            return promise.then(function(response) {
                users.all = users.all.filter(function(user) {
                    return values.indexOf(user) === -1;
                });
                utils.showToast(response.data);
            });
        },
    };

    return users;
}]);