(function () {
    'use strict';

    angular.module('app')
    .directive('helpPage', ['$state', '$http', function($state, $http) {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'assets/views/pages/help-page.html',
            link: function($scope, el, attrs) {
				$scope.data = {
					loading: false,
					faqs: [],
					selectedItem: null,
					searchText: ''
				};


				$scope.searchTextChanged= function(text) {
					angular.forEach($scope.data.faqs, function(faq) {
						faq.show = (text.length > 3) ? true : false;
					});
				};

				$scope.toggleShowFaq = function(event, item) {
					angular.forEach($scope.data.faqs, function(faq) {
						faq.show = false;
					});
					item.show = !item.show;
					event.stopPropagation();
				};

				$scope.selectedItemChanged = function(item) {
					console.log("Selected Item changed to: "+item);
				};

				// done this way to prevent going to the server; we're using the md-autocomplete for styling only at this point.
				$scope.querySearch= function(text) {
					return [];
					// return $http.get('/faqs?query=' + text).then(function(response) {
					// 	// Map the response object to the data object.
					// 	debugger;
					// 	return response.data;
					// });
				};

				$scope.data.loading = true;
				$http.get('/faqs').then(function(response) {
					$scope.data.loading = false;
					$scope.data.faqs = response.data;
				});
            }
        };
    }]);
}());
