(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('hiveTree', ['api', '$rootScope', 'users', 'dialogManager', '$state', '$stateParams', 'utils', function(api, $rootScope, users, dialogManager, $state, $stateParams, utils) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'assets/views/directives/hive-tree.html',
        link: function($scope, $element) {
          $scope.data = {
            hives: [],
            loading: true,
            loaded: false
          };

          function setMemberSelected(memberId) {
            var selected = true;
            $scope.data.hives.forEach(function(hive) {
              hive.members.forEach(function(member) {
                if (member.id == memberId) {
                  if (member.selected) {
                    selected = false;
                    member.selected = true;
                    hive.selected = true;
                  }
                }
              });
            });

            // if (!selected) {
            // }
          }

          function loadHiveList() {
            $scope.data.loading = true;
            $scope.data.loaded = false;
            api.me.hiveList().then(function(data) {
              api.me.pendingInvites().then(function(invites) {
                $scope.data.hives = data.map(function(hive) {
                  hive.selected = false;

                  if (hive.type == 'default') {
                    hive.pending = invites;
                  }

                  hive.members.map(function(member) {
                    member.selected = false;
                    member.profile_url =  users.getAvatar(member);
                    member.full_name = users.getName(member);
                    return member;
                  });
                  return hive;
                });
                changeSelection($state.current.name);
                $scope.data.loading = false;
                $scope.data.loaded = true;
              });
            }, function(data) {
              console.log("error case ", data);
              $scope.data.loading = true;
            });
          }

          loadHiveList();

          $scope.addHiveGroup = function() {
            dialogManager.showAddHiveGroupDialog().then(function(hive) {
              updateHive(hive);
            }, function() {
              // handle failed case
            });
          };

          function updateHive(hive) {
              hive.selected = false;
              hive.members = [];
              $scope.data.hives.push(hive);
              $scope.setSelectedHive(hive);
          }

          $scope.setSelectedHive = function(selectedHive, isMainPage) {
            isMainPage = isMainPage || false;
            if (selectedHive.selected && !isMainPage) {
              if (selectedHive.members.length) {
                var memberSelected = false;
                selectedHive.members.forEach(function(member) {
                  if (member.selected) {
                    memberSelected = true;
                  }
                });
                if (!memberSelected) {
                  return;
                }
              } else {
                return;
              }
            }

            $scope.data.hives.forEach(function(hive) {
              hive.selected = false;
              if (hive.id == selectedHive.id) {
                hive.selected = true;
              }

              if (hive.members.length ) {
                hive.members.forEach(function(member) {
                  member.selected = false;
                });
              }
            });
            $state.go('dashboard.hives.details', { hiveId: selectedHive.id });
          };

          $scope.setSelectedMember = function(hiveId, selectedMember, isMainPage) {
            isMainPage = isMainPage || false;
            if (selectedMember.selected && !isMainPage) {
              return;
            }

            $scope.data.hives.forEach(function(hive) {
              hive.members.forEach(function(member) {
                member.selected = false;
                if (member.id == selectedMember.id) {
                  member.selected = true;
                }
                return member;
              });
            });

            $state.go('dashboard.hives.details.member', {hiveId: selectedMember.hive_id, memberId: selectedMember.id});
          };

          $scope.$on('hives.clicked', function() {
            if ($scope.data.loaded) {
              clearSelection();
            }
          });

          $scope.$on('hive.added', function(e, hive) {
            hive.members = [];
            updateHive(hive);
          });

          $scope.$on('hive.edited', function(e, hive) {
            changeHiveInfo(hive);
          });

          $scope.$on('hive.selected', function(e, hive) {
            $scope.setSelectedHive(hive, true);
          });

          $scope.$on('hive.deleted', function(e, hive) {
            removeHive(hive);
            $state.go('dashboard.hives');
            clearSelection();
          });

          $scope.$on('hive.member.selected', function(e, hiveId, member) {
            $scope.setSelectedMember(hiveId, member, true);
          });

          $scope.$on('hive.member.added', function(e, hive, member) {
            addHiveMemberToLeftNav(hive, member);
          });

          $scope.$on('hive.pending.member.added', function(e, emails) {
            updatePendingInvites(emails);
          });

          $scope.$on('hive.member.custom.deleted', function(e, hiveId, memberId) {
            removeHiveMember(hiveId, memberId);
          });

          $scope.$on('hive.member.default.deleted', function(e, memberUserId) {
            removeHiveMemberFromAllHives(memberUserId);
          });

          $scope.$on('hives.reloaded', function() {
            clearSelection();
          });

          $scope.$on('hivelist.reload', function() {
            loadHiveList();
          });

          function removeHiveMember(currentHiveId, deleteMemberId) {
            $scope.data.hives.forEach(function(hive) {
              if (hive.id == currentHiveId) {
                var i = 0, index = -1;
                if (hive.members.length) {
                  hive.members.forEach(function(member) {
                    if (member.id == deleteMemberId) {
                      index = i;
                    }
                    i++;
                  });

                  if (index > -1) {
                    hive.members.splice(index, 1);
                  }
                }
              }
            });
          }

          function removeHiveMemberFromAllHives(deleteMemberUserId) {
            $scope.data.hives.forEach(function(hive) {
                if (hive.members.length) {
                  var i = 0, index = -1;
                  hive.members.forEach(function(member) {
                    if (member.user_id == deleteMemberUserId) {
                      index = i;
                    }
                    i++;
                  });

                  if (index > -1) {
                    hive.members.splice(index, 1);
                  }
                }
            });
          }

          function addHiveMemberToLeftNav(currentHive, newMember) {
            $scope.data.hives.forEach(function(hive) {
              if (hive.id == currentHive.id) {
                if (!hive.members.length) {
                  hive.members = [];
                }
                newMember.profile_url =  users.getAvatar(newMember);
                newMember.full_name = users.getName(newMember);
                hive.members.push(newMember);
              }
            });
          }

          function removeHive(deletedHive) {
            var i = 0, index = -1;

             $scope.data.hives.forEach(function(hive) {
                if (hive.id == deletedHive.id) {
                  index = i;
                }
                i++;
              });

              if (index > -1) {
                $scope.data.hives.splice(index,1);
              }
          }

          function changeHiveInfo(updatedHive) {
            $scope.data.hives.forEach(function(hive) {
              if (hive.id == updatedHive.id) {
                hive.name = updatedHive.name;
              }
            });
          }

          function changeSelection(stateName) {
            switch(stateName) {
              case 'dashboard.hives':
                clearSelection();
                break;
              case 'dashboard.hives.details':
                if ($stateParams.hiveId) {
                  selectHiveById($stateParams.hiveId);
                }
                break;
              case 'dashboard.hives.details.member':
                if ($stateParams.hiveId && $stateParams.memberId) {
                  selectHiveMemberById($stateParams.hiveId, $stateParams.memberId);
                }
                break;
            }
          }

          function clearSelection() {
            $scope.data.hives.forEach(function(hive) {
              hive.selected = false;
              hive.members.forEach(function(member) {
                member.selected = false;
              });
            });
          }

          function selectHiveById(hiveId) {
            $scope.data.hives.forEach(function(hive) {
              hive.selected = false;
              if (hiveId == hive.id) {
                hive.selected = true;
              }
              hive.members.forEach(function(member) {
                member.selected = false;
              });
            });
          }

          function selectHiveMemberById(hiveId, hiveMemberId) {
            $scope.data.hives.forEach(function(hive) {
              hive.selected = false;
              if (hiveId == hive.id) {
                hive.selected = true;
              }
              hive.members.forEach(function(member) {
                member.selected = false;
                if (hiveMemberId == member.id) {
                  member.selected = true;
                }
              });
            });
          }

          $scope.addHiveMember = function() {
            dialogManager.addMemberDialog().then(function(emails) {
              updatePendingInvites(emails);
            }, function() {
              // handle failed case
            });
          };

          function updatePendingInvites(emails) {
            $scope.data.hives.forEach(function(hive) {
              if (hive.type == "default") {
                if (!hive.pending.length) {
                  hive.pending = [];
                }

                emails.forEach(function(email) {
                  var emailFound = false;

                  hive.pending.forEach(function(pending) {
                    if (pending.email == email) {
                      emailFound = true;
                    }
                  });

                  if (!emailFound) {
                    hive.pending.push({'email': email});
                  }

                });
              }
            });
          }

          function removePendingInvite(deletedEmail) {
            $scope.data.hives.forEach(function(hive) {
              if (hive.type == "default") {
                if (hive.pending.length) {
                  var idx = hive.pending.indexOf(deletedEmail);
                  if (idx > -1 ) {
                    hive.pending.splice(idx, 1);
                  }
                }
              }
            });
          }

          $scope.deletePendingInvite = function(deletedPendingInvite) {
            utils.confirm({
                title: 'hive.deletePendingInvite',
                content: utils.trans('hive.deleteInviteText', { user: deletedPendingInvite.email }),
                ok: 'hive.deleteInvite',
                onConfirm: function() {
                  api.me.deleteInvite(deletedPendingInvite.email).then(function() {
                    removePendingInvite(deletedPendingInvite);
                    utils.showToast('hive.inviteDeleted', true);
                  }, function() {
                    utils.showToast('genericError', true);
                  });
                }
            });
          };
        }
      };
    }]);
})();