(function(){
	'use strict';

	angular.module('app').controller('AnalyticsController', ['$scope', '$http', 'utils', function($scope, $http, utils) {
	    $scope.stats = {};

	    $http.get($scope.baseUrl+'stats').then(function(response) {
	        response.data.files_size = utils.formatFileSize(response.data.files_size);
	        $scope.stats = response.data;
	        $scope.$broadcast('analytics.token', { token: $scope.stats.token });
	    });
	}]);
}());