angular.module('app')
.factory('listeners', ['$state', 'localStorage', '$timeout', '$rootScope', 'utils', '$http', 'api', function($state, localStorage, $timeout, $rootScope, utils, $http, api) {
    var offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
    };

    var pc;
    var peerConnectionDidCreate = false;
    var candidateDidReceived = false;
    var onCandidate = false;
    var remoteStream;
    var localStream;

    function loadEcho() {
        window.Echo = new window.LaravelEcho['default']({
          broadcaster: 'socket.io',
          host: window.location.hostname + ":6001"
        });
    }

    var listeners = {

    	caller: false,

    	luid: undefined,

    	ruid: undefined,

    	callerInfo: undefined,

    	callId: undefined,

		isCaller: false,

        /**
        * All online users
        */
        onlineUsers: [],

        /**
        * All busy users
        */
        busyUsers: [],

        /**
        * Attending call
        */
        attendingCall:  false,

        setCallerUser: function(user) {
        	listeners.caller = user;
        },

        loadBusyUsers: function() {
        	api.user.signalBusyUsers();
        },

        leaveWebsite: function() {
        	if (window.Echo == undefined) {
        		loadEcho();
        	}
        	window.Echo.leave('website-users');
        	listeners.busyUsers = [];
        	listeners.attendingCall = false;
        	listeners.onlineUsers = [];
        },

        loadWebsiteUsersListener: function() {
        	console.log("in loadWebsiteUsersListener");

        	if (window.Echo == undefined) {
        		loadEcho();
        	}

            window.Echo.join('website-users')
                .here(function(onlineUsers) {
                    console.log('here are onlineUsers ', onlineUsers);
                    onlineUsers.forEach(function(onlineUserId) {
                        if (!(onlineUserId in listeners.onlineUsers )) {
                            console.log('adding ' , onlineUserId);
                            listeners.onlineUsers.push(onlineUserId);
                        }
                    });
                    console.log('users onlineUsers here ' , listeners.onlineUsers);
                    $rootScope.$broadcast('user.online.status');
                })
                .joining(function(newUser) {
                    console.log("joining  - " , newUser);
                    var isFound = false;
                    //TODO: FIX this
                    // if (!(onlineUserId in listeners.onlineUsers )) {
                    //     console.log('joining on onlineUsers ' , onlineUserId);
                    //     listeners.onlineUsers.push(onlineUserId);
                    // }

                    listeners.onlineUsers.forEach(function(userId) {
                        if (userId == newUser) {
                            isFound = true;
                        }
                    });

                    if (!isFound) {
                        listeners.onlineUsers.push(newUser);
                    }
                    console.log('users onlineUsers joining ' , listeners.onlineUsers);
                    $rootScope.$broadcast('user.online.status');
                })
                .leaving(function(leavingUser) {
                    console.log('leaving - ', leavingUser);
                    listeners.onlineUsers = listeners.onlineUsers.filter(function(userId) {
                        return userId != leavingUser;
                    });
                    console.log('users onlineUsers leaving ' , listeners.onlineUsers);
                    $rootScope.$broadcast('user.online.status');
                })
                .listen('.online-users' , function(e) {
                    console.log('online-user - ' , e);
                    listeners.busyUsers = e.busy_users;
                    $rootScope.$broadcast('user.online.status');
                });
        },

        loadVideoChatListener: function(user) {
        	listeners.setCallerUser(user);

            if (listeners.caller) {
	        	if (window.Echo == undefined) {
	        		loadEcho();
	        	}

	            window.Echo.private('video-chats.' + user.id)
	                .listen('VideoChatStart', function(data) {
	                    listeners.videoChatEventHandler(data);
	                });
            }
        },

        trace: function(arg) {
            var now = (window.performance.now() / 1000).toFixed(3);
            console.log(now + ': ', arg);
        },

        videoChatEventHandler: function(data) {
            if(data.to != listeners.caller.id){
                return;
            }

            if(data.type === 'signal'){
                listeners.onSignalMessage(data);
            }else if(data.type === 'text'){
                console.log('received text message from ' + data.from + ', content: ' + data.content);
            }else{
                console.log('received unknown message type ' + data.type + ' from ' + data.from);
            }
        },

        onSignalMessage: function(m) {
            // console.log(m.subtype);
            console.log("onSignalMessage " , m);

            if (m.subtype === 'offer') {
                console.log('got remote offer from ' + m.from + ', content ' , m.content);
                listeners.ruid = m.from;
                listeners.luid = m.to;
                listeners.callerInfo = m.callerInfo;
                listeners.callId = m.callId;
                listeners.onSignalOffer(m.content);
            } else if(m.subtype === 'answer') {
            	listeners.callId = m.callId;
                listeners.onSignalAnswer(m.content);
            } else if(m.subtype === 'candidate') {
                listeners.onSignalCandidate(m.content);
            } else if(m.subtype === 'close') {
                listeners.onSignalClose(m.subtype);
            } else if (m.subtype === 'busy') {
                listeners.onSignalClose(m.subtype);
                listeners.postCallEnded(m.subtype);
            } else if (m.subtype == 'noanswer') {
                listeners.onSignalClose(m.subtype);
                listeners.postCallEnded(m.subtype);
            } else if (m.subtype == 'hide-video') {
                listeners.onSignalHideStream(m.subtype);
            } else if (m.subtype == 'add-video') {
                listeners.onSignalAddStream(m.subtype, m.content);
            } else {
                console.log('unknown signal type ' + m.subtype);
            }
        },

        onSignalOffer: function(offer) {
            localStorage.set('offer', offer);
            listeners.trace('onSignalOffer received');

            if (!listeners.attendingCall) {
                listeners.loadIncomingCallDialog();
            }
        },

        loadIncomingCallDialog: function() {
            var content = 'You have an incoming call from ' + listeners.callerInfo.full_name + '. Do you want to want to answer?';
            utils.confirm({
                title: 'buzz.incomingVideoCall',
                content: content,
                ok: 'buzz.answer',
                cancel: 'buzz.decline',
                onConfirm: function() {
                    listeners.answerCall();
                },
                onRejection: function() {
                    listeners.rejectCall();
                }
            });
        },

        rejectCall: function() {
            console.log("You are rejecting this call!!!");
            var message = {from: listeners.luid, to: listeners.ruid, type: 'signal', subtype: 'busy', content: 'Callee is busy', time:new Date()};
            // TODO: Reponse Handling
            api.user.signalVideoChat(message);
        },

        answerCall: function() {
            console.log("accept call - answer");
            if (!listeners.attendingCall && $state.current.name !== 'dashboard.buzz') {
                $state.go('dashboard.buzz', { incoming_call: true });
            }

            if (!listeners.attendingCall && $state.current.name == 'dashboard.buzz') {
                $rootScope.$broadcast('user.call.answer');
            }
            listeners.attendingCall = true;
        },

        onSignalClose: function(type) {
            listeners.trace('Ending call with type ' + type);
            if (pc) {
                pc.close();
            }

            if (!pc && !listeners.isCaller) {
                utils.closeModal();
            }

            pc = null;
            $rootScope.$broadcast('user.videoChat.signalClose', type);
        },

        onResetSetup: function() {
            listeners.isCaller = false;
            peerConnectionDidCreate = false;
            candidateDidReceived = false;
            onCandidate = false;
            listeners.ruid = undefined;
            listeners.callerInfo = undefined;
            localStorage.remove('offer');
            listeners.callId = undefined;
        },

        onSignalAnswer: function(answer) {
            listeners.onRemoteAnswer(answer);
            $rootScope.$on('user.videoChat.onSignalAnswer');

            if (pc && listeners.isCaller) {
                $timeout(function() {
                    if (pc) {
                        // console.log("PC is - " , pc);
                        // console.log("pc.signalingState - " , pc.signalingState);
                        // console.log("pc.readyState - " , pc.readyState);
                        // console.log("pc.connectionState - ", pc.connectionState);
                        if (pc.connectionState != 'connected') {
                            console.log("callNoAnswer 09");
                            listeners.callNoAnswer();
                        }
                    }
                }, 240000);
            }
        },

        onSignalCandidate: function(candidate) {
            listeners.onRemoteIceCandidate(candidate);
        },

        setupVideoCall: function(luid, ruid, isCaller) {
            listeners.luid = luid;
            listeners.ruid = ruid;
            listeners.isCaller = isCaller;
        },

        videoCall: function(stream) {
            stream = stream || null;

            var configuration = { "iceServers": [{ "urls": "stun:stun.l.google.com:19302" }] };
            pc = new RTCPeerConnection(configuration);

            listeners.trace('Created local peer connection object pc');

            pc.onicecandidate = function(e) {
                listeners.onIceCandidate(pc, e);
            };

            pc.oniceconnectionstatechange = function(e) {
                listeners.onIceStateChange(pc, e);
            };

            pc.onconnectionstatechange = function(e) {
                listeners.onConnectionStateChange(pc, e);
            };

            // pc.onaddstream = gotRemoteStream;
            pc.ontrack = listeners.gotRemoteStream;

            pc.addEventListener('track', function(event) {
                // console.log("in track addEventListener");
                $rootScope.$broadcast('user.set.remoteStream');
                if (remoteStream) {
                    remoteStream.addTrack(event.track, remoteStream);
                    // console.log("REMOTE STREM TRACK");
                }
            });

            if (stream) {
                localStream = stream;
            }

            if (localStream) {
                localStream.getTracks().forEach(function(track) {
                    pc.addTrack(track, localStream);
                });
            }

            // pc.addStream(localStream);

            listeners.trace('Added local stream to pc');

            peerConnectionDidCreate = true;

            if(listeners.isCaller) {
                listeners.trace(' createOffer start');
                listeners.trace('pc createOffer start');

                pc.createOffer(
                    offerOptions
                ).then(
                    listeners.onCreateOfferSuccess,
                    listeners.onCreateSessionDescriptionError
                );
            }else{
                listeners.onAnswer();
            }
        },

        onRemoteAnswer: function(answer) {
            listeners.trace('onRemoteAnswer : ' + answer);
            if (pc) {
                pc.setRemoteDescription(answer).then(
                    function(){
                        listeners.onSetRemoteSuccess(pc);
                    }, listeners.onSetSessionDescriptionError);
            }
        },

        onSetRemoteSuccess: function() {
            listeners.trace(pc + ' setRemoteDescription complete');
            if (listeners.isCaller) {
                $rootScope.$broadcast('user.remoteVideo.loaded');
            }
            listeners.applyRemoteCandidates();
        },

        applyRemoteCandidates: function() {
            var candidates = localStorage.get('candidate');
            for(var candidate in candidates){
                listeners.addRemoteCandidate(candidates[candidate]);
            }
            localStorage.remove('candidate');
        },

        addRemoteCandidate: function(candidate) {
            if (pc) {
                pc.addIceCandidate(candidate).then(
                    function() {
                        listeners.onAddIceCandidateSuccess(pc);
                    },
                    function(err) {
                        listeners.onAddIceCandidateError(pc, err);
                    });
            }
        },

        onAddIceCandidateSuccess: function(pc) {
            listeners.trace(pc + ' addIceCandidate success');
        },

        onRemoteIceCandidate: function(candidate) {
            listeners.trace('onRemoteIceCandidate : ' + candidate);
            if (peerConnectionDidCreate) {
                listeners.addRemoteCandidate(candidate);
            } else {
                //remoteCandidates.push(candidate);
                // var candidates = localStorage.getJSON('candidate');
                var candidates = localStorage.get('candidate');
                if(candidateDidReceived){
                    candidates.push(candidate);
                } else {
                    candidates = [candidate];
                    candidateDidReceived = true;
                }
                localStorage.set('candidate', candidates);
            }
        },

        callNoAnswer: function () {
            console.log(" YOU HAVE no ANSWER");
            var message = {from: listeners.luid, to: listeners.ruid, type: 'signal', subtype: 'noanswer', content: 'Call ended', time:new Date()};
            // TODO: Reponse Handling
            api.user.signalVideoChat(message);
            // $http.post('video-chat', message);
            listeners.onSignalClose('noanswer');
        },

        onAddIceCandidateError: function(pc, error) {
            listeners.trace(pc + ' failed to add ICE Candidate: ' + error.toString());
        },

        gotRemoteStream: function(e) {
            remoteStream = e.streams[0];
            $rootScope.$broadcast('user.videoChat.gotRemoteStream', e);
        },

        setRemoteStream: function(stream) {
            remoteStream = stream;
        },

        onSetSessionDescriptionError: function(error) {
            listeners.trace('Failed to set session description: ' + error.toString());
        },

        onCreateSessionDescriptionError: function(error) {
            listeners.trace('Failed to create session description: ' + error.toString());
        },

        onIceCandidate: function(pc, event) {
            if (event.candidate && !onCandidate){
                listeners.trace(pc + ' ICE candidate: \n' + (event.candidate ? event.candidate.candidate : '(null)'));
                var message = {from: listeners.luid, to: listeners.ruid, type: 'signal', subtype: 'candidate', content: event.candidate, time:new Date()};
                // TODO: Reponse Handling
                api.user.signalVideoChat(message);
            }
        },

        onIceStateChange: function() {
        	var message;
            if (pc) {
                listeners.trace(pc + ' ICE state: ' + pc.iceConnectionState);
                console.log('ICE state change event: ', event);
            }
            switch(pc.iceConnectionState) {
                case "connected":
                    if (!listeners.isCaller) {
                        message = {callId: listeners.callId, caller: listeners.ruid, callee: listeners.luid, type: 'log', subtype: pc.iceConnectionState, time:new Date()};
                        api.user.signalVideoChat(message);
                    }
                    break;
                case "checking":
                	break;
                case "connecting":
                    console.log("CONNECTINNGGGG!!!!!");
                    $rootScope.$broadcast('user.videoStatus.ringing');
                    $timeout(function() {
                        if (pc && pc.connectionState == 'connecting') {
                            listeners.callNoAnswer();
                        }
                    }, 240000);
                    break;
                case "disconnected":
                case "failed":
                    console.log("disconnected or faileddd ");
                    $rootScope.$broadcast('user.videoStatus.failed');
                    if (listeners.isCaller) {
                        message = {callId: listeners.callId, caller: listeners.luid, callee: listeners.ruid, type: 'log', subtype: pc.iceConnectionState, time:new Date()};
                        api.user.signalVideoChat(message);
                    }
                    // One or more transports has terminated unexpectedly or in an error
                    break;
                case "closed":
                	console.log("closedddd");
                    if (listeners.isCaller) {
                    	// console.log("closedddd123");
                        message = {callId: listeners.callId, caller: listeners.luid, callee: listeners.ruid, type: 'log', subtype: pc.iceConnectionState, time:new Date()};
                        api.user.signalVideoChat(message);
                    } else {
                    	// console.log("closedddd444");
                    }
                    // The connection has been closed
                    break;
            }
        },

        onConnectionStateChange: function(pc, event) {
            if (pc) {
                listeners.trace(pc + ' ICE connection state: ' + pc.connectionState);
                console.log('ICE connection state change event: ', event);
                if (pc.connectionState == 'connected') {
                    $rootScope.$broadcast('user.remoteVideo.loaded');
                }

                if (pc.connectionState == 'connecting') {
                	$rootScope.$broadcast('user.videoStatus.ringing');
                }
            }
        },

        onAnswer: function() {
            var remoteOffer = localStorage.get('offer');

            pc.setRemoteDescription(remoteOffer).then(function(){
                listeners.onSetRemoteSuccess(pc);
            }, listeners.onSetSessionDescriptionError);

            pc.createAnswer().then(
                listeners.onCreateAnswerSuccess,
                listeners.onCreateSessionDescriptionError
            );
        },

        onCreateAnswerSuccess: function(desc) {
            listeners.trace('Answer from pc:\n' + desc.sdp);
            listeners.trace('pc setLocalDescription start');
            pc.setLocalDescription(desc).then(
                function() {
                    listeners.onSetLocalSuccess(pc);
                },
                listeners.onSetSessionDescriptionError
            );

            var message = {from: listeners.luid, to: listeners.ruid, type: 'signal', subtype: 'answer', content: desc, callId: listeners.callId, time:new Date()};
            // TODO: Reponse Handling
            api.user.signalVideoChat(message);
        },

        onCreateOfferSuccess: function(desc) {
            listeners.trace('Offer from pc\n' + desc.sdp);
            listeners.trace('pc setLocalDescription start');
            pc.setLocalDescription(desc).then(
                function() {
                    listeners.onSetLocalSuccess(pc);
                },
                listeners.onSetSessionDescriptionError
            );

            var callerInfo = {
            	id: listeners.luid,
                url: listeners.caller.url,
                full_name: listeners.caller.full_name
            };

            var message = {from: listeners.luid, to: listeners.ruid, type: 'signal', callerInfo: callerInfo, subtype: 'offer', content: desc, time:new Date()};
            // TODO: Reponse Handling
            api.user.signalVideoChat(message);
            // $http.post('video-chat', message);
        },

        onSetLocalSuccess: function(pc) {
            listeners.trace( pc + ' setLocalDescription complete');
            $timeout(function() {
                // console.log('setLocalDescription timeout');
                // console.log("pc.signalingState - " , pc.signalingState);
                // console.log("pc.readyState - " , pc.readyState);
                // console.log("pc.connectionState - ", pc);
                if (pc && pc.signalingState == 'have-local-offer' && listeners.isCaller) {
                    // console.log("have-local-offer NO");
                    console.log("callNoAnswer 07");
                    listeners.callNoAnswer();
                } else if (pc && pc.signalingState == 'have-remote-offer' && listeners.isCaller) {
                    // console.log("have-remote-offer NO");
                    console.log("callNoAnswer 06");
                    listeners.callNoAnswer();
                }
            }, 240000);
        },

        postCallEnded: function(type) {
			type = type || 'close';
			var message;

			if (type == 'close') {
				message = {callId: listeners.callId, from: listeners.luid, to: listeners.ruid, type: 'signal', subtype: 'close', content: 'Call ended', time:new Date()};
			} else {
				var caller = listeners.luid;
				var callee = listeners.ruid;
				if (!listeners.isCaller) {
					caller = callee;
					callee = listeners.luid;
				}
				message = {caller: caller, callee: callee, type: 'log', subtype: type, time:new Date()};
			}

            // TODO: Reponse Handling
            api.user.signalVideoChat(message);
            listeners.onResetSetup();
        },

	    /* Work in progress */
	    /*
	    onSignalAddStream: function(type, content) {
	    	console.log('Adding stream of type ' + type);
	    	console.log('content from ', content);
	    	type = type.split('-');
	    	// console.log("ooo1");
	   //  	if (type.length == 2) {
	   //  		type = type[1];
				// console.log('remote stream add  ' , remoteStream);
				// if (remoteStream) {
				// 	console.log("ttt1");
				// 	var videoTracks = remoteStream.getVideoTracks();
				// 	videoTracks.forEach(function(videoTrack) {
				// 		videoTrack.active = true;
				// 		videoTrack.play();
				// 		remoteStream.addTrack(videoTrack);
				// 	});
				// 	remoteVideo.srcObject = null;
				// 	remoteVideo.srcObject = remoteStream;
				// 	$scope.data.remoteVideoHidden = true;
				// 	$timeout(function() {
				// 		console.log("ooo2");
		  //       		$scope.$apply(function() {
		  //       			console.log("ooo3");
				// 			$scope.data.remoteVideoLoaded = true;
				// 			$scope.data.hideStatusLoader = true;
				// 			$scope.data.callStatusText = 'Ringing...';
			 //    		});
			 //    	});
				// }
	   //  	}
	    },

	    onSignalHideStream: function(type) {
	    	console.log('Hiding stream of type ' + type);
	    	type = type.split('-');
	    	console.log("eee1");
			$timeout(function() {
				console.log("eee2");
        		$scope.$apply(function() {
        			console.log("eee3");
					$scope.data.remoteVideoLoaded = false;
					$scope.data.hideStatusLoader = true;
					$scope.data.callStatusText = 'On Audio Call';
	    		});
	    	});
	    	if (type.length == 2) {
	    		type = type[1];
				console.log('remote stream removal ' , remoteStream);
				if (remoteStream) {
		    		var videoTracks = remoteStream.getVideoTracks();
					videoTracks.forEach(function(videoTrack) {
						videoTrack.stop();
						remoteStream.removeTrack(videoTrack);
					});
					remoteVideo.srcObject = null;
					remoteVideo.srcObject = remoteStream;
					$scope.data.remoteVideoHidden = true;
				}
	    	}
	    } */
    };

	return listeners;
}]);