(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('previewArea', ['$state', 'users', 'selectedItems', function($state, users, selectedItems) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/modals/preview-area.html',
            scope: {
                item: '='
            },
            link: function($scope) {
                $scope.data = {
                    previewType: 'none'
                };

                function determinePreviewType(item) {
                    var result = 'none';

                    if(item.is_locked) {
                        result = 'lock';
                    } else {
                        var types = ['audio', 'video', 'image', 'html', 'text', 'zip', 'pdf'];

                        for(var ii= 0; ii<types.length; ii++) {
                            if(selectedItems.mimeTypeIs(types[ii], item.mime)) {
                                result = types[ii];
                                break;
                            }
                        }
                    }

                    return result;
                }

                function update() {
                    if($scope.item) {

                        if (!selectedItems.items.length && $state.current.name == "dashboard.hives.details.member.files") {
                            selectedItems.items = [$scope.item];
                        }

                        $scope.data.previewType = determinePreviewType($scope.item);
                    } else {
                        $scope.data.previewType = 'load';
                    }
                }

                $scope.download = function() {
                    selectedItems.download($scope.item);
                };

                $scope.$watch('item', function(newValue) {
                    update();
                });

                // Could be used in future
                // $scope.post = function(privacy) {
                //     $scope.data.isPosting = true;
                //     var post = new api.resources.post();

                //     // update the fields.
                //     post.file_id = $scope.item.id;
                //     post.privacy = privacy;
                //     $scope.data.isPosting = true;
                //     post.$save().then(function(data) {
                //         $scope.data.isPosting = false;
                //         $scope.data.isPosted = true;
                //         $scope.item.is_posted = true;

                //         if (privacy == "private") {
                //             utils.showToast(utils.trans('post.filePostedPrivate', true));
                //         } else {
                //             utils.showToast(utils.trans('post.filePostedPublic', true));
                //         }
                //     });
                // };

                $scope.$on('preview.failed', function() {
                    // previews send this
                    $scope.data.previewType = 'none';
                });

                $scope.$on('preview.update', function() {
                    update();
                });
            }
        };
    }]);
})();