angular.module('app').factory('questions', ['$http', '$rootScope', '$state', '$mdDialog', 'utils', function($http, $rootScope, $state, $mdDialog, utils) {

    var questions = {
        /**
         * Paginate all existing questions.
         *
         * @returns {promise}
         */
        paginate: function(params) {
            return $http.get('questions', {params: params});
        },

        /**
         * Create a new question
         *
         * @param {object}
         * @returns {promise}
         */
        create: function(data) {
            return $http.post('questions', data);
        },

        /**
         * Update question.
         */
        updateQuestion: function(question, id) {
            return $http.put($rootScope.baseUrl+'questions/'+id, question);
        },

        /**
         * toggle question state.
         */
        toggleQuestion: function(state, id) {
            return $http.put($rootScope.baseUrl+'questions/'+id+'/change-state', {state: state});
        },

        /**
         * Delete given questions from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(questions) {
            return $http.post($rootScope.baseUrl+'questions/delete', questions);
        },
    };

    return questions;
}]);
