(function(){
    'use strict';

    angular.module('pixie.dashboard').controller('NavbarController', ['$rootScope', '$scope', '$http', 'utils', 'selectedItems', 'previewStatus','files', '$state', function($rootScope, $scope, $http, utils, selectedItems, previewStatus, files, $state) {

        $scope.selectedItem = false;
        $scope.files = files;

        $scope.getSearchResults = function(query) {
            return $http.get('search/'+query).then(function(response) {
                $scope.searchResults = response.data;
                return response.data;
            });
        };

        $scope.goToSearchPage = function() {
            if ( ! $scope.searchText) {
                return;
            }

            utils.toState('dashboard.search', { query: $scope.searchText });
        };

        $scope.selectItem = function() {
            if ( ! $scope.selectedItem) {
                return;
            }

            selectedItems.set($scope.selectedItem, $scope.selectedItem.type, true);
            selectedItems.preview();
            
            // previewStatus.open = true;
            $scope.searchText = '';
            $scope.selectedItem = false;
        };

        $scope.displayNavbar = function() {
            if (["dashboard.trash", "dashboard.recent", "dashboard.favorites", "dashboard.search"].indexOf($state.current.name) > -1 || selectedItems.first('is_offer')) {
                return false;
            }            
            return true;
        };

    }]);
}());