(function(){
    'use strict';

    angular.module('pixie.dashboard').factory('trash', ['$rootScope', 'api', function($rootScope, api) {
        var trash = {
            /**
             * If we have fetched user trash from server already.
             */
            loaded: false,

            /**
             * Items (files and folders) user has trashed.
             */
            items: [],

            /**
             * Return a trashed item by id.
             *
             * @param {string|int} id
             * @returns {object|undefined}
             */
            getItemById: function(id) {
                for (var i = 0; i < this.items.length; i++) {
                    if (this.items[i].id == id) {
                        return this.items[i];
                    }
                }
            },

            /**
             * Get user trash items.
             *
             * @returns {promise}
             */
            getTrashedItems: function() {
                return api.trash.contents().then(function(data) {
                    trash.items = data;
                    trash.loaded = true;
                });
            },
            
            empty: function() {
                return api.trash.empty().then(function success(data) {
                    trash.items = [];
                });
            }
        };

        $rootScope.$on('user.loggedOut', function() {
            trash.items = [];
        });

        return trash;
    }]);
}());