angular.module('app').factory('storySections', ['$http', '$rootScope', '$state', '$mdDialog', 'utils', 'files', 'api', function($http, $rootScope, $state, $mdDialog, utils, files, api) {
   
    var storySections = {

        /**
         * All sections
         */
        all: [],

        // /**
        //  * Paginate all existing sections.
        //  *
        //  * @returns {promise}
        //  */
        //  paginate: function(params) {
        //    return $http.get('sections', {params: params});
        // },

        /**
         * Create a new section with given info.
         *
         * @param {object} info
         * @returns {promise}
         */
        create: function(section_info) {
            return $http.post('story-section', section_info);
        },

        /**
         * Delete given section from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(values) {
            var promise;
          
            if (angular.isArray(values)) {
                promise = $http.post('story-section/delete-all/', {storySections:values});
            } else {
                promise = $http.delete('story-section/'+values.id);
                values = [values];
            }

            return promise.then(function(response) {
                storySections.all = storySections.all.filter(function(storySection) {
                    return values.indexOf(storySection) === -1;
                });
                utils.showToast(response.data);
            });
        },

        /**
         * Update the section
         *
         * @returns {string|undefined}
         */
        update: function(storySection, id) {
            var sectionId  = id || this.current.id; 
            return $http.put($rootScope.baseUrl+'story-section/'+sectionId, storySection);
        }  
    };
    
    return storySections;
}]);
