(function () {
    'use strict';

    angular.module('pixie.dashboard', ['uiCropper', 'ngMaterial', 'ngSanitize', 'ngMdIcons']);
    angular.module('image.directives', []);
    angular.module('pixie.admin', []);

    // this is the main definition, with all the dependencies for the previews; need to add deps here. (don't like this methodology)
    angular.module('app.dialogs.preview', ['uiCropper']);

    angular.module('app', [
        'ui.router',
        'ngAnimate',
        'ngResource',
        'ngTagsInput',
        'ngSanitize',
        // 'ngMask',
        // 'material.core',
        // 'material.components.autocomplete',
        // 'material.components.switch',
        // 'material.components.tabs',
        // 'material.components.dialog',
        // 'material.components.backdrop',
        // 'material.components.button',
        // 'material.components.checkbox',
        // 'material.components.icon',
        // 'material.components.input',
        // 'material.components.progressCircular',
        // 'material.components.progressLinear',
        // 'material.components.select',
        // 'material.components.toast',
        // 'material.components.sidenav',
        // 'material.components.whiteframe',
        // 'material.components.menu',
        'material.components.datepicker',
        'ngMaterial',
        'ngMessages',
        'afkl.lazyImage',
        'pascalprecht.translate',
        'angularFileUpload',
        'angularUtils.directives.dirPagination',
        'pixie.dashboard',
        'pixie.admin',
        'textAngular',
        'pixie.dashboard.left',
        'pixie.dashboard.right',
        'app.dialogs',
        'app.api',
        'ngSanitize',
        'ngMdIcons',
        'ezfb', // Angular+Facebook Easy Module
        'storyhives.facebook'
    ])
    .config(function (ezfbProvider) {
        ezfbProvider.setInitParams({
            appId            : vars.facebookId,
            version          : 'v9.0'
        });
        console.log("vars.facebookId ", vars.facebookId);
    })
    .factory('addMethodOverrideHeader', function() {
        return {
            request: function (config) {
                if (['PATCH', 'PUT', 'DELETE'].indexOf(config.method) > -1) {
                    config.headers['X-HTTP-Method-Override'] = config.method;
                    config.method = 'POST';
                }

                return config;
            }
        };
    })

    .config(['$mdThemingProvider', '$translateProvider', '$compileProvider', '$httpProvider', '$provide', function($mdThemingProvider, $translateProvider, $compileProvider, $httpProvider, $provide) {
        $compileProvider.debugInfoEnabled(true);

        $mdThemingProvider.theme('default')
            .primaryPalette('amber')
            .accentPalette('orange')
            .warnPalette('deep-orange');

        if (vars.selectedLocale) {
            $translateProvider.translations(vars.selectedLocale, vars.trans);
            $translateProvider.preferredLanguage(vars.selectedLocale);
        } else {
            $translateProvider.translations('en', vars.trans);
            $translateProvider.preferredLanguage('en');
        }

        $translateProvider.useUrlLoader('trans-messages');
        $translateProvider.useSanitizeValueStrategy('escaped');

        $httpProvider.interceptors.push('addMethodOverrideHeader');
    }])
    .config(['$provide', function ($provide) {

        $provide.decorator('$sanitize', [ '$delegate', function($delegate) {
            // replace the video block first with something that will pass.
            function mySanitize(data) {
                // replace custom-video blocks with save div.
                if (typeof arguments[1] != 'undefined') {
                    console.log("arguments[1] => "+JSON.stringify(arguments[1]));
                }

                // we remove our custom video element before passing to the sanitize function (turn it into something that will pass sanitation)
                // data = data.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');
                // data = data.replace(/<video-preview data-src="([-:.?=\/a-zA-Z0-9]*)" data-alt="([a-zA-Z0-9]*)" video="([a-zA-Z0-9]*)"><\/video-preview>/g, '<img src="$1" alt="VideoId:$2">');
                // data = data.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');

                // we sanitize (this is using the textAngular sanitize)
                data = $delegate(data);

                // and we restore what we did above....
                // data = data.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');
                // data = data.replace(/<p id="video-([a-zA-Z0-9]*)"><\/p>/g, '<video-file video-id="$1"></video-file>');

                return data;
            }

            return mySanitize;
        }]);

        $provide.decorator('taOptions', ['$delegate', 'taRegisterTool', 'taToolFunctions', 'utils', 'newUploadManager', '$mdDialog', '$translate', '$state', '$rootScope', function (taOptions, taRegisterTool, taToolFunctions, utils, newUploadManager, $mdDialog, $translate, $state, $rootScope) {

            // Register an upload image functionality into text angular toolbar
            taRegisterTool('uploadImage', {
                iconclass: "fa fa-picture-o",
                tooltiptext: 'Upload an image',
                onElementSelect: {
                    element: 'img',
                    // action: taToolFunctions.imgOnSelectAction
                    action: function(event, $element, editorScope){
                        this.$editor().$emit('select-editor-image', event, $element, editorScope);
                        return false;
                    }
                },
                action: function(deferred) {

                  var $editor = this.$editor;

                  // find an existing one and remove it.
                  var id = 'dynamic-upload-image';
                  $('#'+id).remove();

                  // Create a virtual input element.
                  var input = document.createElement('input');
                  input.type = 'file';
                  input.id = id;
                  input.accept = "image/*,.heic";

                  input.onchange = function() {
                    if (this.files && this.files[0]) {
                        if (!this.files[0].size) {
                            deferred.reject();
                            return;
                        }

                        var model = this;

                        $mdDialog.show({
                            parent: angular.element(document.body),
                            multiple: true,
                            template:
                                    '<md-dialog aria-label="Image Upload Progress">'+
                                        '<md-dialog-content>'+
                                            '<div style="margin: 10px;">'+
                                                '<div layout="row">'+
                                                    '<div flex="none">{{ data.statusText }}</div>'+
                                                    '<div flex="flex"></div>'+
                                                    '<div ng-if="data.hasProgress" flex="none" class="percent" ng-class="{\'completed\': data.uploadCompleted }">{{data.percentageUploaded}}%</div>'+
                                                '</div>'+
                                                '<md-progress-linear ng-if="!data.hasProgress" class="loading-progress" md-mode="indeterminate"></md-progress-linear>'+
                                                '<md-progress-linear ng-if="data.hasProgress" class="loading-progress" md-mode="determinate" value="{{data.percentageUploaded}}" ></md-progress-linear>'+
                                            '</div>'+
                                        '</md-dialog-content>'+
                                    '</md-dialog>',
                            onComplete: function($scope, $element) {
                                $scope.data = {
                                    uploadCompleted: false,
                                    percentageUploaded: 0,
                                    hasProgress: true,
                                    statusText: $translate.instant('editor.inProgressText'),
                                };

                                function progress(evt) {
                                    $scope.data.percentageUploaded = evt.$progress.percentageUploaded;
                                    if ($scope.data.percentageUploaded >= 100) {
                                        $scope.data.uploadCompleted = true;
                                        $scope.data.statusText = "Processing Image..";
                                        $scope.data.hasProgress = false;
                                    }
                                }

                                var state = $state.current.name.split('.');

                                if (state.length == 2) {
                                  if (state[1] == 'folders') {
                                    state = 'doc';
                                  } else if (state[1] == 'buzz') {
                                    state = 'timeline';
                                  } else {
                                    state = state[1];
                                  }
                                } else {
                                  state = 'doc';
                                }

                                newUploadManager.upload(model.files, { url: '/files/upload-file-media', progress: progress, fields: {folder: state} }).then(function success(response) {
                                    var altText = response.length && response[0].shareId ? 'alt="ImageId:' + response[0].shareId + '" ' : '';
                                    $editor().wrapSelection('insertHtml', '<img src="' + response[0].uploadedUrl+ '" ' + altText + '>', true);
                                    deferred.resolve();
                                    $scope.data.uploadCompleted = true;
                                    $scope.data.statusText = $translate.instant('editor.completedText');
                                    $rootScope.$broadcast('imgeditor.completed');
                                }, function failure(data) {
                                    if (typeof data === 'object' && data !== null) {
                                        utils.showToast(data['file'][0]);
                                    } else {
                                        utils.showToast(data);
                                    }
                                    deferred.reject();
                                }).finally(function() {
                                    $('#'+id).remove();
                                    $mdDialog.cancel();
                                });
                            }
                        });
                    } else {
                        deferred.reject();
                    }
                  };

                  document.body.appendChild(input);

                  // Click on a virtual input element.
                  input.click();

                  return false; // tell textangular we want to use the promise passed in (and we'll resolve it)
                }
            });

            // Register an add image to media storage functionality into text angular toolbar
            taRegisterTool('addMediaViaStorage', {
                iconclass: "fa fa-picture-o",
                tooltiptext: 'Upload an image from storage',
                onElementSelect: {
                    element: 'img',
                    action: taToolFunctions.imgOnSelectAction
                },
                action: function ($deferred, restoreSelection) {
                    var $editor = this.$editor;
                    var savedSelection = rangy.saveSelection();

                    $mdDialog.show({
                        templateUrl: 'assets/views/modals/media-from-storage.html',
                        clickOutsideToClose: true,
                        multiple: true,
                        controller: ['$scope', 'api', 'fileTypes', '$rootScope', function ($scope, api, fileTypes, $rootScope) {
                            $scope.data = {
                                url: '',
                                selectedItem: false,
                                searchResults: [],
                                searchText: undefined,
                                time: new Date().getTime().toString(),
                                folder: null,
                                breadcrumbs: [],
                                loading: false,
                            };

                            $scope.close = function () {
                                $mdDialog.cancel();
                            };

                            $scope.save = function () {
                                var state = $state.current.name.split('.');

                                if (state.length == 2) {
                                  if (state[1] == 'folders') {
                                    state = 'doc';
                                  } else if (state[1] == 'buzz') {
                                    state = 'timeline';
                                  } else {
                                    state = state[1];
                                  }
                                } else {
                                  state = 'doc';
                                }

                                $scope.data.loading = true;
                                api.file.duplicateMediaFile(state, $scope.data.selectedItem.share_id).then(function(data) {
                                    $mdDialog.hide({
                                        shareId: data.share_id,
                                        url: data.absoluteUrl
                                    });
                                }, function() {
                                    // handle failded case
                                }).finally(function() {
                                    $scope.data.loading = false;
                                });
                            };

                            $scope.getFolder = function (shareId) {
                                api.folder.getFolder(shareId).then(function (response) {
                                    var validFiles = [];

                                    response.folder.files.forEach(function (item) {
                                        if (item.mime.includes('image')) {
                                            validFiles.push(item);
                                        }
                                    });

                                    response.folder.files = validFiles;
                                    $scope.data.folder = response;

                                    var index = $scope.data.breadcrumbs.findIndex(
                                        function (element) {
                                            return (element.shareId == shareId);
                                        });

                                    if (index >= 0) {
                                        $scope.data.breadcrumbs.length = index;
                                    }

                                    if (shareId == 'root') {
                                        $scope.data.breadcrumbs = [{
                                            'name': response.folder.name,
                                            'shareId': response.folder.share_id
                                        }];
                                    }

                                    if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                                        $scope.data.breadcrumbs.push({
                                            'name': response.folder.name,
                                            'shareId': response.folder.share_id
                                        });
                                    }
                                });
                            };

                            $scope.getFolder('root');

                            $scope.getSearchResults = function (query) {
                                $scope.data.time = new Date().getTime().toString();
                                return api.file.searchMedia($scope.data.searchText).then(function (files) {
                                    $scope.data.searchResults = files;
                                    return $scope.data.searchResults;
                                });
                            };

                            $scope.getMimeIcon = function (mime) {
                                return fileTypes.getMimeIcon(mime);
                            };

                            $scope.getIsThumbnail = function (item) {
                                return item.flags & 0x02 ? true : false;
                            };

                            $scope.getThumbnail = function (item) {
                                return $rootScope.baseUrl + 'user-file/' + item.share_id + '?thumb=1&t=' + $scope.data.time;
                            };

                            $scope.setUrl = function (item) {
                                $scope.data.selectedItem = item;
                                $scope.data.url = item.type == 'file' ? item.absoluteUrl : '';
                            };
                        }]
                    }).then(function (data) {
                        // TODO: FOR VIDOES
                        rangy.restoreSelection(savedSelection);

                        var altText = data.shareId ? 'alt="ImageId:' + data.shareId + '" ' : '';
                        $editor().wrapSelection('insertHtml', '<img src="' + data.url+ '" ' + altText + '>', true);
                        $rootScope.$broadcast('imgeditor.completed');

                        $deferred.resolve();
                    }, function () {
                        rangy.restoreSelection(savedSelection);
                        $deferred.resolve();
                    });
                    return false;
                }
            });

            // Register an add video to media storage functionality into text angular toolbar
            taRegisterTool('uploadVideo', {
                iconclass: "fa fa-picture-o",
                tooltiptext: 'Upload a video',
                // onElementSelect: {
                //   element: 'img',
                //   action: taToolFunctions.imgOnSelectAction
                // },
                action: function($deferred, restoreSelection) {
                    // Sam - if you uncomment the block below, you can see how it would pass this through
                    // if(false)
                    // {
                        // There are going to be multiple things to look out for.
                        // 1) We really want to use the video-preview directive (or something like it), but we can't in "edit" mode.  This is because it will
                        //   fetch the video and use the proper timeouts and safety measures.  So this should probably be an img wrap with the poster url and
                        //   some additional field that has the shareId.  Whatever format you decide, you have to fix the decorator $sanitize $delegate method
                        //   higher up in this file.
                        // 2) Another alternative might be to set the alt text on an img to "video:ID" and the src to the poster frame; that would allow you
                        //   to manipulate the size of the window and see the thumbnail.  On story save it would convert to the video directive, and sanitize would
                        //   simply convert it from the video directive back to this image format (for editing).
                        // 3) You might have to call $compile on the result of the load to get the video-preview directive to run/load.
                        // var embedded = '<video-file ed="1" video-id="wcvurhagfbnxjhvhgwmp"></video-file>';
                        // var $editor = this.$editor;
                        // $editor().wrapSelection('insertHtml', embedded, true);
                        // return true;
                    // } else {
                        var $editor = this.$editor;
                        var savedSelection = rangy.saveSelection();
                        return $mdDialog.show({
                            templateUrl: 'assets/views/modals/upload-video.html',
                            clickOutsideToClose: true,
                            multiple: true,
                            controller: ['$scope', 'api', 'fileTypes', '$rootScope', '$state', '$compile', '$window', function($scope, api, fileTypes, $rootScope, $state, $compile, $window) {
                                $scope.data = {
                                    posterUrl: '',
                                    selectedItem: false,
                                    searchResults: [],
                                    searchText: undefined,
                                    time: new Date().getTime().toString(),
                                    state: 'menu',
                                    folder: null,
                                    breadcrumbs: [],
                                    shareId: '',
                                    loading: false,
                                    recordFileText: '',
                                    showRecord: true,
                                    device: 'mobile'

                                };

                                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test($window.navigator.userAgent)) {
                                    if ($window.innerWidth <= 768) {
                                        $scope.data.device = "mobile";
                                    } else {
                                        $scope.data.device = "tablet";
                                    }
                                } else {
                                    $scope.data.device = "desktop";
                                }

                                function updateShowRecord() {
                                    if ($scope.data.device == "desktop") {
                                        $scope.data.showRecord = false;
                                    } else {
                                        $scope.data.showRecord = true;
                                    }
                                }

                                updateShowRecord();

                                var state = $state.current.name.split('.');
                                var recordId = 'dynamic-video';
                                if (state.length == 2) {
                                  if (state[1] == 'folders') {
                                    state = 'doc';
                                  } else if (state[1] == 'buzz') {
                                    state = 'timeline';
                                  } else {
                                    state = state[1];
                                  }
                                } else {
                                  state = 'doc';
                                }

                                $scope.close = function() {
                                    $mdDialog.cancel();
                                };

                                function dialogForUploadVideo(files) {
                                    return $mdDialog.show({
                                        parent: angular.element(document.body),
                                        multiple: true,
                                        template:
                                            '<md-dialog aria-label="Video Upload Progress">'+
                                                '<md-dialog-content>'+
                                                    '<div style="margin: 10px;">'+
                                                        '<div layout="row">'+
                                                            '<div flex="none">{{ data.statusText }}</div>'+
                                                            '<div flex="flex"></div>'+
                                                            '<div ng-if="data.hasProgress" flex="none" class="percent" ng-class="{\'completed\': data.uploadCompleted }">{{data.percentageUploaded}}%</div>'+
                                                        '</div>'+
                                                        '<md-progress-linear ng-if="!data.hasProgress" class="loading-progress" md-mode="indeterminate"></md-progress-linear>'+
                                                        '<md-progress-linear ng-if="data.hasProgress" class="loading-progress" md-mode="determinate" value="{{data.percentageUploaded}}" ></md-progress-linear>'+
                                                    '</div>'+
                                                '</md-dialog-content>'+
                                            '</md-dialog>',
                                        onComplete: function($scope, $element) {
                                            $scope.data = {
                                                uploadCompleted: false,
                                                percentageUploaded: 0,
                                                hasProgress: true,
                                                statusText: $translate.instant('editor.inProgressText'),
                                            };

                                            function progress(evt) {
                                                $scope.data.percentageUploaded = evt.$progress.percentageUploaded;
                                                if ($scope.data.percentageUploaded >= 100) {
                                                    $scope.data.uploadCompleted = true;
                                                    $scope.data.statusText = "Processing Video..";
                                                    $scope.data.hasProgress = false;
                                                }
                                            }

                                            newUploadManager.upload(files, { url: '/files/upload-file-media', progress: progress, fields: {folder: state} }).then(function success(response) {
                                                $scope.data.uploadCompleted = true;
                                                $scope.data.posterUrl = response[0].uploadedUrl;
                                                $scope.data.statusText = $translate.instant('editor.completedText');
                                                $scope.data.shareId = response[0].shareId;
                                                $rootScope.$broadcast('imgeditor.completed');
                                            }, function failure(data) {
                                                if (typeof data === 'object' && data !== null) {
                                                    utils.showToast(data['file'][0]);
                                                } else {
                                                    utils.showToast(data);
                                                }
                                                $deferred.reject();
                                            }).finally(function() {
                                                $('#'+recordId).remove();
                                                $mdDialog.hide({ posterUrl: $scope.data.posterUrl, shareId: $scope.data.shareId });
                                            });
                                        }
                                    }).then(function(data) {
                                        $mdDialog.hide(data);
                                    }, function() {
                                        //handle error
                                    });
                                }

                                $scope.save = function() {
                                    $scope.data.loading = true;
                                    if ($scope.data.state == 'record') {
                                        return dialogForUploadVideo([$scope.recordedFile]);
                                    } else {
                                        api.file.duplicateMediaFile(state, $scope.data.shareId).then(function(data) {
                                            $mdDialog.hide({
                                                shareId: data.share_id,
                                                posterUrl: data.poster_url
                                            });
                                        }, function() {
                                            // handle failded case
                                        }).finally(function() {
                                            $scope.data.loading = false;
                                        });
                                    }
                                };

                                $scope.recordVideo = function()  {
                                    $('#'+recordId).remove();

                                    // Create a virtual input element.
                                    var input = document.createElement('input');
                                    input.type = 'file';
                                    input.id = recordId;
                                    input.accept = "video/*";
                                    input.setAttribute("record-file-upload", "recordedFile");
                                    $compile(input)($scope);
                                    document.body.appendChild(input);

                                    // Click on a virtual input element.
                                    input.click();
                                };

                                $scope.$watch('recordedFile', function(newFile, oldFile) {
                                    if (!oldFile && newFile) {
                                        $scope.data.recordFileText = utils.trans('uploadVideo.recordFileText', {name:  $scope.recordedFile.name});
                                        $scope.data.state = 'record';
                                    }
                                });

                                $scope.selectVideo = function()  {
                                    // $scope.data.state = 'select';

                                    // find an existing one and remove it.
                                    $('#'+recordId).remove();

                                    // Create a virtual input element.
                                    var input = document.createElement('input');
                                    input.type = 'file';
                                    input.id = recordId;
                                    input.accept = "video/*";

                                    input.onchange = function() {
                                        if (this.files && this.files[0]) {
                                            if (!this.files[0].size) {
                                                $deferred.reject();
                                                return;
                                            }

                                            var model = this;
                                            return dialogForUploadVideo(model.files);
                                        }
                                    };

                                    document.body.appendChild(input);

                                    // Click on a virtual input element.
                                    input.click();

                                    // return false;
                                };

                                $scope.browseVideo = function() {
                                    $scope.data.folder = null;
                                    $scope.data.breadcrumbs = [];
                                    $scope.getFolder('root');
                                    $scope.data.state = 'browse';
                                };

                                $scope.getFolder = function(shareId) {
                                    api.folder.getFolder(shareId).then(function (response) {
                                        var validFiles = [];

                                        response.folder.files.forEach(function (item) {
                                            if (item.mime.includes('video')) {
                                                validFiles.push(item);
                                            }
                                        });

                                        response.folder.files = validFiles;
                                        $scope.data.folder = response;

                                        var index = $scope.data.breadcrumbs.findIndex(
                                            function (element) {
                                                return (element.shareId == shareId);
                                            });

                                        if (index >= 0) {
                                            $scope.data.breadcrumbs.length = index;
                                        }

                                        if (shareId == 'root') {
                                            $scope.data.breadcrumbs = [{
                                                'name': response.folder.name,
                                                'shareId': response.folder.share_id
                                            }];
                                        }

                                        if ($scope.data.breadcrumbs[$scope.data.breadcrumbs.length - 1]['shareId'] != response.folder.share_id) {
                                            $scope.data.breadcrumbs.push({
                                                'name': response.folder.name,
                                                'shareId': response.folder.share_id
                                            });
                                        }
                                    });
                                };

                                $scope.backToMainMenu = function() {
                                    $scope.data.state = 'menu';
                                };

                                $scope.getMimeIcon = function(mime) {
                                    return fileTypes.getMimeIcon(mime);
                                };

                                $scope.getIsThumbnail = function(item) {
                                    return item.flags & 0x02 ? true : false;
                                };

                                $scope.getThumbnail = function(item) {
                                    return $rootScope.baseUrl+'user-file/'+item.share_id+'?thumb=1&t='+ $scope.data.time;
                                };

                                $scope.setUrl = function (item) {
                                    $scope.data.posterUrl = item.poster_url;
                                    $scope.data.shareId = item.share_id;
                                };
                            }]
                        }).then(function(data) {
                            rangy.restoreSelection(savedSelection);
                            var altText = data.shareId ? 'alt="VideoId:' + data.shareId + '" ' : '';
                            $editor().wrapSelection('insertHtml', '<img src="' + data.posterUrl+ '" ' + altText + '>', true);
                            $rootScope.$broadcast('imgeditor.completed');
                            $deferred.resolve();
                        }, function() {
                            rangy.restoreSelection(savedSelection);
                            $deferred.resolve();
                        });
                    // }

                    // return false;
                }
            });

            // taToolFunctions = taToolFunctions;
            taOptions.forceTextAngularSanitize = false;
            taOptions.keyMappings = [];
            taOptions.toolbar = [
                ['h1', 'h2', 'h3', 'p', 'pre', 'quote'],
                ['bold', 'italics', 'underline', 'ul', 'ol', 'redo', 'undo', 'clear'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['html', 'insertLink','uploadImage', 'addMediaViaStorage', 'uploadVideo']
            ];

            taOptions.classes = {
                focussed: '',
                toolbar: 'ta-toolbar',
                toolbarGroup: 'ta-button-group',
                toolbarButton: '',
                toolbarButtonActive: 'active',
                disabled: 'disabled',
                textEditor: 'ta-text-editor',
                htmlEditor: 'md-input'
            };

            return taOptions; // whatever you return will be the taOptions
        }]);

        $provide.decorator('taToolFunctions', ['$delegate', function($delegate) {

            // save original function to call it later
            var imgOnSelectActionFn = $delegate.imgOnSelectAction;

            // decorate imgOnSelectAction
            $delegate.imgOnSelectAction = function(event, $element, editorScope) {

                // call original function in which img toolbar is constructed
                imgOnSelectActionFn(event, $element, editorScope);

                var iconsForPopOver = [
                    { selectorClass: "fa-align-left" , iconClass: "format_align_left"},
                    { selectorClass: "fa-align-right" , iconClass: "format_align_right"},
                    { selectorClass: "fa-align-justify" , iconClass: "format_align_justify"},
                    { selectorClass: "fa-trash-o" , iconClass: "delete_outline"},
                ];

                iconsForPopOver.forEach(function(icon) {
                    editorScope.displayElements.popover.find('.'+icon.selectorClass)
                    .each(function() {
                        angular.element(this).addClass('material-icons').text(icon.iconClass);
                    });
                });
            };

            return $delegate;
        }]);

        $provide.decorator('taTools', ['$delegate', function (taTools) {
            taTools.h1.display = '<md-button type="button" type="button" aria-label="Heading 1">H1</md-button>';
            taTools.h2.display = '<md-button type="button" aria-label="Heading 2">H2</md-button>';
            taTools.h3.display = '<md-button type="button" aria-label="Heading 3">H3</md-button>';
            taTools.p.display = '<md-button type="button" aria-label="Paragraph">P</md-button>';
            taTools.pre.display = '<md-button type="button" aria-label="Pre">pre</md-button>';
            taTools.quote.display = '<md-button class="md-icon-button" type="button" aria-label="Quote"><md-icon md-font-set="material-icons">format_quote</md-icon></md-button>';
            taTools.bold.display = '<md-button class="md-icon-button" type="button" aria-label="Bold"><md-icon md-font-set="material-icons">format_bold</md-icon></md-button>';
            taTools.italics.display = '<md-button class="md-icon-button" type="button" aria-label="Italic"><md-icon md-font-set="material-icons">format_italic</md-icon></md-button>';
            taTools.underline.display = '<md-button class="md-icon-button" type="button" aria-label="Underline"><md-icon md-font-set="material-icons">format_underlined</md-icon></md-button>';
            taTools.ul.display = '<md-button class="md-icon-button" type="button" aria-label="Buletted list"><md-icon md-font-set="material-icons">format_list_bulleted</md-icon></md-button>';
            taTools.ol.display = '<md-button class="md-icon-button" type="button" aria-label="Numbered list"><md-icon md-font-set="material-icons">format_list_numbered</md-icon></md-button>';
            taTools.undo.display = '<md-button class="md-icon-button" type="button" aria-label="Undo"><md-icon md-font-set="material-icons">undo</md-icon></md-button>';
            taTools.redo.display = '<md-button class="md-icon-button" type="button" aria-label="Redo"><md-icon md-font-set="material-icons">redo</md-icon></md-button>';
            taTools.justifyLeft.display = '<md-button class="md-icon-button" type="button" aria-label="Align left"><md-icon md-font-set="material-icons">format_align_left</md-icon></md-button>';
            taTools.justifyRight.display = '<md-button class="md-icon-button" type="button" aria-label="Align right"><md-icon md-font-set="material-icons">format_align_right</md-icon></md-button>';
            taTools.justifyCenter.display = '<md-button class="md-icon-button" type="button" aria-label="Align center"><md-icon md-font-set="material-icons">format_align_center</md-icon></md-button>';
            taTools.justifyFull.display = '<md-button class="md-icon-button" type="button" aria-label="Justify"><md-icon md-font-set="material-icons">format_align_justify</md-icon></md-button>';
            taTools.clear.display = '<md-button class="md-icon-button" type="button" aria-label="Clear formatting"><md-icon md-font-set="material-icons">format_clear</md-icon></md-button>';
            taTools.html.display = '<md-button class="md-icon-button" type="button" aria-label="Show HTML"><md-icon md-font-set="material-icons">code</md-icon></md-button>';
            taTools.insertLink.display = '<md-button class="md-icon-button" type="button" aria-label="Insert link"><md-icon md-font-set="material-icons">insert_link</md-icon></md-button>';
            taTools.uploadImage.display = '<md-button class="md-icon-button" type="button" aria-label="Upload photo"><md-icon md-font-set="material-icons">add_photo_alternate</md-icon></md-button>';
            taTools.addMediaViaStorage.display = '<md-button class="md-icon-button" type="button" aria-label="Add media from storage"><md-icon md-font-set="material-icons">add_to_photos</md-icon></md-button>';
            taTools.uploadVideo.display = '<md-button class="md-icon-button" type="button" aria-label="Upload a video"><md-icon md-font-set="material-icons">video_library</md-icon></md-button>';
            return taTools;
        }]);
    }])
    .filter('capitalize', function () {
        return function (input) {
            return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
        };

    })
    .filter('phonenumber', function () {
        /*
        Format phonenumber as: (xxx) xxx-xxxx
            or as close as possible if phonenumber length is not 10
        */

        return function (number) {
            /*
            @param {Number | String} number - Number that will be formatted as telephone number
            Returns formatted number: (###) ###-####
                if number.length < 4: ###
                else if number.length < 7: (###) ###

            Does not handle country codes that are not '1' (USA)
            */
            if (!number) { return '-'; }


            number = String(number);

            // Will return formattedNumber.
            // If phonenumber isn't longer than an area code, just show number
            var formattedNumber = number;

            // (###) ###-#### as (area) front-end
            var area = number.substring(0,3);
            var front = number.substring(3, 6);
            var end = number.substring(6, 10);

            if (front) {
                formattedNumber = ("(" + area + ") " + front);
            }
            if (end) {
                formattedNumber += ("-" + end);
            }
            return formattedNumber;
        };
    })
    .run(['$rootScope', '$state', 'users', 'utils', '$location', '$http', '$stateParams', '$translate', 'dialogManager', '$window', function($rootScope, $state, users, utils, $location, $http, $stateParams, $translate, dialogManager, $window) {

        //set base url
        $rootScope.baseUrl = vars.baseUrl + '/';

        //see if we're running in a demo env
        // utils.isDemo = parseInt(vars.isDemo);

        //set laravel token
        // $rootScope.token = vars.token;

        //set current user
        users.assignCurrentUser(vars.user ? JSON.parse(vars.user) : false);
        if(vars.user)
        {
            $rootScope.user_id = JSON.parse(vars.user).id;
        }

        //load settings
        utils.setAllSettings(vars.settings);
        utils.version = vars.version;
        utils.environment = vars.environment;

        //remove vars script node and delete vars object from window.
        $('#vars').remove();
        // delete window.vars; // this fails in Chrome, now that strict mode is functionalized?
        // this is another minor comment, to test the deployment scripts.
        try {
            delete window.vars;
        } catch(e) {
            window["vars"] = undefined;
        }

        //set moment locale
        moment.locale(utils.getSetting('dateLocale', 'en'));

        //see if folder upload is supported
        var input = document.createElement('input');
        input.type="file";
        utils.folderUploadSupported = 'webkitdirectory' in input;

        var docWidth = $(document).width();
        $rootScope.isSmallScreen = docWidth <= 768;
        $rootScope.isTablet      = docWidth <= 1200 && docWidth > 768;

        var statesThatNeedAuth = ['dashboard', 'admin'];
        var onlyOrganizationOwnerState = ['organization'];
        var adminSharedStates = ['organizationJoinRequests', 'joinCodes'];

        // hack to fix the sticky header on the home page.
        $rootScope.$on('$stateChangeSuccess', function(e, toState, params) {
            if(toState.name=='home' || toState.name=='new_home') {
                $('body').addClass('vertical-scrollable');
            } else {
                $('body').removeClass('vertical-scrollable');
            }
        });

        $rootScope.$on('$stateChangeStart', function(e, toState, params) {

            //extract parent state name if it's a child state
            var stateName = toState.name.replace(/\..+?$/, '');

            if (!$state.get(stateName)) {
                e.preventDefault();
            }

            //check if user can access this state
            if (statesThatNeedAuth.indexOf(stateName) > -1 && ! users.current) {
                e.preventDefault();
                if (toState.name === 'dashboard.foldersRoot') {
                    $state.go('login', { nextState: toState.name, additionalParams: params });
                } else {
                    $state.go('login');
                }
            }

            //logged in users can't access login or register state
            if ((stateName == 'login' || stateName == 'register' || stateName == 'home') && users.current) {
                e.preventDefault();
                $state.go('dashboard.story');
                // if($rootScope.share_refer) {
                //     $http.post($rootScope.baseUrl + 'shareable/add-non-user', {share_refer: $rootScope.share_refer}).then(function(response) {
                        // $state.go('dashboard.folders');
                //     });
                // }
            }

            //if registration is disabled redirect to login state
            if (stateName == 'register' && ! utils.getSetting('enableRegistration')) {
                e.preventDefault();
                $state.go('login');
            }

            if (stateName == 'admin') {
                if (! users.current || (! users.current.isAdmin && ! users.current.isOrgOwner)) {
                    e.preventDefault();
                    $state.go('dashboard.story');
                }

                var childNames = toState.name.split('.');
                if (childNames.length > 1 && users.current.isOrgOwner) {
                    if (onlyOrganizationOwnerState.indexOf(childNames[1]) == -1 && adminSharedStates.indexOf(childNames[1]) == -1) {
                        e.preventDefault();
                        $state.go('admin.organization');
                    }
                } else if (childNames.length > 1 && users.current.isAdmin) {
                    if (onlyOrganizationOwnerState.indexOf(childNames[1]) > -1) {
                        e.preventDefault();
                        $state.go('admin.analytics');
                    }
                }
            }

            // now determine the document title.
            var title = utils.getSetting('siteName');

            //on of the album state
            if (params.folderName) {
                title = params.folderName+' - '+utils.getSetting('siteName');

            //main dashboard page
            } else if (toState.name.indexOf('folders') > -1) {
                title = $translate.instant('dashboard') + ' - ' + utils.getSetting('siteName');

            //view photo or album page
            } else if (toState.name === 'view') {
                title = params.name + ' - ' + utils.getSetting('siteName');

            } else if (toState.name === 'editor') {
                title = utils.trans('photoEditor') + ' - ' + utils.getSetting('siteName');

            //everything else
            } else {
                title = utils.getSetting('siteName')  + ' - ' + $translate.instant(toState.name.split('.')[1] || toState.name+'Title');
            }

            document.title = title;

            if (utils.getSetting('analytics')) {
                // initialise google analytics
                $window.ga('create', utils.getSetting('analytics'), 'auto');

                // for localhost
                // $window.ga('create', utils.getSetting('analytics'),  {'cookieDomain': 'none'});

                // track pageview on state change
                $rootScope.$on('$stateChangeSuccess', function (event) {
                    if (!$window.ga) {
                        return;
                    }

                    $window.ga('send', 'pageview', {
                        page: $location.path()
                    });
                });
            }

        });
    }]);
}());
