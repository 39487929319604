angular.module('app')

.factory('files', ['$rootScope', '$http', '$mdDialog', 'folders', 'utils', '$stateParams', 'dialogManager', 'api', function($rootScope, $http, $mdDialog, folders, utils, $stateParams, dialogManager, api) {
    var files = {

        /**
         * Fetch a photo with given id from server.
         *
         * @param id
         * @returns promise
         */
        get: function(id) {
            return $http.get($rootScope.baseUrl+'files/'+id);
        },

        /**
         * Fetch all files from the server.
         *
         * @param {boolean} all
         * @param {object} params
         * @returns promise
         */
        getAll: function(all, params) {
            return $http.get($rootScope.baseUrl+'files?all='+all, {params: params});
        },

        /**
         * Attach files with given ids (attach_id) to currently logged in user.
         *
         * @param {array} ids
         * @returns {promise}
         */
        attachToUser: function(ids) {
            return $http.post($rootScope.baseUrl + 'photo/attach-to-user', {ids: ids}).then(function(response) {
                if (response && response.data) {
                    utils.showToast(response.data);
                }
            });
        },

        rename: function(id, file_name) {
            return $http.put($rootScope.baseUrl + 'files/' + id, { name: file_name });
        },

        /**
         * Move items to a new folder.
         *
         * @param {array|object} items
         * @param {int}   folderId
         */
        moveToFolder: function(items, folderId) {
            items = items.reduce(function(items, item) {
                // Filterout items that are already in specified folder
                if (item.folder_id == folderId) { return items; }

                // Filterout items that are canceled by user
                if (item.action && item.action == 'skip') { return items; }

                return items.concat([{ type: item.type, id: item.id, name: item.name, action: item.action }]);

            }, []);

            if (!items.length) {
                // TODO: translation
                return utils.showToast('nothingToMove', true);
            }

            api.items.move(items, folderId).then(function(data) {
              folders.available = data.folders;
              
              //delete moved files from their current folder
              if (folders.selected && folders.selected.files && data.notMoved.length == 0) {
                  folders.selected.files = folders.selected.files.filter(function(file) {
                      return !items.find(function(item) {
                          return item.id == file.id && item.type == 'file';
                      });
                  });
              }

              files.closeModal(); // FIXME: Remove this!
              if(data.notMoved.length) {
                  files.openMoveItemsModal(items, data.notMoved, folderId);
              } else {
                  $rootScope.$broadcast('activity.happened', 'moved', 'items', items);
                  var destinationFolder = folders.getById(folderId);
                  return utils.showToast(utils.trans('movedItems', { number:  items.length, folder: destinationFolder.name }));
              }
            }, function(data) {
              utils.showToast(data);
            });
        },

        openMoveItemsModal: function(items, notMoved, folderId) {
            dialogManager.showMoveFileDialog(items, notMoved, folderId);
        },

        /**
         * Open modal for renaming selected photo.
         *
         * @param {string} name
         */
        openRenameModal: function(name) {
            dialogManager.showRenameFileDialog(name).then(function(data) {
                $rootScope.$broadcast('activity.happened', 'renamed', 'photo', data);
            }, function() {
                // error already displayed
            });
        },


        /**
         * Open modal for creating a new docs.
         *
         * @param {object} $event
         */
        openNewDocwModal: function($event, id) {
            dialogManager.showNewDocDialog($event, id).then(function(data) {
                $rootScope.$broadcast('activity.happened', 'created', 'file', data);
            }, function(){
                // failed;
            });
        },

        closeModal: function() {
            $mdDialog.hide();
            this.fileNameModel = false;
        },
    };

    return files;
}]);