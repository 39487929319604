(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminFooterMenu', ['utils', '$rootScope', 'dialogManager', 'api', function(utils, $rootScope, dialogManager, api) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/footermenu.html',
            scope: {},
            link: function($scope, $element, $attr) {
                $scope.data = {
                    items: [],
                    selected: [],
                    loading: false,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    }
                };

                $scope.toggleAllItems = function() {
                    $scope.data.selected = $scope.data.items;
                };

                $scope.toggleSelect = function(item) {
                    var index = $scope.data.selected.indexOf(item);
                    if(index==-1) {
                        $scope.data.selected.push(item);
                    } else {
                        // remove it.
                        $scope.data.selected.splice(index, 1);
                    }
                };

                $scope.isItemSelected = function(item) {
                    return ($scope.data.selected.indexOf(item) !== -1);
                };

                $scope.showEditItemModal = function($event, item) {
                    $event.preventDefault();

                    dialogManager.showEditFooterMenuDialog($event, item).then(function(data) {
                        $scope.data.items = api.resources.footerMenu.query();
                        utils.showToast('footerMenuUpdated', true);
                    }, function() {
                        //error case
                    });
                };
                                
                $scope.toggleActive = function(item) {
                    api.footermenu.updateMenuItem(item);
                };

                $scope.deleteItems = function(items) {
                    // remove them all.
                    angular.forEach(items, function(item) {
                        var index;

                        // remove from selected
                        index = $scope.data.selected.indexOf(item);
                        if(index != -1) {
                            $scope.data.selected.splice(index, 1);
                        }

                        // remove from normal
                        index = $scope.data.items.indexOf(item);
                        if(index != -1) {
                            $scope.data.items.splice(index, 1);
                        }

                        // delete.
                        item.$delete(); // doesn't work cause route isn't setup.
                    });
                };

                $scope.data.items = api.resources.footerMenu.query();
            }
       	};
    }]);
}());