(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminOrganizations', ['utils', '$rootScope', 'dialogManager', 'api', '$q', '$location', function(utils, $rootScope, dialogManager, api, $q, $location) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/organizations.html',
            scope: {},
            link: function($scope, $element, $attr) {
                var requests = ['Approved', 'Pending', 'Rejected', 'Not Submitted'];
                $scope.data = {
                    items: [],
                    selected: [],
                    loading: false,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    },
                    filterType: 'Show',
                    requests: requests,
                    requestType: requests[0],
                    reactivating: false,
                    startDate: null,
                    endDate: null,
                    showDateFilterBtn: false,
                };

                $scope.filterByDates = function() {
                    if ($scope.data.startDate && $scope.data.endDate) {
                        $scope.data.showDateFilterBtn = true;
                        $scope.loadOrgs();
                    }
                };

                $scope.clearDateFilter = function() {
                    $scope.data.startDate = null;
                    $scope.data.endDate = null;
                    $scope.data.showDateFilterBtn = false;
                    $scope.loadOrgs();
                };

                $scope.toggleAllItems = function() {
                    //all items already selected, deselect all
                    if ($scope.data.selected.length === $scope.data.items.length) {
                        $scope.data.selected = [];
                    }

                    //all items arent selected, copy all items array to selected items
                    else {
                        $scope.data.selected = $scope.data.items.slice();
                    }
                };

                function isAllSelectedItemsApprovable() {
                    var approvableItems = true;
                    $scope.data.selected.forEach(function(item) {
                        if (!item.per_user_charge) {
                            approvableItems = false;
                        }
                    });
                    return approvableItems;
                }

                function getMissingOrgsName() {
                    var orgNames = [];
                    $scope.data.selected.forEach(function(item) {
                        if (!item.per_user_charge) {
                            orgNames.push(item.name);
                        }
                    });

                    var numberOfOrgs = orgNames.length;

                    if (numberOfOrgs > 1) {
                        var needsOxfordComma = numberOfOrgs > 2;
                        var lastOrgConjunction = (needsOxfordComma ? ',' : '') + ' and ';
                        var lastOrg = lastOrgConjunction + orgNames[orgNames.length - 1];
                        return orgNames.slice(0, -1).join(', ') + lastOrg;
                    }
                    return orgNames.join('');
                }

                function removeSelectedItems() {
                    $scope.data.selected.forEach(function(item) {
                        if (!item.per_user_charge) {
                            $scope.toggleSelect(item);
                        }
                    });
                }

                $scope.approveItems = function() {
                    if (isAllSelectedItemsApprovable()) {
                        callApproveItems();
                    } else {
                        var missingOrgsName = getMissingOrgsName();
                        utils.confirm({
                            title: 'organization.cantApproveTitle',
                            content: utils.trans('organization.cantApproveText', { orgs: missingOrgsName }),
                            ok: 'organization.proceed',
                            cancel: 'cancel',
                            onConfirm: function() {
                                removeSelectedItems();
                                callApproveItems();
                            }
                        });
                    }
                };

                function callApproveItems() {
                    if ($scope.data.selected.length) {
                        api.organization.approve(getSelectedItemIds()).then(function(response) {
                            removeItemsFromTable();
                            utils.showToast(utils.trans(response, true));
                        }, function() {
                            // handle failed case
                        });
                    }
                }

                function removeItemsFromTable() {
                    $scope.data.selected.forEach(function(item) {
                        var index;

                        // remove from normal
                        index = $scope.data.items.indexOf(item);
                        if(index != -1) {
                            $scope.data.items.splice(index, 1);
                        }
                    });

                    $scope.data.selected = [];
                }

                function getSelectedItemIds() {
                    return $scope.data.selected.map(function(item) {
                        return item.id;
                    });
                }

                $scope.rejectItems = function() {
                    api.organization.reject(getSelectedItemIds()).then(function(response) {
                        utils.showToast(utils.trans(response, true));
                        removeItemsFromTable();
                    }, function() {
                        // handle failed case
                    });
                };

                $scope.toggleSelect = function(item) {
                    var index = $scope.data.selected.indexOf(item);
                    if(index==-1) {
                        $scope.data.selected.push(item);
                    } else {
                        // remove it.
                        $scope.data.selected.splice(index, 1);
                    }
                };

                $scope.isItemSelected = function(item) {
                    return ($scope.data.selected.indexOf(item) !== -1);
                };

                $scope.showEditItemModal = function($event, item) {
                    $event.preventDefault();
                    dialogManager.showEditOrganizationsDialog($event, item).then(function(data, email) {
                        var itemUpdating;
                        var is_new = true;
                        if(data.item) {
                            itemUpdating = data.item;
                            is_new = false;
                            if (itemUpdating && !itemUpdating.owner_id) {
                                delete itemUpdating.owner_id;
                            }
                        } else {
                            itemUpdating = new api.resources.organization();
                        }

                        // update the fields.
                        itemUpdating.name = data.name;
                        itemUpdating.website_url = data.website_url;
                        itemUpdating.logo_url = data.logo_url;
                        itemUpdating.address_1 = data.address_1;
                        itemUpdating.address_2 = data.address_2;
                        itemUpdating.zip = data.zip;
                        itemUpdating.phone = data.phone;
                        itemUpdating.state = data.state;
                        itemUpdating.city = data.city;
                        itemUpdating.is_parent = data.is_parent;
                        itemUpdating.contact_first_name = data.contact_first_name;
                        itemUpdating.contact_last_name = data.contact_last_name;
                        itemUpdating.contact_email = data.contact_email;
                        itemUpdating.contact_phone = data.contact_phone;
                        itemUpdating.per_user_charge = data.per_user_charge;
                        itemUpdating.is_nonprofit = data.is_nonprofit;
                        itemUpdating.owner_id = data.owner.id;

                        if(data.item) {
                            // update.

                            itemUpdating.$update().then(function(response) {
                                utils.showToast(utils.trans('organization.updatedSuccessfully'));
                                item.owner_name = response.owner_name;
                            }, function(response) {
                                utils.showToast(utils.trans(response.data));
                            });
                        } else {
                            // new save
                            itemUpdating.$save().then(function(response) {
                                $scope.data.items.push(response);
                                utils.showToast(utils.trans('organization.addedSuccessfully'));
                            }, function(response) {
                                utils.showToast(utils.trans(response.data));
                            });
                        }
                    }, function() {
                        // handle error case
                    });
                };

                $scope.deleteItems = function(items) {
                    // remove them all.
                    angular.forEach(items, function(item) {
                        var index;

                        index = $scope.data.items.indexOf(item);
                        // delete.
                        if ($scope.data.filterType.toLowerCase() == 'show') {
                            // remove from normal
                            if(index != -1) {
                                $scope.data.items.splice(index, 1);
                            }
                            item.$delete();
                        } else {
                            var itemOld = angular.copy(item);
                            itemOld.deleted_at = new Date();
                            item.$delete();
                            $scope.data.items[index] = angular.copy(itemOld);
                        }
                    });

                    $scope.data.selected = [];
                };

                $scope.loadOrgs = function() {
                    var qs = $location.search();
                    if (qs['filter_type'] == 'pending') {
                        $scope.data.requestType = requests[1];
                    }

                    var data = {};
                    data['type'] = $scope.data.requestType.toLowerCase().replace(" ", "");
                    data['filter'] = $scope.data.filterType.toLowerCase();

                    if ($scope.data.endDate && $scope.data.startDate) {
                        data['start-date'] = $scope.data.startDate;
                        data['end-date'] = $scope.data.endDate;
                    }

                    api.resources.organization.query(data, function(data) {
                        $scope.data.items = data;
                    }, function() {
                        // handle error cases
                    });
                };

                $scope.loadOrgs();

                $scope.reactivateOrganization = function(item) {
                    if ($scope.data.reactivating) {
                        return;
                    }
                    $scope.data.reactivating = true;
                    api.organization.reactivate(item.id).then(function(data) {
                        item.deleted_at = null;
                        $scope.data.reactivating = false;
                    }, function(response) {
                        utils.showToast(utils.trans(response.data));
                        $scope.data.reactivating = false;
                    });
                };
            }
        };
    }]);
}());