(function(){
    'use strict';

    angular.module('app').controller('QuestionsController', ['$scope', '$rootScope', '$state', '$mdDialog', 'utils', 'users', 'questions', 'dialogManager', function($scope, $rootScope, $state, $mdDialog, utils, users, questions, dialogManager) {

        //Questions search query
        $scope.search = { query: '' };

        $scope.selectedItems = [];

        $scope.isItemSelected = function(item) { 
            return $scope.selectedItems.indexOf(item) > -1;
        };

        $scope.select = function(item) {
            var idx = $scope.selectedItems.indexOf(item);
            if (idx > -1) { 
                $scope.selectedItems.splice(idx, 1);
            }
            else { 
                $scope.selectedItems.push(item);
            }
        };

        $scope.deleteQuestions = function() {

            questions.delete($scope.selectedItems).then(function() {
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        $scope.toggleAllQuestions = function() {

            //all items already selected, deselect all
            if ($scope.selectedItems.length === questions.all.length) {
                $scope.selectedItems = [];
            }

            //all items aren't selected, copy all users array to selected items
            else {
                $scope.selectedItems = questions.all.slice();
            }
        };

        $scope.showCreateQuestionModal = function($event) {
            dialogManager.showCreateQuestionDialog($event).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.showUpdateQuestionModal = function(question, $event) {
            dialogManager.showUpdateQuestionDialog($event, question).then(function() {
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });            
        };

        // $scope.updateQuestion = function() {

        //     questions.updateQuestion($scope.questionModel, $scope.questionModel.id).then(function() {
        //         $mdDialog.hide();
        //         utils.showToast('updatedQuestionSuccessfully', true);
        //         $scope.paginate($scope.params);
        //         $scope.selectedItems = [];
        //     }, function(response) {
        //         $scope.setErrors(response.data);
        //     });
        // };

        $scope.changeState = function(question) {

            questions.toggleQuestion(question.active, question.id).then(function() {
                utils.showToast('updatedQuestionSuccessfully', true);
                $scope.paginate($scope.params);
                $scope.selectedItems = [];
            }, function(response) {
                $scope.setErrors(response.data);
            });
        };

        // $scope.createNewQuestion = function() {

        //     questions.create($scope.questionModel).then(function() {
        //         $mdDialog.hide();
        //         utils.showToast('createdQuestionSuccessfully', true);
        //         $scope.paginate($scope.params);
        //         $scope.errors = [];
        //     }, function(response) {
        //         $scope.setErrors(response.data);
        //     });
        // };

        $scope.paginate = function(params) {
            if ($scope.usersAjaxInProgress) {
                return;
            }

            $scope.usersAjaxInProgress = true;

            questions.paginate(params).then(function(response) {
                $scope.items = response.data.data;
                $scope.totalItems = response.data.total;

                //clear selected values
                $scope.selectedItems = [];
                $scope.usersAjaxInProgress = false;
            });
        };

        $scope.paginate($scope.params);
    }]);
}());