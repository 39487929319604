angular.module('app')
.factory('folders', ['$rootScope', '$http', '$mdDialog', '$stateParams', '$state', 'utils', 'dashboardState', 'dialogManager', '$q', 'localStorage', function($rootScope, $http, $mdDialog, $stateParams, $state, utils, dashboardState, dialogManager, $q, localStorage) {

	function broadcastFolderChange(oldSelection, newSelection, forced) {
		if (forced || !oldSelection && newSelection || oldSelection && !newSelection || oldSelection.id != newSelection.id) {
			$rootScope.$broadcast('folders.selected.changed', { old: oldSelection, new: newSelection });
		}
	}
	// Files pagin on storage
	$rootScope.ajaxProgress = {};
	$rootScope.ajaxProgress.currentFolderFiles = [];

	var folders = {

		//model for renaming/create new folder
		folderNameModel: {},

		//all folders user has created
		available: [],

		//currently selected folder
		selected: false,

		// current breadcrumbs
		breadcrumbs: false,

		folderKey: null,

		// shared ones
		// shared: [],

		page:1,

		/**
		 * Get all folders current user has access to.
		 *
		 * @returns {Promise}
		 */
		getAll: function(all) {
		 	return $http.get('folders?all='+all).then(function(response) {
		 		folders.available = response.data;
		 		folders.bindEvents();
		 		$rootScope.$broadcast('folders.loaded');
		 	});
		},


		/**
		 * Get all folders current user has access to from localstorage
		 *
		 *
		 */
		getFromCache: function() {
			folders.available = folders.getAvailableFolders();
			folders.bindEvents();

			// In case root was missing
			if (!folders.getRoot()) {
				folders.getAll();
			}
		},

		/**
		 * Open folder with share ID (was name; but won't work with multiple levels).
		 *
		 * @param {string} name
		 */
		open: function (shareId) {
			if (!shareId) { return; }
		 	$state.go('dashboard.folders', { folderId: shareId });
		},

		createNewFolderWithParams: function(name, parent, classtype, flags) {
			return $q(function(resolve, reject) {
				var data = {
					name: name,
					parent: parent
				};

				if(classtype !== undefined) {
					data.classtype = classtype;
				}

				if(flags !== undefined) {
					data.flags = flags;
				}

				return $http.post('folders', data).then(function(response) {
					folders.available.push(response.data);
					$rootScope.$broadcast('activity.happened', 'created', 'folder', response.data);

					resolve(response.data);
				}, function(response) {
					reject(response);
				});
			});
		},
		/**
		 * Return a file matching given id from selected folder files.
		 *
		 * @param   {string|int} id
		 * @returns {*}
		 */
		getFileById: function(id) {
		 	if (! this.selected || ! this.selected.files) { return; }

		 	for (var i = 0; i < this.selected.files.length; i++) {
		 		if (this.selected.files[i].id == id) {
		 			return this.selected.files[i];
		 		}
		 	}
		},

		/**
		 * Rename folder matching given name.
		 *
		 * @param {string} name
		 */
		rename: function(folder, payload) {
		 	if (folder && folder.name !== 'root') {
		 		return $http.put($rootScope.baseUrl+'folders/'+folder.id, payload);
		 	}
		},

		/**
		 * Open modal for sharing selected folder.
		 *
		 * @param {object} $event
		 */
		openShareModal: function($event) {
            dialogManager.showShareDialog($event, this.selected);
		},

		/**
		 * Open modal for creating a new folder. (this and method below are poor; should be unified, and not use globals.)
		 *
		 * @param {object} $event
		 */
		openNewModal: function($event) {
			dialogManager.showNewFolderDialog($event, this.selected).then(function(shareId) {
				folders.open(shareId);
			}, function(){
				//failed case
			});
		},

		/**
		 * Open modal for renaming a folder.
		 *
		 * @param {string} name
		 * @param {object} $event
		 */
		openRenameModal: function(id) {
		 	var folder = this.getById(id);

		 	if(folder !== undefined) {
				dialogManager.showRenameFolderDialog(folder).then(function(folder_updated){
					for (var i = 0; i < folders.available.length; i++) {
                      if (folder_updated.id == folders.available[i].id) {
                        folders.available[i] = folder_updated;

                        if (folders.selected.id == folder_updated.id) {
                          folders.selectByShareId(folder_updated.share_id);
                        }
                        break;
                      }
                    }

                    $rootScope.$broadcast('activity.happened', 'renamed', 'folder', folder_updated);
				}, function(){
					// cancelled
				});
		 	}
		},

		closeModal: function() {
		 	folders.folderNameModel = {};
		 	$mdDialog.hide();
		},

		redirectToHome: function () {
		 	$mdDialog.hide();
		 	$state.go('dashboard.folders', {folderId: ''});
		},

		/**
		 * Return whether or not we have any folders open
		 * and if we are in folders state.
		 *
		 * @returns {*|boolean}
		 */
		anyOpen: function() {
		 	return (this.selected && this.selected.name && this.selected.name !== 'root') || $state.current.name.indexOf('albums') === -1;
		},

		/**
		 * Open initial folder based on state params and bind to state change event.
		 */
		bindEvents: function() {
		 	if (this.eventsBound) { return; }
		 	var unbind = $rootScope.$on('folders.selected.changed', function() {
		 		dashboardState.loaded = true; // this is the "initial load" and it's not really clean.
		 		unbind();
		 	});

			if ($state.current.name.indexOf('folders') > -1) {
				this.selectByShareId($stateParams.folderId || 'root', false, false, true);
			}

			this.unbindStateChangeEvent = $rootScope.$on('$stateChangeStart', function(e, toState, params) {
				if (toState.name.indexOf('folders') > -1) {
				  	folders.selectByShareId(params.folderId || 'root', true);
				}
			});

			this.eventsBound = true;
		},

		/**
		 * Get the root folder.
		 *
		 * @returns {object|void}
		 */
		getRoot: function() {
		 	for (var i = 0; i < this.available.length; i++) {
		 		if (this.available[i].name === 'root' && this.available[i].folder_id === null) {
		 			return this.available[i];
		 		}
		 	}
		},

		/**
		 * Get a folder matching given name.
		 *
		 * @param {string} name
		 *
		 * @returns {object|void}
		 */
		getByName: function(name) {
		 	if ( ! name) { return; }

		 	for (var i = 0; i < this.available.length; i++) {
		 		if (this.available[i].name === name) {
		 			return this.available[i];
		 		}
		 	}
		},

		/**
		 * Get a folder matching given classtype.
		 *
		 * @param {string} classtype
		 *
		 * @returns {object|void}
		 */
		getByClasstype: function(classtype) {
		 	if (!classtype) {
		 		return;
		 	}

		 	for (var i = 0; i < this.available.length; i++) {
		 		if (this.available[i].class_type === classtype) {
		 			return this.available[i];
		 		}
		 	}
		},

		updateBreadcrumbs: function(defaultBreadcrumbs) {
		 	defaultBreadcrumbs = defaultBreadcrumbs || null;
		 	if(this.selected) {
		 		var runningPath = '';
		 		var parts;
		 		var result = [];

		 		if(this.selected.path==null) {
		 			parts = [ 'root' ];
		 		} else {
		 			parts = this.selected.path.split('/');
		 		}
		 		for(var index = 0; index<parts.length; index++) {
		 			runningPath += ((index>0)? '/' : '') +parts[index];
					var folder = this.folderByPath(runningPath);
		 			result.push({
						label: index==0 ? '' : parts[index],
		 				shareId: folder.share_id
		 			});
		 		}
		 		this.breadcrumbs = result;

		 	} else if (this.hasDefaultBreadCrumbs()) {
		 		this.breadcrumbs = this.getDefaultBreadCrumbs();
		 	} else {
		 		this.breadcrumbs = false;
		 	}
		},

		hasDefaultBreadCrumbs: function() {
			if (["dashboard.trash", "dashboard.recent", "dashboard.favorites", "dashboard.search"].indexOf($state.current.name) > -1) {
		 		return true;
		 	}
		 	return false;
		},

		getDefaultBreadCrumbs: function() {
		 	if ($state.current.name == "dashboard.trash") {
		 		return [{label: 'Trash', shareId: null, icon: 'icon-trash'}];
		 	} else if ($state.current.name == "dashboard.recent") {
		 		return [{label: 'Recent', shareId: null, icon: 'icon-clock'}];
		 	} else if ($state.current.name == "dashboard.favorites") {
				return [{label: 'Favorites', shareId: null, icon: 'icon-star-filled'}];
			} else if ($state.current.name == "dashboard.search") {
				return [{label: 'Search results for "' + $stateParams.query + '"', shareId: null, icon: 'icon-search'}];
			}
		},

		folderByPath: function(path) {
		 	var result;
		 	this.available.forEach(function(item) {
		 		if(item.path==path || (item.path==null && path=='root')) {
		 			result = item;
		 		}
		 	});
		 	return result;
		},

		clearSelected: function() {
			var oldSelection = folders.selected;
			folders.selected = false;
			broadcastFolderChange(oldSelection, folders.selected);
		},

		newOffersAvailable: function() {
			var offerFolder = folders.getByClasstype('special-offers');
			if (offerFolder) {
				offerFolder.class_type = "special-offers-new";
			}
		},

		refreshFiles: function () {
			this.selectByShareId($stateParams.folderId || 'root', true);
		},

		getSelected: function () {
			if (this.selectionPromise && folders.selected) {
				return this.selectionPromise;
			} else if ($stateParams.folderId) {
				return this.selectByShareId($stateParams.folderId, true, false , true);
			} else {
				return this.selectByShareId('root');
			}
		},

		selectByShareId: function(shareId, force, deselect, first_load) {
			var forced = force || false;
			var deselected = deselect || false;
			var folder = this.getByShareId(shareId);
			var subFolders = folder ? folders.getSubFolders(folder.id) : [];
			var folder_loaded = first_load || false;

			if(folder_loaded || forced){
				folders.page = 1;
				$rootScope.ajaxProgress.currentFolderFiles = [];
			}
			if (shareId && subFolders.length == 0) {
				folder_loaded = true;
				forced = true;
			}
			this.selectionPromise = $q(function(resolve, reject) {
				var oldSelection = folders.selected;

				if (subFolders.length) {
					folders.selected = folder;

					if (!(utils.stateIs('dashboard.recent')|| utils.stateIs('dashboard.favorites') || utils.stateIs('dashboard.trash') || utils.stateIs('dashboard.search')) && !deselected ) {
						folders.updateBreadcrumbs();
					} else {
						folders.selected = false;
					}

					$rootScope.ajaxProgress.files = false;
					resolve({folder_loaded: folder_loaded, new: folders.selected});
				}
				$rootScope.ajaxProgress.autoLoadMore = false;
				$http.get($rootScope.baseUrl+'folders/'+encodeURIComponent(shareId)+'?page='+folders.page).then(function(response) {
					response.data.folder.files = response.data.folder.files.concat(response.data.sharedFiles);
					for(var ff = 0; ff < response.data.folder.files.length; ff++) {
						$rootScope.ajaxProgress.currentFolderFiles.push(response.data.folder.files[ff]);
					}
					response.data.folder.files = $rootScope.ajaxProgress.currentFolderFiles = folders.mergeFile($rootScope.ajaxProgress.currentFolderFiles);
					if (response.data.newOffers) {
						folders.newOffersAvailable();
					}
					
					folders.selected = folders.set(response.data.folder) ? response.data.folder : folders.add(response.data.folder);
					
					folders.page = (parseInt(response.data.folder.page) + 1);
					$rootScope.ajaxProgress.currentSharedID = response.data.folder.share_id;
					$rootScope.ajaxProgress.totalItems = response.data.folder.totalFiles;
					$rootScope.ajaxProgress.totalSubFolders = response.data.subFolders.length;
					if (response.data.subFolders.length) {
						for (var i = 0; i < response.data.subFolders.length; i++) {
							if (!folders.set(response.data.subFolders[i])) {
								folders.add(response.data.subFolders[i]);
							}
						}
					}
					if (!(utils.stateIs('dashboard.recent')|| utils.stateIs('dashboard.favorites') || utils.stateIs('dashboard.trash') || utils.stateIs('dashboard.search')) && !deselected ) {
						folders.updateBreadcrumbs();
					} else {
						folders.selected = false;
					}

					if (!folder || !subFolders.length) {
						resolve({folder_loaded: folder_loaded, new: folders.selected});
					} else {
						$rootScope.$broadcast('items.refresh');
					}
					$rootScope.$broadcast('items.refresh');
					$rootScope.ajaxProgress.files = false;
					$rootScope.ajaxProgress.autoLoadMore = true;
					broadcastFolderChange(oldSelection, folders.selected, forced);
				}, function (response) {
					if(typeof response.data == 'string' && response.data.toLowerCase() == 'password is required') {
						dialogManager.showUnlockDialog(folder).then(function success(item) {
							folders.selectByShareId(shareId);
						}, function err() {
							folders.redirectToHome();
						});
						reject();
					}
					$rootScope.ajaxProgress.files = false;
				});
			});

			return this.selectionPromise;
		},

		getSubFolders: function(id, isRoot) {
			var subFolders = [];
			this.available.forEach(function(folder) {
				if ((folder.folder_id == id) || (isRoot && ! folder.folder_id && folder.name !== 'root')) {
					subFolders.push(folder);
				}
			});
			return subFolders;
		},

		/**
		 * Get a folder by name.
		 *
		 * @param {string} name
		 * @returns {object|undefined}
		 */
		get: function(name) {
			// return folders.getAvailableFolderByName(name);
		 	for (var i = 0; i < this.available.length; i++) {
		 		if (this.available[i].name.toLowerCase() === name) {
		 			return this.available[i];
		 		}
		 	}
		},

	   /**
		* Get a folder by name.
		*
		* @param {string} name
		* @returns {object|undefined}
		*/
		getByShareId: function(shareId) {
			// return folders.getAvailableFolderByShareId(shareId);
			for (var i = 0; i < this.available.length; i++) {
				if (this.available[i].share_id === shareId) {
					return this.available[i];
				}
			}
		},

		/**
		 * Replace an existing folder with a new one.
		 *
		 * @param {object} folder
		 * @returns {object|undefined}
		 */
		set: function(folder) {
			// normal ones
			for (var i = 0; i < this.available.length; i++) {
				if (this.available[i].id === folder.id) {
					this.available[i] = angular.extend(this.available[i], folder);
					return this.available[i];
				}
			}
		},

		mergeFile: function (files) {
			var check = {};
			var res = [];
			for (var h = 0; h < files.length; h++){
				if (!check[files[h]['id']]) {
					check[files[h]['id']] = true;
					res.push(files[h]);
				}
			}
			return res;
		},
		/**
		 * Replace an existing folder files with a new ones.
		 *
		 * @param {object} folder
		 * @returns {object|undefined}
		 */
		setFolderFiles: function(folder) {
			// normal ones
			for (var i = 0; i < this.available.length; i++) {
				if (this.available[i].id === folder.id) {
					this.available[i].files = folder.files;
					return this.available[i];
				}
			}
		},

		/**
		 * Add a folder to available
		 *
		 * @param {object} folder
		 * @returns {object}
		 */
		add: function(folder) {
			this.available.push(folder);
			return folder;
		},

		/**
		 * Get all existing folders
		 *
		 * @param
		 * @returns {array}
		 */
		getAvailableFolders: function() {
			if (this.folderKey) {
				return localStorage.get('folders-'+this.folderKey, []);
			}
			return [];
		},

		/**
		 * Set all existing folders
		 *
		 * @param {array} folder
		 * @returns {object|undefined}
		 */
		setAvailableFolders: function() {
			if (this.folderKey) {
				localStorage.set('folders-'+this.folderKey, folders.available);
			}
		},

		/**
		 * Get a folder matching given id.
		 *
		 * @param id
		 * @returns {object|void}
		 */
		getById: function(id) {
		 	if ( ! id) {
		 		return;
		 	}

		 	for (var i = 0; i < this.available.length; i++) {
		 		if (this.available[i].id == id) {
		 			return this.available[i];
		 		}
		 	}
		},

		setClassType: function(folder, shareId) {
			$http.get($rootScope.baseUrl+'folders/class-type/'+encodeURIComponent(shareId)).then(function(response) {
				folder.class_type = response.data.class_type;
			}, function(response) {

			}).finally(function() {
				$rootScope.ajaxProgress.files = false;
			});
		},
	};

	$rootScope.$on('user.loggedOut', function() {
		folders.available = [];
		folders.selected = false;
		folders.updateBreadcrumbs();
		folders.eventsBound = false;
		folders.unbindStateChangeEvent && folders.unbindStateChangeEvent();
		folders.selectionPromise = null;
	});

	$rootScope.$on('offer.viewed', function() {
		if (folders.selected && folders.selected.class_type == "special-offers-new") {
			folders.setClassType(folders.selected, folders.selected.share_id);
		}
	});

	$rootScope.$on('activity.happened', function(e, type, itemType, item) {
		if (['locked', 'unlocked'].indexOf(type) !== -1) {
			switch(item.type) {
				case 'folder':
				folders.available.map(function(folder){
					if(folder.id == item.id) {
						folder = angular.extend(folder, item);
						if (folder.is_locked) {
							folder.files = [];
						}
					}
					return folder;
				});
				break;

				case 'file':
				folders.selected.files.map(function(file){
					if (file.id == item.id) {
						file = angular.extend(file, item);
					}
					return file;
				});
				break;
			}
		}
	});

	$rootScope.$watch(function(){
	   return folders.available;
	}, function(newValue, oldValue){
		if ( newValue.length ) {
			folders.setAvailableFolders();
		}
	}, true);

	return folders;
}]);