(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('userInvoices', ['users', function(users) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/user-invoices.html',
            scope: {},
            link: function($scope) {
                $scope.data = {
                    loading: false,
                    invoices: []
                };
                
                function update() {
                    $scope.data.loading = true;
                    users.getInvoices().then(function(response) {
                        if(response.data) {
                            $scope.data.invoices = response.data;
                        } else {
                            // ERROR
                        }
                    }, function() {
                        // ERROR...
                    }).finally(function() {
                        $scope.data.loading = false;
                    });
                }
                
                update();
            }
        };
    }]);

})();