angular.module('app')

.factory('EmailLogger', ['$rootScope', '$translate', '$http', 'folders', 'fileTypes', 'users', function($rootScope, $translate, $http, folders, fileTypes, users) {
    var logs = {

         /** Paginate all existing email logger.
         *
         * @returns {promise}
         */
        paginateEmailLogger: function (params) {
            return $http.get('emailLogger', { params: params });
        }
    };
    return logs;
}]);