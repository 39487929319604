(function () {
	'use strict';

	angular.module('app')
		.directive('familyView', ['api', 'dialogManager', function(api, dialogManager) {
		return {
			restrict: 'E',
			scope: {
			},
			templateUrl: 'assets/views/pages/family-view.html',
			link: function($scope, el, attrs) {
				// Removed as of FF-157
				// api.user.firstrun('dashboard.family').then(function(response) {
				// 	if(response.show) {
				// 		dialogManager.showFirstRunDialog(response.info);
				// 	}
				// });
			}
		};
	}]);
}());