(function () {
	'use strict';

	angular.module('app')
		.directive('hiveView', ['api', 'dialogManager', '$rootScope', '$state', 'utils', '$stateParams', function(api, dialogManager, $rootScope, $state, utils, $stateParams) {
		return {
			restrict: 'E',
			scope: {
			},
			templateUrl: 'assets/views/pages/hive-view.html',
			link: function($scope, el, attrs) {
				$scope.data = {
					hives: [],
					loading: true,
					loaded: false,
					selectId: null
				};

				function loadHives() {
					$scope.data.loading = true;
					 api.resources.hive.query({}, function(hives) {
						$scope.data.hives = hives;
						// setDefaultSelected();
						$scope.data.loading = false;
						$scope.data.loaded = true;
					});
				}

				loadHives();

				api.user.firstrun('dashboard.family').then(function(response) {
					if(response.show) {
						dialogManager.showFirstRunDialog(response.info);
					}
				});

				$scope.addHiveGroup = function() {
					dialogManager.showAddHiveGroupDialog().then(function(hive) {
						$scope.data.hives.push(hive);
						$rootScope.$broadcast('hive.added', hive);
					}, function() {

					});
				};

				$scope.loadLeftHiveMembers = function(hive) {
					if (hive.id == $scope.data.selectedHiveId) {
						return;
					}
					$scope.data.selectedHiveId = hive.id;
					selectHive();
					$rootScope.$broadcast('hive.selected', hive);
				};

				function selectHive() {
					$scope.data.hives.map(function(hive) {
						hive.selected = false;
						if (hive.id == $scope.data.selectedHiveId) {
							hive.selected = true;
						}
						return hive;
					});
				}

				function setDefaultSelected() {
					$scope.data.hives.map(function(hive) {
						hive.selected = false;
						if (hive.type == 'default') {
							hive.selected = true;
							$scope.data.selectedHiveId = hive.id;
						}
						return hive;
					});
				}

                // check for a family invite link.
                if ($stateParams.family_invite_link) {
                    api.family.checkLink($stateParams.family_invite_link).then(function(response) {
                        if(typeof response == 'string') {
                            utils.showToast(response);
                            // this clears the share link (it will by default keep the other parameters.)
                            $state.go('dashboard.hives', { family_invite_link: null  });
                        } else {
                            dialogManager.showFamilyMemberInviteDialog(response).then(function() {
                                api.family.acceptInvitation(response.id).then(function() {
                                    utils.showToast(utils.trans('family.invitationAcceptedSuccessful', true));
                                    $state.go('dashboard.hives', { family_invite_link: null  });
                               	}, function() {
                                    utils.showToast('genericError', true);
                               });
                            }, function() {
                                api.family.declineInvitation(response.id).then(function() {
                                    utils.showToast(utils.trans('family.invitationDeclinedSuccessful', true));
                                    $state.go('dashboard.hives', { family_invite_link: null  });
                                });
                            });
                        }
                    }, function(error){
                        utils.showToast('genericError', true);
                        $state.go('dashboard.hives', { family_invite_link: null  });
                    });
                }

                $rootScope.$broadcast('hivelist.reload');
			}
		};
	}]);
}());