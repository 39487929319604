(function(){
    'use strict';

    angular.module('pixie.admin')
    .directive('adminJoinRequests', ['utils', 'api', 'dialogManager', '$window', 'users', function(utils, api, dialogManager, $window, users) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/admin/join-requests.html',
            scope: {},
            link: function($scope, $element, $attr) {
                var requests = ['Pending','Approved','Rejected'];
                var isOrgOwner = users.current && users.current.isOrgOwner;
                var requestData = {};

                $scope.data = {
                    items: [],
                    selected: [],
                    loading: false,
                    startDate: null,
                    endDate: null,
                    params: {
                        itemsPerPage: 10,
                        page: 1 // 0?
                    },
                    requests: requests,
                    requestType: requests[0],
                    requestOrg: null,
                    orgs: [],
                    showOrgFilterBtn: false,
                    showDateFilterBtn: false,
                    requestInvoiced: false,
                    userOrgId: isOrgOwner ? users.current.organization_id : null,
                };

                $scope.clearOrganizationFilter = function() {
                    $scope.data.requestOrg = null;
                    $scope.data.showOrgFilterBtn = false;
                    $scope.loadRequestTypeData();
                };

                $scope.clearDateFilter = function() {
                    $scope.data.startDate = null;
                    $scope.data.endDate = null;
                    $scope.data.showDateFilterBtn = false;
                    $scope.loadRequestTypeData();
                };

                $scope.filterOrganization = function() {
                    if ($scope.data.requestOrg) {
                        $scope.data.showOrgFilterBtn = true;
                        $scope.loadRequestTypeData();
                    }
                };

                $scope.filterByDates = function() {
                    if ($scope.data.startDate && $scope.data.endDate) {
                        $scope.data.showDateFilterBtn = true;
                        $scope.loadRequestTypeData();
                    }
                };

                function getSelectedItemIds() {
                    return $scope.data.selected.map(function(item) {
                        return item.id;
                    });
                }

                $scope.approveItems = function() {
                    api.joinRequest.approve(getSelectedItemIds()).then(function(response) {
                        removeItemsFromTable();
                        utils.showToast(utils.trans(response, true));
                    }, function() {
                        // handle failed case
                    });
                };

                function removeItemsFromTable() {
                    $scope.data.selected.forEach(function(item) {
                        var index;

                        // remove from normal
                        index = $scope.data.items.indexOf(item);
                        if(index != -1) {
                            $scope.data.items.splice(index, 1);
                        }
                    });

                    $scope.data.selected = [];
                }

                $scope.rejectItems = function() {
                    api.joinRequest.reject(getSelectedItemIds()).then(function(response) {
                        utils.showToast(utils.trans(response, true));
                        removeItemsFromTable();
                    }, function() {
                        // handle failed case
                    });
                };

                $scope.toggleAllItems = function() {
                    //all items already selected, deselect all
                    if ($scope.data.selected.length === $scope.data.items.length) {
                        $scope.data.selected = [];
                    }

                    //all items arent selected, copy all items array to selected items
                    else {
                        $scope.data.selected = $scope.data.items.slice();
                    }
                };

                $scope.toggleSelect = function(item) {
                    var index = $scope.data.selected.indexOf(item);
                    if(index==-1) {
                        $scope.data.selected.push(item);
                    } else {
                        // remove it.
                        $scope.data.selected.splice(index, 1);
                    }
                };

                $scope.isItemSelected = function(item) {
                    return ($scope.data.selected.indexOf(item) !== -1);
                };

                $scope.showViewItemModal = function(event, item) {
                    dialogManager.showViewJoinRequestsDialog(item, $scope.data.requestType);
                };

                function getQueryParams(itemIds) {
                    var query = '';
                    itemIds.forEach(function(itemId) {
                        query = query + 'ids[]=' + itemId + '&';
                    });
                    return query.slice(0, -1);
                }

                $scope.invoiceItems = function() {
                    var data = {};
                    data['join_requests'] = getSelectedItemIds();

                    if ($scope.data.requestOrg) {
                        data['org_id'] = $scope.data.requestOrg.id;
                    }

                    if ($scope.data.endDate && $scope.data.startDate) {
                        data['start-date'] = moment($scope.data.startDate).format('MM/DD/YYYY');
                        data['end-date'] = moment($scope.data.endDate).format('MM/DD/YYYY');
                    }

                    api.joinRequest.raiseInvoices(data).then(function(response) {
                        utils.showToast(utils.trans(response['message']));
                        dialogManager.showEditInvoiceFileNameDialog(response['fileName']).then(function(fileName) {
                            var params = getQueryParams(data['join_requests']) + '&name=' + fileName;
                            if (data['org_id']) {
                                params = params + '&org_id=' + data['org_id'];
                            }

                            if (data['start-date'] && data['end-date']) {
                                params = params + '&start-date=' + data['start-date'];
                                params = params + '&end-date=' + data['end-date'];
                            }

                            params = params + '&invoiced-date=' + response['invoicedDate'];
                            $window.open('/organization/join-requests/download-invoice-report?'+params , '_blank');
                        }, function(response) {
                            // handle error case
                        }).finally(function(){
                            removeItemsFromTable();
                        });
                    }, function() {
                        // handle failed case
                    });
                };

                if (isOrgOwner) {
                    $scope.data.userOrgId = users.current.organization_id;
                    requestData = {type: 'org_id', org_id: $scope.data.userOrgId};
                }

                api.resources.organization.query(requestData, function(orgs) {
                    $scope.data.orgs = orgs;
                    $scope.data.orgs.forEach(function(org) {
                        if (org.id == $scope.data.userOrgId) {
                            $scope.data.requestOrg = org;
                        }
                    });
                });

                $scope.loadRequestTypeData = function() {
                    $scope.data.loading = true;

                    var data = {};
                    data['type'] = $scope.data.requestType.toLowerCase();

                    if (data['type'] == 'approved' && $scope.data.requestInvoiced) {
                        data['invoiced'] = true;
                    }

                    if ($scope.data.requestOrg) {
                        data['org_id'] = $scope.data.requestOrg.id;
                    }

                    if (isOrgOwner && $scope.data.userOrgId) {
                        data['org_id'] = $scope.data.userOrgId;
                    }

                    if ($scope.data.endDate && $scope.data.startDate) {
                        data['start-date'] = $scope.data.startDate;
                        data['end-date'] = $scope.data.endDate;
                    }

                    api.resources.joinRequest.query(data, function(res) {
                        $scope.data.selected = [];
                        $scope.data.items = res;
                        $scope.data.loading = false;
                    }, function() {
                        $scope.data.loading = false;
                    });
                };

                $scope.loadRequestTypeData();
            }
        };
    }]);
}());