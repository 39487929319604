(function(){
    'use strict';

    angular.module('pixie.dashboard')
    .directive('folderTree', ['$translate', '$compile', 'folders', 'utils', '$state', function($translate, $compile, folders, utils, $state) {
      var states = ['dashboard.trash', 'dashboard.favorites', 'dashboard.recent'];

      function buildTree(folders) {
        var directRootChildren= [];
        var rootFolder;
        var depth = 1;

        folders.available.forEach(function(folder) {
            if (folder.isRootChild) {
                directRootChildren.push(folder);
            }

            if (folder.name === 'root') {
                rootFolder = folder;
            }
        });

        var rootNode = {
          id: rootFolder.id,
          name: $translate.instant('folders'),
          children: buildList(folders, directRootChildren, depth),
          expanded: true,
          depth: 0,
          share_id: rootFolder.share_id,
          selected: (folders.selected && folders.selected.id && folders.selected.id == rootFolder.id)
        };

        // Restrict expanding on trash, favourites and recent pages
        rootNode.expanded = states.indexOf($state.current.name) == -1 ? true : false;
        return [ rootNode ];
      }

      function buildList(folders, foldersArray, depth) {
        var result = [];

        angular.forEach(foldersArray, function(folder) {
            var children = folders.getSubFolders(folder.id),
            hasChildren = children && children.length;

            var element = {
              id: folder.id,
              name: folder.name,
              children: [],
              expanded: false,
              depth: depth,
              selected: (folders.selected && folders.selected.id && folders.selected.id==folder.id),
              share_id: folder.share_id
            };

            if(hasChildren) {
              element.children = buildList(folders, children, depth+1);
            }

            result.push(element);
        });
        return result;
      }

      function walkTree(arr, callback, userdata, parents) {
        parents = parents || [];
        angular.forEach(arr, function(item) {
          callback(item, userdata, parents);
          if(item.children && item.children.length) {
            parents.push(item);
            walkTree(item.children, callback, userdata, parents);
            parents.pop(item);
          }
        });
      }

      return {
        restrict: 'E',
        scope: {},
        template: '<div class="folder-tree new-tree"><folder-tree-array folders="data.folders"></folder-tree-array></div>',
        link: function($scope, $element) {
          $scope.data = {
            folders: [],
            prevState: '',
          };

          $scope.$on('folders.selected.changed', function(evt, data) {
            if ($state.current.name == "dashboard.folders") {
              if (!$scope.data.folders.length && folders.available.length > 0 ) {
                updateTree();
              }
            }
            // states
            walkTree($scope.data.folders, function(item, userData, parents) {
              if(data.new && data.new.id==item.id) {
                item.selected = true;
                item.expanded = true;
                if (parents.length) {
                  angular.forEach(parents, function(parent) {
                    parent.expanded = true;
                  });
                }
                item.children = buildList(folders, folders.getSubFolders(item.id) , item.depth+1);
              } else {
                item.selected = false;
              }
            });
          });

          // remove .open class from current folder if we're moving to trash, favorites or recent state
          $scope.$on('$stateChangeSuccess', function(e, state) {
            manageTreeStateWise(state.name);
          });

          function manageTreeStateWise(stateName) {
            if (states.indexOf(stateName) > -1) {
              walkTree($scope.data.folders, function(item) {
                if(item.selected) {
                  item.selected = false;
                }
              });

              walkTree($scope.data.folders, function(item) {
                if(item.expanded) {
                  item.expanded = false;
                }
              });

              folders.clearSelected();
            } else if (stateName == "dashboard.folders") {
              if ($scope.data.prevState && $scope.data.prevState != stateName) {
                $scope.data.folders = [];
                updateTree();
              }
            }
            $scope.data.prevState = stateName;
          }

          function updateTree() {
            var expandedItems;
            // determine whatever was already expanded (so we can restore it.)
            if($scope.data.folders && $scope.data.folders.length) {
              expandedItems = [];
              walkTree($scope.data.folders, function(item, items) {
                if(item.expanded) {
                  items.push(item.id);
                }
              }, expandedItems);
            }


            // rebuild...
            $scope.data.folders = buildTree(folders);


            // and reexpand...
            if(expandedItems) // first time through, want root expanded.
            {
              walkTree($scope.data.folders, function(item, items) {
                // keep root expanded.
                item.expanded = ((item.depth==0 || items.indexOf(item.id)>=0) ? true : false);
              }, expandedItems);
            }
          }

          // if items has a folder type
          function hasFolderType(items) {
            if ( !angular.isArray(items) ) {
              items = [items];
            }

            for (var i = 0; i < items.length ; i++) {
              if (items[i].type == 'folder') {
                return true;
              }
            }

            return false;
          }

          // if we add all_loaded, we get a ton of legacy activity recorded.
          $scope.$on('folders.loaded', function(e, action, type, data) {
            updateTree();
          });

          $scope.$on('activity.happened', function(e, action, type, data) {
              if(type=='folder' && (action=='created' || action=='renamed') || (action == "trashed" && hasFolderType(data)) || (action == "unlinked" && hasFolderType(data))) {
                updateTree();
              }
          });


          if (folders.available.length) {
            // not avialable on the first load, only subsequent (ie admin area and back)
            updateTree();
          }
        }
      };
    }]).directive('folderTreeArray', ['$translate', '$compile', 'folders', 'utils', function($translate, $compile, folders, utils) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          folders: '='
        },
        template: '<ul><folder-tree-item ng-repeat="folder in folders" folder="folder"></folder-tree-item></ul>',
      };
    }])
    .directive('folderTreeItem', function() {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          folder: '='
        },
        template: //ng-click="open($event)" //  ng-class="{\'open\': folder.selected }"
          '<li>'+
            '<div class="folder-name" ui-sref="dashboard.folders({ folderId: folder.share_id })" style="padding-left: {{ padding }};" ed-context-menu-item ed-folder-droppable data-id="{{ folder.id }}" data-share-id="{{ folder.share_id }}" data-depth="{{ folder.depth }} data-selected="{{ folder.selected}}" ng-class="{open: folder.selected}">'+
              '<div class="icons" ng-click="folder.expanded = !folder.expanded">'+
                // '<i ng-click="folder.expanded = !folder.expanded;" ng-if="folder.children.length && folder.depth>0 || !folder.expanded" class="icon toggle-icon icon-right-dir"></i>'+
                '<md-icon ng-if="folder.selected" md-svg-src="/assets/images/specialfolders/hexagon.svg" class="s48"></md-icon>'+
                '<md-icon ng-if="!folder.selected" md-svg-src="/assets/images/specialfolders/openhex.svg" class="s48"></md-icon>'+
              '</div>'+
              '<span>'+
                '{{ folder.name }}'+
              '</span>'+
            '</div>'+
            '<ul  ng-if="folder.children.length && folder.expanded"><folder-tree-array folders="folder.children"></folder-tree-array></ul>'+
          '</li>',
        link: function($scope, $element, $attr) {
          $scope.padding = (22+($scope.folder.depth * 18))+'px';
        }
      };
    });
})();