(function(){
    'use strict';

    angular.module('app.api', ['ngMaterial'])
    .factory('api', ['$http', '$q', '$resource', function($http, $q, $resource) {

        function standardCall(url, params) {
            return $q(function(resolve, reject) {
                $http.post(url, params).then(function(response) {
                    if(response.data.success) {
                        resolve(response.data.result);
                    } else {
                        reject(response.data.message);
                    }
                }, reject);
            });
        }

        function legacyGetCall(url) {
            // this handles unpacking the response.data.
            return $q(function(resolve, reject) {
                $http.get(url).then(function success(response) {
                    resolve(response.data);
                }, reject);
            });
        }

        function legacyPostCall(url, params) {
            // this handles unpacking the response.data.
            return $q(function(resolve, reject) {
                $http.post(url, params).then(function success(response) {
                    resolve(response.data);
                }, reject);
            });
        }

        function legacyPutCall(url, params) {
            // this handles unpacking the response.data.
            return $q(function(resolve, reject) {
                $http.put(url, params).then(function success(response) {
                    resolve(response.data);
                }, reject);
            });
        }

        function legacyDeleteCall(url, params) {
            // this handles unpacking the response.data.
            return $q(function(resolve, reject) {
                $http.delete(url).then(function success(response) {
                    resolve(response.data);
                }, reject);
            });
        }

        function objectToQueryString(obj) {
            if (typeof obj === 'object' && obj !== null) {
                return Object.keys(obj).reduce(function(a,k){
                    a.push(k+'='+encodeURIComponent(obj[k]));
                    return a;
                },[]).join('&');
            }
            return "";
        }

        var Faq = $resource('/faqs/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false }
            }
        );

        var Organization = $resource('/organizations/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false },
                delete: {method: 'DELETE'}
            }
        );

        var OrganizationJoinCode = $resource('/organization/join-codes/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false },
                delete: {method: 'DELETE'}
            }
        );

        var OrganizationJoinRequest = $resource('/organization/join-requests/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false },
                delete: {method: 'DELETE'}
            }
        );

        var Family = $resource('/family/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false },
                delete: {method: 'DELETE', isArray: false,}
            }
        );

        var FooterMenu = $resource('/footermenu/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false },
                delete: {method: 'DELETE'}
            }
        );

        var Post = $resource('/posts/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false},
                delete: {method: 'DELETE'}
            }
        );

        var Story = $resource('/story/:id', {id: '@id'},
            {
                update: {method: 'PUT', isArray: false},
                delete: {method: 'DELETE'}
            }
        );

        var UserStory = $resource('/user-story/:id', {id: '@id'},
        {
            update: {method: 'PUT', isArray: false},
            delete: {method: 'DELETE'}
        });

        var Hive = $resource('/hives/:id', {id: '@id'},
        {
            update: {method: 'PUT', isArray: false},
            delete: {method: 'DELETE'}
        });

        return {
            admin: {
                questions: {
                    test: function(userId) {
                        return legacyPostCall('/questions/test', { id: userId });
                    }
                }
            },
            account: {
                checkRegistration: function(data) {
                    return legacyPostCall('/account/validate-registration', data);
                }
            },
            user: {
                logout: function() {
                    return legacyGetCall('/logout');
                },
                firstrun: function(shortname) {
                    return legacyPostCall('/users/first-run', { shortname: shortname });
                },
                updateSettings: function(id, settings) {
                  return legacyPutCall('/users/'+id, settings);
                },
                updateNotificationSettings: function(id, settings) {
                  return legacyPostCall('/users/'+id+'/notification', { notifications: settings });
                },
                deleteAccount: function(id) {
                  return legacyPostCall('/users/'+id+'/delete');
                },
                activateAccount: function(id) {
                  return legacyPostCall('/users/'+id+'/activate');
                },
                modeInfo: function(id) {
                    return legacyGetCall('/users/mode-info?mode='+id);
                },
                searchUser: function(query) {
                    return legacyGetCall('/users/search?query='+query);
                },
                reactivate: function(users) {
                    return legacyPostCall('users/reactivate', {users: users});
                },
                makeAdmin: function(users) {
                    return legacyPostCall('users/make-admin', {users: users});
                },
                removeAdmin: function(users) {
                    return legacyPostCall('users/remove-admin', {users: users});
                },
                modesIntro: function() {
                    return legacyGetCall('/users/modes-intro');
                },
                updateModeInfo: function(modeId, content) {
                    return legacyPostCall('/users/mode-info/'+modeId+'/update', {content: content});
                },
                signalVideoChat: function(message) {
                    return legacyPostCall('/users/video-chat', message);
                },
                accountCleanup: function(key) {
                    return legacyGetCall('/clear-users/'+key);
                },          
                signalBusyUsers: function() {
                    return legacyPostCall('/users/busy-video-users');
                }
            },
            me: {
                familyMembersSuggestion: function(query) {
                    return legacyGetCall('/me/family-suggestion?query='+query);
                },
                hiveGroupsSuggestion: function(query) {
                    return legacyGetCall('/me/hive-suggestion?query='+query);
                },
                familyMembers: function(selected){
                  selected = selected | 0;
                  return legacyGetCall('/me/family?selected='+selected);
                },
                familyMember: function(id){
                  return legacyGetCall('/me/family/'+id);
                },
                userStoryProgress: function() {
                    return legacyGetCall('/me/user-story-progress');
                },
                hiveList: function() {
                    return legacyGetCall('/me/hive-list');
                },
                pendingInvites: function() {
                    return legacyGetCall('/me/pending-invites');
                },
                privacyOptions: function() {
                    return legacyGetCall('/me/privacy-option');
                },
                deleteInvite: function(deletedEmail) {
                    return legacyPostCall('/me/delete-invite', {email: deletedEmail});
                },
                permissions: function() {
                    return legacyGetCall('/me/permissions');
                },
                subscriptions: function() {
                    return legacyGetCall('/me/subscriptions');
                },
                joinRequests: function() {
                    return legacyGetCall('/me/join-requests');
                },
                organization: function() {
                    return legacyGetCall('/me/organization');
                },
                videoPeers: function() {
                    return legacyGetCall('/me/video-peers');
                },
                roominvite: function(payload) {
                    return legacyPostCall('/me/room-invite', payload);
                }
            },
            trash: {
                empty: function() {
                    return standardCall('/trash/empty');
                },
                contents: function() {
                    return legacyGetCall('/user-trash');
                }
            },
            file: {
                getUploadPreSignedUrl: function (params) {
                    return legacyPostCall('/files/upload-presigned-url', params);
                },
                markAsFileUploaded: function (params) {
                    return legacyPostCall('/files/mark-file-upload-complete', params);
                },
                createFile: function(params) {
                    return legacyPostCall('/files/create-file', params);
                },
                duplicateMediaFile: function(state, shareId) {
                    return legacyPostCall('/files/duplicate-file-media', {id: shareId, folder: state});
                },
                deleteThumbnail: function(shareId) {
                    return legacyDeleteCall('/files/thumbnail/'+shareId);
                },
                updateDescription: function(fileId, description) {
                  return legacyPutCall('/files/' + fileId, { description: description });
                },
                lock: function(shareId, type, password) {
                  return legacyPostCall('/lockables/lock', { id: shareId, type: type, password: password });
                },
                unlock: function(shareId, type, password, forever) {
                  return legacyPostCall('/lockables/unlock', { id: shareId, type: type, password: password, forever: forever });
                },
                videoInfo: function(shareId) {
                    return legacyGetCall('/user-video/'+shareId);
                },
                moveTemplate: function(itemId, moveInfo) {
                    return legacyPostCall('/copy-template/'+ itemId, moveInfo);
                },
                htmlContent: function(shareId) {
                    return legacyGetCall('/user-file/'+shareId);
                },
                getTagSuggestions: function(fileId, query) {
                    return legacyGetCall('files/tag-suggestions/'+fileId+'?query='+query);
                },
                updateTags: function(fileId, users, emails) {
                    return legacyPostCall('files/update-tags/'+ fileId, { users: users, emails: emails});
                },
                getTags: function(fileId) {
                    return legacyGetCall('files/get-tags/'+fileId);
                },
                getVideos: function() {
                  return legacyGetCall('files/videos/');
                },
                createPost: function(content, privacy, users) {
                    return legacyPostCall('files/create-post', {content: content, privacy: privacy, users: users});
                },
                searchMedia: function(searchText) {
                    return legacyGetCall('search-media/'+searchText);
                },
                searchVideos: function(searchText) {
                    return legacyGetCall('search-videos/'+searchText);
                },
                details: function(shareId) {
                    return legacyGetCall('files/'+shareId+'/details');
                }
            },
            folder: {
                setTemplateStore: function(folder) {
                    return legacyPostCall('/folders/template-store', { share_id: folder.share_id });
                },
                searchImagesInFolder: function(shareId, query) {
                    return legacyGetCall('/folders/search-images?share_id='+shareId+'&query='+query);
                },
                setFolderImage: function(shareId, imageShareId) {
                    return legacyPostCall('/folders/set-folder-image', { share_id: shareId, image_share_id: imageShareId});
                },
                resetFolderImage: function(shareId) {
                    return legacyPostCall('/folders/reset-folder-image', { share_id: shareId});
                },
                getFolder: function(shareId) {
                    return legacyGetCall('/folders/'+shareId);
                }
            },
            items: {
              move: function(items, folderId) {
                return legacyPostCall('/move-items', { items: items, folderId: folderId });
              }
            },
            item: {
                toggleLike: function(itemId, type) {
                    return legacyPostCall('item/' + itemId+'/toggle-like', {type: type});
                },
                likes: function(itemId, type) {
                    return legacyGetCall('item/' + itemId+'/likes?type='+type);
                },
                addComment: function(data) {
                    return legacyPostCall('comments', data);
                }
            },
            share: {
                info: function(type, shareId) {
                    return legacyPostCall('/shareable/info', { type: type, id: shareId });
                },
                // unshares a file (must be done by the user that is sharing the file)
                unshare: function(item) {
                    return legacyPostCall('/shareable/unshare', { member: item.user_id, itemId: item.id, itemType: item.type });
                },
                unshareById: function(id) {
                    return legacyPostCall('/shareable/unshare', { id: id });
                },
                // unlinks a shared item (one that is shared to me)
                unlink: function(items) {
                    return legacyPostCall('/shareable/unlink-items', { items: items });
                },
                move: function(id, name) {
                    return legacyPostCall('/shareable/move-shared', { id: id, newName: name });
                },
                checkLink: function(link) {
                    return legacyGetCall('/shareable/check-link?link='+link);
                },
                hiveFiles: function(userId) {
                    return legacyGetCall('/shareable/'+userId+'/hive-files');
                }
            },
            offers: {
              toggleActive: function(id, currentState) {
                // don't have a put yet.
                return legacyPutCall('/offers/'+id+'/change-state', {state: currentState });
              },
              paginate: function(params) {
                params = objectToQueryString(params);
                return legacyGetCall('/offers?'+params);
              },
              removeThumbnail: function(id) {
                return legacyPutCall('/offers/'+id+'/remove-thumbnail' );
              },
              htmlContent: function(id) {
                return legacyGetCall('/user-offer/'+id);
              }
            },
            storySections: {
              toggleActive: function(id, currentState) {
                return legacyPutCall('/story-section/'+id+'/change-state', {state: currentState });
              },
              paginate: function(params) {
                return legacyGetCall('/story-section?params='+params);
              },
            },
            payments: {
                addNewCard: function(paymentMethod) {
                    return legacyPostCall('/payments/add-new-card', {payment_method: paymentMethod});
                },
                purchaseTemplate: function(itemId) {
                    return legacyPostCall('/payments/template-purchase/'+itemId);
                },
                getIntent: function() {
                    return legacyPostCall('/payments/get-intent/');
                }
            },
            family: {
              updateFamilyDetails: function(familyId, familyDetails) {
                // don't have a put yet.
                return legacyPutCall('/family/'+familyId, familyDetails);
              },
              addMember: function(emails) {
                return legacyPostCall('/family/add-family-members', emails);
              },
              checkLink: function(link) {
                return legacyGetCall('/family/check-link?link='+link);
              },
              acceptInvitation: function(inviteId) {
                return legacyPostCall('/family/accept-invitation/'+inviteId);
              },
              declineInvitation: function(inviteId) {
                return legacyPostCall('/family/decline-invitation/'+inviteId);
              },
              addMembersToCustom: function(emails, hiveId) {
                return legacyPostCall('/family/'+hiveId+'/add-members', {emails: emails});
              },
              getFamilyForCustom: function(hiveId) {
                return legacyGetCall('/family/'+hiveId+'/member-choices');
              }
            },
            footermenu: {
                getPageContent: function(pageName) {
                    return legacyGetCall('/footermenu?page-name='+pageName);
                },
                addMenuItem: function(item) {
                    return legacyPostCall('/footermenu/add', item);
                },
                updateMenuItem: function(item) {
                    return legacyPostCall('/footermenu/update', item);
                }
            },
            post: {
                updatePrivacy: function(postId, privacy, users) {
                    return legacyPostCall('/posts/'+postId+'/update-privacy', {privacy: privacy, users: users});
                },
                dayWisePosts: function(type) {
                    return legacyGetCall('/posts/daywise?type='+type);
                }
            },
            organization: {
                approveCouponCode: function(data) {
                    return legacyPostCall('/organizations/approve-join-code', data);
                },
                addOrganizationMember: function(data) {
                    return legacyPostCall('/organizations/member-join-request', data);
                },
                validateJoinCode: function(data) {
                    return legacyPostCall('/organizations/validate-join-code', data);
                },
                reactivate: function(itemId) {
                    return legacyPostCall('/organizations/reactivate/'+itemId);
                },
                approve: function(items) {
                    return legacyPostCall('/organizations/approve', {orgs: items});
                },
                reject: function(items) {
                    return legacyPostCall('/organizations/reject', {orgs: items});
                },
                submit: function(orgId) {
                    return legacyPostCall('organization/'+orgId+'/submit');
                }
            },
            joinCode: {
                reactivate: function(itemId) {
                    return legacyPostCall('organization/join-code/reactivate/'+itemId);
                },
            },
            joinRequest: {
                reject: function(items) {
                    return legacyPostCall('/organization/join-requests/reject', {join_requests: items});
                },
                raiseInvoices: function(data) {
                    return legacyPostCall('/organization/join-requests/raise-invoices', data);
                },
                approve: function(items) {
                    return legacyPostCall('/organization/join-requests/approve', {join_requests: items});
                },
                update: function(data) {
                    return legacyPostCall('/organization/join-requests/update', data);
                }
            },
            userStory: {
                sharedStories: function(userId) {
                    return legacyGetCall('/user-story/shared?user_id='+userId);
                },
                getContent: function(story_ids, my_stories) {
                    var storyIds = '';
                    var myStories = '';

                    if (story_ids) {
                        storyIds = '?story_ids='+story_ids;
                        if(my_stories) {
                            myStories = '&my_stories=true';
                        }
                    }
                    // return legacyGetCall('/user-story/content'+storyIds);
                    return legacyGetCall('/user-story/content'+storyIds+myStories);
                }
            },
            storyTopic: {
                add: function(sectionId, topic) {
                    return legacyPostCall('story-topic/add', {section_id: sectionId, topic: topic});
                },
                edit: function(sectionId, topicId, topic) {
                    return legacyPostCall('story-topic/edit', {section_id: sectionId, topic_id: topicId, topic: topic});
                },
                delete: function(sectionId, topicId) {
                    return legacyPostCall('story-topic/delete', {section_id: sectionId, topic_id: topicId});
                },
                toggleActive: function(id, currentState) {
                    return legacyPutCall('/story-topic/'+id+'/change-state', {state: currentState });
                }
            },
            resources: {
                faq: Faq,
                family: Family,
                footerMenu: FooterMenu,
                post: Post,
                story: Story,
                userStory: UserStory,
                organization: Organization,
                hive: Hive,
                joinRequest: OrganizationJoinRequest,
                joinCode: OrganizationJoinCode
            }
        };
    }]);
}());