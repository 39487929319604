(function(){
    'use strict';

    angular.module('app.dialogs.preview')
    .directive('previewUnlock', ['lockables', function(lockables) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/preview-unlock.html',
            scope: {
                'item': '='
            },
            link: function($scope, el) {
                $scope.data = {
                    password: '',
                    invalidPassword: false
                };
                
                $scope.passwordChange = function() { 
                    // we don't know.
                    $scope.previewUnlock.password.$setValidity('incorrect', true);
                };
                
                $scope.unlock = function() {
                    lockables.unlock($scope.item, $scope.data.password).then(
                        function success(item) {
                            // don't replace, since we are indexing an array.
                            angular.forEach(item, function(value, key) {
                                $scope.item[key] = value;
                            });
                            $scope.$emit('preview.update', { item: $scope.item });
                        }, 
                        function errror(err)
                        {
                            // toast will have been shown?
                            $scope.previewUnlock.password.$setValidity('incorrect', false);
                        }
                    );
                };
            }
        };
    }]);
}());
