angular.module('app').directive('doubleClick', function() {
    return {
        restrict: 'A',
        link: function ($scope, el, attrs) {
			var hammer = new Hammer(el[0]);

			hammer.on('doubletap', function() {
			  	$scope.$eval(attrs.doubleClick);
			});
    	}
    };
});


angular.module('app').directive('iosDblclick',
    function () {

        var DblClickInterval = 300; //milliseconds // const, but not available (let)

        var firstClickTime;
        var waitingSecondClick = false;

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind('click', function (e) {

                    if (!waitingSecondClick) {
                        firstClickTime = (new Date()).getTime();
                        waitingSecondClick = true;

                        setTimeout(function () {
                            waitingSecondClick = false;
                        }, DblClickInterval);
                    }
                    else {
                        waitingSecondClick = false;

                        var time = (new Date()).getTime();
                        if (time - firstClickTime < DblClickInterval) {
                            scope.$apply(attrs.iosDblclick);
                        }
                    }
                });
            }
        };
    });