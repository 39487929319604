(function(){
    'use strict';

    angular.module('pixie.dashboard').factory('deleter', ['$rootScope', '$http', 'utils', 'files', 'folders', 'trash', 'constants', '$state', function($rootScope, $http, utils, files, folders, trash, constants, $state) {

        /**
         * Maintain the origin folder id if possible
         */
        var originFolderId = false;

        /**
         * Permanently delete passed in items.
         *
         * @param {array} items
         * @returns {promise}
         */
        function permaDeleteItems(items) {
            return $http.post('delete-items', { items: items }).then(function(response) {
                if (trash.loaded) {
                    trash.items = trash.items.filter(function(file) {
                        return items.indexOf(file) === -1;
                    });
                }

                utils.showToast(utils.trans('permaDeletedItems', {number:response.data}));
            }, function(response) {
                utils.showToast(response.data);
            }).finally(function() {
                $rootScope.ajaxProgress.files = false;
                $rootScope.$broadcast('activity.happened', 'permaDeleted', 'item', items);
            });
        }

        /**
         * Move given items to trash.
         *
         * @param {array} items
         * @returns {promise}
         */
        function moveItemsToTrash(items) {
            return $http.post('trash/put', { items: items }).then(function(response) {
                utils.showToast(utils.trans('movedToTrash', {number:response.data.folders.length + response.data.files.length}));

                //if we're in folders state remove deleted items from their arrays
                if (utils.stateIs(['dashboard.folders', 'dashboard.foldersRoot'])) {
                    folders.selected.files = folders.selected.files.filter(function(file) {
                        return response.data.files.indexOf(file.id) === -1;
                    });

                    folders.available = folders.available.filter(function(folder) {
                        return response.data.folders.indexOf(folder.id) === -1;
                    });
                }

                //if we've already loaded trash from server, push
                //items we've just deleted into existing trash items
                if (trash.loaded) {
                    trash.items = trash.items.concat(items.filter(function(item) {
                        return !item.is_shared && ((item.type == 'file' && response.data.files.indexOf(item.id) != -1) || (item.type == 'folder' && response.data.folders.indexOf(item.id) != -1));
                    }));
                }

                if (response.data.folders.indexOf(folders.selected.id) > -1) {
                    var parent = folders.getById(folders.selected.folder_id) || folders.getRoot();
                    folders.open(parent.shareId);
                }
            }).finally(function() {
                $rootScope.ajaxProgress.files = false;
                $rootScope.$broadcast('activity.happened', 'trashed', 'item', items, originFolderId);
            });
        }

        /**
         * Delete or move to trash given items based on current state and their type.
         *
         * @param {array} items
         */
        function destroy(items) {
            if ( ! items.length) {
                return;
            }

            $rootScope.ajaxProgress.files = true;

            //if we are in trash state we will need to delete folders permanently
            if (utils.stateIs(['dashboard.trash', 'admin.files', 'admin.folders'])) {
                permaDeleteItems(items);
            }

            //otherwise we will move items to trash
            else {
                moveItemsToTrash(items);
            }

            //if photo or folder was deleted from preview page go back to dashboard
            if (utils.stateIs('view')) {
                utils.toState('dashboard.folders');
            }
        }

        /**
         * Get options for creating delete conformation modal based on active state.
         *
         * @param {array} items
         * @returns {object|undefined}
         */
        function getConfirmOptions(items, cb) {
            if (utils.stateIs('dashboard.trash')) {
                var subcontent = 'permaDeleteWarning';
                if (items.length && items[0].is_media_folder) {
                    subcontent = (items.length > 1) ? 'warnPermDeleteMediaItems' : 'warnPermDeleteMediaItem';
                }
                cb({
                    title: 'deleteForever',
                    content: 'confirmPermaDelete',
                    subcontent: subcontent,
                    ok: 'delete',
                    onConfirm: function () {
                        destroy(items);
                    }
                });

            } else {
                var lockedItems = items.filter(function(item) {
                    return item.is_locked;
                });

                var deletableItems = items.filter(function(item) {
                    return item.is_deletable;
                });

                if (deletableItems.length == 0) {
                    cb({
                        type: 'alert',
                        title: 'cannotDeleteItems',
                        content: (items.length > 1) ? 'allNonDeleteItems' : 'allNonDeleteItem',
                        ok: 'Ok'
                    });

                } else if (lockedItems.length == items.length) {
                    cb({
                        type: 'alert',
                        title: 'cannotDeleteItems',
                        content: (items.length > 1) ? 'allLockedItems' : 'allLockedItem',
                        ok: 'Ok'
                    });
                } else if (folders.selected.is_media_folder) {
                    cb({
                        title: 'deleteItems',
                        content: (items.length > 1) ? 'sureWantToDeleteItems' : 'sureWantToDeleteItem',
                        subcontent: (items.length > 1) ? 'warnDeleteMediaItems' : 'warnDeleteMediaItem',
                        ok: 'delete',
                        onConfirm: function() {
                            destroy(deletableItems);
                        }
                    });
                } else {
                    var content = (items.length > 1) ? 'sureWantToDeleteItems' : 'sureWantToDeleteItem';

                    var subcontentHTML = null;
                    if (deletableItems.length != items.length || lockedItems.length) {
                        var list = '<ul>';
                        items.forEach(function(item) {
                            if(!item.is_deletable) {
                                list = list + "<li>" + item.name + " (it is undeletable)</li>";
                            } else if (item.is_locked) {
                                list = list + "<li>" + item.name + " (it is locked)</li>";
                            }
                        });
                        list = list + "</ul><br>";
                        list = list + ((deletableItems.length > 1) ? utils.trans("deleteRestOfTheItems") : utils.trans("deleteRestOfTheItem"));

                        content = ((items.length - deletableItems.length + lockedItems.length) > 1) ? 'proceedWithoutNonDeleteItems' : 'proceedWithoutNonDeleteItem';
                        subcontentHTML = list;
                    }
                    // call to check sharable
                    $rootScope.ajaxProgress.files = true;
                    $http.post('shareable/check-items', { items: items }).then(function(response) {
                        cb({
                            title: 'deleteItems',
                            content: content,
                            subcontentHTML : subcontentHTML,
                            subcontent: (deletableItems.length > 1) ? 'deleteSharedWarningMultiple' : 'deleteSharedWarning',
                            ok: 'delete',
                            onConfirm: function() {
                                destroy(deletableItems);
                            }
                        });

                    }, function() {
                        cb({
                            title: 'deleteItems',
                            content: content,
                            subcontentHTML : subcontentHTML,
                            ok: 'delete',
                            onConfirm: function() {
                                destroy(deletableItems);
                            }
                        });

                    }).finally(function() {
                        $rootScope.ajaxProgress.files = false;
                    });
                }
            }
        }

        /**
         * Sets origin folder id
         *
         * @param {array} items
         * @returns {object|undefined}
         */
        function setOriginFolderId() {
            originFolderId = $state.params.folderId || false;
        }

        return {

            delete: function(items) {
                if ( ! items) {
                    return;
                }

                //always make sure we're working with an array
                if (! angular.isArray(items)) {
                    items = [items];
                }

                setOriginFolderId();

                getConfirmOptions(items, function(options) {

                    //if we've got confirm options means we will need
                    //to confirm the deletion, otherwise we will just
                    //go ahead and delete passed items without confirming
                    if (options) {
                        utils.confirm(options);
                    } else {
                        destroy(items);
                    }
                });
            }
        };
    }]);
}());