(function(){
    'use strict';
// old html preview code
// .directive('htmlPreview', ['$rootScope', '$http', '$translate', 'selectedItems', function($rootScope, $http, $translate, selectedItems) {
//     return {
//         restrict: 'E',
//         link: function($scope, el) {
//             if ($scope.preview.current.is_locked) {
//                 return $scope.showLockContainer();
//             }

//             $http.get($scope.preview.current.absoluteUrl).then(function success(response) {

//                 if (response && response.data) {
//                     el.append('<div class="html-preview-container"></div>').find('.html-preview-container').html(response.data);
//                 } else {
//                     $scope.showNoPreviewContainer();
//                 }



//             }, function error() {
//                 $scope.showNoPreviewContainer();
//             });
//         }
//     };
// }]);

    angular.module('app.dialogs.preview')
    .directive('htmlPreview', ['$http', '$translate', '$sce', '$rootScope', 'api', function($http, $translate, $sce, $rootScope, api) {
        return {
            restrict: 'E',
            scope: {
                item: '='
            },
            template: '<div class="html-preview-container" bind-html-compile="contents"></div>',
            link: function($scope, element) {
                $scope.$watch('item', function() {
                    $scope.contents = "Loading...";

                    api.file.htmlContent($scope.item.share_id).then(function success(response) {
                        if (response) {
                            response = response.replace(/<img src="([-:.?=\/a-zA-Z0-9]*)" alt="VideoId:([a-zA-Z0-9]*)"([a-z0-9 =":;%])*(\/)*>/g, '<video-preview data-src="$1" data-alt="$2" video="$2"></video-preview>');

                            $scope.contents = response;

                            // $scope.contents = $sce.trustAsHtml(response.data); // this could have ramifications with sharing.

                            // update offer special folder icon
                            if ($scope.item.is_offer) {
                                $rootScope.$emit('offer.viewed');
                            }
                        } else {
                            $scope.$emit('preview.failed');
                            $scope.contents = "Unable to load preview.";
                        }
                    }, function error() {
                        // $scope.showNoPreviewContainer();
                        $scope.$emit('preview.failed');
                        $scope.contents = "Unable to load preview.";
                    });
                });
            }
        };
    }])

    .directive('bindHtmlCompile', ['$compile', function ($compile) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          scope.$watch(function () {
            return scope.$eval(attrs.bindHtmlCompile);
          }, function (value) {
            // Incase value is a TrustedValueHolderType, sometimes it
            // needs to be explicitly called into a string in order to
            // get the HTML string.
            element.html(value && value.toString());
            // If scope is provided use it, otherwise use parent scope
            $compile(element.contents())(scope);
          });
        }
      };
    }])

    .directive('recordFileUpload', ['$parse', function ($parse) {
        return {
           restrict: 'A',
           link: function(scope, element, attrs) {
              var model = $parse(attrs.recordFileUpload);

              var modelSetter = model.assign;

              element.bind('change', function() {
                 scope.$apply(function() {
                    if (element[0].files[0]) {
                    }
                    modelSetter(scope, element[0].files[0]);
                 });
              });
           }
        };
    }]);
})();
