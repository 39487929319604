(function(){
    'use strict';

    angular.module('app.dialogs.preview')
    .directive('pdfPreview', function() {
        return {
            restrict: 'E',
            scope: {
                item: '='
            },
            template: '<iframe class="pdf-frame" ng-src="{{ item.absoluteUrl }}"></iframe>'
        };
    });
}());
