(function(){
    'use strict';

    angular.module('pixie.admin').controller('SettingsController', ['$scope', '$http', 'utils', 'users', function($scope, $http, utils, users) {
        $scope.settings = [];

        $scope.regularMailDrivers = ['smtp', 'mail', 'sendmail', 'log'];

        $http.get('settings').then(function(response) {
            $scope.settings = response.data;
        }, function(error) {

        });

        $http.get($scope.baseUrl+'settings/ini/max_upload_size').then(function(response) {
            $scope.max_server_upload_size = response.data;
        }, function(error) {

        });

        $scope.updateSettings = function() {

            $http.post('settings', $scope.settings).then(function(response) {
                utils.showToast(response.data);
            }, function(error) {
                utils.showToast(error);
            });
        };
    }]);
}());