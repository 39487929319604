(function(){
    'use strict';

    angular.module('app.dialogs.preview')
    .directive('textPreview', ['$http', '$translate', function($http, $translate) {
        return {
            restrict: 'E',
            scope: {
                item: '='
            },
            template: '<div class="text-preview-container" ng-content><pre>{{ contents }}</pre></div>',
            link: function($scope, el) {
                $scope.$watch('item', function() {
                    if($scope.item) {
                        $scope.contents = "Loading...";

                        $http.get($scope.item.absoluteUrl).then(function(response) {
                            if (response.data) {
                                $scope.contents = response.data;
                            } else {
                                $scope.$emit('preview.failed');
                                $scope.contents = "Unable to load preview.";
                            }
                        }, function() {
                            $scope.$emit('preview.failed');
                            $scope.contents = "Unable to load preview.";
                        });
                    }
                });
            }
        };
    }]);
}());