(function () {
    'use strict';

    angular.module('app')
        .factory('uploadManager', ['utils', 'users', 'folders', 'localStorage', '$rootScope', '$upload', '$state', 'api', '$timeout', function (utils, users, folders, localStorage, $rootScope, $upload, $state, api, $timeout) {
            var fileTooBig = utils.trans('fileTooBig', { number: utils.getSetting('maxFileSize') }),
                invalidExtension = utils.trans('invalidExtension');
            var data = {
                //files that are currently being uploaded or have
                //already been upload or rejected during this session
                uploadHistory: [],

                //number of files that are currently being uploaded
                uploadsInProgress: 0,

                whitelist: getWhitelist(),
                blacklist: getBlacklist(),
                maxFileSize: utils.getSetting('maxFileSize'),

                //array of uploaded files models
                uploadedFiles: [],
                selectedFile: {},

                //origin folder
                originFolderId: false,

                chunks: [],
                chunksIndex: 0
            };

            function getWhitelist() {
                var wl = utils.getSetting('whitelist');

                if (wl && angular.isString(wl)) {
                    return wl.replace(/ /g, '').split(',');
                }
            }

            function getBlacklist() {
                var bl = utils.getSetting('blacklist');

                if (bl && angular.isString(bl)) {
                    return bl.replace(/ /g, '').split(',');
                }
            }

            /**
            * Check if file extension and size are valid.
            *
            * @param {object} file
            * @returns {boolean}
            */
            function fileNotValid(file) {
                var extensionNotValid = extensionInvalid(file);

                //turn max file size from mb to byes and compare to given file size
                var sizeNotValid = (parseInt(data.maxFileSize) * 1000000) <= file.size;

                if (extensionNotValid) {
                    file.rejectReason = invalidExtension;
                }

                if (sizeNotValid) {
                    file.rejectReason = fileTooBig;
                }

                return extensionNotValid || sizeNotValid;
            }

            /**
            * Check if given file upload extension is not valid.
            *
            * @param {object} file
            * @returns {boolean}
            */
            function extensionInvalid(file) {
                var mimeExt = file.type.split('/')[1];
                var nameExt = utils.extractExtension(file.name);

                if (data.whitelist) {
                    if (data.whitelist.indexOf(mimeExt) === -1) {
                        return true;
                    }
                    if (nameExt && data.whitelist.indexOf(nameExt) === -1) {
                        return true;
                    }
                }

                if (data.blacklist) {
                    if (data.blacklist.indexOf(mimeExt) > -1) {
                        return true;
                    }
                    if (nameExt && data.blacklist.indexOf(nameExt) > -1) {
                        return true;
                    }
                }
            }

            /**
            * Remove files that don't pass validation from given array.
            *
            * @param {array|FileList} files
            * @returns {array}
            */
            function filterOutInvalidFiles(files) {
                if (!files || !files.length) {
                    return [];
                }

                var arrayOfFiles = files;
                if (files instanceof FileList) {
                    arrayOfFiles = [];
                    for (var ii = 0; ii < files.length; ii++) {
                        arrayOfFiles.push(files[ii]);
                    }
                }

                return arrayOfFiles.filter(function (file) {

                    //filter out any directories if uploading a folder
                    if (file.type === 'directory') {
                        return false;
                    }

                    //filter out any invalid files so we don't send them
                    //to server, but add them to upload history as rejected
                    else if (fileNotValid(file)) {
                        file.uploaded = true;
                        file.rejected = true;
                        data.uploadHistory.push(file);

                        return false;
                    }

                    return true;
                });
            }

            /**
            * If user is trying to upload more files at the same time then limit
            * we'll slice the give files array until the limit.
            *
            * @param {array} files
            * @returns {array}
            */
            function filterSimultUploads(files) {
                var maxUploads = utils.getSetting('maxSimultUploads');

                if (files.length > maxUploads) {
                    utils.showToast(utils.trans('maxSimultUploadsWarning', { number: maxUploads }));

                    files.splice(0, files.length - maxUploads);
                }

                return files;
            }

            function getUploadFields() {
                //no user is logged in, means we're uploading from homepage
                if (!users.current) {
                    var rand = utils.randomString();

                    //store the random string we've generated in localStorage so we can
                    //later attach these uploads to user if he registers or logs in
                    localStorage.set('attachIds', rand, true);

                    return { attach_id: rand };
                } else {
                    return { folder: folders.selected.id };
                }
            }

            function sendRequest(file, historyItem, customEvent) {
                customEvent = customEvent || null;
                $upload.upload({
                    url: $rootScope.baseUrl + 'files',
                    file: file,
                    fileName: file.newName !== undefined ? file.newName : null,
                    fields: getUploadFields()
                }).progress(function (evt) {
                    historyItem.percentageUploaded = parseInt(100.0 * evt.loaded / evt.total);
                    historyItem.bytesUploaded = utils.formatFileSize(evt.loaded);
                    $rootScope.$broadcast('upload.progress', { item: historyItem });
                }).success(function (successData) {
                    data.uploadedFiles = data.uploadedFiles.concat(successData.uploaded);

                    //if we are not in dashboard just fire an event and bail
                    if (!$state.includes('dashboard')) {
                        data.selectedFile = successData.uploadedFiles[0];
                        return $rootScope.$emit('photos.uploaded', successData);
                    }

                    if (successData.uploaded && successData.uploaded.length) {
                        folders.selected.files = folders.selected.files.concat(successData.uploaded);
                        historyItem.id = successData.uploaded[0].id;
                    }

                    if (successData.rejected && successData.rejected.length) {
                        historyItem.uploaded = true;
                        historyItem.rejected = true;
                        historyItem.rejectReason = successData.rejected[0].reason;
                    }
                }).error(function (errorData) {
                    historyItem.rejected = true;

                    if (angular.isString(errorData) && errorData.length < 300) {
                        utils.showToast(errorData, null, true);
                    }
                }).finally(function () {
                    historyItem.uploaded = true;
                    data.uploadsInProgress -= 1;
                    if (data.uploadsInProgress === 0 && users.current) {
                        if (data.chunkIndex + 1 == data.chunks.length) {
                            data.uploadHistory.filter(function (f) { return !f.rejected; });

                            if (data.uploadedFiles.length) {
                                $rootScope.$broadcast('activity.happened', 'uploaded', 'files', data.uploadedFiles.slice(), data.originFolderId);
                                if (customEvent) {
                                    $rootScope.$broadcast(customEvent, data.uploadedFiles);
                                }
                            }
                        } else {
                            chunkWiseUpload(customEvent, data.chunkIndex + 1);
                        }
                    }
                });
            }

            function setOriginFolderId() {
                data.originFolderId = $state.params.folderId || false;
            }

            function getFileUploadChunks(files) {
                var allChunks = [];
                allChunks['rejected'] = [];
                allChunks['accepted'] = [];
                var maxUserSpace = users.current.space_available;
                var maxUploads = utils.getSetting('maxSimultUploads');
                var userSpace = users.current.storage_info;
                var acceptedFiles = [];
                var rejectedFiles = [];
                angular.forEach(files, function (file, key) {
                    if (users.current.is_unlimited || ((userSpace + file.size) < maxUserSpace)) {
                        userSpace += file.size;
                        acceptedFiles.push(file);
                    } else {
                        rejectedFiles.push(file);
                    }
                });

                angular.forEach(acceptedFiles, function (file) {
                    var spliceCount = acceptedFiles.length;
                    if (spliceCount > maxUploads) {
                        spliceCount = maxUploads;
                    }
                    allChunks['accepted'].push(acceptedFiles.splice(0, spliceCount));
                });
                angular.forEach(rejectedFiles, function (file) {
                    var spliceCount = rejectedFiles.length;
                    if (spliceCount > maxUploads) {
                        spliceCount = maxUploads;
                    }
                    allChunks['rejected'].push(rejectedFiles.splice(0, spliceCount));
                });
                return allChunks;
            }

            function uploadFileWithPresignedRequest(customEvent, chunkIndex, params, file, historyItem, runPreSignedUrl) {
                if (runPreSignedUrl) {
                    api.file.getUploadPreSignedUrl(params).then(function (res) {
                        if (res && res['storage-info']) {
                            var remainingSpace = parseInt(res['storage-info']['max_space']) - parseInt(res['storage-info']['space_used']);
                                var params = {
                                    postURL: res['url'],
                                    uploadBucket: res['bucket'],
                                    uploadParameters: res['params'],
                                    uploadKey: res['params']['key'],
                                    mimeType: res['mimeType']
                                };

                                var fd = new FormData();

                                // Populate the Post paramters.
                                for (var key in params.uploadParameters) {
                                    fd.append(key, params.uploadParameters[key]);
                                }

                                fd.append('success_action_status', 201);
                                fd.append('file', file);

                                var xhr = new XMLHttpRequest();
                                var file_name = file.name;
                                var fileId = res['file'];

                                // LOAD EVENT HANDLER
                                xhr.addEventListener("load", function (xhr) {
                                    if (xhr && xhr.currentTarget.status == 201) {
                                        var params = {
                                            file_name: file_name,
                                            file: fileId
                                        };
                                        api.file.markAsFileUploaded(params).then(function (successData) {
                                            historyItem.uploaded = true;
                                            data.uploadedFiles = data.uploadedFiles.concat(successData.uploaded);

                                            //if we are not in dashboard just fire an event and bail
                                            if (!$state.includes('dashboard')) {
                                                data.selectedFile = successData.uploadedFiles[0];
                                                return $rootScope.$emit('photos.uploaded', successData);
                                            }

                                            if (successData.uploaded && successData.uploaded.length) {
                                                folders.selected.files = folders.selected.files.concat(successData.uploaded);
                                                historyItem.id = successData.uploaded[0].id;
                                            }

                                            data.uploadsInProgress -= 1;
                                            if (data.uploadsInProgress === 0 && users.current) {
                                                if (chunkIndex +1 == data.acceptedChunks.length) {
                                                    data.uploadHistory.filter(function (f) { return !f.rejected; });
                                                    $rootScope.$broadcast('upload.completed');
                                                    if (data.uploadedFiles.length) {
                                                        $rootScope.$broadcast('activity.happened', 'uploaded', 'files', data.uploadedFiles.slice(), data.originFolderId);
                                                        if (customEvent) {
                                                            data.uploadedFiles.forEach(function (updateUploadedFiles) {
                                                                updateUploadedFiles.is_new = true;
                                                            });
                                                            $rootScope.$broadcast(customEvent, data.uploadedFiles);
                                                        }
                                                    } else {
                                                    }
                                                } else {
                                                    chunkWiseUpload(customEvent, chunkIndex + 1, data.acceptedChunks, true);
                                                }
                                            }

                                            if (successData.rejected && successData.rejected.length) {
                                                historyItem.uploaded = true;
                                                historyItem.rejected = true;
                                                // historyItem.rejectReason = successData.rejected[0].reason;
                                            }
                                        }, function (err) {
                                            historyItem.rejected = true;
                                            historyItem.uploaded = true;
                                            data.uploadsInProgress -= 1;
                                            if (data.uploadsInProgress === 0 && users.current) {
                                                if (chunkIndex +1 == data.chunks.length) {
                                                    data.uploadHistory.filter(function (f) { return !f.rejected; });
                                                    $rootScope.$broadcast('upload.completed');
                                                    if (data.uploadedFiles.length) {
                                                        $rootScope.$broadcast('activity.happened', 'uploaded', 'files', data.uploadedFiles.slice(), data.originFolderId);
                                                        if (customEvent) {
                                                            $rootScope.$broadcast(customEvent, data.uploadedFiles);
                                                        }
                                                    } else {
                                                    }
                                                } else {
                                                    chunkWiseUpload(customEvent, chunkIndex + 1, data.acceptedChunks, true);
                                                }
                                            }
                                            // TO BE DEVELOPED/DONE
                                            // if (angular.isString(err) && err.length < 300) {
                                            //     utils.showToast(err, null, true);
                                            // }
                                            console.log("S3 error", err);
                                        });
                                    } else {
                                        alert("load load load " + xhr.currentTarget.response);
                                    }
                                }, false);

                                // ERROR EVENT HANDLER
                                xhr.addEventListener("error", function (evt) {
                                    historyItem.rejected = true;
                                    historyItem.uploaded = true;
                                    // TO BE DEVELOPED/DONE
                                    // if (angular.isString(err) && err.length < 300) {
                                    //     utils.showToast(err, null, true);
                                    // }
                                    console.log("S3 error event ", evt);
                                }, false);

                                // xhr.addEventListener("onreadystatechange", function(evt) {
                                //     if (xhr.readyState == XMLHttpRequest.DONE) {
                                //         alert('ppp');
                                //     }
                                // }, false);

                                // ABORT EVENT HANDLER
                                xhr.addEventListener("abort", function (evt) {
                                    historyItem.rejected = true;
                                    historyItem.uploaded = true;
                                    // TO BE DEVELOPED/DONE
                                    // if (angular.isString(err) && err.length < 300) {
                                    //     utils.showToast(err, null, true);
                                    // }
                                    console.log("S3 abort event ", evt);
                                }, false);

                                // PROGRESS EVENT HANDLER
                                xhr.upload.addEventListener("progress", function (evt) {
                                    $timeout(function () {
                                        var sizeCutFromWork = parseInt(evt.total - file.size);
                                        var remainSize = parseInt(evt.loaded - sizeCutFromWork);
                                        historyItem.percentageUploaded = parseInt(100.0 * evt.loaded / evt.total);
                                        historyItem.bytesUploaded = utils.formatFileSize(remainSize);
                                        $rootScope.$broadcast('upload.progress', { item: historyItem });
                                    });
                                }, false);

                                historyItem.uploading = true;
                                xhr.open('POST', params.postURL, true);
                                xhr.send(fd);
                            }
                    }, function () {
                            // body...
                    });
                } else {
                    historyItem.rejected = true;
                    historyItem.uploaded = true;
                    historyItem.rejectReason = "Out of Storage";
                    data.rejectedInProgress -= 1;
                    if (data.rejectedInProgress === 0 && users.current) {
                        if (chunkIndex + 1 == data.chunks.length) {
                            $rootScope.$broadcast('upload.rejected');
                            data.uploadHistory.filter(function (f) { return !f.rejected; });
                            if (data.uploadedFiles.length) {
                                $rootScope.$broadcast('activity.happened', 'uploaded', 'files', data.uploadedFiles.slice(), data.originFolderId);
                                if (customEvent) {
                                    $rootScope.$broadcast(customEvent, data.uploadedFiles);
                                }
                            } else {
                            }
                        } else {
                            chunkWiseUpload(customEvent, chunkIndex + 1, data.rejectedChunks, false);
                        }
                    }
                }
            }

            function chunkWiseUpload(customEvent, chunkIndex, chunksData, isAccepted) {
                data.chunks = chunksData;
                if (isAccepted) {
                    data.uploadsInProgress = data.chunks[chunkIndex].length;
                } else {
                    data.rejectedInProgress = data.chunks[chunkIndex].length;
                }

                for (var i = 0; i < data.chunks[chunkIndex].length; i++) {
                    var file = data.chunks[chunkIndex][i];

                    //push the file we're currently uploading into
                    //upload history and get it's index in the array
                    var index = data.uploadHistory.push(file) - 1;

                    // Get presigned url
                    //Client sends upload request to serrver
                    //Server ersponsds with some identification information, and presigned key url
                    var mime = file.type;
                    if (utils.extractExtension(file.name) == 'heic' || utils.extractExtension(file.name) == 'heif') {
                        mime = 'image/' + utils.extractExtension(file.name);
                    }
                    var params = {
                        folder: folders.selected.id,
                        mime: mime,
                        extension: utils.extractExtension(file.name),
                        file_size: file.size
                    };
                    uploadFileWithPresignedRequest(customEvent, chunkIndex, params, file, data.uploadHistory[index], isAccepted);
                }
            }

            return {
                upload: function (files, customEvent) {

                    customEvent = customEvent || null;
                    //if uploading from homepage is disabled and user is trying to upload from home we'll bail
                    if (!utils.getSetting('enableHomeUpload') && !users.current) {
                        return utils.showToast('loginToUpload', true);
                    }

                    setOriginFolderId();

                    //reset previous upload history in case there is any
                    data.uploadsInProgress = 0;
                    data.rejectedInProgress = 0;
                    data.uploadHistory = [];
                    data.uploadedFiles = [];
                    data.chunks = [];
                    data.acceptedChunks = [];
                    data.rejectedChunks = [];

                    //if there are no files bail
                    if (!files || !files.length) {
                        return;
                    }

                    if (folders.selected.permission !== undefined && folders.selected.permission == 0) {
                        utils.showToast('UploadFileAccessError', true);
                        return;
                    }

                    files = filterOutInvalidFiles(files);

                    $rootScope.$broadcast('upload.started');
                    // data.chunks = getFileUploadChunks(files);

                    var chunks = getFileUploadChunks(files);
                    data.acceptedChunks = chunks['accepted'];
                    data.rejectedChunks = chunks['rejected'];

                    if (!data.acceptedChunks.length && data.rejectedChunks.length) {
                        $rootScope.$broadcast('upload.progress.visible', {item: false});
                        utils.confirm({
                            title: 'uploadImage.limitReach',
                            subcontentHTML: "You have reached your storage limit. Please remove any unwanted files to free up more space OR you can upgrade your storage capacity. ",
                            ok: 'OK',
                            type: 'alert'
                        });
                    }
                    if (data.acceptedChunks.length && !data.rejectedChunks.length) {
                        $rootScope.$broadcast('upload.progress.visible', {item: true});
                        chunkWiseUpload('forced.refresh', 0, data.acceptedChunks, true);
                    }
                    if (data.acceptedChunks.length && data.rejectedChunks.length) {
                        $rootScope.$broadcast('upload.progress.visible', {item: true});
                        chunkWiseUpload('limit.reached', 0, data.acceptedChunks, true);
                        chunkWiseUpload(customEvent, 0, data.rejectedChunks, false);
                    }
                    //make sure user is not trying to upload
                    //more files at the same time then allowed
                    // files = filterSimultUploads(files);
                    // for (var i = 0; i < files.length; i++) {
                    //     var file = files[i];

                    //     //push the file we're currently uploading into
                    //     //upload history and get it's index in the array
                    //     var index = data.uploadHistory.push(file) - 1;

                    //     sendRequest(file, data.uploadHistory[index], customEvent);
                    // }
                },
                inProgress: function () {
                    return data.uploadsInProgress;
                },
                rejectedProgress: function () {
                    return data.rejectedInProgress;
                },
                history: function () {
                    return data.uploadHistory; // does this return a ref or a copy?
                }
            };
        }])
        .directive('uploadProgressPanel', ['uploadManager', 'utils', 'fileTypes', function (uploadManager, utils, fileTypes) {
            return {
                restrict: 'E',
                scope: {
                },
                templateUrl: 'uploadProgressPanel.html',
                link: function ($scope) {
                    $scope.utils = utils; // sigh
                    $scope.fileTypes = fileTypes; // sigh
                    $scope.data = {
                        visible: uploadManager.inProgress() ? true : false,
                        uploadsInProgress: uploadManager.inProgress(),
                        rejectedInProgress: uploadManager.rejectedProgress(),
                        uploadHistory: uploadManager.history()
                    };
                    function update() {
                        $scope.data.uploadsInProgress = uploadManager.inProgress();
                        $scope.data.rejectedInProgress = uploadManager.rejectedProgress();
                        $scope.data.uploadHistory = uploadManager.history();
                    }

                    $scope.close = function () {
                        $scope.data.visible = false;
                    };

                    $scope.$on('upload.progress.visible', function (event, opt) {
                        if (opt.item) {
                            $scope.data.visible = true;
                        } else {
                            $scope.data.visible = false;
                        }
                    });

                    $scope.$on('upload.started', function () {
                        $scope.data.visible = true;
                        update();
                    });

                    $scope.$on('upload.progress', function () {
                        update();
                    });

                    $scope.$on('upload.rejected', function () {
                        $scope.data.rejectedInProgress = 0,
                            $scope.data.uploadHistory = uploadManager.history();
                    });

                    $scope.$on('upload.completed', function () {
                        $scope.data.uploadsInProgress = 0,
                            $scope.data.uploadHistory = uploadManager.history();
                    });

                }
            };
        }])
        .factory('newUploadManager', ['utils', '$rootScope', '$upload', '$q', function (utils, $rootScope, $upload, $q) {
            var fileTooBig = utils.trans('fileTooBig', { number: utils.getSetting('maxFileSize') }),
                invalidExtension = utils.trans('invalidExtension');
            var data = {
                //files that are currently being uploaded or have
                //already been upload or rejected during this session
                uploadHistory: [],

                //number of files that are currently being uploaded
                uploadsInProgress: 0,

                whitelist: getWhitelist(),
                blacklist: getBlacklist(),
                maxFileSize: utils.getSetting('maxFileSize'),

                //array of uploaded files models
                uploadPromises: []
            };

            function getWhitelist() {
                var wl = utils.getSetting('whitelist');

                if (wl && angular.isString(wl)) {
                    return wl.replace(/ /g, '').split(',');
                }
            }

            function getBlacklist() {
                var bl = utils.getSetting('blacklist');

                if (bl && angular.isString(bl)) {
                    return bl.replace(/ /g, '').split(',');
                }
            }

            /**
            * Check if file extension and size are valid.
            *
            * @param {object} file
            * @returns {boolean}
            */
            function fileNotValid(file) {
                var extensionNotValid = extensionInvalid(file);

                //turn max file size from mb to byes and compare to given file size
                var sizeNotValid = (parseInt(data.maxFileSize) * 1000000) <= file.size;

                if (extensionNotValid) {
                    file.rejectReason = invalidExtension;
                }

                if (sizeNotValid) {
                    file.rejectReason = fileTooBig;
                }

                return extensionNotValid || sizeNotValid;
            }

            /**
            * Check if given file upload extension is not valid.
            *
            * @param {object} file
            * @returns {boolean}
            */
            function extensionInvalid(file) {
                var mimeExt = file.type.split('/')[1];
                var nameExt = utils.extractExtension(file.name);

                if (data.whitelist) {
                    if (data.whitelist.indexOf(mimeExt) === -1) {
                        return true;
                    }
                    if (nameExt && data.whitelist.indexOf(nameExt) === -1) {
                        return true;
                    }
                }

                if (data.blacklist) {
                    if (data.blacklist.indexOf(mimeExt) > -1) {
                        return true;
                    }
                    if (nameExt && data.blacklist.indexOf(nameExt) > -1) {
                        return true;
                    }
                }
            }

            /**
            * Remove files that don't pass validation from given array.
            *
            * @param {array|FileList} files
            * @returns {array}
            */
            function filterOutInvalidFiles(files) {
                if (!files || !files.length) {
                    return [];
                }

                var arrayOfFiles = files;
                if (files instanceof FileList) {
                    arrayOfFiles = [];
                    for (var ii = 0; ii < files.length; ii++) {
                        arrayOfFiles.push(files[ii]);
                    }
                }

                return arrayOfFiles.filter(function (file) {

                    //filter out any directories if uploading a folder
                    if (file.type === 'directory') {
                        return false;
                    }

                    //filter out any invalid files so we don't send them
                    //to server, but add them to upload history as rejected
                    else if (fileNotValid(file)) {
                        file.uploaded = true;
                        file.rejected = true;
                        data.uploadHistory.push(file);

                        return false;
                    }

                    return true;
                });
            }

            function sendRequest(historyItem, file, params) {
                return $q(function (resolve, reject) {
                    $upload.upload({
                        url: params.url,
                        file: file,
                        fileName: file.newName !== undefined ? file.newName : null,
                        fields: params.fields
                    }).progress(function (evt) {
                        historyItem.percentageUploaded = parseInt(100.0 * evt.loaded / evt.total);
                        historyItem.bytesUploaded = utils.formatFileSize(evt.loaded);

                        if (params.progress) {
                            params.progress({
                                $progress: historyItem
                            });
                        }
                    }).success(function (successData) {
                        historyItem.uploadedUrl = successData.url;
                        historyItem.shareId = successData.share_id ? successData.share_id : '';
                        if (successData.rejected && successData.rejected.length) {
                            historyItem.rejected = true;
                            historyItem.rejectReason = successData.rejected;
                        }
                    }).error(function (errorData) {
                        historyItem.rejected = true;

                        if (angular.isString(errorData) && errorData.length < 300) {
                            historyItem.rejectReason = errorData;
                            utils.showToast(errorData, null, true);
                        }
                    }).finally(function () {
                        historyItem.uploaded = true;
                        data.uploadsInProgress -= 1;

                        if (historyItem.rejected) {
                            reject(historyItem);
                        } else {
                            resolve(historyItem);
                        }
                    });
                });
            }

            return {
                upload: function (files, params) {
                    return $q(function (resolve, reject) {
                        if (!params.url) {
                            reject("Must have a url in the params!");
                        } else if (!params.fields) {
                            reject("Must have a fields in the params!");
                        } else if (!files || !files.length) {
                            reject("No files to process.");
                        }

                        files = filterOutInvalidFiles(files);
                        if (files.length) {
                            var promises = [];
                            for (var i = 0; i < files.length; i++) {
                                var file = files[i];

                                //push the file we're currently uploading into
                                //upload history and get it's index in the array
                                var index = data.uploadHistory.push(file) - 1;

                                promises.push(sendRequest(data.uploadHistory[index], file, params));
                            }

                            $q.all(promises).then(function (data) {
                                resolve(data);
                            }, reject);
                        } else {
                            reject("No files to process after filtering");
                        }
                    });
                }
            };
        }]);
}());