angular.module('app').factory('offers', ['$http', '$rootScope', '$state', '$mdDialog', 'utils', 'files', 'api', function($http, $rootScope, $state, $mdDialog, utils, files, api) {

    var offers = {

        /**
         * All offers
         */
        all: [],

        // /**
        //  * Paginate all existing offers.
        //  *
        //  * @returns {promise}
        //  */
        //  paginate: function(params) {
        //    return $http.get('offers', {params: params});
        // },

        /**
         * Create a new offer with given info.
         *
         * @param {object} info
         * @returns {promise}
         */
        create: function(offer_info) {
            return $http.post('offers', offer_info);
        },

        /**
         * Delete given offer from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(values) {
            var promise;
          
            if (angular.isArray(values)) {
                promise = $http.post('offers/delete-all/', {offers:values});
            } else {
                promise = $http.delete('offers/'+values.id);
                values = [values];
            }

            return promise.then(function(response) {
                offers.all = offers.all.filter(function(offer) {
                    return values.indexOf(offer) === -1;
                });
                utils.showToast(response.data);
            });
        },

        /**
         * Update the offer
         *
         * @returns {string|undefined}
         */
        updateOffer: function(offer, id) {
            var offerId  = id || this.current.id; 
            return $http.put($rootScope.baseUrl+'offers/'+offerId, offer);
        }  
    };

    return offers;
}]);
