(function(){
    'use strict';

    angular.module('pixie.dashboard')
    // .value("familyPanelState", {
    //     open: true
    // })
    // .directive('edToggleFamilyPanel', ['$rootScope', 'familyPanelState', 'rightPanel', 'localStorage', function($rootScope, familyPanelState, rightPanel, localStorage) {
    //     return {
    //         restrict: 'A',
    //         link: function($scope, el) {
    //             el.on('click', function() {
    //                 $scope.$apply(function() {
    //                     familyPanelState.open = !familyPanelState.open;
    //                     if (familyPanelState.open) {
    //                         rightPanel.open = false;
    //                         localStorage.set('rightPanelOpen', false);
    //                         $rootScope.$broadcast('familyPanel.reload');
    //                     }
    //                     localStorage.set('familyPanelOpen', familyPanelState.open);
    //                 });
    //             });
    //         }
    //     };
    // }])

    // .directive('edFamilyPanelCloser', ['familyPanelState', 'localStorage', function(familyPanelState, localStorage) {
    //     return {
    //         restrict: 'A',
    //         link: function($scope, el) {
    //             el.on('click', function() {
    //                 $scope.$apply(function() {
    //                     familyPanelState.open = false;
    //                     localStorage.set('familyPanelOpen', false);
    //                 });
    //             });
    //         }
    //     };
    // }])

    .directive('familyPanel', ['utils', 'fileTypes', 'api', 'selectedItems' , 'dialogManager', 'folders', '$rootScope', function(utils, fileTypes, api, selectedItems, dialogManager, folders, $rootScope) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/directives/family-panel.html',
            scope : {
                userId : "="
            },
            link: function($scope, el, attr) {
                $scope.data = {
                    familyfiles: [],
                    sharedFiles: [],
                    loading: false
                };

                function adjustFamilyResponse(response) {

                    var items = [];
                    angular.forEach(response, function(item) {
                        var sharedItem = item;
                        sharedItem.imgUrl = getImageUrl(item);
                        if (sharedItem.imgUrl) {
                            sharedItem.icon = null;
                        } else {
                            sharedItem.icon = fileTypes.getMimeIcon(item.mime);
                        }

                        items.push(sharedItem);
                    });

                    return items;
                }

                function getImageUrl(item) {
                    if (fileTypes.isImage(item.mime) || (item.flags & 0x02)) {
                        return $rootScope.baseUrl+'user-file/'+item.share_id+'?thumb=1';
                    }
                    else if(item.type=='folder')
                    {
                        return (item.class_type == 'normal') ?
                            'assets/images/specialfolders/folder.png' :
                            'assets/images/specialfolders/'+item.class_type+'.png';
                    } else {
                        return null;
                    }
                }

                function load(userId) {
                    if ($scope.data.loading) { return; }

                    $scope.data.loading = true;

                    api.share.hiveFiles($scope.userId).then(function(data) {
                        $scope.data.familyfiles = adjustFamilyResponse(data['sharedByMe']);
                        $scope.data.sharedFiles = adjustFamilyResponse(data['sharedToMe']);
                    }).finally(function() {
                        $scope.data.loading = false;
                    });
                }

                $scope.unshare = function(item) {
                    // Chaging to current user
                    item.user_id = $scope.userId;
                    api.share.unshare(item).then(function(data) {
                        $scope.data.familyfiles = adjustFamilyResponse(data);
                    }, function(data) {
                        utils.showToast(data);
                    });
                };

                $scope.unlink = function(item) {
                    api.share.unlink([ item ]).then(function() {
                        $scope.data.sharedFiles = $scope.data.sharedFiles.filter(function(file) {
                            return file.id != item.id;
                        });
                    });
                };

                $scope.openItem = function(item) {
                    if (item.type == 'folder') {
                         if (!item.is_locked) {
                            if ( !folders.available.length ) {
                                folders.getFromCache();
                            }
                            folders.open(item.share_id);
                         } else {
                            console.log("locked folder issue");
                            // FIX ISSUE:
                            //     dialogManager.showUnlockDialog(this.first()).then(function success(item) {
                            //         selectedItems.previewFolder(link_shareable_id);
                            //     }, function err(msg) {
                            //         // already shown.
                            //     });

                         }
                    } else if (item.type == 'file') {
                        dialogManager.showPreviewDialog(undefined, [item]).then(function(data) {
                            if(data.postVerb == 'share') {
                                // share this item.
                                dialogManager.showShareDialog(undefined, data.item);
                            }
                        }, function() {
                            // don't care.
                        });
                        $rootScope.$emit('preview.shown'); // not sure if we need htis.
                    }
                };

                $scope.$watch('userId', function() {
                    if ($scope.userId) {
                        load($scope.userId);
                    }
                });
            }
        };
    }]);
}());