angular.module('pixie.dashboard')

.directive("htmlInput", ['$timeout', function($timeout) {
	return {
		restrict: "E",
		require: "ngModel",
		scope: {
			onEnter : '&',
			disabled: '&ngDisabled',
			info: '=info'
		},
		link: function($scope, element, attrs, ngModel) {

			element.attr('contenteditable', true);

			function read() {
				// view -> model
				var html = element.html();
				html = html.replace(/&nbsp;/g, "\u00a0");
				ngModel.$setViewValue(html);
			}

			// model -> view
			ngModel.$render = function() {
				element.html(ngModel.$viewValue || "");
			};

			element.bind("blur", function() {
				$scope.$apply(read);
			});

			element.bind("keydown keypress", function (event) {
				if ($scope.disabled()) {
					return event.preventDefault();
				}

				if(event.which === 13 && !event.shiftKey) {
					this.blur();
					event.preventDefault();
					if ($scope.onEnter) {
						$scope.$apply(function() {
							$scope.onEnter();
						});
					}
				}
			});

			element.bind("keyup", function(event) {
				$scope.$apply(read);
			});

			$scope.$watch('disabled', function(oldVal, newVal) {
				if (oldVal != newVal) {
					element.attr('contenteditable', newVal);
				}
			});

			$scope.$watch('info', function(val) {
				if (val && Object.keys(val).length !== 0) {
					var type = 'Image';
					var url = '';
					if ('posterUrl' in val) {
						type = 'Video';
						url = val.posterUrl;
					} else {
						url = val.url;
					}
					var altText = val.shareId ? 'alt="' + type +'Id:' + val.shareId + '" ' : '';
					element.prepend('<div id="postImage"><img src="'+ url + '" ' + altText + '></div><br>');
					angular.element(element).find("img").on("load", function() {
						$timeout(function(){
							setCaret();
							$scope.$digest();
						});
					});
				}
			});

			function setCaret() {
				var el = document.getElementById("editable");
				var range = document.createRange();
				var sel = window.getSelection();
				var lastNode = el.childNodes[el.childNodes.length-1];
				range.setStart(lastNode, lastNode.length ? lastNode.length : 0);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
				el.focus();
			}
		}
	};
}]);