(function(){
    'use strict';

	var module = angular.module('storyhives.facebook', []);

    module.directive('facebookImport', ['$timeout', '$parse', 'utils', '$http', 'dialogManager', '$rootScope', '$window', 'ezfb', '$interval', '$state', 'folders', function($timeout, $parse, utils, $http, dialogManager, $rootScope, $window, ezfb, $interval, $state, folders) {
		return {
			restrict: 'E', // can't have scope, or completion won't fire.
			link: function ($scope, $element, $attrs) {
				$scope.data = {
					facebookImportStatus: '',
					fbLogin: false
				};

				$rootScope.$on('facebookImportStatusCompleted', function() {					
					$scope.stopInterval();
					$http.post('users/facebook-import-finished',  { success: true });
				});

				$rootScope.$on('facebookLoginFailed', function() {					
					$scope.stopInterval();
					$http.post('users/facebook-import-finished',  { success: false });
				});

				$scope.facebookStatusCount = 0;
				$scope.getfacebookStatus = function () {
					$http.get('users/facebook-import-status').then(function (response) {
						$scope.data.facebookImportStatus = response.data || 'none';
						$rootScope.$broadcast('facebookImportStatusChanged', $scope.data.facebookImportStatus);
						console.log("Facebook Import Status: ", $scope.data.facebookImportStatus);
						if ($scope.data.facebookImportStatus == 'completed' && $scope.facebookStatusCount > 0) {
							$scope.stopInterval();
							$http.get('getfacebook').then(function(response) {
								if (response.data.folder && response.data.folder.length) {
									folders.available = folders.available.concat(response.data.folder);
								}
								$rootScope.$broadcast('activity.happened', 'refresh', 'folder', response.data.folder);
						   });
						}
						$scope.facebookStatusCount++;
					}, function (error) {
						utils.showToast(error);
					});
				};

				$scope.facebookImport = function ($event) {
					$scope.getfacebookStatus();
					dialogManager.showFacebookImportDialog();
				};
				$scope.stopInterval = function () {
					$interval.cancel($scope.intervalPromise);
				};
				if(!$rootScope.$$listenerCount['startImportingImages']){
					$rootScope.$on('startImportingImages', function (event, data) {
						console.log("start importing");
						/**
						 * Calling FB.login with required permissions specified
						 * https://developers.facebook.com/docs/reference/javascript/FB.login/v2.0
						 */
						console.log('ezfb', ezfb.$$ready);
						$scope.data.fbLogin = true;
						ezfb.login(function (res) {
							console.log('login');
							/**
							 * no manual $scope.$apply, I got that handled
							 */
							if (res.authResponse) {
								if (res.status == 'connected') {
									$scope.intervalPromise = $interval(function () { $scope.getfacebookStatus(); }, 15000);
									// We exchange short-term token (1 hour duration) for a long-term token, in case the process takes more than 1 hour and need to cron it																								
									$http.post('users/token-exchange', { accessToken: res.authResponse.accessToken }).then(function (responseExchange) {
										var data = responseExchange.data;

										while (data.substr(data.length - 1) != '}') { // Sometimes the response get a character after the }
											data = responseExchange.data.slice(0, -1);
										}

										data = JSON.parse(data);
												
										$http.post('users/get-facebook-photos', { 'accessToken': data.access_token }).then(function (response) {
										}, function () {
											$http.post('users/delete-run-row').then(function () {
												$rootScope.$broadcast('facebookLoginFailed');
											});
										});
									}, function () {
										$http.post('users/delete-run-row').then(function () {
											$rootScope.$broadcast('facebookLoginFailed');
										});
									});
								} else {
									$http.post('users/delete-run-row').then(function () {
										$rootScope.$broadcast('facebookLoginFailed');
									});
								}
							}
						}, { scope: 'user_photos' });
					});
				}
			}
		};
    }]);
}());
