(function () {
'use strict';

    angular.module('app')
    .directive('sharedFilesView', ['$rootScope', 'users', '$stateParams', '$state', 'selectedItems', 'fileTypes', 'utils', '$http', function($rootScope, users, $stateParams, $state, selectedItems, fileTypes, utils, $http) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/view.html',
            link: function($scope, $element, $attr) {
                $scope.selectedItems = selectedItems;
                $scope.fileTypes = fileTypes;
                $scope.items = [];
                // $scope.previewStatus = { open: false };
                $scope.data = {
                    preview: {
                        items: [],
                        index: 0
                    },
                    state: 'preview',
                    sharePassword: ''
                };
                $scope.shareable_id = $stateParams.id;

                //ads html trusted by angular
                if ( !users.current.isSubscribed) {
                    $scope.ad1 = utils.trustHtml(utils.getSetting('ad_preview_slot_1'));
                    $scope.ad2 = utils.trustHtml(utils.getSetting('ad_preview_slot_2'));
                }

                function shareableContent(params) {
                    $http.post('shareable/preview', params).then(function(response) {
                        assignShareable(response.data);
                    }, function() {
                        if (users.current) {
                            $state.go('dashboard.folders');
                        } else {
                            $state.go('home');
                        }
                    });
                }

                //if we already have a shareable on rootScope preview that
                if ($rootScope.shareable && users.current) {
                    assignShareable($rootScope.shareable);

                // //otherwise fetch shareable from server based on state params
                } else {
                    shareableContent($stateParams);
                }

                // preview emits this if we're sharing.
                $rootScope.$on('share.fetch_child', function(e, folder) {
                    // $state.go('view', { type: $stateParams.type, id: $stateParams.id, name: $stateParams.name, route: folder.share_id });
                    $state.go('view', { route: folder.share_id });
                });

                function assignShareable(shareable) {
                    $scope.shareable = shareable;
                    $scope.type = shareable.type;


                    // selectedItems.setAll(shareable.files || shareable);
                    // $scope.previewStatus = { open: true };
                    if($scope.type == 'file'){
                        $scope.data.preview.items = shareable.files || [ shareable ];
                        $scope.data.preview.index = 0;
                        $scope.data.state = 'preview';
                    } else {
                        // bad form here: FIXME: this list shouldn't be available until unlocked.
                        $scope.items = shareable.files.concat(shareable.folders);

                        //ask for password if it's not currently logged in users file and it's password protected
                        if(shareable.is_locked) {
                            $scope.data.state = 'password';
                        } else {
                            $scope.data.state = 'grid'; 
                        }
                    }
        
                    $scope.$broadcast('shareable.ready', shareable);
                }
    
                $scope.nextItem = function() {
                    if($scope.data.preview.index + 1 < $scope.data.preview.items.length) {
                        $scope.data.preview.index += 1;
                    }
                };

                $scope.prevItem = function() {
                    if($scope.data.preview.index - 1 >= 0) {
                        $scope.data.preview.index -= 1;
                    }
                };
    
                $scope.passwordChange = function() { 
                    // we don't know.
                    $scope.sharePasswordForm.password.$setValidity('incorrect', true);
                };

                $scope.checkPassword = function() {
                    var payload = {
                        id: $scope.shareable.id,
                        type: $scope.type,
                        password: $scope.data.sharePassword
                    };

                    $http.post('shareable-password/check', payload).then(function() {
                        // NOTE: Removed for FF-46
                        // selectedItems.password = $scope.password;
                        //$scope.passContainerVisible = false;
                        $scope.data.state = 'grid';
                    }, function(response) {
                        $scope.sharePasswordForm.password.$setValidity('incorrect', false);
                        $scope.error = response.data;
                        utils.showToast(response.data);
                    });
                };

                $scope.getSize = function(item) {
                    var result = '';
                    if (item) {
                        var size = item.type === 'file' ? utils.formatFileSize(item.file_size) : utils.getFolderSize(item);

                        if (size) {
                            result = size;
                        }
                    }
        
                    return result;
                };

                $scope.$on('$destroy', function() {
                    $rootScope.shareable = false;
                    selectedItems.deselectAll();
                });
            }
        };
    }]);
}());
