(function () {
  'use strict';

  angular.module('pixie.dashboard')
    .directive('buzzNavigation', ['$rootScope', function ($rootScope) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: 'assets/views/directives/buzz-navigation.html',
        link: function ($scope, $element) {
          $scope.data = {
            selectedTabOption: -1
          };

          $scope.openTabMenu = function (tabOption) {
            $scope.data.selectedTabOption = tabOption;
            $rootScope.$broadcast('left.buzzOption.clicked', tabOption);
            // $rootScope.$broadcast('buzz.tab.switched', tabOption);
          };

        }
      };
    }]);
})();