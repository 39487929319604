(function () {
    'use strict';
    angular.module('app')
        .config(['$urlRouterProvider', '$stateProvider', '$urlMatcherFactoryProvider', '$locationProvider', function ($urlRouterProvider, $stateProvider, $urlMatcherFactoryProvider, $locationProvider) {

            //enable html5 pushState if user selected it
            if (parseInt(vars.settings.enablePushState)) {
                $locationProvider.html5Mode(true);
            }

            $urlMatcherFactoryProvider.strictMode(false);

            // what is this?
            $urlRouterProvider.when('/dashboard', '/dashboard/folders');
            $urlRouterProvider.otherwise('/');

            $stateProvider
                .state('home', {
                    url: '/',
                    template: '<new-home-page></new-home-page>',
                })
                .state('new_home', {
                    url: '/nh',
                    template: '<new-home-page></new-home-page>',
                })
                .state('terms', {
                    url: '/page/terms',
                    template: '<static-page page="terms"></static-page>',
                })
                .state('privacy', {
                    url: '/page/privacy',
                    template: '<static-page page="privacy"></static-page>',
                })
                .state('about', {
                    url: '/page/about-us',
                    template: '<static-page page="about"></static-page>',
                })
                .state('refund', {
                    url: '/page/refund-policy',
                    template: '<static-page page="refund-policy"></static-page>',
                })
                .state('contactus', {
                    url: '/contact-us',
                    template: '<static-page page="contact-us"></static-page>'
                })
                .state('antispam', {
                    url: '/page/antispam-policy',
                    template: '<static-page page="antispam-policy"></static-page>',
                })
                .state('share', {
                    url: '/share/:link',
                    controller: ['$stateParams', '$state', 'users', function ($stateParams, $state, users) {
                        if ($stateParams && $stateParams.link !== undefined) {
                            if (users.current) {
                                $state.go('dashboard.folders', { share_link: $stateParams.link });
                            } else {
                                $state.go('login', { share_link: $stateParams.link });
                            }
                            return;
                        }
                    }]
                })
                .state('room-invite', {
                    url: '/room-invite/:link',
                    template: '<room-invite></room-invite>'
                })
                .state('family-invite', {
                    url: '/family-invite/:link',
                    template: '<family-invite></family-invite>'
                })
                .state('executor-request', {
                    url: '/executor-request/:hash',
                    template: '<executor></executor>',
                })
                .state('view', {
                    url: '/view/:type/:id/:name?route',
                    template: '<shared-files-view></shared-files-view>',
                    params: {
                        permission: { value: null }
                    }
                })
                .state('login', {
                    url: '/login?share_link?family_invite_link?remote_auth_key?remote_user?client?room_link',
                    templateUrl: 'assets/views/login.html',
                    controller: 'LoginController',
                    params: {
                        nextState: { value: null },
                        additionalParams: { value: null },
                        room_link: { value: null },
                        share_link: { value: null },
                        family_invite_link: { value: null },
                        remote_auth_key: { value: null },
                        remote_user: { value: null },
                        client: { value: null }
                    }
                })
                .state('register', {
                    url: '/register?share_refer?share_link?family_invite_link?room_link',
                    templateUrl: 'assets/views/register.html',
                    controller: 'RegisterController',
                    params: {
                        share_refer: { value: null },
                        share_link: { value: null },
                        family_invite_link: { value: null },
                        room_link: { value: null }
                    }
                })
                .state('dashboard', {
                    url: '/dashboard',
                    abstract: true,
                    templateUrl: 'assets/views/dashboard/newdashboard.html', // rdm testing
                })
                .state('dashboard.trash', {
                    url: '/trash',
                    template: '<dashboard-trash-contents></dashboard-trash-contents>',
                })
                .state('dashboard.recent', {
                    url: '/recent',
                    template: '<dashboard-recent-contents></dashboard-recent-contents>',
                })
                .state('dashboard.favorites', {
                    url: '/favorites',
                    template: '<dashboard-favorite-contents></dashboard-favorite-contents>',
                })
                .state('dashboard.search', {
                    url: '/search/:query',
                    template: '<dashboard-search-contents></dashboard-search-contents>',
                })
                .state('dashboard.folders', {
                    url: '/folders/:folderId?share_link?question?manage_email?family_invite_link',
                    template: '<dashboard-file-contents></dashboard-file-contents>',
                    params: {
                        folderId: { value: null }, // should be folderId
                        share_link: { value: null },
                        question: { value: null },
                    }
                })
                .state('dashboard.foldersRoot', {
                    url: '/folders?question?share_link?manage_email?family_invite_link',
                    template: '<dashboard-file-contents></dashboard-file-contents>',
                    params: {
                        folderId: { value: null },
                        share_link: { value: null },
                        question: { value: null },
                    },
                })
                .state('dashboard.footer', {
                    url: '/page/:slug',
                    template: '<static-page></static-page>',
                    params: {
                        slug: { value: null }
                    }
                })
                .state('dashboard.invoices', {
                    url: '/invoices',
                    template: '<user-invoices></user-invoices>'
                })
                .state('dashboard.help', {
                    url: '/help',
                    template: '<help-page></help-page>'
                })
                .state('dashboard.hives', {
                    url: '/hives',
                    template: '<div ui-view="hive-view"><hive-view></hive-view></div>',
                    params: {
                        family_invite_link: { value: null }
                    },
                })
                .state('dashboard.hives.details', {
                    url: '/:hiveId',
                    views: {
                        'hive-view': {
                            template: '<div ui-view="hive-detail-view"><hive-detail-view></hive-detail-view></div>'
                        }
                    },
                    params: {
                        hiveId: { value: null }
                    }
                })
                .state('dashboard.hives.details.member', {
                    url: '/member/:memberId',
                    views: {
                        'hive-detail-view': {
                            template: '<family-page></family-page>'
                        }
                    },
                    params: {
                        memberId: { value: null }
                    },
                    redirectTo: 'dashboard.hives.details.member.files',
                })
                .state('dashboard.hives.details.member.files', {
                    url: '/files',
                    views: {
                        'hive-detail-view': {
                            template: '<family-page></family-page>'
                        }
                    },
                    params: {
                        memberId: { value: null }
                    }
                })
                .state('dashboard.hives.details.member.stories', {
                    url: '/stories',
                    views: {
                        'hive-detail-view': {
                            template: '<family-page></family-page>'
                        }
                    },
                    params: {
                        memberId: { value: null }
                    }
                })
                .state('dashboard.hives.details.member.post', {
                    url: '/post',
                    views: {
                        'hive-detail-view': {
                            template: '<family-page></family-page>'
                        }
                    },
                    params: {
                        memberId: { value: null }
                    }
                })
                .state('dashboard.story', {
                    url: '/story',
                    template: '<story-page></story-page>',
                    params: {
                        manage_email: { value: null },
                        manage_orgs: { value: null },
                    },
                })
                .state('dashboard.story.view', {
                    url: '/view',
                    template: '<story-page></story-page>',
                    params: {
                        manage_email: { value: null },
                        manage_orgs: { value: null },
                    },
                })
                .state('dashboard.story.current', {
                    url: '/:sectionId/:topicId',
                    template: '<story-page></story-page>',
                    params: {
                        manage_email: { value: null },
                        manage_orgs: { value: null },
                    },
                })
                .state('dashboard.buzz', {
                    url: '/buzz',
                    template: '<buzz-page></buzz-page>',
                    params: {
                        incoming_call: { value: null },
                        video_chat: { value: null },
                    },
                })
                .state('dashboard.buzz.timeline', {
                    url: '/timeline',
                    views: {
                        'buzz-view': {}
                    }
                })
                .state('dashboard.buzz.timeline.date', {
                    url: '/:date',
                    views: {
                        'buzz-view': {}
                    }
                })
                .state('dashboard.buzz.chat', {
                    url: '/chat',
                    views: {
                        'buzz-view': {}
                    }
                })
                .state('dashboard.buzz.video', {
                    url: '/video',
                    views: {
                        'buzz-view': {}
                    }
                })
                .state('dashboard.rooms', {
                    url: '/rooms/:roomId',
                    template: '<rooms-page></rooms-pagae>',
                    params: {
                        roomId: { value: null }
                    }
                })
                //ADMIN
                .state('admin', {
                    url: '/admin',
                    abstract: true,
                    templateUrl: 'assets/views/admin/admin.html',
                    controller: 'AdminController',
                })
                .state('admin.users', {
                    url: '/users',
                    templateUrl: 'assets/views/admin/users.html'
                })
                .state('admin.files', {
                    url: '/files',
                    templateUrl: 'assets/views/admin/files.html'
                }).state('admin.folders', {
                    url: '/folders',
                    templateUrl: 'assets/views/admin/folders.html'
                })
                .state('admin.analytics', {
                    url: '/analytics',
                    templateUrl: 'assets/views/admin/analytics.html',
                    controller: 'AnalyticsController'
                })
                .state('admin.settings', {
                    url: '/settings',
                    templateUrl: 'assets/views/admin/settings.html',
                    controller: 'SettingsController'
                })
                .state('admin.ads', {
                    url: '/ads',
                    templateUrl: 'assets/views/admin/admin-ads.html',
                    controller: 'SettingsController'
                })
                .state('admin.questions', {
                    url: '/questions',
                    templateUrl: 'assets/views/admin/questions.html'
                })
                .state('admin.emailtemplates', {
                    url: '/emailtemplates',
                    templateUrl: 'assets/views/admin/emailtemplates.html'
                })
                .state('admin.offers', {
                    url: '/offers',
                    templateUrl: 'assets/views/admin/offers.html'
                })
                .state('admin.sections', {
                    url: '/story-section',
                    templateUrl: 'assets/views/admin/sections.html'
                })
                .state('admin.footermenu', {
                    url: '/footermenu',
                    template: '<admin-footer-menu></admin-footer-menu>'
                })
                .state('admin.faq', {
                    url: '/faq',
                    template: '<admin-faq></admin-faq>'
                }).state('admin.firstrun', {
                    url: '/firstrun',
                    template: '<admin-first-run></admin-first-run>'
                })
                .state('admin.introdialogs', {
                    url: '/intro-dialogs',
                    template: '<admin-intro-dialogs></admin-intro-dialogs>'
                })
                .state('admin.organizations', {
                    url: '/organizations',
                    template: '<admin-organizations></admin-organizations>',
                    params: {
                        filter_type: { value: null },
                    },
                })
                .state('admin.organization', {
                    url: '/organization',
                    template: '<admin-edit-organization></admin-edit-organization>'
                })
                .state('admin.organizationJoinRequests', {
                    url: '/join-requests',
                    template: '<admin-join-requests></admin-join-requests>'
                })
                .state('admin.joinCodes', {
                    url: '/organizations-join-codes',
                    template: '<admin-join-codes></admin-join-codes>'
                })
                .state('admin.emailLogger', {
                    url: '/email-logger-list',
                    templateUrl: 'assets/views/admin/emaillogger.html'
                });
        }]).
        run(['$rootScope', '$state', function($rootScope, $state) {
            $rootScope.$on('$stateChangeStart', function(evt, to, params) {
              if (to.redirectTo) {
                evt.preventDefault();
                  $state.go(to.redirectTo, params, { location: 'replace' });
              }
            });
        }]);
}());
