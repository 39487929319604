(function(){
    'use strict';

    angular.module('app').controller('UsersController', ['$scope', '$rootScope', '$state', '$mdDialog', 'utils', 'users', 'dialogManager', 'api', function($scope, $rootScope, $state, $mdDialog, utils, users, dialogManager, api) {
        var requests = ['All', 'Admin', 'Non-Profit', 'Org Owner', 'None'];
        $scope.users = users;
        $scope.params = {};

        //users search query
        $scope.search = { query: '' };
        $scope.selectedItems = [];
        $scope.data = {
            filterType: 'Show',
            requestType: requests[0],
            requests: requests,
        };

        $scope.isItemSelected = function(item) {
            return $scope.selectedItems.indexOf(item) > -1;
        };

        $scope.select = function(item) {
            var idx = $scope.selectedItems.indexOf(item);
            if (idx > -1) {
                $scope.selectedItems.splice(idx, 1);
            }
            else {
                $scope.selectedItems.push(item);
            }
        };

        $scope.makeAdmin = function() {
            var ids = $scope.selectedItems.map(function(item) { return item.id; });
            api.user.makeAdmin(ids).then(function(response) {
                utils.showToast(utils.trans(response, true));
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        $scope.removeAdmin = function() {
            var ids = $scope.selectedItems.map(function(item) { return item.id; });
            api.user.removeAdmin(ids).then(function(response) {
                utils.showToast(utils.trans(response, true));
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        //filter model for checkbox to filter photos attach/not attached to user
        $scope.showNotAttachedPhotosOnly = false;

        function rejectUserDelete(toShowDialog, counter, dontDelete, itemIdx) {
            dontDelete.push(itemIdx);
            counter++;
            showWarningDialogs(toShowDialog, counter, dontDelete);
        }

        function confirmUserDelete(toShowDialog, counter, dontDelete, itemIdx) {
            counter++;
            showWarningDialogs(toShowDialog, counter, dontDelete);
        }

        function showWarningDialogs (toShowDialog, counter, dontDelete) {
            if (counter == toShowDialog.length) {
                var toDelete = [];
                for (var i = 0; i < $scope.selectedItems.length; i++) {
                    if (dontDelete.indexOf(i) == -1) {
                        toDelete.push($scope.selectedItems[i]);
                    }
                }
                if (toDelete.length) {
                    removeUsers(toDelete);
                }
            } else {
                var itemIdx = toShowDialog[counter];
                if ($scope.selectedItems[itemIdx].isOrgOwner) {
                    utils.confirm({
                        title: 'organization.ownerAccountDeleteWarning',
                        subcontentHTML: "User " + users.getName($scope.selectedItems[itemIdx]) + " is currently the owner of <b>" + $scope.selectedItems[itemIdx].organization.name + "</b>. You must choose a new owner for the organization before you can mark this user for deletion.",
                        ok: 'OK',
                        type: 'alert',
                        onConfirm: function() {
                            rejectUserDelete(toShowDialog, counter, dontDelete, itemIdx);
                        }
                    });
                } else if ($scope.selectedItems[itemIdx].isSubscribed) {
                    var content = '';
                    if ($scope.selectedItems[itemIdx].organization_id && Object.keys($scope.selectedItems[itemIdx].permissions).length && ('is_nonprofit' in $scope.selectedItems[itemIdx].permissions)) {
                        content = utils.trans('admin.userDeleteWarningText', {name: users.getName($scope.selectedItems[itemIdx])});
                    } else if (!Object.keys($scope.selectedItems[itemIdx].permissions).length) {
                        content = utils.trans('admin.userPaidDeleteWarningText', {name: users.getName($scope.selectedItems[itemIdx])});
                    }

                    if (content) {
                        utils.confirm({
                            title: 'admin.userDeleteWarning',
                            content: content,
                            ok: 'admin.continue',
                            cancel: 'admin.cancel',
                            onConfirm: function() {
                                confirmUserDelete(toShowDialog, counter, dontDelete, itemIdx);
                            },
                            onRejection: function() {
                                rejectUserDelete(toShowDialog, counter, dontDelete, itemIdx);
                            }
                        });
                    } else {
                        confirmUserDelete(toShowDialog, counter, dontDelete, itemIdx);
                    }
                } else {
                    confirmUserDelete(toShowDialog, counter, dontDelete, itemIdx);
                }
            }
        }

        $scope.deleteUsers = function() {
            var dontDelete = [];
            var toShowDialog = [];
            for (var i = 0; i < $scope.selectedItems.length; i++) {
                if ($scope.selectedItems[i].isOrgOwner || $scope.selectedItems[i].isSubscribed) {
                    toShowDialog.push(i);
                }
            }

            if (toShowDialog.length) {
                showWarningDialogs(toShowDialog, 0, dontDelete);
            } else if ($scope.selectedItems.length) {
                removeUsers($scope.selectedItems);
            }
        };

        $scope.permanentdeleteUsers = function() {
            utils.confirm({
                title: 'admin.userDeleteWarning',
                content: 'admin.userPermanentlyDeleteWarning',
                ok: 'admin.continue',
                cancel: 'admin.cancel',
                onConfirm: function () {
                    permanentlyRemoveUsers($scope.selectedItems);
                },
                onRejection: function () {
                }
            });
        };

        function permanentlyRemoveUsers(userList) {
            userList = userList.length ? userList : $scope.selectedItems;
            users.permanentlyDelete(userList).then(function() {
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        }

        function removeUsers(userList) {
            userList = userList.length ? userList : $scope.selectedItems;
            users.delete(userList).then(function() {
                $scope.selectedItems = [];
                $scope.paginate($scope.params);
            }, function(response) {
                utils.showToast(response.data);
            });
        }

        $scope.reactivateUser = function(user) {
            api.user.reactivate([user]).then(function(response) {
                user.deleted_at = null;
                utils.showToast(utils.trans('users.reactivated', true));
            }, function(response) {
                utils.showToast(response.data);
            });
        };

        $scope.toggleAllUsers = function() {

            //all items already selected, deselect all
            if ($scope.selectedItems.length === users.all.length) {
                $scope.selectedItems = [];
            }

            //all items aren't selected, copy all users array to selected items
            else {
                $scope.selectedItems = users.all.slice();
            }
        };

        $scope.showCreateUserModal = function($event) {
            dialogManager.showCreateUserDialog($event).then(function() {
                $scope.paginate($scope.params);
            }, function() {
                // cancelled
            });
        };

        $scope.accountCleanup = function() {
            dialogManager.showAccountCleanupDialog().then(function() {
            }, function() {
                // cancelled
            });
        };

        $scope.showUpdateUserModal = function(user, $event) {
            $event.stopPropagation(); // don't highlight the column.

            dialogManager.showUpdateUserDialog($event, user).then(function(){
                $scope.paginate($scope.params);
            }, function(){
                // cancelled
            });
        };

        $scope.sendTestQuestion = function($event, user) {
            $event.stopPropagation();

            var confirm = $mdDialog.confirm()
                .title('Send Test Question?')
                .textContent('Are you sure you want to send a test question to this user?')
                .targetEvent($event)
                .ok('Send')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function() {
                // send one.
                api.admin.questions.test(user.id).then(function(data) {
                    var alert = $mdDialog.alert()
                      .title("Test Question Sent")
                      .textContent("You sent this question to "+data.sentTo+" users.  (If this is zero, the user has turned off this email type.)")
                      .ok('Okay');
                      $mdDialog.show(alert);
                }, function() {
                    // debugger;
                });
            }, function() {
            });
        };

        $scope.filterUsers = function() {
            $scope.paginate($scope.params, true);
        };

        $scope.paginate = function(params, isFilter) {
            if ($scope.usersAjaxInProgress) {
                return;
            }
            $scope.usersAjaxInProgress = true;
            isFilter = isFilter || false;
            params = params || $scope.params;
            params['filter'] = $scope.data.filterType.toLowerCase();
            params['type'] = $scope.data.requestType.replace(" ", "").replace('-','').toLowerCase();
            if (isFilter) {
                params['page'] = 1;
            }
            $scope.items = [];
            users.paginate(params).then(function(response) {
                $scope.items = response.data.data;
                $scope.totalItems = response.data.total;

                $scope.usersAjaxInProgress = false;

                // clearing all selected items
                $scope.selectedItems = [];
            });
        };

        $scope.paginate($scope.params);
    }]);
}());