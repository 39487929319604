(function () {
  'use strict';

  angular.module('app.dialogs.preview')
    .directive('imagePreview', ['$upload', '$q', '$rootScope', '$timeout', function ($upload, $q, $rootScope, $timeout) {
      return {
        restrict: 'E',
        scope: {
          item: '='
        },
        templateUrl: 'assets/views/modals/image-preview.html',
        replace: true,
        link: function ($scope, el) {
          function reset() {
            if ($scope.item.is_locked) {
              return $scope.showLockContainer(); // FIXME!
            }
            $scope.isCropping = false;
            $scope.isFilter = false;
            $scope.hasChanges = false;
            $scope.previewURL = $scope.item.absoluteUrl;
            $scope.data.isRotation = false;
            $scope.data.isRotating = false;
            $scope.data.isEditing = false;
            $scope.data.isSaving = false;
            $scope.cropped = {
              image: null,
              blob: null,
              blobUrl: null
            };
          }

          $scope.data = {
            isEditing: false,
            filterTags: [{ name: 'Fluorescent', src: '' },
            { name: 'Sumie', src: '' },
            { name: 'Phykos', src: '' },
            { name: 'Lotus', src: '' },
            { name: 'Memphis', src: '' },
            { name: 'Deutlich', src: '' },
            { name: 'Nostalgia', src: '' }],
            filtering: false,
            isViewOnly: $scope.item.is_media_folder,
          };

          $scope.setRotation = function () {
            $scope.data.isRotation = true;
          };

          function setFilterImage(filter) {
            $scope.data.filterTags.forEach(function (filter) {
              var filteredImage = new Image();
              filteredImage.src = $scope.item.absoluteUrl;
              filter.src = ApplyEffects[filter.name.toLowerCase()](filteredImage);
            });
          }

          $scope.applyFilter = function (filter) {

            if ($scope.data.filtering) {
              return;
            }

            $scope.data.filtering = true;
            var filteredImage = new Image();
            filteredImage.src = $scope.item.absoluteUrl;
            $scope.previewURL = ApplyEffects[filter.toLowerCase()](filteredImage);
            $scope.data.filtering = false;
          };

          $scope.showImageFilters = function () {
            if (!$scope.data.filterImages) {
              setFilterImage();
            }

            $scope.isFilter = true;
            $scope.data.isEditing = true;
          };

          $scope.abortChanges = function () {
            reset();
          };

          $scope.setCropping = function () {
            $scope.isCropping = true;
            $scope.data.isEditing = true;
          };

          // since we might have this change out from under us.
          // nb: this is called on initial setup.
          $scope.$watch('item', function () {
            reset();
          });

          $scope.applyChanges = function () {
            var file;
            var successUrl;

            if ($scope.isCropping) {
              file = $scope.cropped.blob;
              successUrl = $scope.cropped.blobUrl;
            } else {
              file = dataURItoBlob($scope.previewURL);
              successUrl = $scope.previewURL;
            }

            $scope.data.isRotation = false;
            $scope.data.isSaving = true;
            $upload.upload({
              // url: $scope.baseUrl + 'files/replace-file/'+selectedItems.first().share_id, // rdm replaced
              url: '/files/replace-file/' + $scope.item.share_id,
              // url: $scope.baseUrl + 'files/replace-file/'+$scope.item.share_id,
              file: file
            }).success(function (data) {
              $scope.item.absoluteUrl = successUrl;
              reset();
              var items = [];
              data.uploaded.forEach(function (item) {
                items.push({'id':item.id});
              });
              $rootScope.$broadcast('update.item.thumb', items);
            }).error(function (data) {
              // FIXME
            }).finally(function () {
              $scope.data.isSaving = false;
            });
          };

          $scope.rotate = function (isClockwise) {
            if (!$scope.previewURL) {
              return;
            }

            $scope.data.isRotating = true;
            rotateBase64Image($scope.previewURL, isClockwise).then(function (result) {
              $scope.previewURL = result;
              $scope.data.isEditing = true;
              $scope.data.isRotating = false;
            });
          };

          function rotateBase64Image(base64data, isClockwise) {
            return $q(function (resolve, reject) {
              var image = new Image();
              image.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = image.height;
                canvas.height = image.width;
                var ctx = canvas.getContext("2d");
                var deg = isClockwise ? Math.PI / 2 : Math.PI / -2;
                // translate to center-canvas
                // the origin [0,0] is now center-canvas
                ctx.translate(canvas.width / 2, canvas.height / 2);
                // roate the canvas by +90% (==Math.PI/2)
                ctx.rotate(deg);
                // draw the signature
                // since images draw from top-left offset the draw by 1/2 width & height
                ctx.drawImage(image, -image.width / 2, -image.height / 2);
                // un-rotate the canvas by -90% (== -Math.PI/2)
                ctx.rotate(-deg);
                // un-translate the canvas back to origin==top-left canvas
                ctx.translate(-canvas.width / 2, -canvas.height / 2);

                $scope.$apply(function () {
                  resolve(canvas.toDataURL());
                });
              };

              // Tainted canvases may not be exported issue
              // Reason - https://stackoverflow.com/questions/22710627/tainted-canvases-may-not-be-exported
              image.crossOrigin = "Anonymous";
              image.src = base64data;
            });
          }

          function dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            //New Code
            return new Blob([ab], { type: mimeString });
          }
        }
      };
    }]);
}());
