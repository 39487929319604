(function () {
    'use strict';

    angular.module('app')
    .directive('editorImageLoader', ['$timeout', function($timeout) {
        return {
            restrict: 'E',
            scope: {
            },
            templateUrl: 'assets/views/directives/editorImageLoader.html',
            link: function($scope) {
                $scope.data = {
                    visible: false,
                    percentageUploaded: 0,
                    uploadCompleted: false
                };       
                
                $scope.$on('imgeditor.started', function() {
                    $scope.data.visible = true;
                    $scope.data.percentageUploaded = 0;
                });

                $scope.$on('imgeditor.progress', function(event, params) {
                    $scope.data.percentageUploaded = params.percentageUploaded;
                    if ($scope.data.percentageUploaded >= 100) {
                        $scope.data.uploadCompleted = true;
                    }
                });

                $scope.$on('imgeditor.completed', function(){                    
                    $timeout(function(){
                        $scope.data.visible = false;
                    }, 2000);                    
                });                
            }
        };
    }]);
}());  