(function(){
    'use strict';

    angular.module('pixie.dashboard')

    .directive('folderGridItemSelect', [ 'dialogManager', 'fileTypes', 'selectedItems', '$rootScope', 'localStorage', function(dialogManager, fileTypes, selectedItems, $rootScope, localStorage) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'assets/views/directives/folder-grid-item-select.html',
            scope: {
                item : '=',
                getFolder : '=',
                selectFolder : '=',
                itemIsSelected : '='
            },
            link: function($scope, $element, $attr) {
                $scope.data = {
                    mimeIcon: undefined,
                    imageUrl: undefined,
                    name: undefined
               };

                $scope.$watch('item', function(newValue) {
                    if(newValue) {
                        // if it's an image or it has a custom thumbnail
                        if (fileTypes.isImage(newValue.mime) || newValue.folder_image_url) { 
                            $scope.data.imageUrl = newValue.absoluteUrl+'?thumb=1'; 
                            $scope.data.mimeIcon = undefined;
                        }
                        else if(newValue.type=='folder')
                        {
                            $scope.data.imageUrl = (newValue.class_type == 'normal') ?
                                'assets/images/specialfolders/folder.png' :
                                'assets/images/specialfolders/'+newValue.class_type+'.png';
                            $scope.data.mimeIcon = undefined;
                        } else {
                            $scope.data.mimeIcon = fileTypes.getMimeIcon(newValue.mime);
                            $scope.data.imageUrl = undefined;
                        }

                        var filteredName = newValue.name;
                        if (localStorage.get('stripExtensions', true) && newValue.type !== 'folder')
                        {
                            $scope.data.name = filteredName.replace(/\.[^/.]+$/, "");
                        } else {
                            $scope.data.name = filteredName;
                        }
                    }
                }, true);

                $scope.selectItem = function($event, item) {
                    if($scope.itemIsSelected(item)) {
                        $scope.getFolder(item.share_id);
                    } else {
                        $scope.selectFolder(item.id);   
                    }                 
                };

                $scope.preview = function($event, item) {
                    if($attr['linkShareableId']) {
                        // fixme: use $event, don't use selectedItems (as it should be *this* item.)
                        selectedItems.preview($attr['linkShareableId']);
                    } else {
                        // fixme: use $event, don't use selectedItems (as it should be *this* item.)
                        selectedItems.preview();
                    }
                };
            }
        };
    }])
    ;
})();
