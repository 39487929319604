/* global videojs */
(function () {
    'use strict';

    angular.module('app')
    .directive('homePage', ['utils', 'users', '$state', function(utils, users, $state) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/home.html',
            link: function($scope, el, attrs) {
                $scope.ad1 = utils.trustHtml(utils.getSetting('ad_home_slot_1'));
                
                // check to see if the user is already logged in; if they are, redirect them (this also uses the remember token)
                users.loggedIn().then(function success(data) {
                    $state.go('dashboard.folders');
                }, function failure(data) {
                    // nothing
                });
                
                // load the player
                var currentPlayer = videojs('#my-player', {
                  controls: true,
                  autoplay: false,
                  preload: 'auto',
                  fluid: true
                });
                
                currentPlayer.ready(function() {
                  // console.log("Ready!");
                });

                // make sure to dispose of it when done.
                $scope.$on('$destroy', function() {
                  if(currentPlayer) {
                    currentPlayer.dispose();
                    currentPlayer = null;
                  }
                });
            }
        };
    }])

    .directive('newHomePage', ['utils', 'users', '$state', function(utils, users, $state) {
        return {
            restrict: 'E',
            templateUrl: 'assets/views/new_home.html',
            link: function($scope, el, attrs) {
                $scope.ad1 = utils.trustHtml(utils.getSetting('ad_home_slot_1'));
                
                // check to see if the user is already logged in; if they are, redirect them (this also uses the remember token)
                users.loggedIn().then(function success(data) {
                    $state.go('dashboard.folders');
                }, function failure(data) {
                    // nothing
                });
                
                // // load the player
                // var currentPlayer = videojs('#my-player', {
                //   controls: true,
                //   autoplay: false,
                //   preload: 'auto',
                //   fluid: true
                // });
                
                // currentPlayer.ready(function() {
                //   // console.log("Ready!");
                // });

                // // make sure to dispose of it when done.
                // $scope.$on('$destroy', function() {
                //   if(currentPlayer) {
                //     currentPlayer.dispose();
                //     currentPlayer = null;
                //   }
                // });
            }
        };
    }]);

}());
