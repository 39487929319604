(function () {
	'use strict';

	angular.module('app')
		.directive('shNestedSelect', ['$rootScope', function ($rootScope) {
			return {
				restrict: 'E',
				template: '<div><nested-select ng-if="data.options.length" text="text" caption="caption" options="options" value-changed="udpateSelectedPrivacyOption($value)" on-close="doneSelection($event)" float-bottom="floatBottom" full-width="fullWidth" full-width100="fullWidth100" multiple="multiple" block-events="blockEvents" empty-text="emptyText" remove-borders="removeBorders" collapsible show-selection allow-select-all ng-class="{\'full-width\': fullWidth, \'remove-borders\': removeBorders, \'full-width100\': fullWidth100}"></nested-select></div>',
				// old Conflict resolve
				// template: '<div><nested-select ng-if="data.options.length" text="text" caption="caption" options="options" value-changed="udpateSelectedPrivacyOption($value)" on-close="doneSelection($event)" float-bottom="floatBottom" full-width="fullWidth" full-width100="fullWidth100" multiple="multiple" block-events="blockEvents" empty-text="emptyText" collapsible show-selection allow-select-all ng-class="{\'full-width\': fullWidth, \'full-width100\': fullWidth100}"></nested-select></div>',
				scope: {
					text: '=?',
					caption: '=?',
					selectedPrivacy: '=?',
					selectedUser: '=?',
					options: '=',
					defaultSelection: '=?',
					post: '=?',
					onDone: "&?",
					userList: '=?',
					privacyList: '=?',
					floatBottom: '=?',
					fullWidth: '=?',
					fullWidth100: '=?',
					privacyChanged: '=?',
					multiple: '=?',
					nonEmpty: '=?',
					blockEvents: '=?',
					emptyText: '=?',
					normalDropdown: '=?',
					removeBorders: '=?'
				},
				link: function ($scope, el, attrs) {
					var myHiveSelected = -1;
					var privacyType = '';

					$scope.data = {
						options: [],
					};

					$scope.blockEvents = $scope.blockEvents ? $scope.blockEvents : false;
					$scope.floatBottom = $scope.floatBottom ? $scope.floatBottom : false;
					$scope.fullWidth = $scope.fullWidth ? $scope.fullWidth : false;
					$scope.fullWidth100 = $scope.fullWidth100 ? $scope.fullWidth100 : false;
					$scope.privacyChanged = $scope.privacyChanged ? $scope.privacyChanged : false;
					$scope.multiple = $scope.multiple ? $scope.multiple : false;
					$scope.nonEmpty = $scope.nonEmpty ? $scope.nonEmpty : false;
					$scope.emptyText = $scope.emptyText ? $scope.emptyText : false;
					$scope.normalDropdown = $scope.normalDropdown ? $scope.normalDropdown : false;
					$scope.removeBorders = $scope.removeBorders ? $scope.removeBorders : false;

					$scope.doneSelection = function () {
						if ($scope.selectedPrivacy.length || (myHiveSelected == -1 && $scope.selectedUser.length)) {
							$scope.privacyChanged = true;
						} else {
							$scope.privacyChanged = false;
						}

						var myHive = checkAllUserForMyHive();

						if (myHive && $scope.selectedUser.length) {
							$scope.selectedUser.splice(0);
						}

						if ($scope.nonEmpty && !$scope.privacyChanged) {
							if ($scope.defaultSelection) {
								selectPrivacyByOptionId($scope.options, $scope.defaultSelection);
								$rootScope.$broadcast('options.changed', $scope.options);
							}
						}

						if (typeof $scope.onDone === "function") {
							$scope.onDone();
						}
					};

					function removeOption(type, option) {
						var idx = type.indexOf(option.id);
						if (idx != -1) {
							type.splice(idx, 1);
							if (option.type == 'default' && option.user == false) {
								myHiveSelected = -1;
							}
						}
					}

					function addOption(type, option) {
						var idx = type.indexOf(option.id);
						if (idx == -1) {
							if (option.type == 'default' && option.user == false) {
								myHiveSelected = option.id;
							}
							type.push(option.id);
							privacyType = option.type;
						}
					}

					function checkAllUserForMyHive() {
						var selectedOpt = false;
						$scope.options.forEach(function (opt) {
							if ('children' in opt && opt.children.length) {
								var allSelected = true;
								opt.children.forEach(function (child) {
									if ($scope.selectedUser.indexOf(child.id) == -1) {
										allSelected = false;
									}
								});

								if (allSelected) {
									selectedOpt = opt;
								}
							}
						});
						return selectedOpt;
					}

					function clearOptions() {
						$scope.selectedUser.splice(0);
						$scope.selectedPrivacy.splice(0);
						myHiveSelected = -1;
					}

					$scope.udpateSelectedPrivacyOption = function (option) {
						var idx;
						if (option.selected) {
							if (option.type != privacyType || !$scope.multiple) {
								clearOptions();
							}

							if (option.user) {
								addOption($scope.selectedUser, option);
								var myHive = checkAllUserForMyHive();
								if (myHive) {
									addOption($scope.selectedPrivacy, myHive);
								}
							} else {
								addOption($scope.selectedPrivacy, option);
								if ('children' in option && option.children.length && $scope.multiple) {
									option.children.forEach(function (child) {
										addOption($scope.selectedUser, child);
									});
								}
							}
						} else {
							if (option.user) {
								removeOption($scope.selectedUser, option);
								if (myHiveSelected != -1) {
									removeOption($scope.selectedPrivacy, { id: myHiveSelected, type: 'default', user: false });
								}
							} else {
								removeOption($scope.selectedPrivacy, option);
								if ('children' in option && option.children.length) {
									option.children.forEach(function (child) {
										removeOption($scope.selectedUser, child);
									});
								}
							}
						}
					};

					function selectPrivacyByOptionId(type, optId) {
						$scope.selectedPrivacy.splice(0);
						$scope.selectedUser.splice(0);
						type.forEach(function (option) {
							if (option.id == optId) {
								addOption($scope.selectedPrivacy, option);
								option.selected = true;
							}
						});
						return type;
					}

					function loadSelectedPrivacy(type, checkedSelectedUser, allChildSelected) {
						checkedSelectedUser = checkedSelectedUser || false;
						allChildSelected = allChildSelected || false;
						$scope.selectedPrivacy.splice(0);
						type.forEach(function (option) {
							var idx = $scope.privacyList.indexOf(option.id != 'private' ? option.id : 0);
							if (idx != -1) {
								option.selected = true;
								addOption($scope.selectedPrivacy, option);
								if ('children' in option && option.type == 'default') {
									if (!checkedSelectedUser) {
										allChildSelected = checkSelectedUser(option);
									}

									if (!allChildSelected) {
										option.selected = false;
										removeOption($scope.selectedPrivacy, option);
									} else {
										myHiveSelected = option.id;
										option.children.forEach(function (child) {
											child.selected = true;
											addOption($scope.selectedUser, child);
										});
									}
								}
							} else {
								option.selected = false;
							}
						});
					}

					function loadSelectedUser(type, loadPrivacy) {
						var allChildSelected = true;
						loadPrivacy = loadPrivacy || false;
						$scope.selectedUser.splice(0);
						type.forEach(function (option) {
							if (!option.user && 'children' in option && option.children.length && $scope.userList.length) {
								option.children.forEach(function (child) {
									var idx = $scope.userList.indexOf(child.id);
									if (idx != -1) {
										child.selected = true;
										addOption($scope.selectedUser, child);
									} else {
										allChildSelected = false;
										child.selected = false;
									}
								});
							}
						});

						if (loadPrivacy) {
							loadSelectedPrivacy(type, true, allChildSelected);
						}
					}

					function checkSelectedUser(option) {
						var allSelected = true;
						option.children.forEach(function (child) {
							if (!child.selected) {
								allSelected = false;
							}
						});
						return allSelected;
					}

					$scope.$watch('options', function () {
						if ($scope.options) {
							if ($scope.defaultSelection) {
								selectPrivacyByOptionId($scope.options, $scope.defaultSelection);
							} else if ($scope.privacyList && $scope.privacyList.length) {
								loadSelectedUser($scope.options, true);
							}
							$scope.data.options = angular.copy($scope.options);
						}
					});

					$scope.$on('storypage.options.changed', function (event, options) {
						if (!$scope.blockEvents) {
							if (!$scope.defaultSelection) {
								loadSelectedUser(options, true);
							}
							$rootScope.$broadcast('options.changed', options);
						}
					});
				}
			};
		}]);
}());