angular.module('app').factory('templates', ['$http', '$rootScope', '$state', '$mdDialog', 'utils', 'files', function($http, $rootScope, $state, $mdDialog, utils, files) {

    var templates = {

        /**
         * All templates
         */
        all: [],

        /**
         * Paginate all existing templates.
         *
         * @returns {promise}
         */
         paginate: function(params) {
            return $http.get('templates', {params: params});
        },

        /**
         * Create a new template with given info.
         *
         * @param {object} info
         * @returns {promise}
         */
        create: function(template_info) {
            return $http.post('templates', template_info);
        },

        /**
         * Delete given template from database.
         *
         * @param {array|object} values
         * @returns {*|void}
         */
        delete: function(values) {
            var promise;
          
            if (angular.isArray(values)) {
                promise = $http.post('templates/delete-all/', {templates:values});
            } else {
                promise = $http.delete('templates/'+values.id);
                values = [values];
            }

            return promise.then(function(response) {
                templates.all = templates.all.filter(function(template) {
                    return values.indexOf(template) === -1;
                });
                utils.showToast(response.data);
            });
        },


        /**
         * Update the template
         *
         * @returns {string|undefined}
         */
        updateTemplate: function(template, id) {
            var templateId  = id || this.current.id; 
            return $http.put($rootScope.baseUrl+'templates/'+templateId, template);
        },

         /**
         * Get the email wrapper with 
         *
         * @returns {string|undefined}
         */

        preview: function(body) {
            return $http.post($rootScope.baseUrl+'templates/preview', {body: body});
        },

         /**
         * Get the email wrapper 
         *
         * @returns {string|undefined}
         */

        editEmailWrapper: function(wrapper) {
            return $http.post($rootScope.baseUrl+'templates/edit-emailwrapper', {wrapper: wrapper});
        },

        closeModal: function() {
            $mdDialog.hide();
        }    

    };

    return templates;
}]);
