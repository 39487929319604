(function(){
    'use strict';

    angular.module('app').controller('FilesController', ['$scope', '$rootScope', 'utils', '$http', '$mdDialog', 'files', 'folders', 'deleter', 'fileTypes', function($scope, $rootScope, utils, $http, $mdDialog, files, folders, deleter, fileTypes) {
        $scope.files = files;
        $scope.allItems = [];
        $scope.fileTypes = fileTypes;
        $scope.utils = utils;

        //files search query
        $scope.search = { query: '' };

        $scope.selectedItems = [];

        $scope.isItemSelected = function(item) {
            return $scope.selectedItems.indexOf(item) > -1;
        };

        $scope.select = function(item) {
            var idx = $scope.selectedItems.indexOf(item);
            if (idx > -1) { 
                $scope.selectedItems.splice(idx, 1);
            }
            else { 
                $scope.selectedItems.push(item);
            }
        };

        $scope.deleteFiles = function() {

            deleter.delete($scope.selectedItems);

            setTimeout(function() {
                $scope.paginate($scope.params);
            }, 100);

            $scope.selectedItems = [];
        };

        $scope.toggleAllPhotos = function() {

            //all items already selected, deselect all
            if ($scope.selectedItems.length === $scope.allItems.length) {
                $scope.selectedItems = [];
            }

            //all items arent selected, copy all photos array to selected items
            else {
                $scope.selectedItems = $scope.allItems.slice();
            }
        };

        var uri = '';
        if (utils.stateIs('admin.files')) {
            uri = 'files';
        } else if (utils.stateIs('admin.folders')) {
            uri = 'folders';
        }

        $scope.paginate = function(params) {
            if ($scope.itemsAjaxInProgress) {
                return;
            }

            if ( ! params) {
                params = {};
            }

            $scope.itemsAjaxInProgress = true;

            params.all = true;

            $http.get($rootScope.baseUrl+uri, { params: params }).then(function(response) {
                $scope.items = response.data.data;
                $scope.totalItems = response.data.total;

                $scope.itemsAjaxInProgress = false;
            }, function(error) {

            });
        };

        $scope.paginate($scope.params);
    }]);
}());