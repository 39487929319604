(function () {
    'use strict';

    angular.module('pixie.dashboard.left', ['ngMaterial'])
        .directive('leftDashboardColumn', ['$mdSidenav', '$mdMedia', '$state', 'utils', '$rootScope', 'dialogManager', 'api', 'rooms', function ($mdSidenav, $mdMedia, $state, utils, $rootScope, dialogManager, api, rooms) {
            return {
                restrict: 'E',
                templateUrl: 'leftDashboardColumn.html',
                replace: true,
                scope: {
                },
                link: function ($scope) {
                    $scope.data = {
                        modeSelected: -1,
                        name: '',
                        primaryBarShow: false,
                        modes: ['dashboard.folders', 'dashboard.story', 'dashboard.family', 'dashboard.buzz.timeline', 'dashboard.buzz', 'dashboard.buzz.chat', 'dashboard.buzz.video', 'dashboard.rooms', 'dashboard.story.view', 'dashboard.story.current'],
                        category: '',
                        scrollAuto: false,
                        isBuzzRoot: false,
                    };

                    $scope.close = function () {
                        $mdSidenav('left').close();
                    };

                    function loadModeName(stateName) {
                        $scope.data.category = '';
                        $scope.data.scrollAuto = false;
                        $scope.data.isBuzzRoot = false;

                        switch (stateName) {
                            case 'dashboard.folders':
                            case 'dashboard.recent':
                            case 'dashboard.trash':
                            case 'dashboard.favorites':
                                if (stateName == 'dashboard.folders') {
                                    $scope.data.primaryBarShow = true;
                                } else {
                                    $scope.data.primaryBarShow = false;
                                    $scope.data.category = stateName.split('.')[1];
                                }
                                $scope.data.modeSelected = 0;
                                $scope.data.name = utils.trans('myStorage', true);
                                break;
                            case 'dashboard.story':
                                $scope.data.modeSelected = 1;
                                $scope.data.name = utils.trans('myStory', true);
                                $scope.data.scrollAuto = true;
                                break;
                            case 'dashboard.story.view':
                                $scope.data.modeSelected = 8;
                                $scope.data.name = utils.trans('myStory', true);
                                $scope.data.scrollAuto = true;
                                break;
                            case 'dashboard.story.current':
                                $scope.data.modeSelected = 9;
                                $scope.data.name = utils.trans('myStory', true);
                                $scope.data.scrollAuto = true;
                                break;
                            case 'dashboard.hives':
                            case 'dashboard.hives.details':
                            case 'dashboard.hives.details.member':
                            case 'dashboard.hives.details.member.files':
                            case 'dashboard.hives.details.member.stories':
                            case 'dashboard.hives.details.member.post':
                                $scope.data.modeSelected = 2;
                                $scope.data.name = utils.trans('family', true);
                                $scope.data.scrollAuto = true;
                                break;
                            case 'dashboard.buzz.timeline':
                            case 'dashboard.buzz.timeline.date':
                                $scope.data.modeSelected = 3;
                                $scope.data.name = utils.trans('post.myTimeline', true);
                                break;
                            case 'dashboard.buzz':
                                $scope.data.modeSelected = 4;
                                $scope.data.name = utils.trans('buzz.theBuzz', true);
                                $scope.data.isBuzzRoot = true;
                                break;
                            case 'dashboard.buzz.chat':
                                $scope.data.modeSelected = 5;
                                $scope.data.name = utils.trans('buzz.chat', true);
                                break;
                            case 'dashboard.buzz.video':
                                $scope.data.modeSelected = 6;
                                $scope.data.name = utils.trans('buzz.videochat', true);
                                break;
                            case 'dashboard.rooms':
                                $scope.data.modeSelected = 7;
                                $scope.data.name = utils.trans('rooms.title', true);
                                break;
                            default:
                                $scope.data.modeSelected = -1;
                                $scope.data.name = '';
                                break;
                        }

                        if (stateName != 'dashboard.rooms') {
                            rooms.disconnectSession();
                        }
                    }

                    $scope.isMyModeSelected = function (mode) {
                        if ($scope.data.modeSelected == -1) {
                            return false;
                        }

                        if ($scope.data.modes[$scope.data.modeSelected].indexOf(mode) > -1) {
                            return true;
                        }
                    };

                    $scope.$on('$stateChangeSuccess', function (e, toState, params) {
                        loadModeName(toState.name);
                    });

                    loadModeName($state.current.name);

                    $scope.$on('buzz.tab.switched', function (e, tabOption) {
                        var modeName = 'dashboard.buzz';
                        if (tabOption == 0) {
                            modeName = 'dashboard.buzz.timeline';
                        } else if (tabOption == 1) {
                            modeName = 'dashboard.buzz.chat';
                        } else if (tabOption == 2) {
                            modeName = 'dashboard.buzz.video';
                        }
                        loadModeName(modeName);
                    });

                    $scope.showModeIntro = function () {
                        if ($scope.data.modeSelected == -1) {
                            return false;
                        }

                        var text = '';
                        if ($scope.data.modeSelected == 4) {
                            //Content number for dashboard buzz
                            text = utils.getSetting('introContentMode_' + 9);
                        } else if ($scope.data.modeSelected == 5) {
                            //Content number for dashboard chat
                            text = utils.getSetting('introContentMode_' + 10);
                        } else if ($scope.data.modeSelected == 6) {
                            //Content number for dashboard videochat
                            text = utils.getSetting('introContentMode_' + 11);
                        } else if ($scope.data.modeSelected == 7) {
                            //Content number for dashboard rooms
                            text = utils.getSetting('introContentMode_' + 11);
                        } else {
                            text = utils.getSetting('introContentMode_' + $scope.data.modeSelected);
                        }

                        text = text || '<p>Content to be added soon</p>';
                        dialogManager.showModeIntroDialog($scope.data.modeSelected, $scope.data.name, text);
                    };

                    $scope.loadHivePage = function () {
                        if ($scope.data.modeSelected != 2) {
                            return;
                        }
                        $state.go('dashboard.hives');
                        $rootScope.$broadcast('hives.reloaded');
                    };
                }
            };
        }]);
})();