(function () {
	'use strict';

	angular.module('app')
	.directive('roomsPage', ['$state','api', 'dialogManager', '$rootScope', '$mdSidenav', 'users', 'utils', '$stateParams', '$timeout', 'rooms', function($state, api, dialogManager, $rootScope, $mdSidenav, users, utils, $stateParams, $timeout, rooms) {
		return {
			restrict: 'E',
			scope: {
			},
			templateUrl: 'assets/views/pages/rooms-page.html',
			link: function($scope, el, attrs) {
				$scope.data = {
					loading: false,
					roomId: null
				};

				$scope.data.loading = false;

				if ($stateParams.roomId) {
					var regex = /^[0-9a-zA-Z]+$/;
					console.log("roomId before decodeURI " , $stateParams.roomId);
					var roomId = decodeURI($stateParams.roomId);
					if(roomId.match(regex)) {
						$scope.data.loading = true;
						console.log("ROOM ID is " , roomId);
						$scope.data.roomId = roomId;
						rooms.setRoomId($scope.data.roomId);
						console.log("sett room idd " , $scope.data.roomId);
						rooms.numOfVideos = 0;
						rooms.joinRoom($scope.data.roomId);
					}
				}

				$scope.$on('rooms.loaded', function() {
					console.log("APPPEEE");
			        $timeout(function() {
			        	$scope.$apply(function() {
			        		console.log("APEE 123");
			        		$scope.data.loading = false;
			    		});
			    	});
				});

				$scope.addVideoImage = function() {
					rooms.addVideoImage();
				};

				$scope.startRoom = function() {
					// dialogManager.showGetRoomDialog();
					var newRoomdId = Math.random().toString(36).slice(2);
					rooms.numOfVideos = 0;
					$state.go('dashboard.rooms', { roomId: newRoomdId });
				};

				$scope.muteAudio = function() {
					rooms.muteAudio();
				};

				$scope.muteVideo = function() {
					rooms.muteVideo();
				};

				$scope.leaveRoom = function() {
					console.log(" I AM leaVE rOOm");
					rooms.leaveRoom();
				};

				$scope.loadChatInviteDialog = function() {
					dialogManager.showChatInviteDialog();
				};

				// $scope.addSound = function() {
				// 	var audioElement = new Audio($rootScope.baseUrl + 'assets/audio/ding.mp3');
				// 	audioElement.play();
				// };

				$scope.$on('play.chime', function() {
					var audioElement = new Audio($rootScope.baseUrl + 'assets/audio/ding.mp3');
					audioElement.play();
				});
			}
		};
	}]);
}());
