(function(){ 
    'use strict';

    angular.module('pixie.dashboard')
    .factory('lockables', ['$rootScope', 'utils', '$q', 'api', function($rootScope, utils, $q, api) {
        
        function unlock(shareId, type, password, forever, showError) {
            var showErr = (showError==undefined ? true : showError);
            return $q(function(resolve, reject) {
                api.file.unlock(shareId, type, password, forever).then(function success(data) {
                    $rootScope.$broadcast('activity.happened', 'unlocked', type, data);
                    resolve(data);
                }, function error(data) {
                    if(showErr) {
                        utils.showToast(data);
                    }
                    reject(data);
                });
            });
        }
        
        return {
            lock: function(item, password) {
                return $q(function(resolve, reject) {
                  api.file.lock(item.share_id, item.type, password).then(function success(data) {
                      $rootScope.$broadcast('activity.happened', 'locked', item.type, data);
                      resolve(data);
                  }, function error(data) {
                      utils.showToast(data);
                      reject(data);
                  });
                });
            },

            unlock: function(item, password) {
                return unlock(item.share_id, item.type, password, false);
            },

            removeLock: function(item, password) {
                return unlock(item.share_id, item.type, password, true);
            },
            
            tryRemoveLock: function(item) {
                return unlock(item.share_id, item.type, 't', true, false);
            }
        };
    }]);

}());