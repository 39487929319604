(function () {
	'use strict';

	angular.module('app')
		.directive('timelinePage', ['api', 'users', 'utils', '$state', '$rootScope', 'dialogManager', function (api, users, utils, $state, $rootScope, dialogManager) {
			return {
				restrict: 'E',
				templateUrl: 'assets/views/pages/timeline-page.html',
				link: function ($scope, el, attrs) {
					var dates = [];
					var defaultFilters = [{
						id: "all",
						text: "All Posts",
						type: "all",
						selected: false,
						collapsed: true,
						user: false,
					},
					{
						id: "shared",
						text: "Shared To Me Posts",
						type: "shared",
						selected: false,
						collapsed: true,
						user: false,
					}];

					$scope.data = {
						loading: false,
						users: users,
						content: '',
						posting: false,
						exclude_ids: [],
						initialLoadDone: false,
						loaded: false,
						loading_more: true,
						selectedPrivacy: [],
						selectedUser: [],
						selectedFilterPrivacy: [],
						selectedFilterUser: [],
						options: [],
						type: 'all',
						postType: 'private',
						defaultOptions: [],
						filterOptions: [],
						viewingText: 'You are viewing: ',
						postText: '',
						info: {},
						filterFloatBottom: false
					};

					function loadOptions(options) {
						options.forEach(function (opt) {
							opt.text = opt.name;
							opt.user = false;
							if ('users' in opt) {
								opt.children = [];
								opt.collapsed = true;
								opt.users.forEach(function (user) {
									var child = {};
									child.id = user.id;
									child.text = user.name;
									child.user = true;
									child.type = opt.type;
									opt.children.push(child);
								});
							}
						});
						return options;
					}

					api.me.privacyOptions().then(function (options) {
						$scope.data.defaultOptions = loadOptions(options);
						$scope.data.options = loadOptions(options);
						$scope.data.filterOptions = angular.copy(defaultFilters);
						var opts = angular.copy(loadOptions(options));
						$scope.data.filterOptions = $scope.data.filterOptions.concat(opts);
						$scope.data.initialLoadDone = true;
						$scope.onFilterSelected();
					});

					$scope.openAddImageToPost = function () {
						dialogManager.showUploadImageDialog().then(function (data) {
							if (data) {
								$scope.data.info = data;
							}
						}, function () {

						});
					};

					$scope.openAddVideoToPost = function () {
						dialogManager.showUploadVideoDialog().then(function (data) {
							if (data) {
								$scope.data.info = data;
							}
						}, function () {

						});
					};

					$scope.load_posts = function (type, isScrollToDate, user) {
						isScrollToDate = isScrollToDate || false;
						user = user || 0;
						$scope.data.loading_more = false;
						api.resources.post.get({ 'page': $scope.data.page, 'type': type, 'exclude_ids[]': $scope.data.exclude_ids, 'user': user }, function (response) {
							$scope.data.loading = false;
							response.data.forEach(function (post) {
								post.showDate = false;
								if (dates.indexOf(post.created_at.split('T')[0]) == -1) {
									post.showDate = true;
									dates.push(post.created_at.split('T')[0]);
								}
							});
							$scope.data.posts = $scope.data.posts.concat(response.data);
							$scope.data.page = response.current_page + 1;
							$scope.data.load_more = response.current_page + 1 <= response.last_page ? true : false;
							$scope.data.loaded = true;
							$scope.data.loading_more = true;
							if (isScrollToDate) {
								loadOnLeftPanelSelect(isScrollToDate, true);
							}
							if (!$scope.data.posts.length) {
								if (!$scope.data.filterFloatBottom) {
									$scope.data.filterFloatBottom = true;
									$rootScope.$broadcast('options.floatbottom.changed', true);
								}
							} else {
								if (!$scope.data.filterFloatBottom) {
									$scope.data.filterFloatBottom = false;
									$rootScope.$broadcast('options.floatbottom.changed', false);
								}
							}
						});
					};

					$scope.isObjectEmpty = function (obj) {
						return Object.keys(obj).length === 0;
					};

					$scope.submitPost = function () {
						$scope.data.posting = true;
						var type, newPostAdd;
						api.file.createPost($scope.data.content, $scope.data.selectedPrivacy, $scope.data.selectedUser).then(function (post) {
							utils.showToast(utils.trans('post.filePostedSuccessful', true));
							if ($scope.data.selectedFilterPrivacy.length) {
								type = $scope.data.selectedFilterPrivacy[0];
								if($scope.data.selectedPrivacy.indexOf(type) !== -1){
									newPostAdd = true;
								}
							}
							var selectedUsersInBothFilters = $scope.data.selectedUser.filter(function (element) {
								return $scope.data.selectedFilterUser.includes(element);
							});
							if(selectedUsersInBothFilters.length){
								newPostAdd = true;
							}
														
							$scope.data.posts.unshift(post);
							$scope.data.exclude_ids.push(post.id);

							$scope.data.content = '';
							var newOptions = $scope.data.defaultOptions;
							$scope.data.options = angular.copy(newOptions);
							$scope.data.posting = false;
							$rootScope.$broadcast('timelinepost.reloadpanel', true);
						}, function () {
							// handle failed case
						}).finally(function () {
							$scope.data.info = {};
						});
					};

					$scope.removePost = function (post) {
						$scope.postPrivacyChanged(post, true);
						api.resources.post.delete({ id: post.id });
					};

					$scope.closePrivacyFilterOptions = function () {
						$scope.onFilterSelected();
					};

					$scope.postPrivacyChanged = function (post, toRemove) {
						toRemove = toRemove || false;
						if (!toRemove && post.privacy.length && ($scope.data.type == 'all' || post.privacy.indexOf($scope.data.type) !== -1)) {
							return;
						}

						var index = 0;
						for (var i = 0; i < $scope.data.posts.length; i++) {
							if ($scope.data.posts[i].id == post.id) {
								index = i;
								break;
							}
						}
						$scope.data.posts.splice(index, 1);
						$rootScope.$broadcast('timelinepost.reloadpanel');
					};

					$scope.onFilterSelected = function () {
						$scope.data.loading = true;
						$scope.data.page = 1;
						$scope.data.load_more = false;
						$scope.data.posts = [];
						$scope.data.loaded = false;
						$scope.data.exclude_ids = [];
						createLoadPostCall();
						dates = [];
						$rootScope.$broadcast('timeline.tab.changed', $scope.data.type);
					};

					$scope.load_more = function () {
						createLoadPostCall();
					};

					function createLoadPostCall() {
						if ($scope.data.selectedFilterPrivacy.length || $scope.data.selectedFilterUser.length) {
							var type;
							var user = 0;
							if ($scope.data.selectedFilterPrivacy.length && $scope.data.selectedFilterPrivacy.length == 1 && !$scope.data.selectedFilterUser.length) {
								type = $scope.data.selectedFilterPrivacy[0];
							} else if ($scope.data.selectedFilterUser.length && $scope.data.selectedFilterUser.length == 1) {
								type = $scope.data.selectedFilterUser[0];
								user = 1;
							}
							$scope.load_posts(type, false, user);
						} else {
							$scope.load_posts($scope.data.type);
						}
					}

					$scope.getSelectedFilterText = function () {
						if (!$scope.data.type) {
							return utils.trans('genericError', true);
						} else {

						}
					};

					$rootScope.$on('left-nav.selectDate', function (evt, data) {
						loadOnLeftPanelSelect(data.date, data.more);
					});

					$scope.$on('post.media.info', function (evt, data) {
						$scope.data.info = data;
					});

					function loadOnLeftPanelSelect(date, afterLoadMore) {
						afterLoadMore = afterLoadMore || false;
						if ($('.date-panel.' + date + ':not(.ng-hide)').length) {
							console.log("load on left panel select ", 1);
							$(".files-view").animate({ scrollTop: $(".files-view").scrollTop() + $('.date-panel.' + date + ':not(.ng-hide)').offset().top - 168 }, "slow");
							if (afterLoadMore) {
								console.log("load on left panel select ", 2);
								//TODO: THIS NEEDS TO WORK ON PAGINATION
								$rootScope.$broadcast('timelinepost.reloadpanel', true);
							}
						} else {
							console.log("load on left panel select ", 3);
							$(".files-view").animate({ scrollTop: $(".files-view").scrollTop() + $('.load_more').offset().top - 168 }, "slow");
							$scope.load_posts($scope.data.type, date);
						}
						// console.log("my url was aaa ", url);
						// if ('URLSearchParams' in window) {
						// 	console.log("po");
						// 	var searchParams = new URLSearchParams(window.location.search)
						// 	searchParams.set("foo", "bar");
						// 	var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
						// 	history.pushState(null, '', newRelativePathQuery);
						// }
						// history.pushState(null, '', newRelativePathQuery);
						// window.history.pushState({}, null, url);
					}
				}
			};
		}]);
}());
